const timestamp = new Date().getTime();

const monitoringAPI = async (
    status: Number,
    level: String,
    sublevel: String,
    message: String,
    bookingLoc: String,
    apiEndpoint: String,
    body: any
) => {
    const uniqueId = localStorage.getItem("uniqueCode");

    const req = {
        status,
        level,
        sublevel,
        message,
        bookingLoc,
        uniqueId,
        applicationId: "SITE",
        apiEndpoint,
        boxId: '',
        paternId: "Bondinho",
        bodyInfo: JSON.stringify(body),
        timestamp,
    }

    try {
        const resp: any = await fetch(
            `${process.env.REACT_APP_SERVER_MONITORING_API}`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(req),
            }
        );

        if (resp.status === 200) {
            const authResp: any = await resp.json();

            return authResp;
        } else {
            const error = await resp.json();
            throw new Error(JSON.stringify(error));
        }
    } catch (error) {
        return error;
    }
}

export default monitoringAPI;
