import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState, useEffect } from 'react';


///alertType: string -> alert / info / sucess / danger

function SimpleAlertCoupon(props: any) {
    const [fade, setFade] = useState<boolean>(false);
    const delay = 2;

    useEffect(() => {
        //setShow(true);
        /* let timer1 = setTimeout(() => setShow(false), delay * 2000);
        let timer2 = setTimeout(() => setFade(true), 4000);

        return () => {
            clearTimeout(timer1);
            clearTimeout(timer2);
        }; */
    }, []);

    return (
        <>
            {props.show === true
                ?
                <div className="w-100 text-center">
                <div className={props.alertType + " alert bg-coupon d-flex align-items-center w-100"} /* style={{opacity: fade === true ? "0" : "1"}} */>
                    <div>
                        <p className='px-4'>{props.children}</p>
                    </div>
                    <div style={{ position: 'absolute', right: '.5rem', cursor: 'pointer' }} onClick={() => props.setShow(false)}>
                        <FontAwesomeIcon
                            icon={["fal", "times"]}
                            size="1x"
                        />
                    </div>
                </div>
                </div>
                :
                ''
            }
        </>
    );
}

export default SimpleAlertCoupon;