import React from "react";

function ExchangePolicyPT() {
    return (
        <div className="bg-exchange-policy p-2">
            <h4 className="text-center mb-2 mb-md-4">Lojas Parque Bondinho Pão de Açúcar</h4>
            <div>
                <h6>Chilli Beans</h6>
                <p className="mb-1">Troca em qualquer loja do Brasil mediante apresentação do CPF utilizado na compra.</p>
                <p className="mb-0">Entre em contato com a loja pelo número: +55 21 2541-6446 ou consulte os endereços no site: <a href="https://loja.chillibeans.com.br" target="_blank" rel="noreferrer">loja.chillibeans.com.br</a>.</p>
            </div>
            <div>
                <h6>Espaço Rubro Negro</h6>
                <p className="mb-1">Troca em qualquer loja do Brasil mediante apresentação de NF e etiqueta na peça.</p>
                <p className="mb-0">Entre em contato com a loja pelo número: +55 21 99085-2145 ou consulte os endereços no site: <a href="https://www.espacorubronegro.com.br" target="_blank" rel="noreferrer">www.espacorubronegro.com.br</a>.</p>
            </div>
            <div>
                <h6>Estúdio Alma</h6>
                <p className="mb-0">Troca na loja mediante apresentação do produto com a etiqueta da loja, o contato deve ser feito através dos números: +55 21 98104-0303 ou pelo Instagram: <a href="https://www.instagram.com/sigaestudioalma/" target="_blank" rel="noreferrer">@sigaestudioalma</a>.</p>
            </div>
            <div>
                <h6>Havaianas</h6>
                <p className="mb-1">Troca em qualquer loja Havaianas do Brasil mediante apresentação do cupom de troca ou Nota Fiscal.</p>
                <p className="mb-0">Consulte os endereços no site: <a href="https://www.havaianas.com.br" target="_blank" rel="noreferrer">www.havaianas.com.br</a>.</p>
            </div>
            <div>
                <h6>Palhas de Buriti</h6>
                <p className="mb-1">Trocas somente por defeito de fabricação e no prazo de até 7 dias após a compra em qualquer loja Palhas de Buriti mediante apresentação de NF.</p>
                <p className="mb-0">Entre em contato com a loja pelo número: +55 21 98354-1119 ou através do e-mail: <a href="mailto:atendimentopbrj@gmail.com">atendimentopbrj@gmail.com</a> ou pelo Instagram: <a href="https://www.instagram.com/palhasburitirj/" target="_blank" rel="noreferrer">@palhasburitirj</a>.</p>
            </div>
            <div>
                <h6>Pierim</h6>
                <p className="mb-0">Troca na loja através do contato com a gerência pelo telefone: +55 21 97197-9878 ou pelo e-mail: <a href="mailto:pierim.rio@pierim.com.br">pierim.rio@pierim.com.br</a>.</p>
            </div>
            <div>
                <h6>Oazis</h6>
                <p className="mb-1">Troca em qualquer loja Oazis mediante apresentação do cupom fiscal. Entre em contato com a loja pelo número: +55 21 9 7375-9821 ou e-mail: <a href="mailto:oazis.rj@gmail.com" target="_blank" rel="noreferrer">oazis.rj@gmail.com</a></p>
            </div>
            <div>
                <h6>Sauer</h6>
                <p className="mb-1">Troca em até 30 dias em qualquer loja do Brasil mediante apresentação de NF.</p>
                <p className="mb-0">Para mais informações entre em contato com a loja pelo número: +55 21 2525-0000
                    ou consulte os endereços no site: <a href="https://www.sauer1941.com" target="_blank" rel="noreferrer">www.sauer1941.com</a>.</p>
            </div>
        </div>
    )
}

export default ExchangePolicyPT;