import React from 'react';
import '../../App.css';

import './Login.scss';
import AuthLogin from '../../components/Authenticated/AuthLogin/AuthLogin';
import PageDafault from '../../templates/PageDafault';
import { Helmet } from 'react-helmet';
import i18next from 'i18next';


function Login() {
    return (
        <>
            <Helmet>
                <html lang={i18next.language} />
                <title>Página de Login | Parque Bondinho</title>
                <meta name="description" content="Já possui login no no site do Parque Bondinho? Acesse nossa página efetuando login e tenha acesso a mais informações. Confira!" />
                <meta name="googlebot" content={process.env.REACT_APP_SERVER_ROBOTS} />

                {/* Essential META Tags */}
                <meta property="og:title" content="Página de Login | Parque Bondinho" />
                <meta property="og:type" content="TouristAttraction" />
                <meta property="og:image" content={`${process.env.REACT_APP_SERVER_URL}${process.env.REACT_APP_SERVER_LOGO}`} />
                <meta property="og:url" content={process.env.REACT_APP_SERVER_URL} />

                {/* Non-Essential, But Recommended */}
                <meta property="og:description" content="Já possui login no no site do Parque Bondinho? Acesse nossa página efetuando login e tenha acesso a mais informações. Confira!" />
                <meta property="og:site_name" content={process.env.REACT_APP_SERVER_NAME} />

                <meta name="robots" content={process.env.REACT_APP_SERVER_ROBOTS} />
                <link rel="canonical" href={process.env.REACT_APP_SERVER_URL} />
            </Helmet>

            <PageDafault>
                <div className='container'>
                    <div className='login-container'>
                        <div className="modal-new-account">
                            <h1 className="display-5 mb-4">Login</h1>
                            <AuthLogin />
                        </div>
                    </div>
                </div>
            </PageDafault>
        </>
    );
}

export default Login;