import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import api from '../../../../services/api';
import BootstrapTable, { SelectRowProps } from 'react-bootstrap-table-next';

import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.css";

import "./PartialEdit.scss";

export interface propPartialEdit {
    setPartialEditModal: any;
    currentReservation: any;
    setModalShow: any;
    setModalLog: any;
    setModalMessage: any;
}

const PartialEdit: React.FC<propPartialEdit> = ({
    setPartialEditModal,
    currentReservation,
    setModalShow,
    setModalLog,
    setModalMessage,
}: propPartialEdit) => {
    const [t] = useTranslation();
    const [item, setItem] = useState<any>(null);
    const [acessCode, setAcessCode] = useState<any>(null);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [selected, setSelected] = useState<number[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [loadingRefresh, setLoadingRefresh] = useState<boolean>(false);
    const [isLoad, setIsLoad] = useState<boolean>(false);

    const local = JSON.parse(localStorage.getItem('bpaSigAuth') || '{}');
    const useId = local?.user?.id;

    const getDetails = async () => {
        setItem(null);

        const token = localStorage.getItem('bpasiteAuth') || '{}';
        const config = {
            headers: { 'Authorization': `Bearer ${JSON.parse(token)?.token?.token}`, 'Content-Type': 'application/json', "ngrok-skip-browser-warning": "69420" },
        };
        try {
            const res = await api.get(`/api/NewReservations/GetNewReservationsByIdAsync/${currentReservation?.idNewItems}`, config);
            if (res.status !== 400) {
                setItem(res.data.data)
            }
        } catch (error: any) { console.log(error.response) }
    };

    useEffect(() => {
        getDetails();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const token = localStorage.getItem('bpasiteAuth') || '{}';
        const config = {
            headers: { 'Authorization': `Bearer ${JSON.parse(token)?.token?.token}`, 'Content-Type': 'application/json', "ngrok-skip-browser-warning": "69420" },
        };

        async function listVoucher() {
            try {
                const { data } = await api.post(
                    `/api/Reservations/ListAllItemEdit`,
                    {
                        idItem: currentReservation?.idNewItems,
                    },
                    config,
                );
                if (data.status !== 400) {
                    setLoadingRefresh(false);
                    setAcessCode(data.data.data);
                }
            } catch (error: any) {
                if (error.response.status === 401) {
                    window.location.href = window.location.origin + '/';
                }
            }
        }
        listVoucher();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [/* currentReservation, currentReservation?.idNewItems,  */isLoad]);

    /* useEffect(() => {
        const removeElem: any = document.querySelectorAll(
            '.bg-voucher-download tbody tr td input',
        );
        const removeElemMobile: any = document.querySelectorAll(
            '.bg-voucher-download #card-table tbody',
        );
        const trElem: any = document.querySelectorAll(
            '.bg-voucher-download tbody tr',
        );
        const trElemMobile: any = document.querySelectorAll(
            '.bg-voucher-download #card-table',
        );
        for (let i = 0; i < acessCode?.length; i++) {
            if (acessCode[i].texto.includes('Usado') || acessCode[i].texto.includes('Cancelado')) {
                trElem[i].style =
                    'pointer-events: none; border-color: #cecece !important;border-right: 1px solid #cecece !important; border-top: 1px solid #cecece !important; border-bottom: 1px solid #cecece !important; border-left: 2px solid #707070 !important; background-color: #cecece4f !important; transition: .3s;';
                trElemMobile[i].style =
                    'pointer-events: none; border-color: #cecece !important;border-right: 1px solid #cecece !important; border-top: 1px solid #cecece !important; border-bottom: 1px solid #cecece !important; border-left: 2px solid #707070 !important; background-color: #cecece4f !important; transition: .3s;';
                removeElem[i]?.remove();
                removeElemMobile[i]?.remove();
            }
        }
    }, [acessCode]); */

    function convertDate(cell: any) {
        return <>{cell?.split(' ')[0]}</>;
    }

    function convertToMobile(cell: any, row: any) {
        return (
            <>
                <div className='d-flex flex-column'>
                    <span className='mb-1'><strong>{t("myReservations.partial.date")}:</strong> {row.date?.split(' ')[0]}</span>
                    <span className='mb-1'><strong>Ticket:</strong> {row.qr}</span>
                    <span className='mb-1'><strong>{t("myReservations.partial.situacion")}:</strong> {row.texto}</span>
                </div>
            </>
        )
    }

    const columns = [
        { dataField: 'date', text: t("myReservations.partial.date"), formatter: convertDate },
        { dataField: 'qr', text: 'Ticket' },
        { dataField: 'texto', text: t("myReservations.partial.situacion") },
    ];

    const columnsMobile = [
        { dataField: 'date', text: 'Tickets', formatter: convertToMobile },
    ];

    function loadingFunc() {
        return (
            <>
                <div className="animated-background row-loading"></div>
            </>
        );
    }

    const loadingColumns = [
        {
            dataField: 'contasd',
            text: 'Carregando Tickets',
            formatter: loadingFunc,
        },
    ];

    const loadingProducts = [
        { contas: '' },
        { contas: '' },
        { contas: '' },
        { contas: '' },
        { contas: '' },
    ];
    /* [END] loading table */

    const rowClasses = (row: any) => {
        return '';
    };

    let acessCodes: any = [];

    const selectRow: SelectRowProps<any> = {
        mode: 'checkbox',
        clickToSelect: true,
        selected: selected,
        onSelect: (row: any, isSelect: any) => {
            if (isSelect === true) {
                acessCodes.push(row.qr);
            } else {
                let i: any = acessCodes.indexOf(row.qr);
                acessCodes.splice(i, 1);
            }
        },
        onSelectAll: (isSelect: any, rows: any) => {
            if (isSelect === true) {
                for (let i = 0; i < rows.length; i++) {
                    if (acessCode[i].texto.includes('Usado') !== true && acessCode[i].texto.includes('Cancelado') !== true) {
                        acessCodes.push(rows[i].qr);
                    }
                }
            } else {
                acessCodes.splice(0);
            }
        },
    };

    const UpdateAcessCodes = async () => {
        let qtdAdults: number = item.qrCodeAccessReservationDTO?.filter((elem: any) => { return (elem.status === 1 && !acessCodes.includes(elem.accessCode)) })?.filter((elem: any) => { return (elem.faixaLabel === 'Adult') })?.map((elem: any) => { return (1) })?.reduce((acum: any, value: any) => acum + value, 0)
        let qtdChild: number = item.qrCodeAccessReservationDTO?.filter((elem: any) => { return (elem.status === 1 && !acessCodes.includes(elem.accessCode)) })?.filter((elem: any) => { return (elem.faixaLabel === 'Child') })?.map((elem: any) => { return (1) })?.reduce((acum: any, value: any) => acum + value, 0)
        let qtdInfant: number = item.qrCodeAccessReservationDTO?.filter((elem: any) => { return (elem.status === 1 && !acessCodes.includes(elem.accessCode)) })?.filter((elem: any) => { return (elem.faixaLabel === 'Infant') })?.map((elem: any) => { return (1) })?.reduce((acum: any, value: any) => acum + value, 0)
        let qtdElders: number = item.qrCodeAccessReservationDTO?.filter((elem: any) => { return (elem.status === 1 && !acessCodes.includes(elem.accessCode)) })?.filter((elem: any) => { return (elem.faixaLabel === 'Student') })?.map((elem: any) => { return (1) })?.reduce((acum: any, value: any) => acum + value, 0)
        let qtdStudent: number = item.qrCodeAccessReservationDTO?.filter((elem: any) => { return (elem.status === 1 && !acessCodes.includes(elem.accessCode)) })?.filter((elem: any) => { return (elem.faixaLabel === 'Elders') })?.map((elem: any) => { return (1) })?.reduce((acum: any, value: any) => acum + value, 0)

        setLoading(true);
        setModalLog(null);
        setModalMessage("Carregando...")
        setModalShow(true);

        const token = localStorage.getItem('bpasiteAuth') || '{}';
        const config = {
            headers: { 'Authorization': `Bearer ${JSON.parse(token)?.token?.token}`, 'Content-Type': 'application/json', "ngrok-skip-browser-warning": "69420" },
        };
        
        try {
            if (acessCodes.length > 0) {
                const { data } = await api.post(
                    `${process.env.REACT_APP_SERVER_URL_API}/api/Checkout/EditEnviaCheckoutSite`,
                    {
                        "firstName": item.nome,
                        "lastName": item.sobrenome,
                        "phone": item.telefone,
                        "email": item.email,
                        "idTarif": item.idTarifario,
                        "internalNotes": item.internalNotesItem,
                        "IdNewItem": item.idItem,
                        "date": item.startTimeLocal,
                        "hora": item.startTimeLocal?.split("T")[1],
                        "idBooking": item.idBookings,
                        "BookingLoc": item.bookingLoc,
                        "Foreign": 1,
                        "Language": 1,
                        "newProduct": item.idCanal === 0 ? 1 : 0,
                        "adults": qtdAdults,
                        "childs": qtdChild,
                        "infants": qtdInfant,
                        "elders": qtdElders,
                        "students": qtdStudent,
                        // "pickup": undefined,
                        "accessCodeListToRemove": acessCodes
                    },
                    config,
                );
                if (data.status !== 400) {
                    if (data.data.log === 0 || data.data.log === '0') {
                        //setPartialEditModal(false);
                        setModalLog(data.data.log);
                        setModalMessage(data.data.texto);
                        setLoading(false);
                        logReprint(acessCodes);
                    } else if (data.data.log === 1 || data.data.log === '1') {
                        setModalLog(data.data.log);
                        setLoading(false);
                        setModalMessage(data.data.texto);
                    }
                }
            } else {
                setModalLog(1);
                setLoading(false);
                setModalMessage('Por favor, selecione um ticket a ser retirado da reserva.');
            }
        } catch (error: any) {
            setLoading(false);
            setModalLog(1);
        }
    };

    const getAccessCode = async (idItem: any) => {
        setLoadingRefresh(true);

        try {
            const { data } = await api.get(
                `/api/NewReservations/UpdateStatusSkidataReservationsByIdAsync/${idItem}`,
            );

            if (data.status !== 400) {
                setIsLoad(!isLoad);
            }
        } catch (error: any) { }
    };

    const logReprint = async (vouchers: any) => {
        // Log de Reimpressão
        if (currentReservation?.idNewItems === undefined) {
            currentReservation.idNewItems = currentReservation?.idItem;
        }

        try {
            const { data } = await api.post(`/api/Reservations/SavePrintLog`, {
                idItem: currentReservation.idNewItems,
                qr: vouchers,
                Uid: useId
            });
            if (data?.statusCode !== 400) {
                setTimeout(() => {
                    //setManagement('success');
                }, 5000);
            } else {
                //setManagement("error");
            }
        } catch (e) {
            //setManagement("error");
        }
    };

    if (acessCode !== null && loadingRefresh === false) {
        return (
            <>
            <div className="d-flex flex-column justify-content-center h-100">
                <Modal.Header className="justify-content-between align-items-center pb-3">
                    <div>
                        <h4 className="pt-1 text-primary title-reservation">
                            {t("myReservations.cancelPartial")}
                        </h4>
                    </div>
                    <FontAwesomeIcon
                        icon={['fal', 'times']}
                        className="mx-3 text-info"
                        size="lg"
                        data-dismiss="modal"
                        aria-label="Close"
                        onClick={() => {
                            setPartialEditModal(false);
                        }}
                    />
                </Modal.Header>
                <div className="bg-voucher-download">
                    <hr className="w-100 my-2" style={{ borderBottom: '1px solid' }}></hr>

                    <div className='mt-3'>
                        <small className='mb-0' style={{ fontWeight: 600 }}>{t("myReservations.subtitleCancel")}</small>
                    </div>

                    <div className="table-default mt-2">
                        {/* <FontAwesomeIcon
                            icon={['fad', 'redo-alt']}
                            className="reload-btn text-primary mr-2 d-md-block d-none"
                            onClick={() => {
                                getAccessCode(currentReservation?.idNewItems);
                            }}
                            size="1x"
                        /> */}
                        <div className='d-none d-md-block'>
                            <BootstrapTable
                                bootstrap4
                                keyField="qr"
                                selectRow={selectRow}
                                data={acessCode}
                                columns={columns}
                                rowClasses={rowClasses}
                            />
                        </div>

                        <div className='d-block d-md-none'>
                            <BootstrapTable
                                bootstrap4
                                keyField="qr"
                                selectRow={selectRow}
                                data={acessCode}
                                columns={columnsMobile}
                                rowClasses={rowClasses}
                            />
                        </div>
                    </div>
                </div>
                <Modal.Footer className="d-flex justify-content-end px-0 pb-0">
                    <button className="btn btn-outline-primary" onClick={() => { setPartialEditModal(false) }}>
                        Cancelar
                    </button>
                    <button className="btn btn-primary" onClick={UpdateAcessCodes}>
                        {loading === true ? <div className="load small white"></div> : 'Confirmar'}
                    </button>
                </Modal.Footer>
            </div>
            
            </>
        );
    } else {
        return (
            <div className="d-flex flex-column justify-content-center h-100">
                <Modal.Header className="justify-content-between align-items-center">
                    <div>
                        <h4 className="pt-1 text-primary title-reservation">
                            Cancelamento Parcial
                        </h4>
                    </div>
                    <FontAwesomeIcon
                        icon={['fal', 'times']}
                        className="mx-3 text-info"
                        size="lg"
                        data-dismiss="modal"
                        aria-label="Close"
                        onClick={() => {
                            setPartialEditModal(false);
                        }}
                    />
                </Modal.Header>
                <div className="bg-voucher-download">
                    <hr className="w-100 my-2" style={{ borderBottom: '1px solid' }}></hr>
                    <div className="table-default loading">
                        <BootstrapTable
                            bootstrap4
                            keyField="qr"
                            selectRow={selectRow}
                            data={loadingProducts}
                            columns={loadingColumns}
                            rowClasses={rowClasses}
                        />
                    </div>
                </div>
            </div>
        );
    }
};

export default PartialEdit;