import React from "react";
import i18next from "i18next";

import { useTranslation } from "react-i18next";
import AcessRulesPT from "./components/AcessRulesPT/AcessRulesPT";
import AcessRulesEN from "./components/AcessRulesEN/AcessRulesEN";
import AcessRulesES from "./components/AcessRulesES/AcessRulesES";

import './AcessRules.scss';

function AcessRules() {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [t] = useTranslation();

    return (
        <>
            <div className="py-3">
                {
                    i18next.language === "pt"
                        ?
                        <AcessRulesPT />
                        :
                        i18next.language === "en"
                            ?
                            <AcessRulesEN/>
                            :
                            i18next.language === "es"
                                ?
                                <AcessRulesES />
                                :
                                <AcessRulesPT />

                }
            </div>
        </>
    )
}

export default AcessRules;