/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from "react";

import i18next from "i18next";

import { Row, Col } from "react-bootstrap"
import Button from "@restart/ui/esm/Button";
import Modal from "react-bootstrap/Modal";
import { useTranslation } from "react-i18next";
import Helmet from 'react-helmet';
import Accordion from "react-bootstrap/Accordion";
import { Link as LinkRoll } from "react-scroll";
import "./Ticket.scss";
import api from "../../services/api";

import ProductSliderSigxSig from "../../components/Sliders/ProductSliderSigxSig/ProductSliderSigxSig";
//import ProductsReviews from "../../components/Products/ProductsReviews/ProductsReviews";
import CardTourSide from "../../components/CardTourSide/CardTourSide";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

//import SetCartItem from "../../components/Cart/SetCartItem";
import { useAppSelector, useAppDispatch } from "../../store/hooks";
import { addItemCart } from "../../store/cart";

import PageDafault from "../../templates/PageDafault";

import TagManager from 'react-gtm-module';

import C2rio from '../../assets/img/c2rio.svg';
import TicketOptionsSigxSig from "../../components/ProductOptions/TicketOptionsSigxSig";
import ProductsReviews from "../../components/ProductsReviews/ProductsReviews";

const TicketSigxSig = () => {
    const params = new URLSearchParams(window.location.search);

    const { t } = useTranslation();
    const lng = i18next.language === "pt" ? "BR" : i18next.language === "en" ? "EN" : i18next.language === "es" ? "ES" : 'BR';
    const [tourResponse, setTourResponse] = useState<any>("null");
    const [optionTour, setOptionTour] = useState<any>([]);
    const [cardTourSide, setCardTourSide] = useState<any>([]);
    const [alert, setAlert] = useState<any>(false);

    const cartBPA: any = JSON.parse(JSON.stringify(useAppSelector((state: any) => state.cart)));

    const dispatch = useAppDispatch();

    const url = window.location.href;
    const codeUrl = url.split("/");
    const slugUrl = codeUrl[codeUrl.length - 1].split('?')[0];

    const [langSelect, setLangSelect] = useState<any>(lng);
    const [count, setCount] = useState<number>(1);

    const addToCart = (itemOption: any, productCode: any) => {
        var repeatedItem: any = false; //impede de adicionar produto repetido no carrinho

        cartBPA.dados.forEach((elem: any) => {
            if (elem.productCode === productCode) {
                repeatedItem = true
            }
        });

        if (repeatedItem === true) {
            setAlert(true);
        } else {

            const item = {
                productName: tourResponse.productName,
                productNameBR: tourResponse.productNameBR,
                productNameEN: tourResponse.productNameEN,
                productNameES: tourResponse.productNameES,
                imagesBaseUrl: tourResponse.imagesBaseUrl,
                imgCart: tourResponse.images[0],
                price: itemOption?.tarif?.price,

                idTarif: itemOption.idTarif,
                idPickup: itemOption.idPickup,

                priceAdults: itemOption.priceAdults,
                priceChilds: itemOption.priceChilds,
                priceInfants: itemOption.priceInfants,
                priceElders: itemOption.priceElders,
                priceStudent: itemOption.priceStudent,
                priceGlobalPeople: itemOption.priceGlobalPeople,
                reservationSystem: tourResponse.reservationSystem,

                ranges: itemOption.ranges,
                hours: itemOption.hours,

                sellingType: itemOption.sellingType,
                adults: itemOption.adults,
                childs: itemOption.childs,
                infants: itemOption.infants,
                elders: itemOption.elders,
                student: itemOption.student,

                globalPeople: itemOption.globalPeople,
                totalPeople: itemOption.totalPeople,

                productType: 4,
                productCode: tourResponse.productCode,
                productModCode: itemOption.productModCode,
                productSlug: slugUrl,
                slugBR: tourResponse.slugBR,
                slugEN: tourResponse.slugEN,
                slugES: tourResponse.slugES,
                time: itemOption.time,
                date: itemOption.date,
                supplier: tourResponse.supplier,
                typePickup: itemOption.embarkingType,
                meetingPoint: itemOption.embarkingType === "0" ? tourResponse.eventAddress : tourResponse.eventAddress,
                pickupListId: itemOption.embarkingType === "0" ? 0 : itemOption.pickupListId,
                pickup: 0,

                discount: 0,
                customValueNet: 0,
                customValueSell: 0,
                goingSource: "null",
                commingSource: "null",
                latOrigem: "null",
                lngOrigem: "null",
                latDestino: "null",
                lngDestino: "null",
                cia: "null",
                voo: "null",
                smallSuitcase: 0,
                bigSuitcase: 0,
                internalNotes: " ",
                idVeiculo: 0,
                passengers: [],

                utmSource: params.get('utm_source') || undefined,
                utmMedium: params.get('utm_medium') || undefined,
                utmCampaign: params.get('utm_campaign') || undefined,
                utmId: params.get('utm_id') || undefined,
                utmTerm: params.get('utm_term') || undefined,

            }

            //SetCartItem(item);
            dispatch(addItemCart(item));
            window.location.href = window.location.origin + '/checkout';
        }
    }

    useEffect(() => {
        let idChannel: number = 1;
        async function getItems() {
            try {
                const { data } = await api.post(
                    `${process.env.REACT_APP_SERVER_URL_API}/api/Products/FetchSingleTicketAsync`,
                    {
                        productCode: "",
                        lang: lng,
                        Slug: `${slugUrl.replaceAll("%20", " ").replaceAll("%C3%A9", "é")}`,
                        idCanal: idChannel
                    }
                );
                if (data.status !== 400) {
                    // console.log(data)
                    if (data.statusCode === 204) {
                        if (count <= 3) {
                            if (data.data === null && (i18next.language === "pt-BR" || i18next.language === 'pt')) {
                                setCount(count + 1);
                                setLangSelect('EN');
                                i18next.changeLanguage('en');
                            } else if (data.data === null && i18next.language === "en") {
                                setCount(count + 1);
                                setLangSelect('ES');
                                i18next.changeLanguage('es');
                            } else {
                                setCount(count + 1);
                                setLangSelect('BR');
                                i18next.changeLanguage('pt');
                            }
                        } else {
                            window.location.href = "/404"
                        }
                    } else {
                        setTourResponse(data.data);
                        setOptionTour(data.data.modalities);
                        setCardTourSide(data.data.modalities);
                    }
                }
            } catch (error) { }
        }

        const config = {
            headers: { "ngrok-skip-browser-warning": "69420" },
        };

        async function getIdChannel() {
            try {
                const { data } = await api.get(`${process.env.REACT_APP_SERVER_URL_API}/api/Products/GetChannelBySource/site`, config);
                if (data.statusCode === 200) {
                    idChannel = data.data.data;
                    getItems()
                }
            } catch (error) { }
        }

        getIdChannel();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const newSlug = (i18next.language === "pt" ? tourResponse.slugBR : i18next.language === "en" ? tourResponse.slugEN : i18next.language === "es" ? tourResponse.slugES : tourResponse.slugBR);
        if (slugUrl !== newSlug && newSlug !== undefined) {
            window.location.href = window.location.origin + "/ticket/" + newSlug;
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [i18next.language]);

    useEffect(() => {
        if (tourResponse !== "null") {
            TagManager.dataLayer({
                dataLayer: {
                    ecommerce: {
                        detail: {
                            products: [{
                                name: tourResponse.productName,         // Name or ID is required.
                                id: tourResponse.productCode,
                                price: tourResponse?.modalities[0]?.tarif.price,
                                brand: 'Bondinho',
                            }]
                        }
                    }
                }
            });
        }
    }, [tourResponse]);

    if (tourResponse !== "null") {

        const ldJson = {
            "@context": "http://schema.org/",
            "@type": "TouristAttraction",
            "description": tourResponse.productInfo.replace(/<[^>]*>?/gm, ''),
            "image": tourResponse.imagesBaseUrl + '/medium_' + tourResponse.images[0],
            "name": process.env.REACT_APP_SERVER_NAME,
            "url": process.env.REACT_APP_SERVER_URL
        };
        //console.log(tourResponse.modalities[0].tarif)

        //console.log(tourResponse.productDescription.split("</p>"));

        return (
            <>
                <Helmet>
                    <html lang={i18next.language} />
                    <title>{tourResponse.productName + ' | Parque Bondinho'}</title>
                    <meta name="description" content={tourResponse.productDescription.replace(/<[^>]*>?/gm, '')} />
                    <meta name="googlebot" content={process.env.REACT_APP_SERVER_ROBOTS} />

                    {/* Essential META Tags */}
                    <meta property="og:title" content={tourResponse.productName + ' | Parque Bondinho'} />
                    <meta property="og:type" content="TouristAttraction" />
                    <meta property="og:image" content={`${tourResponse.imagesBaseUrl}/medium_${tourResponse.images[0]}`} />
                    <meta property="og:url" content={process.env.REACT_APP_SERVER_URL} />

                    {/* Non-Essential, But Recommended */}
                    <meta property="og:description" content={tourResponse.productDescription.replace(/<[^>]*>?/gm, '')} />
                    <meta property="og:site_name" content={process.env.REACT_APP_SERVER_NAME} />

                    {/* Non-Essential, But Required for Analytics */}
                    {/* <meta property="fb:app_id" content="your_app_id" /> */}

                    {
                        tourResponse.productCode === "PXY4929" || tourResponse.productCode === "PXC8157" || tourResponse.productCode === "PXE9656"
                            ?
                            <meta name="robots" content="noindex, nofollow" />
                            :
                            <meta name="robots" content={process.env.REACT_APP_SERVER_ROBOTS} />
                    }

                    <link rel="canonical" href={process.env.REACT_APP_SERVER_URL} />

                    <script type="application/ld+json">
                        {JSON.stringify(ldJson)}
                    </script>

                </Helmet>


                <PageDafault>
                    <div className="bg-light">
                        <div
                            className="destinoBG"
                            id="bg-img"
                            style={{
                                backgroundImage: `url(${tourResponse.imagesBaseUrl}${tourResponse.images[0]})`,
                                backgroundSize: "cover",
                                backgroundPosition: "center",
                            }}
                        >
                            <div className="jumbotron p-0 pt-5 mb-0 text-white">
                                <div className="container">
                                    <div className="row w-100 d-flex align-content-end pb-5">
                                        <div className="col-md-12 py-0 py-md-5 px-4">
                                            <h1
                                                className="h1 font-weight-bold pb-2 secondary"
                                                id="name-product"
                                            >
                                                {tourResponse.productName}
                                            </h1>
                                        </div>
                                    </div>
                                </div>
                                <div className="bg-black-20">
                                    <div className="container">
                                        <div className="row pt-3 pb-1 px-3 m-0">
                                            <div className="col-4 col-sm-4 col-md-4">
                                                <h3
                                                    className="font-weight-bold mb-0 secondary"
                                                    id="duration"
                                                >
                                                    {`${tourResponse.modalities[0].activityDurationHours >= 9 ? `${tourResponse.modalities[0].activityDurationHour}` : `0${tourResponse.modalities[0].activityDurationHours}`}:${tourResponse.modalities[0].activityDurationMinutes >= 9 ? `${tourResponse.modalities[0].activityDurationMinutes}` : `0${tourResponse.modalities[0].activityDurationMinutes}`}`}
                                                </h3>
                                                <span className="small">{t("tour.totalDuration")}</span>
                                            </div>

                                            <div className="col-3 col-sm-4 col-md-4"></div>

                                            {/*  <div className="col-5 col-sm-4 col-md-4">
                                                <h3
                                                    className="font-weight-bold mb-0 secondary"
                                                    id="max-people"
                                                >
                                                    {tourResponse.maxNumPeople}
                                                </h3>
                                                <span className="small">{t("tour.totalCapacity")}</span>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <nav
                            id="navbar-destino"
                            className="navbar navbar-light bg-white mb-3 justify-content-center"
                        >
                            <div
                                className="container text-center p-0 m-0"
                                style={{ display: "block" }}
                            >
                                <ul className="nav-pills scroll-nav">
                                    <Row>
                                        <Col md={12} className="flex-column">
                                            <Row>
                                                <Col md={3}>
                                                    <li className="nav-item">
                                                        <LinkRoll
                                                            activeClass="active"
                                                            to="session-experience"
                                                            spy={true}
                                                            smooth={true}
                                                            offset={-80}
                                                            duration={500}
                                                        >
                                                            <span className="nav-link link-tour nav-tour">
                                                                {" "}
                                                                <p className="d-md-inline d-lg-inline text-md-uppercase">
                                                                    <FontAwesomeIcon
                                                                        icon={["fal", "grin-wink"]}
                                                                        size="1x"
                                                                        style={{ marginRight: "5px" }}
                                                                    />
                                                                    <span>{t("tour.theExperience")}</span>
                                                                </p>
                                                            </span>
                                                        </LinkRoll>
                                                    </li>
                                                </Col>
                                                <Col md={3}>
                                                    <li className="nav-item">
                                                        <LinkRoll
                                                            activeClass="active"
                                                            to="modalidades"
                                                            spy={true}
                                                            smooth={true}
                                                            offset={-80}
                                                            duration={500}
                                                        >
                                                            <span className="nav-link link-tour nav-tour">
                                                                {" "}
                                                                <p className="d-lg-inline text-md-uppercase">
                                                                    <FontAwesomeIcon
                                                                        icon={["fal", "list"]}
                                                                        size="1x"
                                                                        style={{ marginRight: "5px" }}
                                                                    />
                                                                    <span>{t("tour.options")}</span>
                                                                </p>
                                                            </span>
                                                        </LinkRoll>
                                                    </li>
                                                </Col>
                                                <Col md={3}>
                                                    <li className="nav-item">
                                                        <LinkRoll
                                                            activeClass="active"
                                                            to="session-info"
                                                            spy={true}
                                                            smooth={true}
                                                            offset={-140}
                                                            duration={500}
                                                        >
                                                            <span className="nav-link link-tour nav-tour">
                                                                {" "}
                                                                <p className="d-lg-inline text-md-uppercase">
                                                                    <FontAwesomeIcon
                                                                        icon={["fal", "info-circle"]}
                                                                        size="1x"
                                                                        style={{ marginRight: "5px" }}
                                                                    />
                                                                    <span>{t("tour.Information")}</span>
                                                                </p>
                                                            </span>
                                                        </LinkRoll>
                                                    </li>
                                                </Col>
                                                <Col md={3}>
                                                    <li className="nav-item">
                                                        <LinkRoll
                                                            activeClass="active"
                                                            to="session-contact"
                                                            spy={true}
                                                            smooth={true}
                                                            offset={-80}
                                                            duration={500}
                                                        >
                                                            <span className="nav-link link-tour nav-tour">
                                                                {" "}
                                                                <p className="d-lg-inline text-md-uppercase">
                                                                    <FontAwesomeIcon
                                                                        icon={["fal", "phone-rotary"]}
                                                                        size="1x"
                                                                        style={{ marginRight: "5px" }}
                                                                    />
                                                                    <span>{t("tour.contact")}</span>
                                                                </p>
                                                            </span>
                                                        </LinkRoll>
                                                    </li>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </ul>
                            </div>
                        </nav>

                        <div className="container destino text-dark bg-light px-0">
                            <div className="row w-100 m-0">
                                <div
                                    className="col-12 col-lg-9 order-2 order-lg-1 px-0"
                                    data-spy="scroll"
                                    data-target="#navbar-destino"
                                    data-offset="0"
                                >
                                    <div className="col-12 p-4">
                                        {/* Carrosel de imagens */}
                                        <section>
                                            <div id="carousel-thumb">
                                                <ProductSliderSigxSig arrayImages={tourResponse.images} imagesBaseUrl={tourResponse.imagesBaseUrl} />
                                            </div>
                                        </section>
                                        {/* Carrosel de imagens */}
                                        <div className="conteudo" style={{ bottom: "0px" }}>
                                            {/* Navegador Tour */}

                                            <div className="row d-flex align-content-end">
                                                <div className="col-md-12 py-2">
                                                    {
                                                        tourResponse.reservationSystem === 99
                                                            ?
                                                            <div className="bagde-sig">
                                                                <FontAwesomeIcon
                                                                    icon={["fas", "circle"]}
                                                                    size="5x"
                                                                    style={{ fontSize: ".5em", color: "#FF6600" }}
                                                                />
                                                                <h6 style={{ fontSize: "1rem" }} className="mb-0 d-flex align-items-center gap-1">Operado por: <img src={C2rio} height="22px" alt='logo C2rio' /></h6>
                                                            </div>
                                                            :
                                                            ""
                                                    }
                                                    <h1 className="h1 font-weight-bold pb-2 text-primary" id="name-product">
                                                        {tourResponse.productName}
                                                    </h1>
                                                </div>
                                            </div>

                                            <h5 className="text-uppercase text-primary mt-2">
                                                <FontAwesomeIcon
                                                    icon={["fal", "info-circle"]}
                                                    size="1x"
                                                    style={{ marginRight: "5px" }}
                                                />
                                                {t("tour.informations")}
                                            </h5>
                                            <span
                                                id="infos-product"
                                                dangerouslySetInnerHTML={{
                                                    __html: tourResponse.productInfo,
                                                }}
                                            ></span>

                                            {/* Conteúdo do passeio */}
                                            <h5 className="text-uppercase text-primary" id="experiencia">
                                                <FontAwesomeIcon
                                                    icon={["fal", "smile"]}
                                                    size="1x"
                                                    style={{ marginRight: "5px" }}
                                                />
                                                {t("tour.theExperience")}
                                            </h5>
                                            <span
                                                id="description"
                                                dangerouslySetInnerHTML={{
                                                    __html: tourResponse.productDescription,
                                                }}
                                            ></span>
                                            {/*                     <h5 className="text-uppercase text-primary mt-2">
                      <FontAwesomeIcon
                        icon={["fal", "star"]}
                        size="1x"
                        style={{ marginRight: "5px" }}
                      />
                      {t("tour.highlights")}{" "}
                    </h5>
                    <h5 className="text-uppercase text-primary mt-2">
                      <FontAwesomeIcon
                        icon={["fal", "star"]}
                        size="1x"
                        style={{ marginRight: "5px" }}
                      />
                      {t("tour.details")}
                    </h5> */}

                                            <section
                                                id="modalidades"
                                                className=" my-3"
                                                data-destine="modalidades"
                                            >
                                                <h5 className="text-uppercase text-primary pb-4">
                                                    <FontAwesomeIcon
                                                        icon={["fal", "list"]}
                                                        size="1x"
                                                        style={{ marginRight: "5px" }}
                                                    />
                                                    {t("tour.options")}
                                                </h5>
                                                <TicketOptionsSigxSig
                                                    options={optionTour}
                                                    response={tourResponse}
                                                    action={addToCart}
                                                ></TicketOptionsSigxSig>
                                            </section>
                                            {/* Opções do passeio */}

                                            <div className="accordion accordion-rules" id="info">
                                                <Accordion defaultActiveKey="0" className="mt-4">
                                                    <Accordion.Item eventKey="0">
                                                        <Accordion.Header>
                                                            <span
                                                                className="h4 text-primary font-weight-normal text-uppercase"
                                                                id="politica"
                                                            >
                                                                Outras Informações
                                                            </span>
                                                            <i className="fal float-right p-0"></i>
                                                        </Accordion.Header>
                                                        <Accordion.Body>
                                                            <div
                                                                className="collapse-inner py-2"
                                                                id="info-adicional"
                                                                dangerouslySetInnerHTML={{
                                                                    __html: tourResponse.voucherInstructions,
                                                                }}
                                                            />
                                                        </Accordion.Body>
                                                    </Accordion.Item>
                                                </Accordion>

                                            </div>

                                            <div className="my-4">
                                                <div className="row m-0 overflow-hidden">
                                                    <div className="col-12 col-sm-6 col-md- col-lg- col-xl-8 align-items-center d-flex bg-white px-3 py-2">
                                                        <h4 id="questoes" className="py-0">
                                                            {" "}
                                                            {t("tour.code")}?
                                                        </h4>
                                                    </div>

                                                    <div className="col-12 col-sm-6 col-md- col-lg- col-xl-4 p-0 bg-primary text-white align-items-center justify-content-center d-flex">
                                                        <span id="product-code">
                                                            {t("tour.code")}: {tourResponse.productCode}
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="row mt-4">
                                                    <div className="col-12 text-center pb-2">
                                                        <p>{t("tour.emergencyContacts")}</p>
                                                    </div>
                                                </div>
                                                <div className="row text-center justify-content-evenly">
                                                    <div className="col-12 col-sm-4 col-md- pb-4 mb-md-0">
                                                        <FontAwesomeIcon
                                                            icon={["fal", "phone-alt"]}
                                                            size="3x"
                                                        />

                                                        <strong className="d-none">
                                                            {t("tour.telephone")} :
                                                        </strong>
                                                        <span className="d-block py-2" id="tel-info">
                                                            +55 (21) 3828-0370
                                                        </span>
                                                        <a
                                                            href={`tel:+55 (21) 3828-0370`}
                                                            title=""
                                                            className="btn btn-sm btn-outline-primary btn-contact"
                                                        >
                                                            {t("tour.callPhone")}
                                                        </a>
                                                    </div>
                                                    <div className="col-12 col-sm-4 col-md- pb-4 mb-md-0">
                                                        <FontAwesomeIcon
                                                            icon={["fab", "whatsapp"]}
                                                            size="3x"
                                                        />
                                                        <strong className="d-none">WhatsApp:</strong>
                                                        <span className="d-block py-2" id="whats-info">
                                                            +55 (21) 3828-0370
                                                        </span>
                                                        <a
                                                            href={`http://wa.me/552138280370`}
                                                            title=""
                                                            className="btn btn-sm btn-outline-primary btn-contact"
                                                        >
                                                            {t("tour.messageWpp")}
                                                        </a>

                                                        {/*                             <small className="d-block mt-1" style={{ fontSize: ".75rem" }}>{t("tour.belowMessageWpp")}</small> */}
                                                    </div>
                                                    <div className="col-12 col-sm-4 col-md- pb-4 mb-md-0">
                                                        <FontAwesomeIcon
                                                            icon={["fal", "envelope"]}
                                                            size="3x"
                                                        />

                                                        <strong className="d-none">{t("tour.email")}:</strong>
                                                        <span className="d-block py-2" id="mail-info">
                                                            {" "}
                                                            info@c2rio.travel
                                                        </span>
                                                        <a
                                                            href={`mailto:info@c2rio.travel`}
                                                            title=""
                                                            className="btn btn-sm btn-outline-primary btn-contact"
                                                        >
                                                            {t("tour.sendEmail")}
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-lg-3 order-1 order-lg-2">
                                    <CardTourSide options={cardTourSide} />
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <ProductsReviews code={tourResponse.productCode} /> */}

                    <Modal
                        className="modal-confirm"
                        show={alert}
                        onHide={() => {
                            setAlert(false)
                        }}
                        size="lg"
                        aria-labelledby="contained-modal-title-vcenter"
                    >
                        <div className='modal-body text-center'>
                            <div>
                                <FontAwesomeIcon
                                    icon={["fal", "times-circle"]}
                                    size="5x"
                                    style={{ fontSize: "7.5em", color: "#FF6600" }}
                                />

                            </div>
                            <div className="px-3 px-md-5">
                                {t("tour.alertRepeatedItem")}
                            </div>
                            <div>
                                <Button type="submit" className="btn btn-primary form-button px-4 py-2" onClick={() => setAlert(false)}>Fechar</Button>
                            </div>
                        </div>
                    </Modal>
                </PageDafault>
            </>
        );
    } else {
        return <></>;
    }
};

export default TicketSigxSig;
