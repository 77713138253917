/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import CardReactFormContainer from 'react-credit-cards';
import { useTranslation } from "react-i18next";
import { parseCookies, destroyCookie } from 'nookies';
// import i18next from "i18next";
import TagManager from 'react-gtm-module';

//import Helmet from 'react-helmet';

import InputMask from "react-input-mask";
import { Controller, useForm } from "react-hook-form";

import 'react-credit-cards/es/styles-compiled.css';
import './StepPayment.scss';

import brapagSelo from '../../../assets/img/selo-braspag.png';
import iconPix from '../../../assets/icons/svg/icon-pix.svg'

//Components
import CheckoutSummary from '../CheckoutSummary/CheckoutSummary';
// import ModalDefault from "../../Modal/ModalDefault/ModalDefault";
import api from '../../../services/api';
import { Modal, Form, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ModalContentPayment from '../../Modal/ModalContentPayment/ModalContentPayment';
import Button from "@restart/ui/esm/Button";
import { useAppSelector, useAppDispatch } from "../../../store/hooks";
// import { updateKoin } from "../../../store/cart";
import { Helmet } from 'react-helmet';
import i18next from 'i18next';
import monitoringAPI from '../../../services/monitoring';

//let controlKoin: boolean = true

declare module 'react' {
  interface HTMLAttributes<T> extends AriaAttributes, DOMAttributes<T> {
    // extends React's HTMLAttributes
    org_id?: string;
  }
}

declare global {
  interface Window {
    koinSessionId?: any;
    getSessionID?: any;
    SESSION_COOKIE_NAME?: any;
  }
}

export interface propAction {
  back: any,
  stepCtrl: any;
};

let koinId: any = undefined;

const StepPayment: React.FC<propAction> = ({
  back, stepCtrl
}: propAction) => {

  // KoynScript('https://securegtm.despegar.com/risk/fingerprint/statics/track-min.js');

  const [alertBool, setAlertBool] = useState<any>(false);
  const [alertMessage, setAlertMessage] = useState<any>("");

  const [cvc, setCvc] = useState<string>('');
  const [expiry, setExpiry] = useState<any>('');
  const [focus, setFocus] = useState<any>('');
  const [name, setName] = useState<string>('');
  const [number, setNumber] = useState<string>('');
  const [brand, setBrand] = useState<string>('');
  const [paymentMethod, setPaymentMethod] = useState<any>('1');
  const [documentType, setDocumentType] = useState<any>('CPF');
  const [documentNumber, setDocumentNumber] = useState<any>('');
  const [koinSession, setKoinSession] = useState<any>(1);
  const [email, setEmail] = useState<any>('');
  const [checkImportDados, setCheckImportDados] = useState<boolean>(false);
  const [invalidDocument, setInvalidDocument] = useState<boolean>(false);

  const [modalResponse, setModalResponse] = useState<any>(null);
  const [checkoutResponse, setCheckoutResponse] = useState<any>('');
  const [modalContent, setModalContent] = useState<any>('loading');

  const [installments, setInstallments] = useState<string>("1");
  const [defaultChecked, setDefaultChecked] = useState<number>(1);

  const [allowResetCart, setAllowResetCart] = useState<boolean>(false);
  const [sendingCheckout, setSendingCheckout] = useState<boolean>(false);

  const [loading, setLoading] = useState<any>(false);

  const koin: any = useAppSelector((state: any) => state.koin);

  const dispatch = useAppDispatch();
  const cartBPA: any = JSON.parse(JSON.stringify(useAppSelector((state: any) => state.cart)));

  const delete_cookie = (name: string) => {
    document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:01 GMT;';
  };

  useEffect(() => {
    if (paymentMethod === '1' || paymentMethod === 1) {
      TagManager.dataLayer({
        dataLayer: {
          event: 'cartaodecredito',
          event_category: 'Compra',
          event_label: 'ingresso',
          value: window.cart.totalCart
        }
      });
    } else if (paymentMethod === '2' || paymentMethod === 2) {
      TagManager.dataLayer({
        dataLayer: {
          event: 'pix',
          event_category: 'Compra',
          event_label: 'ingresso',
          value: window.cart.totalCart
        }
      });
    } else {

    }
  }, [paymentMethod])

  const handlePayment = () => {
    (document.getElementById('buttonPayment') as HTMLElement).click();
  }

  const getChangeInstallments = (e: any): any => {
    setInstallments(e.target.value);
    setDefaultChecked(e.target.value);
  };

  const { t } = useTranslation();

  const getKoin = async () => {
    if (koinId !== undefined) {
      return window.koinSessionId;
    } else {
      if (!!window?.getSessionID) {
        destroyCookie(null, window.SESSION_COOKIE_NAME);
        let koin: string = ' ';
        await new Promise((resolve: any) => {
          window.getSessionID(function (sessionId: any) {
            window.koinSessionId = sessionId;
            const cookies = parseCookies();
            koin = cookies[window.SESSION_COOKIE_NAME] === sessionId ? sessionId : ' ';
            resolve();
          });
        });
        return koin;
      } else {
        return ' ';
      }
    }
  }

  const handleSubmitCreditCard = async () => {
    if (CpfValidator(documentNumber) || documentType === 'DNI') {
      setInvalidDocument(false);
      setLoading(true);

      let koinValue = await getKoin();

      createObjectToPayment(koinValue);
    } else {
      setInvalidDocument(true);
    }
  }

  const handleSubmitPIX = () => {
    setLoading(true);
    createObjectToPayment(' ');
  }

  const resetCart = () => {
    setAllowResetCart(true);
    localStorage.removeItem("cartBPA");
    window.location.href = "/";
  }

  useEffect(() => {
    if (modalResponse === null) {
      /* controlKoin = true; */
      if (allowResetCart === true) {
        localStorage.removeItem("cartBPA");
        window.location.href = "/";
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalResponse]);


  const config = {
    headers: { "ngrok-skip-browser-warning": "69420" },
  }

  async function getIdChannel(func: any) {
    let idChannel: number = 2;

    try {
      const { data } = await api.get(`${process.env.REACT_APP_SERVER_URL_API}/api/Products/GetChannelBySource/site`, config);
      if (data.statusCode === 200) {
        monitoringAPI(200, 'Products', 'GetChannelBySource', data?.data?.texto || 'Sucesso', '', '/api/Products/GetChannelBySource/site', '');
        idChannel = data.data.data;
      }else {
        monitoringAPI(400, 'LoginSite', 'GetPerfil', data?.data?.texto || data?.texto || 'Retornado status diferente de 200', '', '/api/LoginSite/GetPerfil', '');
      }
      func(idChannel);
    } catch (error: any) {
      monitoringAPI(400, 'LoginSite', 'GetPerfil', error?.response?.data?.errorMessage || 'Retornado status diferente de 200', '', '/api/LoginSite/GetPerfil', '');
      func(idChannel);
    }
  }

  const createObjectToPayment = (koinSessionId: any) => {
    const lng = i18next.language === "pt" ? 1 : i18next.language === "en" ? 2 : i18next.language === "es" ? 3 : 1;

    let today = new Date();
    let dd = String(today.getDate()).padStart(2, '0');
    let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    let yyyy = today.getFullYear();

    // today = mm + '/' + dd + '/' + yyyy;
    let todayFoPayment = yyyy + '-' + mm + '-' + dd;
    let timeT = 0;

    const cookies = parseCookies();

    let payments = [{
      brand: brand,
      cardNumber: number,
      codeVerifier: cvc,
      DocumentNumber: paymentMethod === '1' ? documentNumber : cartBPA?.dados[0]?.passengers[0]?.DocumentNumber, //window.cart.dados[0].passengers[0].DocumentNumber,
      documentType: documentType,
      SessionIdKoin: koinSessionId,
      datePayment: todayFoPayment,
      expiration: expiry,
      holder: name,
      installments: installments,
      MerchantPayMethod: paymentMethod === '1' ? 3 : 2,
      valor: '',
      payMethod: paymentMethod === '1' ? 1 : 96,
      merchant: '0',
      refCode: '',
    }];

    if (paymentMethod === '1') {
      if (brand === 'unknown') {
        alert('Cartão Inválido');
        setLoading(false);
      } else if (expiry === "" || expiry?.split('/')[1][3] === '_') {
        alert('Validade do Cartão Inválido');
        setLoading(false);
      } else {
        cartBPA.payments = payments;
        cartBPA.language = lng;

        getIdChannel(createPayment)
      }
    } else {
      cartBPA.payments = payments;
      cartBPA.language = lng;
      getIdChannel(createPayment)
    }
  }

  const createPayment = async (idChannel: any) => {
    setModalResponse(true);
    setModalContent('loading');

    cartBPA.idCanal = cartBPA.isProductBdBTrilha === true ? 7 : idChannel;

    // eslint-disable-next-line array-callback-return
    cartBPA.dados.map((item: any) => {
      delete item?.imagesBaseUrl;
      delete item?.imgCart;
      delete item?.channel;
      delete item?.timeRange;
    })

    delete cartBPA.isProductBdB;
    delete cartBPA.isProductBdBTrilha;
    delete cartBPA.isProductC2Rio;

    const objPayment = JSON.stringify(cartBPA);

    const auth = JSON.parse(localStorage.getItem('bpasiteAuth') || '{}');
    const config = {
      headers: {
        'Authorization': `Bearer ${auth.token.token}`,
        'Content-Type': 'application/json'
      }
    };

    try {
      const res: any = await api.post('/api/Checkout/EnviaCheckoutBondinho', objPayment, config);
      if (res.status !== 400) {
        if (res.data.statusCode === 200) {
          if (res.data.data.log === '1' || res.data.data.log === 1) {
            monitoringAPI(400, 'Checkout', 'EnviaCheckoutBondinho', res?.data?.data?.texto || res?.data?.texto || 'Retornado status diferente de 200', '', '/api/Checkout/EnviaCheckoutBondinho', cartBPA);
            setCheckoutResponse(res.data)
            setModalContent('error');
          } else {
            monitoringAPI(200, 'Checkout', 'EnviaCheckoutBondinho', res?.data?.data?.texto || res?.data?.texto || 'Sucesso', '', '/api/Checkout/EnviaCheckoutBondinho', cartBPA);
            localStorage.removeItem("uniqueCode");
            setCheckoutResponse(res.data);
            setModalContent('success');
          }
        } else {
          monitoringAPI(400, 'Checkout', 'EnviaCheckoutBondinho', res?.data?.data?.texto || res?.data?.texto || 'Retornado status diferente de 200', '', '/api/Checkout/EnviaCheckoutBondinho', cartBPA);
          setModalContent('error');
        }
        setLoading(false)
      } else {
        monitoringAPI(400, 'Checkout', 'EnviaCheckoutBondinho', res?.data?.data?.texto || res?.data?.texto || 'Retornado status diferente de 200', '', '/api/Checkout/EnviaCheckoutBondinho', cartBPA);
        setModalContent('error');
      }

    } catch (error: any) {
      monitoringAPI(400, 'Checkout', 'EnviaCheckoutBondinho', error?.response?.data?.errorMessage || 'Retornado status diferente de 200', '', '/api/Checkout/EnviaCheckoutBondinho', cartBPA);
      setCheckoutResponse(error.response)
      setModalContent('error');
      setLoading(false)
    }
  }

  const handleInputFocus = (e: any) => {
    setFocus(e.target.name);
  }

  const handleInputFocusOut = (e: any) => {
    setFocus('number');
  }

  const handleInputChange = (e: any) => {
    setNumber(e.target.value);
    if (e.target.value.length > 4 && brand === 'unknown') {
      setNumber('');
      setAlertMessage(t("checkoutSteps.alertInvalidCard"));
      setAlertBool(true);
      /* alert('Cartão Inválido'); */
    }
  }

  const handleCallback = ({ issuer }: any, isValid: any) => {
    setBrand(issuer);
    if (isValid) {
    }
  }

  const handleInputChangeName = (e: any) => {
    setName(e.target.value);
  }

  const handleInputChangeExpiry = (e: any) => {
    setExpiry(e.target.value);
  }

  const handleInputChangeCVC = (e: any) => {
    setCvc(e.target.value);
  }

  const handlePaymentType = (e: any) => {
    setPaymentMethod(e.target.value);
  }

  useEffect(() => {
    if (checkImportDados === false) {
      setDocumentNumber('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentType])

  useEffect(() => {
    setPaymentMethod('1');
  }, [stepCtrl.key])

  const {
    control,
    formState: { errors },
  } = useForm();

  const cartItem: any = JSON.parse(JSON.stringify(useAppSelector((state: any) => state.cart)));

  let totalCart = cartBPA?.cupom?.type === 2 ? Number(cartBPA.totalCart - cartBPA.discount) : cartBPA?.cupom?.type === 1 ? Number(cartBPA.totalCart - (cartBPA.totalCart * (cartBPA?.cupom?.value / 100))) : Number(cartBPA.totalCart);

  let parcelas = 0;

  if (cartItem.totalCart >= 150 && cartItem.totalCart < 200) {
    parcelas = 3;
  } else if (cartItem.totalCart >= 200 && cartItem.totalCart < 250) {
    parcelas = 4;
  } else if (cartItem.totalCart >= 250 && cartItem.totalCart < 300) {
    parcelas = 5;
  } else if (cartItem.totalCart >= 300) {
    parcelas = 6;
  }

  function changeDocumentType() {
    var typeDocument: any = document.querySelector('#type_document');
    typeDocument.value = cartBPA.Country === "BR" ? 'CPF' : 'DNI';
    setDocumentType(cartBPA.Country === "BR" ? 'CPF' : 'DNI');
    setDocumentNumber(cartBPA.dados[0].passengers[0].DocumentNumber);
    setEmail(cartBPA.dados[0].passengers[0].email);
  }

  useEffect(() => {

    if (stepCtrl.key === "stepPayment") {
      changeDocumentType();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stepCtrl]);

  const handleCheckImportDados = () => {
    if (!checkImportDados === true) {
      changeDocumentType();
    } else {
      setDocumentNumber('');
      setEmail('');
    }

    setCheckImportDados(!checkImportDados);
  }

  function CpfValidator(strCPF: any) {
    if (strCPF !== null) {
      // eslint-disable-next-line no-useless-escape
      strCPF = strCPF.replace(/\./g, '').replace(/\-/g, '').replace(/\_/g, '');  //remove ".", "-" e "-" que a máscara coloca
      var Soma;
      var Resto;
      Soma = 0;

      if (strCPF === "00000000000") {
        return false;
      }

      for (var i: any = 1; i <= 9; i++) Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i);
      Resto = (Soma * 10) % 11;

      if ((Resto === 10) || (Resto === 11)) Resto = 0;
      if (Resto !== parseInt(strCPF.substring(9, 10))) {
        return false;
      }

      Soma = 0;
      for (i = 1; i <= 10; i++) Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (12 - i);
      Resto = (Soma * 10) % 11;

      if ((Resto === 10) || (Resto === 11)) Resto = 0;
      if (Resto !== parseInt(strCPF.substring(10, 11))) {
        return false;
      }

      return true;
    } else {
      return false;
    }
  }

  return (
    <div className="row">
      <Helmet>
        <script src="https://securegtm.despegar.com/risk/fingerprint/statics/track-min.js" type="text/javascript" org_id="h9tJSP8JhW" id="deviceId_fp" async={true} ></script>
      </Helmet>
      <div className="col-12 col-md-8 col-lg-8 order-1 order-md-2 mt-4">
        <div>
          <h1 className="ml-2 h5">Escolha seu meio de pagamento</h1>
        </div>

        <div className="my-2">
          <ul className="optionsPayment">
            {process.env.REACT_APP_CREDIT_CARD === "true"
              ?
              <li className='row'>
                <div className="">
                  <div className="custom-control custom-radio ">
                    <input type="radio" id="paymentCreditCard" name="customRadio" value={1} data-payment="formPayment" className="custom-control-input" onChange={(e) => handlePaymentType(e)} />
                    <label className={paymentMethod === '1' || cartBPA.Country !== "BR" ? "custom-control-label custom-control-payment active" : "custom-control-label custom-control-payment"} htmlFor="paymentCreditCard">{t("checkoutSteps.payCredit")}
                      <FontAwesomeIcon
                        icon={["fal", "credit-card"]}
                        size="1x"
                        className="ml-2"
                      />
                    </label>
                  </div>

                </div>
              </li>
              :
              ''
            }
            {cartBPA.Country === "BR" ?
              process.env.REACT_APP_PIX === "true"
                ?
                <li className='row'>
                  <div className="">
                    <div className="custom-control custom-radio ">
                      <input type="radio" id="paymentPix" name="customRadio" value={2} data-payment="formPayment" className="custom-control-input" onChange={(e) => handlePaymentType(e)} />
                      <label className="custom-control-label custom-control-payment" htmlFor="paymentPix">Pix
                        <img src={iconPix} alt="icon pix" className="ml-2" />
                      </label>
                    </div>

                  </div>
                </li>
                :
                <></>
              :
              <></>
            }
          </ul>
        </div>

        {paymentMethod === '1' || cartBPA.Country !== "BR" ?
          process.env.REACT_APP_CREDIT_CARD === "true"
            ?
            <div className="card method" id="payment-div">
              <div className="row p-md-3 p-1 m-1 w-100">
                <div className="row paymentRow py-4" data-payment="paymentCreditCard" style={{ display: 'block' }}>
                  {cartBPA.totalCart > 150 && cartBPA.Country === "BR" ?
                    <div className={cartBPA.Country === "BR" ? 'col-12 col-md-6 float-left' : 'd-none'}>
                      <div className="col-md-8 col-12 float-left p-0">
                        <div className="custom-control custom-radio mb-3">
                          <input type="radio" id="parsePayments1" name="customPayment" value="1" className="custom-control-input" onChange={getChangeInstallments} checked={1 === Number(defaultChecked)} />
                          <label className="custom-control-label pPayment small d-flex flex-row align-items-center" htmlFor="parsePayments1"> À vista R$ {cartItem.totalCart.toFixed(2).split('.').join(',')}</label>
                        </div>

                        {[...Array(Number(parcelas - 1))].map((x, i) =>
                          <div key={i} className="custom-control custom-radio mb-3">
                            <input type="radio" id={'parsePayments' + (i + 2)} name="customPayment" value={(i + 2)} className="custom-control-input" onChange={getChangeInstallments} checked={Number(i + 2) === Number(defaultChecked)} />
                            <label className="custom-control-label pPayment small d-flex flex-row align-items-center" htmlFor={'parsePayments' + (i + 2)} >
                              <span className="mx-1" data-parse={i}> {(i + 2)}x R$ {(cartItem.totalCart / (i + 2)).toFixed(2).split('.').join(',')}</span>
                              <small> sem juros</small>
                            </label>
                          </div>
                        )}
                        <div className="invalid-payments">Escolha uma das opções de parcelamento</div>
                      </div>
                    </div>
                    : <></>}

                  <div className={cartBPA.Country === "BR" || cartItem.totalCart < 150 ? 'col-12 float-left col-md-6' : 'col-12 float-left col-md-12'} data-content="card">
                    <div className="row d-flex justify-content-center mt-4">
                      <div className="card-wrapper" id="PaymentForm"></div>
                      <CardReactFormContainer
                        cvc={cvc}
                        expiry={expiry}
                        focused={focus}
                        name={name}
                        number={number}
                        callback={handleCallback}
                      />
                      <div className="formCredit needs-validation-card mt-4">
                        <div className="row col-12 m-0 mb-2 p-0">
                          <div className="col-12 mb-2">
                            <label htmlFor="FormControlInput1Nome" className="small">{t("checkoutSteps.numCart")}</label>
                            <input onChange={(e) => handleInputChange(e)} onFocus={(e) => handleInputFocus(e)} className="form-control bpmpi_cardnumber" type="tel" maxLength={19} name="number" required onPaste={() => { return false }} />
                          </div>
                          <div className="col-12 mb-2">
                            <label htmlFor="FormControlInput1Nome" className="small">{t("checkoutSteps.cardName")}</label>
                            <input onChange={(e) => handleInputChangeName(e)} onFocus={(e) => handleInputFocus(e)} className="form-control bpmpi_cardalias" type="text" name="name" onPaste={() => { return false }} />
                          </div>
                          <Row className="mt-2 mb-1 ">
                            <Form.Group as={Col} controlId="BR" className="d-flex">
                              <label htmlFor="importDados" style={{ whiteSpace: 'nowrap' }}>
                                <Form.Group>
                                  <Controller
                                    control={control}
                                    name="importDados"
                                    render={({ field, e }: any) => (
                                      <Form.Check
                                        {...field}
                                        id="importDados"
                                        name="check"
                                        className="mr-2"
                                        label={t("checkoutSteps.importDocument")}
                                        onChange={handleCheckImportDados}
                                        checked={checkImportDados}
                                      />
                                    )}
                                  />
                                </Form.Group>
                              </label>
                            </Form.Group>
                          </Row>
                          <div className="col-12 mb-2">
                            <label htmlFor="FormControlInput1Nome" className="small">{t("checkoutSteps.documentType")}</label>
                            <select name="type_document" id="type_document" disabled={checkImportDados} className="form-select" onChange={(e: any) => { setDocumentType(e.target.value) }}>
                              <option value="CPF">{t("checkoutSteps.holdersCpf")}</option>
                              <option value="DNI">{t("checkoutSteps.holdersPassport")}</option>
                            </select>
                          </div>
                          <div className="col-12 mb-2">
                            <label htmlFor="FormControlInput1Nome" className="small">{documentType === 'CPF' ? t("checkoutSteps.holdersCpf") : t("checkoutSteps.holdersPassport")}</label>
                            <input disabled={checkImportDados} onChange={(e: any) => setDocumentNumber(e.target.value)} value={documentNumber} className="form-control bpmpi_cardalias" maxLength={documentType === 'CPF' ? 11 : undefined} type="text" name="document" required onKeyPress={(e: any) => { if (documentType === 'CPF') !/[0-9]/.test(e.key) && e.preventDefault() }} /* onPaste={() => { return false }} */ />
                            {invalidDocument === true ? <small style={{ color: 'red' }}>{t("checkoutSteps.invalidDocument")}</small> : <></>}
                          </div>
                          {/* <div className="col-12 mb-2">
                            <label htmlFor="FormControlInput1Nome" className="small">{t("checkoutSteps.email")}</label>
                            <input disabled={checkImportDados} onChange={(e: any) => setEmail(e.target.value)} value={email} className="form-control bpmpi_cardalias" type="email" name="email" required />
                          </div> */}
                        </div>
                        <div className="row col-12 m-0 mb-2 p-0">
                          <div className="col-6">
                            <label htmlFor="FormControlInput1Nome" className="small">{t("checkoutSteps.valCart")}</label>
                            <Controller
                              control={control}
                              name="expiry"
                              render={({ field }: any) => (
                                <InputMask mask="99/9999" value={field?.value} onChange={(e) => handleInputChangeExpiry(e)}>
                                  {(inputProps: any) => (
                                    <Form.Control
                                      {...inputProps}
                                      aria-invalid={errors?.cpf ? "true" : ""}
                                      placeholder="99/9999"
                                      variant="standard"
                                      margin="normal"
                                      type="text"
                                      required
                                    />
                                  )}
                                </InputMask>

                              )}
                            />
                          </div>

                          <div className="col-6 columns">
                            <label htmlFor="FormControlInput1Nome" className="small">{t("checkoutSteps.codPass")}</label>
                            <input onChange={(e) => handleInputChangeCVC(e)} onFocus={(e) => handleInputFocus(e)} onBlur={(e) => handleInputFocusOut(e)} className="form-control c" placeholder="CVC" type="tel" maxLength={4} name="cvc" onPaste={() => { return false }} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            :
            <></>
          : <></>}

        {paymentMethod === '2' && cartBPA.Country === "BR" ?
          process.env.REACT_APP_PIX === "true"
            ?
            <div className="card method mt-3" id="payment-div">
              <div className="row p-md-3 p-1 m-1">
                <div className='col-8'>
                  <small>{t("checkoutSteps.pix.text01")}</small>
                  <br />
                  <br />
                  <div>
                    <small>
                      {t("checkoutSteps.pix.text02")}<br />
                      {t("checkoutSteps.pix.text03")}<br />
                      {t("checkoutSteps.pix.text04")}<br />
                    </small>
                  </div>
                </div>
              </div>
            </div>
            :
            <></>
          :
          <></>
        }

        <div className="row mt-4">
          <div className="col-4 col-md-6 mb-4 text-left order-3 float-left">
            <button title="" onClick={back} className="btn bg-previous text-uppercase btn-back-payments w-100 p-3" >{t("checkoutSteps.back")}</button>
          </div>
          {paymentMethod === '1' || cartBPA.Country !== "BR" ?
            <div className="col-8 col-md-6 mb-4 text-right float-right order-3">
              <div className="col-12">
                {loading === false ?
                  <button id="card-button" className="btn btn-primary  text-uppercase mt-0 w-100 p-3 border-0" onClick={handleSubmitCreditCard}>{t("checkoutSteps.payment")}</button>
                  :
                  <button id="card-button" className="btn btn-primary  text-uppercase mt-0 w-100 p-3 border-0" ><div className="load medium"></div></button>
                }

              </div>
              <div className=" col-12 pl-1">
                <div className="float-right" data-typepayment="type" style={{ height: '40px' }}>
                  <img src={brapagSelo} alt="Secured by Braspag" />
                </div>
              </div>
            </div>
            : <></>}

          {paymentMethod === '2' && cartBPA.Country === "BR" ?
            <div className="col-8 col-md-6 mb-4 text-right float-right order-3">
              <div className="col-12">
                {loading === false ?
                  <button id="card-button" className="btn btn-primary  text-uppercase mt-0 w-100 p-3 border-0" onClick={handleSubmitPIX}>{t("checkoutSteps.payment")}</button>
                  :
                  <button id="card-button" className="btn btn-primary  text-uppercase mt-0 w-100 p-3 border-0" ><div className="load medium"></div></button>
                }
              </div>
            </div>
            : <></>}
        </div>

      </div>

      <CheckoutSummary></CheckoutSummary>
      <Modal
        className="modal-custom modalCheckout modalPayment"
        show={modalResponse !== null}
        onHide={() => {
          //controlKoin = true;
          setModalResponse(null)
        }}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static"
      >
        <>
          <Modal.Body>
            <ModalContentPayment pix={paymentMethod === '2' ? true : false} modalContent={modalContent} checkoutResponse={checkoutResponse} resetCart={resetCart} setModalResponse={setModalResponse} ></ModalContentPayment>
          </Modal.Body>
        </>
      </Modal>

      <Modal
        className="modal-confirm"
        show={alertBool}
        onHide={() => {
          setAlertBool(false)
        }}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
      >
        <div className='modal-body text-center'>
          <div>
            <FontAwesomeIcon
              icon={["fal", "times-circle"]}
              size="5x"
              style={{ fontSize: "7.5em", color: "#FF6600" }}
            />

          </div>
          <div className="px-md-5" style={{ padding: "0 3rem" }}>
            {alertMessage}
          </div>
          <div>
            <Button type="submit" className="btn btn-primary form-button px-4 py-2" onClick={() => setAlertBool(false)}>Fechar</Button>
          </div>
        </div>
      </Modal>

    </div>
  );
}

export default StepPayment;