import React, { useState } from "react";

import { Container, Row, Col } from "react-bootstrap";

import FilterStores from "../../components/Filter/FilterStores/FilterStores";
import StoresList from "../../components/StoresList/StoresList";

import data from "../../components/StoresList/locales/stores.json";

import "./Stores.scss";
import PageDafault from "../../templates/PageDafault";
import { Helmet } from "react-helmet";
import i18next from "i18next";

function Stores() {
    const [filterType, setFilterType] = useState<any>([]);
    const [filterLocation, setFilterLocation] = useState<any>([]);
    const [pagination, setPagination] = useState<any>(5);

    return (
        <>
            <Helmet>
                <html lang={i18next.language} />
                <title>Nossas Lojas | Parque Bondinho</title>
                <meta name="description" content="Conheça todas as lojas presentes no Parque Bondinho Pão de Açúcar, tatuagem, alimentação e óculos e acessórios, entre outros. Acesse nosso site!" />
                <meta name="googlebot" content={process.env.REACT_APP_SERVER_ROBOTS} />

                {/* Essential META Tags */}
                <meta property="og:title" content="Nossas Lojas | Parque Bondinho" />
                <meta property="og:type" content="TouristAttraction" />
                <meta property="og:image" content={`${process.env.REACT_APP_SERVER_URL}${process.env.REACT_APP_SERVER_LOGO}`} />
                <meta property="og:url" content={process.env.REACT_APP_SERVER_URL} />

                {/* Non-Essential, But Recommended */}
                <meta property="og:description" content="Conheça todas as lojas presentes no Parque Bondinho Pão de Açúcar, tatuagem, alimentação e óculos e acessórios, entre outros. Acesse nosso site!" />
                <meta property="og:site_name" content={process.env.REACT_APP_SERVER_NAME} />

                <meta name="robots" content={process.env.REACT_APP_SERVER_ROBOTS} />
                <link rel="canonical" href={process.env.REACT_APP_SERVER_URL} />
            </Helmet>

            <PageDafault>
                <Container className="py-5">
                    <div className="store-title">
                        <h1 className="text-center pb-4 display-2">Lojas</h1>
                    </div>
                    <Row>
                        <Col md={3}>
                            <div className="card-filter">
                                <FilterStores stores={data.stores.default} setFilterLocation={setFilterLocation} setFilterType={setFilterType} setPagination={setPagination} />
                            </div>
                        </Col>
                        <Col md={9}>
                            <StoresList stores={data.stores.default} filterLocation={filterLocation} filterType={filterType} pagination={pagination} setPagination={setPagination} />
                        </Col>
                    </Row>
                </Container>
            </PageDafault>
        </>
    )
}

export default Stores;