import React from 'react';

function ModalAlertES() {
  const [christmasAlert, setChristmasAlert] = React.useState<boolean>();

  React.useEffect(() => {
    let date = new Date().toLocaleString('pt-BR');

    let dateNow = date
      .split(' ')[0]
      .split('/')
      .reverse()
      .join('')
      .replaceAll(',', '');

    if (`${dateNow}` <= '20231225') {
      setChristmasAlert(true);
    } else {
      setChristmasAlert(false);
    }
  }, []);
  return (
    <>
      <div className="mb-3">
        <p>
          <strong>
            ¡El Parque Bondinho Pão de Açúcar tiene una{' '}
            <span>información importante</span> para usted!
          </strong>
        </p>
        <p>
          <strong>Horario del Parque</strong>
        </p>
        {christmasAlert === true ? (
          <div>
            <small>
              <strong>
                24/12 (Víspera de Navidad): <span>08h</span> a las{' '}
                <span>17h</span>. La última entrada es hasta las{' '}
                <span>16h</span>.
              </strong>
            </small>
            <small>
              <strong>
                25/12 (Navidad): <span>08h</span> a las <span>20h</span>. La
                última entrada es hasta las <span>18h30</span>.
              </strong>
            </small>
          </div>
        ) : (
          <></>
        )}

        <small>
          <strong>
            31/12 (Víspera de Año Nuevo): <span>08h</span> a las{' '}
            <span>17h</span>. La última entrada es hasta las <span>16h</span>.
          </strong>
        </small>
        <small>
          <strong>
            01/01 (Año Nuevo): <span>09h30</span> a las <span>21h</span>. La
            última entrada es hasta las <span>19h30</span>.
          </strong>
        </small>
        <small>
          <strong>
            Del 26/12 al 7/1, el horario de apertura será de <span>8h</span> a{' '}
            <span>21h</span>. La última entrada es hasta las <span>19h30</span>.
          </strong>
        </small>
        <small>
          <strong>¡Hasta pronto!</strong>
        </small>
      </div>
      {/* <div>
                <p>¡Nuestro sueño ya ha comenzado!</p>
                <p>Se acerca la esperada nueva atracción del Parque Bondinho, la Tirolesa. Por eso, durante tu visita, podrás notar o escuchar las obras de la Tirolesa que tendrán la vista más hermosa del mundo.</p>
                <p>¡Agradecemos su comprensión y esperamos verlo pronto para disfrutar de una nueva experiencia impresionante!</p>
            </div> */}
    </>
  );
}

export default ModalAlertES;
