import React from 'react';

import './TermsOfPurchase.scss';
import { Link } from 'react-router-dom';

function TermsOfPurchasePT() {
  return (
    <div className='bg-terms-of-purchase'>
      <h5>Introdução:</h5>

      <ul>
        <li>
          O(s) termo(s) “Evento(s)” referem-se as atividades de lazer promovidas pelo Parque Bondinho Pão de Açúcar, sejam estas: acesso ao Parque e Bondinho, shows de música, eventos de esporte, festivais, exposições, ou qualquer outro conteúdo cultural e de entretenimento, cuja entrada requeira um passe de acesso.
        </li>
        <li>
          O(s) termo(s) “Ingresso(s)” referem-se ao direito de acesso ao(s) evento(s) ou serviço(s), ao Parque ou ao Bondinho. Este acesso por se dar por meio de um papel, de forma digital, ou outros métodos que contenham uma forma de validação com código de barras, biometria ou QR Codes emitidos de forma exclusiva pelo Parque Bondinho Pão de Açúcar e, comercializados ou distribuídos por meio de nossa plataforma digital ou bilheterias físicas.
        </li>
        <li>
          O(s) termo(s) “Usuário(s)”, referem-se a qualquer pessoa (i) que efetue cadastro na plataforma do Parque Bondinho Pão de Açúcar, após conhecer e concordar com os Termos de Uso, para a utilização de qualquer uma das ferramentas disponíveis; (ii) aqueles adquirentes de Ingresso/Produto/Pacote/Experiência, produtos ou serviços por meio de qualquer outro canal de vendas disponível, inclusive a Bilheteria Oficial (vendas físicas); ou (iii) visitantes em geral.
        </li>
        <li>
          O(s) termo(s) “Pacote(s)”, referem-se aos pacotes oferecidos para venda de um Ingresso e um ou mais produtos ou serviços adicionais, como Visita Guiada, entre outros.
        </li>
        <li>
          Os termos “Produto(s)”, “Serviço(s)” e “Experiência(s)”, referem-se aos produtos/serviços/experiências oferecidos para venda nas plataformas físicas ou on-line do Parque Bondinho Pão de Açúcar.
        </li>
        <li>
          O(s) termo(s) “Voucher(s)”, referem-se aos comprovantes de compras de produtos, serviços ou experiências.
        </li>
        <li>
          Esta “Política de Compra” estabelece os termos e condições aplicáveis às compras de Ingressos.
        </li>
      </ul>

      <h6>1.	Contrato de vinculação legal:</h6>

      <p>1.	Nesta Política de Compra, nos referimos a quaisquer produtos e serviços oferecidos para venda realizada por nós.</p>

      <p>1.1.	Para fazer uma compra de Ingresso/Produto/Pacote/Experiência, seja através de Bilheteria ou de nossa plataforma on-line, o Usuário precisa ter pelo menos 18 (dezoito) anos e ser capaz de celebrar contratos juridicamente vinculativos. Se o Usuário estiver realizando uma compra on-line, também precisa ter uma conta no site www.bondinho.com.br, de modo que estará igualmente submetido aos Termos de Uso deste.</p>
      <p>1.2.	Qualquer compra em nossa plataforma (física ou on-line) constitui um contrato juridicamente vinculativo que está sujeito a: (i) esta Política de Compra; (ii) quaisquer termos e condições especiais declarados aplicáveis a um Item e/ou Evento específico; (iii) outros termos e condições do(s) Parceiro(s) de eventuais Evento(s) que ocorram no Parque Bondinho Pão de Açúcar. O Usuário deve ler esta Política de Compra cuidadosamente antes de realizar uma compra.</p>
      <p>1.3.	Ao comprar um ou mais Itens em nossa plataforma (física ou on-line), o Usuário reconhece e concorda em ficar vinculado aos termos e condições desta Política de Compra. No caso de discordância com esta Política de Compra ou quaisquer outros termos e condições aplicáveis, ou se não puder cumprir com algum deles, o Usuário não deverá realizar uma compra.</p>
      <p>1.4.	Reservamo-nos o direito de, periodicamente, fazer alterações nesta Política de Compra. Sempre que fizermos tais alterações, publicaremos a versão atualizada desta Política de Compra em nosso site. Portanto, recomendamos que o Usuário verifique esta Política de Compra regularmente para se manter informado sobre seus termos e condições atuais. Todas as compras estão sujeitas à versão aplicável desta Política de Compra que foi publicada no momento da compra. Se o Usuário não concordar com qualquer versão revisada desta Política de Compra, ou se não puder cumpri-la, não deverá realizar uma compra.</p>
      <p>1.5.	O site de venda de Ingresso/Produto/Pacote/Experiência pertence ao Parque Bondinho Pão de Açucar Tecnologia Eletrônica LTDA. que concedeu licença de uso à Companhia Caminho Aéreo Pão De Açúcar.</p>
      <p>1.6.	A plataforma de vendas dos Ingresso/Produto/Pacote/Experiência é de propriedade do Parque Bondinho Pão de Açúcar Tecnologia Eletrônica Ltda., prestadora de serviços da Companhia Caminho Aéreo Pão De Açúcar.</p>
      <p>1.7.	O Parque Bondinho Pão de Açucar presta serviços de venda e divulgação dos eventos, não fazendo parte da produção ou organização dos passeios divulgados no site.</p>
      <p>1.8.	As regras de compra e cancelamento são estabelecidas pela Companhia Caminho Aéreo Pão de Açúcar.</p>

      <h6>2.	Preços, Pagamentos e Pedidos:</h6>

      <p>2.1.	Todos os preços dos Ingressos, Pacotes, Produtos e Experiências comercializados por meio dos nossos canais oficiais incluem tributos incidentes na operação, conforme legislação vigente.</p>
      <p>2.2.	Os métodos de pagamentos aceitos incluem cartões de débito e dinheiro (apenas para compras presenciais), cartões de crédito (Visa / Elo / Mastercard / Diners / Amex / Hipercard / Discover / JCB) e transferências via PIX.</p>
      <p>2.3.	Todas as compras estão sujeitas à verificação do cartão de crédito ou débito (se aplicável), ou outras verificações de segurança, de modo que poderão ser solicitados documentos de comprovação de cadastro para finalização de seu pedido. O pedido pode ser cancelado se não for aprovado em nosso processo de verificação ou se o pagamento não for recebido integralmente. Em raras circunstâncias, se o pagamento for cancelado pelo banco ou provedor de pagamento associado, nos reservamos o direito de cancelar e reembolsar qualquer pedido para o qual uma confirmação tenha sido enviada.</p>
      <p>2.4.	Reservamo-nos o direito de cancelar quaisquer pedidos que razoavelmente suspeitemos terem sido feitos de forma fraudulenta, sem qualquer aviso ao Usuário, e todos e quaisquer Itens obtidos como parte de tais pedidos serão anulados. Certifique-se de ler os detalhes completos da descrição do item e conferir a sua seleção antes da compra.</p>
      <p>2.5. A compra estará sujeita à disponibilidade de Ingresso/Produto/Pacote/Experiência e à aprovação da operadora de seu cartão de crédito ou compensação do boleto bancário, que poderá levar até 5 (cinco) dias. O cliente somente receberá a confirmação do agendamento do passeio após a confirmação do pagamento.</p>

      <h6>3.	Entrega:</h6>

      <p>3.1.	Se a compra for efetivada através de nosso site (www.bondinho.com.br) e a opção de entrega online do Ingresso/Produto/Pacote/Experiência estiver disponível, o Usuário é responsável por (i) se assegurar de fazer o login em sua conta e baixar seus Ingressos/Vouchers em tempo hábil antes do embarque/evento; ou (ii) fornecer um endereço de e-mail válido para entrega on-line de Ingressos/Vouchers e se assegurar de poder receber a entrega destes por e-mail (por exemplo, se assegurando que sua caixa de e-mail não rejeite, devolva ou impeça qualquer e-mail de ser entregue), de modo que também deve verificar sua caixa de e-mail de forma regular – incluindo lixo eletrônico e SPAM.</p>
      <p>3.2.	O Usuário não terá direito a qualquer reembolso quando o Ingresso/Voucher for perdido, não resgatado, não recebido em sua caixa de e-mail ou por sua falha em realizar o download de Ingressos/Vouchers através de sua conta em nosso site (www.bondinho.com.br), por resultado de sua falha em fornecer informações de entregas corretas e completas e/ou falha em garantir a sua posse com relação ao Ingresso/Voucher.</p>
      <p>3.3.	O Usuário deve entrar em contato conosco através de nosso SAC (<Link to={{ pathname: "https://sac.bondinho.com.br/hc/pt-br" }} target='_blank'>https://sac.bondinho.com.br/hc/pt-br</Link>) imediatamente se houver qualquer engano ou erro com seu Ingresso/Voucher, ou se não receber seus Ingresso/Voucher conforme solicitado e/ou conforme descrito na confirmação do pedido.</p>

      <h6>4.	Direitos e Obrigações do Usuário:</h6>

      <p>4.1.	Qualquer compra de Ingresso/Produto/Pacote/Experiência por meio de nossos canais oficiais (Site www.bondinho.com.br ou Bilheteria física) concede uma licença pessoal de acesso ao evento/local, esse passível de cancelamento nos termos deste instrumento.</p>
      <p>4.2.	Ao receber ou retirar seus Ingressos/Vouchers, guarde-os em local seguro. Não seremos responsáveis por quaisquer Ingresso/Voucher perdidos, roubados, extraviados, danificados ou destruídos após o recebimento pelo Usuário. A exposição à luz solar ou calor podem danificar os ingressos/vouchers físicos. Além disso, recomendamos que o Usuário não publique fotos ou detalhes de seus Ingressos/vouchers online, pois isso pode permitir que terceiros falsifiquem seus ingressos/vouchers ou comprometam a integridade destes e não seremos responsáveis se sua entrada for recusada como resultado.</p>
      <p>4.3.	Confira atentamente os dados de seu pedido (agendamento, dia, período, quantidades) antes da confirmação de sua compra. Não será permitida a troca do seu ingresso/voucher para outra data agendada, salvo nas hipóteses previstas na Política de Cancelamento (Cláusula 9). O seu Ingresso/Produto/Pacote/Experiência é um produto único, ou seja, após sua compra ele não estará mais disponível para venda.</p>
      <p>4.4.	Para compras com cartão de crédito de terceiros, é indispensável a apresentação de uma cópia do documento oficial de identificação com foto do titular do cartão de crédito utilizado na compra e uma cópia somente da frente do cartão. A não apresentação dos documentos fica sob pena de não ser autorizada a entrada.</p>
      <p>4.5.	Nas hipóteses de compra de Ingresso/Produto/Pacote/Experiência via internet, você pode apresentar seu Ingresso/Voucher em formato de papel (imprimindo o mesmo) ou em formato digital (pelo telefone celular ou tablet, por exemplo). Estes documentos conterão um 'QR Code' que será identificado nas catracas.</p>
      <p>4.6.	Ingressos e/ou Vouchers obtidos de fontes não autorizadas podem ser inválidos, perdidos, roubados ou falsificados; o Parque Bondinho Pão de Açucar, em verificando tal prática poderá revogá-los, anulá-los ou cancelá-los, sem direito a qualquer reembolso.</p>
      <p>4.7.	O Parque Bondinho Pão de Açúcar atenderá e/ou prestará suporte apenas ao Usuário, ou seja, a pessoa física ou jurídica devidamente cadastrada, e exclusivamente em aquisições que comprovadamente tenham ocorrido através da conta daquele Usuário junto ao site de venda de Ingresso/Produto/Pacote/Experiência, ou, pelo próprio comprador do ingresso/voucher físico, no caso da compra realizada na bilheteria.</p>
      <p>4.8.	A violação de qualquer um dos Termos e Condições do Parque ou Regras de Acesso, além de qualquer comportamento inaceitável que possa causar danos, incômodos ou ferimentos, dará ao Parque Bondinho Pão de Açucar o direito de expulsar o Usuário do local.</p>

      <h6>5.	Restrições de Ingressos/Vouchers:</h6>

      <p>5.1.	Os Ingressos/Vouchers podem ser vendidos sujeitos a certas restrições de entrada ou uso, como idade mínima para entrada. Quaisquer restrições serão exibidas ou notificadas ao Usuário antes ou no momento da sua reserva. É uma responsabilidade do Usuário verificar tais informações importantes exibidas durante o processo de compra. O Parque Bondinho Pão de Açúcar não será responsável se o Usuário ou qualquer convidado em sua reserva for recusado a admissão devido a uma falha em atender ou comprovar que este(s) atende(m) a quaisquer restrições (por exemplo, um requisito de idade mínima).</p>
      <p>5.2.	Deverão ser observadas as Regras de Descontos dispostas no site www.bondinho.com.br no ato da compra pelo Usuário.</p>
      <p>5.3.	Meia-Entrada e Gratuidade: as pessoas que adquirirem ingressos do tipo meia entrada deverão se dirigir à Bilheteria para verificação dos documentos originais/impressos, que validem o direito ao benefício e receberão pulseiras de identificação.</p>

      <h6>6.	Horários e Entradas de Evento(s)/ ou Acesso ao Parque:</h6>

      <p>6.1.	Observe que os horários de início do(s) Evento(s) ou serviços anunciados – tais como: Visita Guiada, Café da Manhã, entre outros – estão sujeitos a alterações.</p>
      <p>6.2.	Ao participar de um Evento, o Usuário concorda em ser fotografado, filmado e/ou gravado em relação ao Evento e/ou para proteção e segurança, incluindo filmagem pela polícia. Ademais, concorda que as fotografias, vídeos e gravações de áudio e/ou gravações audiovisuais resultantes podem ser usadas em toda e qualquer mídia para qualquer finalidade a qualquer momento em todo o mundo pelo Parque Bondinho Pão de Açucar e seus parceiros.</p>

      <h6>7.	Cancelación y Modificaciones de Eventos/Servicios por parte del Parque Bondinho Pão de Açúcar®:</h6>

      <p>7.1.	Si un Evento o Servicio es cancelado, reprogramado o sustancialmente modificado, haremos todos los esfuerzos razonables para comunicar dicha información. Sin embargo, no podemos garantizar que el Usuario será notificado de tales cambios, ya que estos pueden ocurrir en cualquier momento, incluso por decisión de una autoridad pública, caso fortuito y/o fuerza mayor.</p>
      <p>7.2.	Si un Evento para el cual el Usuario compró Boletos/Vouchers o Paquetes es cancelado completamente y no se reprograma, el pedido será cancelado, y el Usuario será reembolsado por el monto pagado por su boleto o paquete.</p>
      <p>7.3.	Reprogramación: Si un Evento o Servicio/Experiencia para el cual el Usuario compró un Boleto/Producto/Paquete/Experiencia es reprogramado, los Boletos/Vouchers y Paquetes serán válidos para la nueva fecha. Sin embargo, si el Usuario nos notifica dentro del plazo especificado según la situación que no está interesado o no puede asistir a la nueva fecha programada, podrá cancelar su pedido y recibir el reembolso total del precio pagado por sus Boletos/Vouchers o Paquetes.</p>
      <p>7.3.1.	Si no se especifica un plazo en la situación concreta, el plazo estándar será de 48 horas antes de la nueva fecha del Evento o Servicio reprogramado.</p>
      <p>7.4.	Si el Usuario no nos notifica dentro del plazo aplicable que no asistirá al Evento o Servicio/Experiencia reprogramado, se considerará una reconfirmación de su pedido, y no podrá solicitar un reembolso.</p>
      <p>7.5.	Para solicitar el reembolso según la Cláusula 7.3., el Usuario deberá seguir las instrucciones proporcionadas en la notificación de cambio recibida o solicitarlo por escrito a través del "SAC" (Servicio de Atención al Cliente) del Parque Bondinho Pão de Açúcar® (<Link to={{ pathname: "https://sac.bondinho.com.br/hc/pt-br/requests/new" }} target='_blank'>https://sac.bondinho.com.br/hc/pt-br/requests/new</Link>) o mediante el correo electrónico (<a href={ "mailto:sac@bondinho.com.br"}>sac@bondinho.com.br</a>).</p>
      <p>7.6.	Los reembolsos se realizarán a través del mismo método de pago utilizado en la compra inicial.</p>
      
      <h6>8.	Limitação de responsabilidade:</h6>

      <p>8.1.	Na extensão máxima permitida por lei, o Parque Bondinho Pão de Açúcar e seus Parceiros não serão responsáveis por quaisquer perdas, ferimentos ou danos a qualquer pessoa (incluindo o Usuário) ou propriedade, nos seguintes termos:</p>
      <p>(a)	em quaisquer circunstâncias em que não haja violação de contrato ou dever legal de cuidado devido por nós ou pelo Parceiro de Evento ou Serviço/Experiência;</p>
      <p>(b)	em circunstâncias em que tal perda ou dano não seja diretamente resultado de qualquer violação; ou</p>
      <p>(c) na medida em que qualquer aumento em qualquer perda ou dano resulte da negligência, imprudência, imperícia ou violação, do Usuário ou Visitante, dos termos e condições aplicáveis e/ou a quaisquer leis ou regulamentos aplicáveis.</p>
      
      <h6>9.	Política de Cancelación y Reprogramación por parte del Consumidor:</h6>
      
      <p>9.1.	Conforme al artículo 49 del Código de Defensa del Consumidor, el pedido de cancelación podrá realizarse en los próximos 7 (siete) días corridos si la compra se realizó fuera del establecimiento del Parque Bondinho Pão de Açúcar®. En este caso, se garantiza el reembolso total del valor pagado por el Boleto/Producto/Paquete/Experiencia, en la misma forma en que se efectuó el pago.</p>
      <p>9.1.1.	Para compras realizadas dentro del establecimiento (taquilla física o tótems del  Parque Bondinho Pão de Açúcar®), si el Usuario solicita la cancelación del Boleto/Producto/Paquete/Experiencia, se garantiza el reembolso completo siempre que el pedido de cancelación se realice con al menos 24 horas de anticipación respecto al Evento/Embarque indicado en el Boleto/Producto/Paquete/Experiencia, según lo estipulado en la Cláusula 9.2.</p>
      <p>9.2.	En caso de cancelación de Boleto/Producto/Paquete/Experiencia, realizada con anticipación de 23h59min antes del inicio del Evento/Embarque indicado en el Boleto/Producto/Paquete/Experiencia, se aplicará una retención del 20% del valor pagado.</p>
      <p>9.3.	No se aceptarán cancelaciones solicitadas después del inicio del paseo.</p>
      <p>9.4.	Si es necesario reprogramar, el visitante puede solicitarlo con más de 24 horas de antelación al horario de inicio del paseo, siempre que haya disponibilidad para la nueva fecha y hora deseadas. Si la solicitud de reprogramación se realiza con menos de 24 horas de antelación al horario del paseo, se permitirá la reprogramación solo una vez, según la disponibilidad del Parque, y no será posible realizar nuevas reprogramaciones, independientemente del nuevo horario establecido.</p>
      <p>9.4.1. Podrán reprogramarse los Boletos/Vouchers o Paquetes adquiridos a través de la taquilla o del sitio web www.parquebondinho.com.</p>
      <p>9.4.2. No se permitirá la reprogramación si la solicitud se realiza después de la fecha y hora del Evento/Embarque.</p>
      <p>9.4.3. Solo el Usuario titular de la compra puede solicitar la reprogramación.</p>
      <p>9.5.	Los reembolsos indicados aplican a Boletos o Paquetes comprados directamente en la taquilla física o en línea a través del sitio web www.parquebondinho.com.br.</p>
      <p>9.6.	Para compras realizadas en canales en línea, las cancelaciones deben ser solicitadas por el cliente a través del sitio web www.parquebondinho.com.br o nuestros canales de atención. Las compras realizadas en las instalaciones del Parque deben cancelarse directamente en la taquilla o mediante nuestros canales de atención.</p>
      <p>9.6.1.	Solo el Usuario titular de la compra puede solicitar la cancelación.</p>
      <p>9.7.	Una vez recibida la confirmación de cancelación, se reembolsará el monto pagado según lo estipulado en las Cláusulas 9.1 a 9.3., utilizando el mismo método de pago en los siguientes plazos y condiciones:</p>
      <p>9.7.1. Para compras en efectivo, el reembolso será inmediato en la taquilla, siempre que se cumplan las disposiciones de las Cláusulas 9.1 a 9.3.</p>
      <p>9.7.2. Para compras con tarjeta de crédito, el Parque Bondinho Pão de Açúcar® comunicará el reembolso a la entidad emisora de la tarjeta de crédito, en un máximo de 5 días hábiles.</p>
      <p>9.7.3. El crédito dependerá del procesamiento del banco emisor y la fecha de cierre del estado de cuenta, pudiendo visualizarse en hasta tres ciclos de facturación, plazo que es independiente del Parque Bondinho Pão de Açúcar®, ya que funciona de acuerdo a los procedimientos internos de cada administradora de tarjeta de crédito. Recomendamos que sea realizada una consulta directa con el banco emisor.</p>
      <p>9.7.4. Para compras con tarjeta de débito en la taquilla, el Parque Bondinho Pão de Açúcar® comunicará el reembolso a la entidad financiera en un máximo de 5 días hábiles. En este caso, recomendamos que sea realizada una consulta directa con la Institución Financiera responsable.</p>
      <p>9.7.5. Para pagos vía PIX, el proceso de reembolso se iniciará en un máximo de 24 horas (1 día) tras la confirmación de la cancelación, completándose en un plazo máximo de 72 horas, contados a partir de la fecha que el Usuario envíe los datos bancarios para el reembolso. El valor debe ser devuelto en la misma cuenta usada para el pago del pedido.</p>
      <p>9.7.6. El Parque Bondinho Pão de Açúcar® no se responsabiliza por retrasos causados en el procedimiento previsto por la Cláusula 9.7.5, en caso de información incorrecta proporcionada por el Usuario para recibir el reembolso.</p>
      <p>9.8.	En el caso de la compra de un Paquete, la cancelación/reprogramación de un producto implicará la cancelación/reprogramación del otro. Por ejemplo, en el caso del Paquete de Visita Guiada, que incluye la Entrada y la Visita Guiada, si se cancela/reprograma uno de los servicios, el otro también será cancelado/reprogramado. De este modo, el Usuario que desee únicamente el servicio de acceso al Parque deberá adquirir la Entrada correspondiente.</p>
      <p>9.9.	En caso de que el Usuario y las demás personas para quienes haya adquirido Entradas, Paquetes o Vales no se presenten en la hora y el lugar programados para el inicio del Embarque o Evento, sin previa comunicación, resultará en un “No Show”, lo que, en adición a lo dispuesto en la Cláusula 9.3, no se reembolsarán los valores pagados.</p>
      <p>9.10. En el caso de abandono o desistimiento del Evento después de iniciado, tampoco habrá derecho a reembolso o compensación por los servicios no utilizados, ya que los operadores y guías ya habrán preparado toda la infraestructura contratada.</p>
    </div>
  );
}

export default TermsOfPurchasePT;