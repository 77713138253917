function DiscountRulesTourMaravilhaES(props: any) {
    return (
        <>
            <p className="my-2">En todos los casos, <b>es necesario presentar documentación acreditativa.</b></p>
            <p>Los descuentos son concedidos para nacidos o residentes del {props.productCode === process.env.REACT_APP_CARIOCA_STATE ? "Estado de RJ" : "Rio de Janeiro y Grande Rio,"} mediante presentación individual de los siguientes documentos originales (o copia certificada), digital o impreso.</p>
            <ul>
                <li>
                    <p><b>Adultos Residentes: </b>Comprobante de residencia en su nombre (agua, gas, luz, celular, internet, tarjeta o teléfono) y documento de identificación oficial con foto (digital o impreso).</p>
                </li>
                <li>
                    <p><b>Adultos nacidos: </b>Documento de identificación oficial con foto, (digital o impreso).</p>
                </li>
                <li>
                    <p><b>Niños Residentes (3 a 12 años): </b>Certificado de nacimiento original (o copia certificada), o documento oficial de identificación con foto, que acredite la filiación del titular de la residencia, (digital o impreso).</p>
                </li>
                <li>
                    <p><b>Niños nacidos (3 a 12 años): </b>Certificado de nacimiento original (o copia certificada) o documento oficial de identificación con foto (digital o impreso).</p>
                </li>
                <li>
                    <p><b>Cónyuge: </b>Presentación del certificado de matrimonio o unión estable original (o copia autenticada), documento oficial de identificación con foto (o copia certificada) y comprobante de residencia en titularidad del cónyuge (digital o impreso).</p>
                </li>
            </ul>
            <p>Los descuentos son personales e intransferibles.</p>
            <p>La empresa se reserva el derecho de suspender las promociones en días de evento en el Parque, en caso de saturación agotada, por motivos de mantenimiento o fuerza mayor. Promoción válida hasta agotar existencias.</p>
        </>
    )
}

export default DiscountRulesTourMaravilhaES;