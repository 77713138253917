/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from "react";

import { Row, Col, InputGroup, Modal } from 'react-bootstrap';

import { Controller, useForm } from "react-hook-form";
import InputMask from "react-input-mask";
//import { ErrorMessage } from '@hookform/error-message';
import { useTranslation } from "react-i18next";
import Form from 'react-bootstrap/Form';
//import SingleCalendar from "../../../../../../components/Calendar/SingleCalendar";
import SingleCalendarBirthday from "../../../../../../components/Calendar/SingleCalendarBirthday";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ErrorMessage } from "@hookform/error-message";

export interface propForm {
    action: any,
    choice: any,
    supplierControlObject: any
};

const FormPersonal: React.FC<propForm> = React.memo(({
    action, choice, supplierControlObject
}: propForm) => {
    const { t } = useTranslation();
    const [isLegal, setIsLegal] = useState<boolean>(/* supplierControlObject?.compType !== 1 ? false : true */ true);
    const [date, setDate] = useState<any>([]);
    //const [cpf, setCpf] = useState<any>([]);

    const [cpfValidation, setCpfValidation] = useState<boolean>(false);   ///usado nos atributos "isValid" e "isInvald" do cpf
    const [passwordStr, setPasswordStr] = useState<any>(0);
    const [passwordStrColor, setPasswordStrColor] = useState<any>('#ccc');
    const [passwordStrText, setPasswordStrText] = useState<any>('');
    const [passwordValidation, setPasswordValidation] = useState<boolean>(false);   ///usado nos atributos "isValid" e "isInvald" dos inputs

    const [modalShow, setModalShow] = useState<any>(false);
    const [modalMessage, setModalMessage] = useState<any>('Carregando');
    const [modalLog, setModalLog] = useState<any>(null);

    choice(isLegal);

    var regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()\-_=+{}[\]|;:'",.<>/?])\S{8,}$/;
    var regexLetter = /^(?=.*[A-Za-z]{1})/;
    var regexNumber = /^(?=.*\d)/;
    var regexSymble = /^(?=.*[@$!%*#?&])/;

    const validated = false;

    const {
        control,
        handleSubmit,
        watch,
        setValue,
        getValues,
        reset,
        formState: { errors },
    } = useForm({
        defaultValues: {
            compType: supplierControlObject ? supplierControlObject.compType : '',
            /* Pessoa Física */
            clientsType: supplierControlObject ? supplierControlObject.clientsType : '9',
            firstName: supplierControlObject ? supplierControlObject.firstName : '',
            lastName: supplierControlObject ? supplierControlObject.lastName : '',
            email: supplierControlObject ? supplierControlObject.email : '',
            cellPhone: supplierControlObject ? supplierControlObject.cellPhone : '',
            cpf: supplierControlObject ? supplierControlObject.cpf : '',
            rg: supplierControlObject ? supplierControlObject.rg : '',
            orIssuer: supplierControlObject ? supplierControlObject.orIssuer : '',
            password: supplierControlObject ? supplierControlObject.password : '',
            confirm: supplierControlObject ? supplierControlObject.password : '',

            /* Pessoa Jurídica */
            fantasyName: supplierControlObject ? supplierControlObject.fantasyName : '',
            socialReason: supplierControlObject ? supplierControlObject.socialReason : '',
            clientsTypeJuridico: supplierControlObject ? supplierControlObject.clientsType : '2',
            cnpj: supplierControlObject ? supplierControlObject.cnpj : '',
            companyEmail: supplierControlObject ? supplierControlObject.companyEmail : '',
            municipalId: supplierControlObject ? supplierControlObject.municipalId : '',
            estadualId: supplierControlObject ? supplierControlObject.estadualId : '',
            phone: supplierControlObject ? supplierControlObject.phone : '',
        },
    });

    useEffect(() => {
        setValue('cpf', supplierControlObject?.cpf);
        setDate(supplierControlObject?.birthDate?.split("-").reverse().join("/"));
    }, [setValue, supplierControlObject?.birthDate, supplierControlObject?.cpf])

    useEffect(() => {
        Validation();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    function Validation() {
        var strength: any = 0;
        var password = (document.getElementById('password') as HTMLInputElement)?.value;

        if (regexNumber.exec(password)) {
            strength += 1;
        }

        if (regexSymble.exec(password)) {
            strength += 1;
        }

        if (regexLetter.exec(password)) {
            strength += 1;
        }

        if (!regex.exec(password)) {
            setPasswordValidation(false);
        } else {
            strength = 4;
            setPasswordValidation(true);
        }

        if (strength === 0) {
            setPasswordStrColor('#ccc');
            setPasswordStrText('');
        } else if (strength === 1) {
            setPasswordStrColor('red');
            setPasswordStrText(t("affiliates.weak"));
        } else if (strength === 2 || strength === 3) {
            setPasswordStrColor('#e0e00d');
            setPasswordStrText(t("affiliates.medium"));
        } else {
            setPasswordStrColor('green');
            setPasswordStrText(t("affiliates.strong"));
        }

        setPasswordStr(strength);

        return true;

    }

    const category = watch(`clientsType`, "9");
    const categoryJuridico = watch(`clientsTypeJuridico`, "2");

    //supplierType e compType

    const onSubmit = (data: any) => {
        if (isLegal === true && data.phone.replaceAll("_", "").replaceAll("(", "").replaceAll(")", "").replaceAll("-", "").replaceAll(" ", "").length < 10) {
            setModalShow(true);
            setModalMessage("Por favor, preencha um telefone da empresa válido!");
            setModalLog(1);
            return;
        }

        if (data.cellPhone.replaceAll("_", "").replaceAll("(", "").replaceAll(")", "").replaceAll("-", "").replaceAll(" ", "").length < 10) {
            setModalShow(true);
            setModalMessage("Por favor, preencha um telefone válido!");
            setModalLog(1);
            return;
        }


        if (date === undefined) {
            setModalShow(true);
            setModalMessage("Por favor, preencha a data de nascimento!");
            setModalLog(1);
            return;
        }

        if (data.password !== data.confirm) {
            setModalShow(true);
            setModalMessage("Senhas diferentes!");
            setModalLog(1);
            return;
        }

        if (CpfValidator(data.cpf) === false) {
            setModalShow(true);
            setModalMessage("CPF inválido!!");
            setModalLog(1);
            return;
        }

        if (isLegal !== false) {
            if (typeof data.municipalId === typeof '') {
                data.municipalId = Number(data.municipalId.split('.').join('').split('-').join('').split('/').join(''));
            }
            if (typeof data.estadualId === typeof '') {
                data.estadualId = Number(data.estadualId.split('.').join('').split('-').join('').split('/').join(''));
            }
        } else {
            data.municipalId = 0;
            data.estadualId = 0;
        }

        if (data.password.length >= 8 && passwordValidation === true) {
            //setRegexValid(false);
            data.compType = isLegal === false ? 2 : 1;
            data.clientsType = isLegal === false ? Number(getValues('clientsType')) : Number(getValues('clientsTypeJuridico'));
            data.clientsTypeJuridico = undefined
            data.birthDate = date.length > 1 ? date.split("/").reverse().join("-") : date[0].split("/").reverse().join("-");
            data.supplierIdentity = "";
            data.confirm = undefined;
            data.cpf = data.cpf?.split(".").join("").replace("-", "");
            data.cnpj = data.cnpj?.split(".").join("").replace("-", "").replace("/", "");
            data.cellPhone = data.cellPhone?.replace("_", "").replace("(", "").replace(")", "").replace("-", "").replace(" ", "");
            data.phone = data.phone.replace("_", "").replace("(", "").replace(")", "").replace("-", "").replace(" ", "");
            action(data);
        } else {
            //setRegexValid(true);
            setModalShow(true);
            setModalMessage("Senha fraca!!");
            setModalLog(1);
        }

    }

    function CpfValidator(strCPF: any) {
        strCPF = strCPF.replace(/\./g, '').replace(/-/g, '').replace(/_/g, '');  //remove ".", "-" e "-" que a máscara coloca
        var Soma;
        var Resto;
        Soma = 0;

        if (strCPF === "00000000000") {
            setCpfValidation(false)
            return false;
        }

        for (var i: any = 1; i <= 9; i++) Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i);
        Resto = (Soma * 10) % 11;

        if ((Resto === 10) || (Resto === 11)) Resto = 0;
        if (Resto !== parseInt(strCPF.substring(9, 10))) {
            setCpfValidation(false)
            return false;
        }

        Soma = 0;
        for (i = 1; i <= 10; i++) Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (12 - i);
        Resto = (Soma * 10) % 11;

        if ((Resto === 10) || (Resto === 11)) Resto = 0;
        if (Resto !== parseInt(strCPF.substring(10, 11))) {
            setCpfValidation(false)
            return false;
        }

        setCpfValidation(true)
        return true;
    }

    useEffect(() => {
        reset();
        //setValue('compType','');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLegal])

    const handleBlurConfirmPassword = () => {
        let data: any = getValues();

        if (data.password !== data.confirm) {
            setModalShow(true);
            setModalMessage("Senhas diferentes!");
            setModalLog(1);
        }
    }

    return (
        <>
            <div>
                <Form noValidate validated={validated} onSubmit={handleSubmit(onSubmit)} autoComplete="false">
                    <Row className="mb-4">
                        <Form.Group as={Col} md="1">
                            <span>{t("affiliates.iAm")}</span>
                        </Form.Group>
                        {/* <Form.Group as={Col} md="2" controlId="">
                            <div className="d-flex">
                                <Controller
                                    control={control}
                                    name="compType"
                                    render={({ field }: any) => (
                                        <Form.Check
                                            {...field}
                                            onClick={() => { setIsLegal(false) }}
                                            checked={isLegal === false}
                                            type="radio"
                                            variant="standard"
                                            margin="normal"
                                            value="1"
                                            required
                                            className="mx-2"
                                        />
                                    )}
                                />
                                <Form.Label className="mb-0">
                                {t("affiliates.physicalPerson")}
                                </Form.Label>
                            </div>
                        </Form.Group> */}
                        <Form.Group as={Col} md="2" controlId="">
                            <div className="d-flex">
                                <Controller
                                    control={control}
                                    name="compType"
                                    render={({ field }: any) => (
                                        <Form.Check
                                            {...field}
                                            onClick={() => { setIsLegal(true) }}
                                            checked={isLegal === true}
                                            type="radio"
                                            variant="standard"
                                            margin="normal"
                                            value="2"
                                            required
                                            className="mx-2"
                                        />
                                    )}
                                />
                                <Form.Label className="mb-0">
                                    {t("affiliates.legalPerson")}
                                </Form.Label>
                            </div>
                        </Form.Group>
                    </Row>
                    {
                        isLegal === false
                            ?
                            <>
                                <Row>
                                    <Form.Group as={Col} md={6} controlId="formResourcesType" className="mb-3">
                                        <Form.Label>
                                            {t("affiliates.formName")}
                                        </Form.Label>
                                        <Controller
                                            control={control}
                                            name="firstName"
                                            rules={{ required: { value: true, message: t("affiliates.formError") } }}
                                            render={({ field }: any) => (
                                                <Form.Control
                                                    {...field}
                                                    aria-invalid={errors?.firstName ? "true" : ""}
                                                    variant="standard"
                                                    margin="normal"
                                                    autoComplete="new-password"
                                                />
                                            )}
                                        />
                                        <ErrorMessage
                                            errors={errors}
                                            name="firstName"
                                            render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col} md={6} controlId="formVehiclesType" className="mb-3">
                                        <Form.Label>
                                            {t("affiliates.formLastName")}
                                        </Form.Label>
                                        <Controller
                                            control={control}
                                            name="lastName"
                                            rules={{ required: { value: true, message: t("affiliates.formError") } }}
                                            render={({ field }: any) => (
                                                <Form.Control
                                                    {...field}
                                                    aria-invalid={errors?.lastName ? "true" : ""}
                                                    variant="standard"
                                                    margin="normal"
                                                    autoComplete="new-password"
                                                />
                                            )}
                                        />
                                        <ErrorMessage
                                            errors={errors}
                                            name="lastName"
                                            render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                        />
                                    </Form.Group>
                                </Row>
                                <Row>
                                    <Form.Group as={Col} md={6} className="d-flex flex-column mb-3">
                                        <Form.Label>
                                            {t("affiliates.formBirthDate")}
                                        </Form.Label>
                                        <InputGroup
                                            className=""
                                            size="sm"
                                            placeholder="Quando?"
                                        >
                                            <SingleCalendarBirthday setValue={setDate} defaultTime={date} />
                                        </InputGroup>
                                    </Form.Group>
                                    <Form.Group as={Col} md={6} controlId="formVehiclesType" className="mb-3">
                                        <Form.Label>
                                            {t("affiliates.formEmail")}
                                        </Form.Label>
                                        <Controller
                                            control={control}
                                            name="email"
                                            rules={{ required: { value: true, message: t("affiliates.formError") } }}
                                            render={({ field }: any) => (
                                                <Form.Control
                                                    {...field}
                                                    type="email"
                                                    aria-invalid={errors?.email ? "true" : ""}
                                                    variant="standard"
                                                    margin="normal"
                                                    autoComplete="new-password"
                                                />
                                            )}
                                        />
                                        <ErrorMessage
                                            errors={errors}
                                            name="email"
                                            render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                        />
                                    </Form.Group>
                                </Row>
                                <Row>
                                    <Form.Group as={Col} md={6} controlId="formResourcesType" className="mb-3">
                                        <Form.Label>
                                            {t("affiliates.formPhone")}
                                        </Form.Label>
                                        <Controller
                                            control={control}
                                            name="cellPhone"
                                            rules={{ required: { value: true, message: t("affiliates.formError") } }}
                                            render={({ field }: any) => (
                                                <InputMask mask="(99) 99999-9999" value={field?.value} onChange={field?.onChange}>
                                                    {(inputProps: any) => (
                                                        <Form.Control
                                                            {...inputProps}
                                                            aria-invalid={errors?.cellPhone ? "true" : ""}
                                                            variant="standard"
                                                            margin="normal"
                                                            autoComplete="new-password"
                                                        />
                                                    )}
                                                </InputMask>
                                            )}
                                        />
                                        <ErrorMessage
                                            errors={errors}
                                            name="cellPhone"
                                            render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col} md={6} controlId="formVehiclesType" className="mb-3">
                                        <Form.Label>
                                            {t("affiliates.formCpf")}
                                        </Form.Label>
                                        <Controller
                                            control={control}
                                            name="cpf"
                                            rules={{ required: { value: true, message: t("affiliates.formError") } }}
                                            render={({ field }: any) => (
                                                <InputMask mask="999.999.999-99" value={field?.value} onChange={field?.onChange} onInput={(e: any) => { CpfValidator(e.target.value) }}>
                                                    {(inputProps: any) => (
                                                        <Form.Control
                                                            {...inputProps}
                                                            aria-invalid={errors?.cpf ? "true" : ""}
                                                            variant="standard"
                                                            margin="normal"
                                                            autoComplete="new-password"
                                                        />
                                                    )}
                                                </InputMask>
                                            )}
                                        />
                                        <ErrorMessage
                                            errors={errors}
                                            name="cpf"
                                            render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                        />
                                    </Form.Group>
                                </Row>
                                <Row>
                                    <Form.Group as={Col} md={6} controlId="formResourcesType" className="mb-3">
                                        <Form.Label className="notranslate">
                                            {t("affiliates.formRg")}
                                        </Form.Label>
                                        <Controller
                                            control={control}
                                            name="rg"
                                            rules={{ required: { value: true, message: t("affiliates.formError") } }}
                                            render={({ field }: any) => (
                                                <Form.Control
                                                    {...field}
                                                    aria-invalid={errors?.rg ? "true" : ""}
                                                    variant="standard"
                                                    margin="normal"
                                                    autoComplete="new-password"
                                                    onKeyPress={(e: any) => !/[0-9]/.test(e.key) && e.preventDefault()}
                                                />
                                            )}
                                        />
                                        <ErrorMessage
                                            errors={errors}
                                            name="rg"
                                            render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col} md={6} controlId="formVehiclesType" className="mb-3">
                                        <Form.Label>
                                            {t("affiliates.formIssuingBody")}
                                        </Form.Label>
                                        <Controller
                                            control={control}
                                            name="orIssuer"
                                            rules={{ required: { value: true, message: t("affiliates.formError") } }}
                                            render={({ field }: any) => (
                                                <Form.Control
                                                    {...field}
                                                    aria-invalid={errors?.orIssuer ? "true" : ""}
                                                    variant="standard"
                                                    margin="normal"
                                                    autoComplete="new-password"
                                                />
                                            )}
                                        />
                                        <ErrorMessage
                                            errors={errors}
                                            name="orIssuer"
                                            render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                        />
                                    </Form.Group>
                                </Row>
                                <Row>
                                    <Form.Group as={Col} md={6} controlId="formResourcesType" className="mb-3">
                                        <Form.Label>
                                            {t("affiliates.formCategory")}
                                        </Form.Label>
                                        <Controller
                                            control={control}
                                            name="clientsType"
                                            //rules={{ required: { value: true, message: t("affiliates.formError") } }}
                                            defaultValue={category}
                                            render={({ field }: any) => (
                                                <Form.Select
                                                    {...field}
                                                    aria-invalid={errors?.clientsType ? "true" : ""}
                                                    label="Categoria"
                                                    as="select"
                                                    variant="standard"
                                                    margin="normal"
                                                    required
                                                >
                                                    {/* <option value="" selected disabled>Selecione</option> */}
                                                    <option value="9" selected>Guia Turistico</option>
                                                    <option value="11">Motorista</option>
                                                    <option value="12">Recepcionista</option>
                                                    <option value="13">Outros</option>
                                                </Form.Select>
                                            )}
                                        />
                                        {/* <ErrorMessage
                                        errors={errors}
                                        name="supplierVehicleTypeModel"
                                        render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                    /> */}
                                    </Form.Group>
                                </Row>
                                <Row className="mb-3">
                                    <Form.Group as={Col} md={6} controlId="password" className="mb-3">
                                        <Form.Label>
                                            {t("affiliates.formPassword")}
                                        </Form.Label>
                                        <Controller
                                            control={control}
                                            name="password"
                                            rules={{ required: { value: true, message: t("affiliates.formError") } }}
                                            render={({ field }: any) => (
                                                <Form.Control
                                                    {...field}
                                                    type="password"
                                                    aria-invalid={errors?.password ? "true" : ""}
                                                    variant="standard"
                                                    //id="password"
                                                    margin="normal"
                                                    autoComplete="new-password"
                                                    onInput={() => Validation()}
                                                />
                                            )}
                                        />
                                        <div className="password-strength-container row d-flex justify-content-evenly">
                                            <div className="password-strength col-3" style={{ borderColor: `${passwordStr >= 1 ? passwordStrColor : ''}` }}></div>
                                            <div className="password-strength col-3" style={{ borderColor: `${passwordStr >= 2 ? passwordStrColor : ''}` }}></div>
                                            <div className="password-strength col-3" style={{ borderColor: `${passwordStr >= 3 ? passwordStrColor : ''}` }}></div>
                                            <div className="password-strength col-3" style={{ borderColor: `${passwordStr >= 4 ? passwordStrColor : ''}` }}></div>
                                        </div>
                                        <div className="d-flex justify-content-center" style={{ color: `${passwordStrColor}` }}>
                                            {passwordStrText}
                                        </div>
                                        <small style={{ fontSize: ".8rem", opacity: ".7" }}>{t("affiliates.formPasswordRule")}</small>

                                    </Form.Group>
                                    <Form.Group as={Col} md={6} controlId="formVehiclesType" className="mb-3">
                                        <Form.Label>
                                            {t("affiliates.formConfirmPassword")}
                                        </Form.Label>
                                        <Controller
                                            control={control}
                                            name="confirm"
                                            rules={{ required: { value: true, message: t("affiliates.formError") } }}
                                            render={({ field }: any) => (
                                                <Form.Control
                                                    {...field}
                                                    type="password"
                                                    aria-invalid={errors?.confirm ? "true" : ""}
                                                    variant="standard"
                                                    margin="normal"
                                                    onBlur={() => { handleBlurConfirmPassword() }}
                                                    autoComplete="new-password"
                                                />
                                            )}
                                        />
                                    </Form.Group>
                                </Row>
                            </>
                            :
                            <>
                                <Row>
                                    <Form.Group as={Col} md={6} controlId="formVehiclesType" className="mb-3">
                                        <Form.Label>
                                            {t("affiliates.formSocial")}
                                        </Form.Label>
                                        <Controller
                                            control={control}
                                            name="socialReason"
                                            rules={{ required: { value: true, message: t("affiliates.formError") } }}
                                            render={({ field }: any) => (
                                                <Form.Control
                                                    {...field}
                                                    aria-invalid={errors?.socialReason ? "true" : ""}
                                                    variant="standard"
                                                    margin="normal"
                                                    autoComplete="new-password"
                                                />
                                            )}
                                        />
                                        <ErrorMessage
                                            errors={errors}
                                            name="socialReason"
                                            render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col} md={6} controlId="formResourcesType" className="mb-3">
                                        <Form.Label>
                                            {t("affiliates.formFantasyName")}
                                        </Form.Label>
                                        <Controller
                                            control={control}
                                            name="fantasyName"
                                            rules={{ required: { value: true, message: t("affiliates.formError") } }}
                                            render={({ field }: any) => (
                                                <Form.Control
                                                    {...field}
                                                    aria-invalid={errors?.fantasyName ? "true" : ""}
                                                    variant="standard"
                                                    margin="normal"
                                                    autoComplete="new-password"
                                                />
                                            )}
                                        />
                                        <ErrorMessage
                                            errors={errors}
                                            name="fantasyName"
                                            render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                        />
                                    </Form.Group>
                                </Row>
                                <Row>
                                    <Form.Group as={Col} md={6} controlId="formResourcesType" className="mb-3">
                                        <Form.Label>
                                            {t("affiliates.formCnpj")}
                                        </Form.Label>
                                        <Controller
                                            control={control}
                                            name="cnpj"
                                            rules={{ required: { value: true, message: t("affiliates.formError") } }}
                                            render={({ field }: any) => (
                                                <InputMask mask="99.999.999/9999-99" value={field?.value} onChange={field?.onChange}>
                                                    {(inputProps: any) => (
                                                        <Form.Control
                                                            {...inputProps}
                                                            aria-invalid={errors?.cnpj ? "true" : ""}
                                                            variant="standard"
                                                            margin="normal"
                                                            autoComplete="new-password"
                                                        />
                                                    )}
                                                </InputMask>
                                            )}
                                        />
                                        <ErrorMessage
                                            errors={errors}
                                            name="cnpj"
                                            render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col} md={6} controlId="formVehiclesType" className="mb-3">
                                        <Form.Label>
                                            {t("affiliates.formCompanyEmail")}
                                        </Form.Label>
                                        <Controller
                                            control={control}
                                            name="companyEmail"
                                            rules={{ required: { value: true, message: t("affiliates.formError") } }}
                                            render={({ field }: any) => (
                                                <Form.Control
                                                    {...field}
                                                    aria-invalid={errors?.companyEmail ? "true" : ""}
                                                    variant="standard"
                                                    margin="normal"
                                                    autoComplete="new-password"
                                                />
                                            )}
                                        />
                                        <ErrorMessage
                                            errors={errors}
                                            name="companyEmail"
                                            render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                        />
                                    </Form.Group>
                                </Row>
                                <Row>
                                    <Form.Group as={Col} md={6} controlId="formResourcesType" className="mb-3">
                                        <Form.Label>
                                            {t("affiliates.formCompanyPhone")}
                                        </Form.Label>
                                        <Controller
                                            control={control}
                                            name="phone"
                                            rules={{ required: { value: true, message: t("affiliates.formError") } }}
                                            render={({ field }: any) => (
                                                <InputMask mask="(99) 99999-9999" value={field?.value} onChange={field?.onChange}>
                                                    {(inputProps: any) => (
                                                        <Form.Control
                                                            {...inputProps}
                                                            aria-invalid={errors?.phone ? "true" : ""}
                                                            variant="standard"
                                                            margin="normal"
                                                            autoComplete="new-password"
                                                        />
                                                    )}
                                                </InputMask>
                                            )}
                                        />
                                        <ErrorMessage
                                            errors={errors}
                                            name="phone"
                                            render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col} md={6} controlId="formResourcesType" className="mb-3">
                                        <Form.Label>
                                            {t("affiliates.formCategory")}
                                        </Form.Label>
                                        <Controller
                                            control={control}
                                            defaultValue={categoryJuridico}
                                            name="clientsTypeJuridico"
                                            rules={{ required: { value: false, message: t("affiliates.formError") } }}
                                            render={({ field }: any) => (
                                                <Form.Select
                                                    {...field}
                                                    aria-invalid={errors?.clientsTypeJuridico ? "true" : ""}
                                                    label="Categoria"
                                                    as="select"
                                                    variant="standard"
                                                    margin="normal"
                                                    required

                                                >
                                                    {/* <option value='1'>OTA</option> */}
                                                    {/* <option value="2" selected>Operadora Turística</option> */}
                                                    <option value="3">Agência de Viagem</option>
                                                    {/* <option value="4">DMC</option> */}
                                                    {/* <option value='5'>Hotéis</option> */}
                                                    {/* <option value="6">Empresa</option> */}
                                                    {/* <option value="7">Pessoa Física</option> */}
                                                    {/* <option value="8">Fornecedores</option> */}
                                                    {/* <option value='10'>Cliente Interno</option> */}
                                                </Form.Select>
                                            )}
                                        />
                                    </Form.Group>
                                </Row>
                                <Row>
                                    <Form.Group as={Col} md={6} controlId="formResourcesType" className="mb-3">
                                        <Form.Label>
                                            {t("affiliates.formMunicipalRegistration")}
                                        </Form.Label>
                                        <Controller
                                            control={control}
                                            name="municipalId"
                                            rules={{ required: { value: true, message: t("affiliates.formError") } }}
                                            render={({ field }: any) => (
                                                <Form.Control
                                                    {...field}
                                                    aria-invalid={errors?.municipalId ? "true" : ""}
                                                    variant="standard"
                                                    margin="normal"
                                                    autoComplete="new-password"
                                                    onKeyPress={(e: any) => !/[0-9]/.test(e.key) && e.preventDefault()}
                                                    maxLength={15}
                                                />
                                            )}
                                        />
                                        <ErrorMessage
                                            errors={errors}
                                            name="municipalId"
                                            render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col} md={6} controlId="formVehiclesType" className="mb-3">
                                        <Form.Label>
                                            {t("affiliates.formStateRegistration")}
                                        </Form.Label>
                                        <Controller
                                            control={control}
                                            name="estadualId"
                                            rules={{ required: { value: false, message: t("affiliates.formError") } }}
                                            render={({ field }: any) => (
                                                <Form.Control
                                                    {...field}
                                                    aria-invalid={errors?.estadualId ? "true" : ""}
                                                    variant="standard"
                                                    margin="normal"
                                                    autoComplete="new-password"
                                                    onKeyPress={(e: any) => !/[0-9]/.test(e.key) && e.preventDefault()}
                                                    maxLength={15}
                                                />
                                            )}
                                        />
                                        {/* <ErrorMessage
                                            errors={errors}
                                            name="supplierVehicleTypeModel"
                                            render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                        /> */}
                                    </Form.Group>
                                </Row>
                                <Row>
                                    <Form.Group as={Col} md={6} controlId="formResourcesType" className="mb-3">
                                        <Form.Label>
                                            {t("affiliates.formRepresentativeName")}
                                        </Form.Label>
                                        <Controller
                                            control={control}
                                            name="firstName"
                                            rules={{ required: { value: true, message: t("affiliates.formError") } }}
                                            render={({ field }: any) => (
                                                <Form.Control
                                                    {...field}
                                                    aria-invalid={errors?.firstName ? "true" : ""}
                                                    variant="standard"
                                                    margin="normal"
                                                    autoComplete="new-password"
                                                />
                                            )}
                                        />
                                        <ErrorMessage
                                            errors={errors}
                                            name="firstName"
                                            render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col} md={6} controlId="formVehiclesType" className="mb-3">
                                        <Form.Label>
                                            {t("affiliates.formRepresentativeLastName")}
                                        </Form.Label>
                                        <Controller
                                            control={control}
                                            name="lastName"
                                            rules={{ required: { value: true, message: t("affiliates.formError") } }}
                                            render={({ field }: any) => (
                                                <Form.Control
                                                    {...field}
                                                    aria-invalid={errors?.lastName ? "true" : ""}
                                                    variant="standard"
                                                    margin="normal"
                                                    autoComplete="new-password"
                                                />
                                            )}
                                        />
                                        <ErrorMessage
                                            errors={errors}
                                            name="lastName"
                                            render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                        />
                                        {/* <ErrorMessage
                                            errors={errors}
                                            name="supplierVehicleTypeModel"
                                            render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                        /> */}
                                    </Form.Group>
                                </Row>
                                <Row>
                                    <Form.Group as={Col} md={6} controlId="formResourcesType" className="mb-3">
                                        <Form.Label>
                                            {t("affiliates.formRepresentativeEmail")}
                                        </Form.Label>
                                        <Controller
                                            control={control}
                                            name="email"
                                            rules={{ required: { value: true, message: t("affiliates.formError") } }}
                                            render={({ field }: any) => (
                                                <Form.Control
                                                    {...field}
                                                    type="email"
                                                    aria-invalid={errors?.email ? "true" : ""}
                                                    variant="standard"
                                                    margin="normal"
                                                    autoComplete="new-password"
                                                />
                                            )}
                                        />
                                        <ErrorMessage
                                            errors={errors}
                                            name="email"
                                            render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col} md={6} controlId="formVehiclesType" className="mb-3">
                                        <Form.Label>
                                            {t("affiliates.formRepresentativePhone")}
                                        </Form.Label>
                                        <Controller
                                            control={control}
                                            name="cellPhone"
                                            rules={{ required: { value: true, message: t("affiliates.formError") } }}
                                            render={({ field }: any) => (
                                                <InputMask mask="(99) 99999-9999" value={field?.value} onChange={field?.onChange}>
                                                    {(inputProps: any) => (
                                                        <Form.Control
                                                            {...inputProps}
                                                            aria-invalid={errors?.cellPhone ? "true" : ""}
                                                            variant="standard"
                                                            margin="normal"
                                                            autoComplete="new-password"
                                                        />
                                                    )}
                                                </InputMask>
                                            )}
                                        />
                                        <ErrorMessage
                                            errors={errors}
                                            name="cellPhone"
                                            render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                        />
                                    </Form.Group>
                                </Row>
                                <Row>
                                    <Form.Group as={Col} md={6} controlId="formResourcesType" className="mb-3">
                                        <Form.Label>
                                            {t("affiliates.formRepresentativeCpf")}
                                        </Form.Label>
                                        <Controller
                                            control={control}
                                            name="cpf"
                                            rules={{ required: { value: true, message: t("affiliates.formError") } }}
                                            render={({ field }: any) => (
                                                <InputMask mask="999.999.999-99" value={field?.value} onChange={field?.onChange} onInput={(e: any) => { CpfValidator(e.target.value) }}>
                                                    {(inputProps: any) => (
                                                        <Form.Control
                                                            {...inputProps}
                                                            aria-invalid={errors?.cpf ? "true" : ""}
                                                            variant="standard"
                                                            margin="normal"
                                                            autoComplete="new-password"
                                                        />
                                                    )}
                                                </InputMask>
                                            )}
                                        />
                                        <ErrorMessage
                                            errors={errors}
                                            name="cpf"
                                            render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col} md={6} controlId="formVehiclesType" className="mb-3">
                                        <Form.Label>
                                            {t("affiliates.formRepresentativeRg")}
                                        </Form.Label>
                                        <Controller
                                            control={control}
                                            name="rg"
                                            rules={{ required: { value: true, message: t("affiliates.formError") } }}
                                            render={({ field }: any) => (
                                                <Form.Control
                                                    {...field}
                                                    aria-invalid={errors?.rg ? "true" : ""}
                                                    variant="standard"
                                                    margin="normal"
                                                    autoComplete="new-password"
                                                    onKeyPress={(e: any) => !/[0-9]/.test(e.key) && e.preventDefault()}
                                                />
                                            )}
                                        />
                                        <ErrorMessage
                                            errors={errors}
                                            name="rg"
                                            render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                        />
                                    </Form.Group>
                                </Row>
                                <Row>
                                    <Form.Group as={Col} md={6} controlId="formResourcesType" className="mb-3">
                                        <Form.Label>
                                            {t("affiliates.formRepresentativeBirthDate")}
                                        </Form.Label>
                                        <InputGroup
                                            className=""
                                            size="sm"
                                            placeholder="Quando?"
                                        >
                                            <SingleCalendarBirthday setValue={setDate} defaultTime={date} />
                                        </InputGroup>
                                    </Form.Group>
                                </Row>
                                <Row>
                                    <Form.Group as={Col} md={6} controlId="password" className="mb-3">
                                        <Form.Label>
                                            {t("affiliates.formPassword")}
                                        </Form.Label>
                                        <Controller
                                            control={control}
                                            name="password"
                                            rules={{ required: { value: true, message: t("affiliates.formError") } }}
                                            render={({ field }: any) => (
                                                <Form.Control
                                                    {...field}
                                                    type="password"
                                                    aria-invalid={errors?.password ? "true" : ""}
                                                    variant="standard"
                                                    margin="normal"
                                                    autoComplete="new-password"
                                                    onInput={() => Validation()}
                                                />
                                            )}
                                        />
                                        <div className="password-strength-container row d-flex justify-content-evenly">
                                            <div className="password-strength col-3" style={{ borderColor: `${passwordStr >= 1 ? passwordStrColor : ''}` }}></div>
                                            <div className="password-strength col-3" style={{ borderColor: `${passwordStr >= 2 ? passwordStrColor : ''}` }}></div>
                                            <div className="password-strength col-3" style={{ borderColor: `${passwordStr >= 3 ? passwordStrColor : ''}` }}></div>
                                            <div className="password-strength col-3" style={{ borderColor: `${passwordStr >= 4 ? passwordStrColor : ''}` }}></div>
                                        </div>
                                        <div className="d-flex justify-content-center" style={{ color: `${passwordStrColor}` }}>
                                            {passwordStrText}
                                        </div>
                                        <small style={{ fontSize: ".8rem", opacity: ".7" }}>{t("affiliates.formPasswordRule")}</small>
                                    </Form.Group>
                                    <Form.Group as={Col} md={6} controlId="formVehiclesType" className="mb-3">
                                        <Form.Label>
                                            {t("affiliates.formConfirmPassword")}
                                        </Form.Label>
                                        <Controller
                                            control={control}
                                            name="confirm"
                                            rules={{ required: { value: true, message: t("affiliates.formError") } }}
                                            render={({ field }: any) => (
                                                <Form.Control
                                                    {...field}
                                                    type="password"
                                                    aria-invalid={errors?.confirm ? "true" : ""}
                                                    variant="standard"
                                                    margin="normal"
                                                    onBlur={() => { handleBlurConfirmPassword() }}
                                                    autoComplete="new-password"
                                                />
                                            )}
                                        />
                                        {/* <ErrorMessage
                                            errors={errors}
                                            name="supplierVehicleTypeModel"
                                            render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                        /> */}
                                    </Form.Group>
                                </Row>
                            </>
                    }

                    <Row className="d-flex justify-content-end pt-5">
                        <Col md={3}>
                            <button type="submit" className="btn btn-primary">{t("affiliates.formContinue")}</button>
                        </Col>
                    </Row>

                </Form>
                <Modal
                    className="modalAuth modal-validation"
                    show={modalShow}
                    onHide={() => {
                        setModalLog(null);
                        setModalMessage("Carregando");
                        setModalShow(false);
                    }}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <>
                        <Modal.Body
                            className="modal-body text-center sucess-pos modal-default d-flex flex-column justify-content-evenly px-4"
                        >
                            {modalLog === null ? (
                                <>
                                    <div className="loading-modal">
                                        <div className="load"></div>
                                    </div>
                                </>
                            ) : (
                                <div>
                                    {modalLog === 0 ? (
                                        <FontAwesomeIcon
                                            icon={["fal", "check"]}
                                            size="5x"
                                        />
                                    ) : (
                                        <FontAwesomeIcon
                                            icon={["fal", "times"]}
                                            size="5x"
                                        />
                                    )}
                                </div>
                            )}
                            <div>
                                <h5 className="mb-1">{modalLog === 1 ? "Erro na validação!" : modalLog === 0 ? "Sucesso!" : "Esperando validação!"}</h5>
                                <small style={{ color: "#707070" }}>{modalMessage}</small>
                            </div>
                            <div className="d-flex justify-content-center pt-3">
                                <button
                                    onClick={() => {
                                        setModalLog(null);
                                        setModalMessage("Carregando");
                                        setModalShow(false);
                                    }}
                                    className="btn btn-primary mx-2 w-25"
                                >
                                    Confirmar
                                </button>
                            </div>
                        </Modal.Body>
                    </>
                </Modal>
            </div>
        </>
    )
});

export default FormPersonal;