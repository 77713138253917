import React from 'react';

function ModalAlertPT() {
  const [christmasAlert, setChristmasAlert] = React.useState<boolean>();

  React.useEffect(() => {
    let date = new Date().toLocaleString('pt-BR');

    let dateNow = date
      .split(' ')[0]
      .split('/')
      .reverse()
      .join('')
      .replaceAll(',', '');

    if (`${dateNow}` <= '20231225') {
      setChristmasAlert(true);
    } else {
      setChristmasAlert(false);
    }
  }, []);

  return (
    <>
      <div className="mb-3">
        <p>
          <strong>
            O Parque Bondinho Pão de Açúcar tem uma{' '}
            <span>informação importante</span> para você! Fique ligado no nosso
            horário de funcionamento <span>especial</span> de final de ano:
          </strong>
        </p>
        <p>
          <strong>Horário de Funcionamento</strong>
        </p>

        {christmasAlert === true ? (
          <div>
            <p>
              <strong>
                24/12 (Véspera de Natal): <span>08h</span> às <span>17h</span>,
                entrada até <span>16h</span>.
              </strong>
            </p>
            <p>
              <strong>
                25/12 (Natal): <span>08h</span> às <span>20h</span>, entrada até{' '}
                <span>18h30</span>.
              </strong>
            </p>
          </div>
        ) : (
          <></>
        )}

        <p>
          <strong>
            31/12 (Véspera de Ano Novo): <span>08h</span> às <span>17h</span>,
            entrada até <span>16h</span>.
          </strong>
        </p>
        <p>
          <strong>
            01/01 (Ano Novo): <span>09h30</span> às <span>21h</span>, entrada
            até às <span>19h30</span>.
          </strong>
        </p>
        <p>
          <strong>
            Nos demais dias, de 26/12 a 7/1, o horário de funcionamento será das
            <span> 8h</span> às <span>21h</span>, com último embarque às{' '}
            <span>19h30</span>.
          </strong>
        </p>
        <p>
          <strong>Até breve!</strong>
        </p>
      </div>
      {/* <div>
                <p>O nosso sonho já começou!</p>
                <p>A Tirolesa, tão aguardada nova atração do Parque Bondinho, está chegando. Por isso, em sua visita, você poderá notar ou ouvir as obras dessa que será a Tirolesa com a vista mais linda do mundo.</p>
                <p>Agradecemos a sua compreensão e esperamos vê-lo em breve para curtir uma nova experiência de tirar o fôlego!</p>
            </div> */}
    </>
  );
}

export default ModalAlertPT;
