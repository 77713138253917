import React, { useEffect, useState } from "react";
import api from "../../services/api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";

import { Container, Row, Col } from "react-bootstrap";
import apiGoogleMaps from "../../services/apiGoogleMaps";
import configData from "../../config/config.json";
import InputMask from "react-input-mask";

import Form from "react-bootstrap/Form";
import { Controller, useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import Button from "react-bootstrap/Button";

import GlobalAlert from "../../components/Alert/GlobalAlert/GlobalAlert";

import "./Profile.scss";

import ModalDefault from "../../components/Modal/ModalDefault/ModalDefault";
import PageDafault from "../../templates/PageDafault";

import ApprovedTerms from "../../components/AppovedTerms/ApprovedTerms";
import GetCitysByState from "../../components/C2Points/GetCitysByState";
import GetStates from "../../components/C2Points/GetStates";

import SingleCalendar from "../../components/Calendar/SingleCalendar";
import monitoringAPI from "../../services/monitoring";

function Profile() {
  const [t] = useTranslation();
  //const [errorMessage, setErrorMessage] = useState<any>(null);

  const [modalContent, setModalContent] = useState<any>();
  const [log, setLog] = useState<any>();

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [cpf, setCpf] = useState("");
  const [passport, setPassport] = useState("");
  const [phone, setPhone] = useState("");
  const [whatsapp, setWhatsapp] = useState("");
  const [email, setEmail] = useState("");
  const [cep, setCEP] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [address, setAddress] = useState("");
  const [country, setCountry] = useState("");
  const [number, setNumber] = useState("");
  const [complement, setComplement] = useState("");
  const [foreign, setForeign] = useState<any>("");

  const [aceptPrivacy, setAceptPrivacy] = useState<any>(null);
  const [aceptWpp, setAceptWpp] = useState<any>(null);

  const profile = true;

  const [privacy, setPrivacy] = useState<any>(false);

  const [profileImg, setProfileImg] = useState<any>(null);
  const [, setProfileNameImg] = useState<any>("None");

  const [editProfile, setEditProfile] = useState<any>(false);

  const [sucess, setSucess] = useState<any>("Carregando...");

  const [disableState, setDisableState] = useState(false);
  const [disableCity, setDisableCity] = useState(false);
  const [disableAddress, setDisableAddress] = useState(false);

  const [isSelect, setIsSelect] = useState<boolean>(false);

  const [dateBirth, setDateBirth] = useState<any>();

  const validated = false;

  const imageHandler = (e: any) => {
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.readyState === 2) {
        setProfileImg(reader.result);
      }
    };
    reader.readAsDataURL(e.target.files[0]);
    setProfileNameImg(e.target.files[0].name);
  };

  const handleChangeFirstName = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setFirstName(newValue);
  };
  const handleChangeLastName = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setLastName(newValue);
  };
  /* const handleChangeCpf = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        setCpf(newValue);
    } */
  const handleChangePhone = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setPhone(newValue);
  };
  const handleChangeWhatsapp = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setWhatsapp(newValue);
  };

  /* const handleChangeEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        setEmail(newValue);
    } */

  const handleChangeCEP = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setCEP(newValue);
  };

  const handleChangeState = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setState(newValue);
  };

  const handleChangeCity = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setCity(newValue);
  };

  const handleChangeAddress = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setAddress(newValue);
  };

  const handleChangeCountry = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setCountry(newValue);
  };

  const handleChangeNumber = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setNumber(newValue);
  };

  const handleChangeComplement = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setComplement(newValue);
  };

  useEffect(() => {
    setDisableAddress(true);
    setDisableCity(true);
    setDisableState(true);
  }, []);

  useEffect(() => {
    const token = localStorage.getItem("bpasiteAuth") || "{}";
    const config = {
      headers: { Authorization: `Bearer ${JSON.parse(token)?.token?.token}` },
    };

    const getAuthenticateAccount = async () => {
      try {
        const { data } = await api.post("/api/LoginSite/GetPerfil", "", config);
        if (data.status !== 400) {
          monitoringAPI(200, 'LoginSite', 'GetPerfil', data?.data?.texto || data?.texto || 'Sucesso', '', '/api/LoginSite/GetPerfil', '');
          setFirstName(data.data.firstName);
          setLastName(data.data.lastName);
          setCpf(data.data.cpf);
          setPassport(data.data.passport);
          setPhone(data.data.phone);
          setWhatsapp(data.data.whatsapp);
          setEmail(data.data.email);
          setProfileImg(data.data.photo);
          setCEP(data.data.userComplement.postCode);
          setState(data.data.userComplement.state);
          setCity(data.data.userComplement.city);
          setAddress(data.data.userComplement.address);
          setCountry(data.data.userComplement.country);
          setAceptPrivacy(data.data.aceitePolitica);
          setAceptWpp(data.data.aceiteComunicacao);
          setForeign(data.data.foreign);
          setNumber(data.data.userComplement.number);
          setComplement(data.data.userComplement.complement);
          setDateBirth(data.data.birthdate);
        } else {
          monitoringAPI(400, 'LoginSite', 'GetPerfil', data?.data?.texto || data?.texto || 'Retornado status diferente de 200', '', '/api/LoginSite/GetPerfil', '');
        }
      } catch (error: any) {
        monitoringAPI(400, 'LoginSite', 'GetPerfil', error?.response?.data?.errorMessage || 'Retornado status diferente de 200', '', '/api/LoginSite/GetPerfil', '');
        if (error?.response?.status === 400) {
        }
      }
    };

    getAuthenticateAccount();
  }, []);

  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
    setValue,
    getValues,
  } = useForm();

  useEffect(() => {
    if (
      errors?.cep ||
      errors?.state ||
      errors?.city ||
      errors?.checkPolice !== undefined
    ) {
      setModalContent("error");
      setLog(1);
      setSucess("Preencher informações");
    }
  }, [errors?.city, errors?.cep, errors?.state, errors.checkPolice]);

  const watchState: any = watch(`state`, "");
  const watchCity: any = watch(`city`, "");

  const onSubmit = (objData: any) => {
    let checked: any = document.querySelector("#checkNew");
    objData.checkNew = checked.checked;

    objData.firstName = firstName;
    objData.lastName = lastName;
    objData.cellphone = phone;
    objData.whatsapp = whatsapp;
    objData.cep = cep;
    objData.state = state;
    objData.city = city;
    objData.address = address;
    objData.country = country;
    objData.number = number;
    objData.complement = complement;
    objData.date = dateBirth?.length === 1 ? dateBirth[0] : dateBirth;

    //setLoading(true)
    setModalContent("loading");

    const token = localStorage.getItem("bpasiteAuth") || "{}";
    const config = {
      headers: { Authorization: `Bearer ${JSON.parse(token)?.token?.token}` },
    };

    const getAuthenticateAccount = async () => {

      let req: any = {
        ///UPDATE LOGIN API
        firstName: objData.firstName,
        lastName: objData.lastName,
        cellphone: objData.cellphone,
        Whatsapp: objData.whatsapp,
        photo: profileImg,
        Birthdate: objData.date,
        userComplement: {
          address: objData.address,
          postCode: objData.cep,
          city: watchCity?.description || objData.city,
          state: watchState?.description || objData.state,
          country: objData.country,
          number: objData.number,
          complement: objData.complement,
        },
        aceitePolitica: getValues("checkPolice"),
        aceiteComunicacao: objData.checkNew,
      }

      try {
        const { data } = await api.post("/api/LoginSite/UpdateLogin", req, config);
        if (data.status !== 400) {
          if (data.statusCode === 400) {
            monitoringAPI(400, 'LoginSite', 'UpdateLogin', data?.data?.texto || 'Retornado status diferente de 200', '', '/api/LoginSite/UpdateLogin', req);
            setTimeout(() => {
              setSucess(data.errorMessage);
              setModalContent("error");
              setLog(data.data.log);
            }, 750);
          } else if (data.data.log === 1) {
            monitoringAPI(400, 'LoginSite', 'UpdateLogin', data?.data?.texto || 'Retornado status diferente de 200', '', '/api/LoginSite/UpdateLogin', req);
            setTimeout(() => {
              setSucess(data.data.texto);
              setModalContent("error");
              setLog(data.data.log);
            }, 750);
          } else {
            monitoringAPI(200, 'LoginSite', 'UpdateLogin', data?.data?.texto || 'Sucesso', '', '/api/LoginSite/UpdateLogin', req);
            setTimeout(() => {
              setSucess(data.data.texto);
              setModalContent("sucess");
              setLog(data.data.log);
            }, 750);

            //Altera a foto do perfil no header
            var newImage: any = localStorage.getItem("bpasiteAuth");
            newImage = JSON.parse(newImage);
            newImage.userModel.photo = profileImg;
            localStorage.setItem("bpasiteAuth", JSON.stringify(newImage));

            <GlobalAlert alertType={"success"}>{data.data.texto}</GlobalAlert>;

            setTimeout(() => {
              window.location.reload();
            }, 5000);
          }
        } else {
          monitoringAPI(400, 'LoginSite', 'UpdateLogin', data?.data?.texto || data?.texto || 'Retornado status diferente de 200', '', '/api/LoginSite/UpdateLogin', req);
        }
      } catch (error: any) {
        monitoringAPI(400, 'LoginSite', 'UpdateLogin', error?.response?.data?.errorMessage || 'Retornado status diferente de 200', '', '/api/LoginSite/UpdateLogin', '');
        //setLoading(false)
        if (error?.response?.status === 400) {
          setSucess("Preencher informações");
          setModalContent("error");
          setLog(1);
        } else if (error?.response?.status === 401) {
          window.location.href = window.location.origin + "/login";
        }
      }
    };

    getAuthenticateAccount();
  };

  const searchCEP = async (term: any) => {
    setIsSelect(false);
    setDisableState(false);
    setDisableCity(false);
    setDisableAddress(false);

    const response = await apiGoogleMaps.get("/geocode/json", {
      params: {
        address: term,
        key: configData.GOOGLE_KEYS.GOOGLE_MAPS,
      },
    });

    if (response.data.status === "OK") {
      response.data.results[0].address_components.forEach(function (
        place: any
      ) {
        switch (place.types[0]) {
          case "route":
            setAddress(place.long_name);
            setValue("address", place.long_name);
            setDisableAddress(true);
            break;

          case "administrative_area_level_2":
            setCity(place.long_name);
            setValue("city", place.long_name);
            setDisableCity(true);
            break;

          case "country":
            setCountry(place.long_name);
            break;

          case "administrative_area_level_1":
            setState(place.long_name);
            setValue("state", place.long_name);
            setDisableState(true);
            break;

          default:
        }
      });
    } else {
      setIsSelect(true);
      setDisableState(false);
      setDisableCity(false);
      setDisableAddress(false);
    }
  };

  const handleBlurCEP = (event: any) => {
    searchCEP(event.target.value);
  };

  function ScrollTop() {
    window.scrollTo(0, 0);
  }

  return (
    <>
      <PageDafault>
        <div className="container profile">
          <Container className="profile-container" fluid>
            <Form
              noValidate
              validated={validated}
              onSubmit={handleSubmit(onSubmit)}
            >
              <Row
                style={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "nowrap",
                }}
              >
                <h2 className="display-5 mb-4 w-auto"> {t("profile.title")}</h2>
                {editProfile === false ? (
                  <span
                    className="w-auto"
                    style={{
                      cursor: "pointer",
                      color: "#FF6600",
                      fontSize: "1.125rem",
                    }}
                    onClick={() => {
                      setEditProfile(!editProfile);
                    }}
                  >
                    {t("profile.edit")}
                    <FontAwesomeIcon
                      icon={["fal", "user-edit"]}
                      size="1x"
                      style={{ cursor: "pointer" }}
                      className="ml-2"
                    />
                  </span>
                ) : (
                  <></>
                )}
              </Row>

              <Row className="mb-3">
                <Form.Group className="mb-3">
                  <div className="label">
                    <label className="image-upload" htmlFor="input">
                      <h4>{t("profile.profilePhoto")}</h4>
                      <div
                        className="logo-info-company mx-3 mb-3 mt-2"
                        style={{
                          backgroundImage: `url(${profileImg})`,
                          backgroundSize: "cover",
                          cursor: editProfile === true ? "pointer" : "auto",
                        }}
                      >
                        {/* <img src={profileImg} alt="" /> */}
                      </div>
                    </label>
                    {editProfile === true ? (
                      <input
                        type="file"
                        accept="image/*"
                        name="image-upload"
                        id="input"
                        onChange={imageHandler}
                      />
                    ) : (
                      ""
                    )}
                  </div>
                </Form.Group>

                <h4 className="mt-3 mb-3">{t("profile.personalData.title")}</h4>

                <Form.Group as={Col} md="4" className="mb-4" controlId="">
                  <Form.Label>{t("profile.personalData.name")}</Form.Label>
                  {/* <input type="text" id="firstName" value={firstName} onChange={handleChangeFirstName} /> */}
                  {editProfile === true ? (
                    <>
                      <Controller
                        control={control}
                        name="firstName"
                        //rules={{ required: { value: true, message: 'Por favor, informe o nome' } }}
                        render={({ field }: any) => (
                          <Form.Control
                            {...field}
                            variant="standard"
                            margin="normal"
                            value={firstName}
                            onChange={handleChangeFirstName}
                            required
                          />
                        )}
                      />
                      <ErrorMessage
                        errors={errors}
                        name="firstName"
                        render={({ message }) => (
                          <div
                            style={{
                              width: "100%",
                              backgroundColor: "#f8d7da",
                              padding: "0 0.75rem",
                            }}
                          >
                            <small style={{ color: "red" }}>{message}</small>
                          </div>
                        )}
                      />
                    </>
                  ) : (
                    <Form.Text className="text-muted text-user-info">
                      {firstName}
                    </Form.Text>
                  )}
                </Form.Group>
                <Form.Group as={Col} md="4" className="mb-4" controlId="">
                  <Form.Label>{t("profile.personalData.lastname")}</Form.Label>
                  {editProfile === true ? (
                    <>
                      <Controller
                        control={control}
                        name="lastName"
                        //rules={{ required: { value: true, message: 'Por favor, informe o sobrenome' } }}
                        render={({ field }: any) => (
                          <Form.Control
                            {...field}
                            variant="standard"
                            margin="normal"
                            value={lastName}
                            onChange={handleChangeLastName}
                            required
                          />
                        )}
                      />
                      <ErrorMessage
                        errors={errors}
                        name="lastName"
                        render={({ message }) => (
                          <div
                            style={{
                              width: "100%",
                              backgroundColor: "#f8d7da",
                              padding: "0 0.75rem",
                            }}
                          >
                            <small style={{ color: "red" }}>{message}</small>
                          </div>
                        )}
                      />
                    </>
                  ) : (
                    <Form.Text className="text-muted text-user-info">
                      {lastName}
                    </Form.Text>
                  )}
                </Form.Group>
                <Form.Group as={Col} md="4" className="mb-4" controlId="">
                  {cpf && foreign === 2 ? (
                    <>
                      <Form.Label>{t("profile.personalData.cpf")}</Form.Label>
                      <Form.Text className="text-muted text-user-info">
                        {cpf}
                      </Form.Text>
                    </>
                  ) : (
                    <></>
                  )}
                  {passport && foreign === 1 ? (
                    <>
                      <Form.Label>
                        {t("profile.personalData.passport")}
                      </Form.Label>
                      <Form.Text className="text-muted text-user-info">
                        {passport}
                      </Form.Text>
                    </>
                  ) : (
                    <></>
                  )}
                </Form.Group>
                <Form.Group as={Col} md="4" className="mb-4" controlId="">
                  <Form.Label>{t("profileNew.date")}</Form.Label>
                  {editProfile === true ? (
                    <>
                      <Controller
                        control={control}
                        name="date"
                        render={({ field }: any) => (
                          <SingleCalendar setValue={setDateBirth} defaultTime={dateBirth} />
                        )}
                      />
                    </>
                  ) : (
                    <Form.Text className="text-muted text-user-info">
                      {dateBirth?.split("-").reverse().join("/") || "Não informado"}
                    </Form.Text>
                  )}
                </Form.Group>
                <Form.Group as={Col} md="4" className="mb-4" controlId="">
                  <Form.Label>{t("profile.personalData.phone")}</Form.Label>
                  {editProfile === true ? (
                    <>
                      <Controller
                        control={control}
                        name="cellphone"
                        render={() => (
                          <InputMask
                            mask={`(99) 9.9999-9999`}
                            value={phone}
                            onChange={handleChangePhone}
                          >
                            {({ inputProps }: any) => (
                              <Form.Control
                                {...inputProps}
                                variant="standard"
                                margin="normal"
                                required
                              />
                            )}
                          </InputMask>
                        )}
                      />
                      <ErrorMessage
                        errors={errors}
                        name="cellphone"
                        render={({ message }) => (
                          <div
                            style={{
                              width: "100%",
                              backgroundColor: "#f8d7da",
                              padding: "0 0.75rem",
                            }}
                          >
                            <small style={{ color: "red" }}>{message}</small>
                          </div>
                        )}
                      />
                    </>
                  ) : (
                    <Form.Text className="text-muted text-user-info">
                      {phone}
                    </Form.Text>
                  )}
                </Form.Group>
                <Form.Group as={Col} md="4" className="mb-4" controlId="">
                  <Form.Label>{t("profile.personalData.whatsapp")}</Form.Label>
                  {/* <input type="text" id="cpf" value={cpf} onChange={handleChangeCPF} /> */}
                  {editProfile === true ? (
                    <>
                      <Controller
                        control={control}
                        name="whatsapp"
                        //rules={{ required: { value: true, message: 'Por favor, informe o whatsapp' } }}
                        render={() => (
                          <InputMask
                            mask={`(99) 9.9999-9999`}
                            value={whatsapp}
                            onChange={handleChangeWhatsapp}
                          >
                            {(inputProps: any) => (
                              <Form.Control
                                {...inputProps}
                                variant="standard"
                                margin="normal"
                                required
                              />
                            )}
                          </InputMask>
                        )}
                      />
                      <ErrorMessage
                        errors={errors}
                        name="whatsapp"
                        render={({ message }) => (
                          <div
                            style={{
                              width: "100%",
                              backgroundColor: "#f8d7da",
                              padding: "0 0.75rem",
                            }}
                          >
                            <small style={{ color: "red" }}>{message}</small>
                          </div>
                        )}
                      />
                    </>
                  ) : (
                    <Form.Text className="text-muted text-user-info">
                      {whatsapp}
                    </Form.Text>
                  )}
                </Form.Group>

                <h4 className="mt-3 mb-3">{t("profile.address.title")}</h4>

                {foreign !== 1 ? (
                  <>
                    <Form.Group as={Col} md="4" className="mb-4" controlId="">
                      <Form.Label>{t("profile.address.postCode")}</Form.Label>
                      {/* <input type="text" id="cpf" value={cpf} onChange={handleChangeCPF} /> */}
                      {editProfile === true ? (
                        <>
                          <Controller
                            control={control}
                            name="cep"
                            /* rules={{
                              required: {
                                value: true,
                                message: "Por favor, informe um cep",
                              },
                            }} */
                            render={({ field }: any) => (
                              <Form.Control
                                {...field}
                                variant="standard"
                                margin="normal"
                                value={cep}
                                onChange={handleChangeCEP}
                                onBlur={handleBlurCEP}
                                required
                              />
                            )}
                          />
                        </>
                      ) : (
                        <Form.Text className="text-muted text-user-info">
                          {cep}
                        </Form.Text>
                      )}
                    </Form.Group>

                    {!isSelect ? (
                      <Form.Group as={Col} md="4" className="mb-4" controlId="">
                        <Form.Label>{t("profile.address.state")}</Form.Label>
                        {/* <input type="text" id="cpf" value={cpf} onChange={handleChangeCPF} /> */}
                        {editProfile === true ? (
                          <>
                            <Controller
                              control={control}
                              name="state"
                              render={({ field }: any) => (
                                <Form.Control
                                  {...field}
                                  variant="standard"
                                  margin="normal"
                                  value={state}
                                  onChange={handleChangeState}
                                  disabled={disableState}
                                  required
                                />
                              )}
                            />
                            <ErrorMessage
                              errors={errors}
                              name="state"
                              render={({ message }) => (
                                <div
                                  style={{
                                    width: "100%",
                                    backgroundColor: "#f8d7da",
                                    padding: "0 0.75rem",
                                  }}
                                >
                                  <small style={{ color: "red" }}>
                                    {message}
                                  </small>
                                </div>
                              )}
                            />
                          </>
                        ) : (
                          <Form.Text className="text-muted text-user-info">
                            {state}
                          </Form.Text>
                        )}
                      </Form.Group>
                    ) : (
                      <Form.Group as={Col} md="4" className="mb-4" controlId="">
                        <Form.Label>{t("profile.address.state")}</Form.Label>
                        {/* <input type="text" id="cpf" value={cpf} onChange={handleChangeCPF} /> */}
                        {editProfile === true ? (
                          <>
                            <Controller
                              control={control}
                              name="state"
                              rules={{
                                required: {
                                  value: true,
                                  message: t("profileNew.errorState"),
                                },
                              }}
                              render={({ field }: any) => (
                                <GetStates
                                  propsField={field}
                                  propsErrors={errors}
                                  setValue={setValue}
                                />
                              )}
                            />
                            <ErrorMessage
                              errors={errors}
                              name="state"
                              render={({ message }) => (
                                <small style={{ color: "red" }}>
                                  {message}
                                </small>
                              )}
                            />
                          </>
                        ) : (
                          <Form.Text className="text-muted text-user-info">
                            {state}
                          </Form.Text>
                        )}
                      </Form.Group>
                    )}

                    {!isSelect ? (
                      <Form.Group as={Col} md="4" className="mb-4" controlId="">
                        <Form.Label>{t("profile.address.city")}</Form.Label>
                        {/* <input type="text" id="cpf" value={cpf} onChange={handleChangeCPF} /> */}
                        {editProfile === true ? (
                          <>
                            <Controller
                              control={control}
                              name="city"
                              render={({ field }: any) => (
                                <Form.Control
                                  {...field}
                                  variant="standard"
                                  margin="normal"
                                  value={city}
                                  onChange={handleChangeCity}
                                  disabled={disableCity}
                                  required
                                />
                              )}
                            />
                            <ErrorMessage
                              errors={errors}
                              name="city"
                              render={({ message }) => (
                                <small style={{ color: "red" }}>
                                  {message}
                                </small>
                              )}
                            />
                          </>
                        ) : (
                          <Form.Text className="text-muted text-user-info">
                            {city}
                          </Form.Text>
                        )}
                      </Form.Group>
                    ) : (
                      <Form.Group as={Col} md="4" className="mb-4" controlId="">
                        <Form.Label>{t("profile.address.city")}</Form.Label>
                        {/* <input type="text" id="cpf" value={cpf} onChange={handleChangeCPF} /> */}
                        {editProfile === true ? (
                          <>
                            <Controller
                              control={control}
                              name="city"
                              rules={{
                                required: {
                                  value: true,
                                  message: "Por favor, informe a Cidade.",
                                },
                              }}
                              render={({ field }: any) => (
                                <GetCitysByState
                                  propsField={field}
                                  propsErrors={errors}
                                  uf={watchState?.id}
                                  setValue={setValue}
                                />
                              )}
                            />
                            <ErrorMessage
                              errors={errors}
                              name="city"
                              render={({ message }) => (
                                <small style={{ color: "red" }}>
                                  {message}
                                </small>
                              )}
                            />
                          </>
                        ) : (
                          <Form.Text className="text-muted text-user-info">
                            {city}
                          </Form.Text>
                        )}
                      </Form.Group>
                    )}

                    <Form.Group as={Col} md="4" className="mb-4" controlId="">
                      <Form.Label>{t("profile.address.address")}</Form.Label>
                      {/* <input type="text" id="cpf" value={cpf} onChange={handleChangeCPF} /> */}
                      {editProfile === true ? (
                        <>
                          <Controller
                            control={control}
                            name="address"
                            render={({ field }: any) => (
                              <Form.Control
                                {...field}
                                variant="standard"
                                margin="normal"
                                value={address}
                                onChange={handleChangeAddress}
                                disabled={disableAddress}
                                required
                              />
                            )}
                          />
                          <ErrorMessage
                            errors={errors}
                            name="address"
                            render={({ message }) => (
                              <div
                                style={{
                                  width: "100%",
                                  backgroundColor: "#f8d7da",
                                  padding: "0 0.75rem",
                                }}
                              >
                                <small style={{ color: "red" }}>
                                  {message}
                                </small>
                              </div>
                            )}
                          />
                        </>
                      ) : (
                        <Form.Text className="text-muted text-user-info">
                          {address}
                        </Form.Text>
                      )}
                    </Form.Group>

                    <Form.Group as={Col} md="4" className="mb-4" controlId="">
                      <Form.Label>Número</Form.Label>
                      {editProfile === true ? (
                        <>
                          <Controller
                            control={control}
                            name="number"
                            render={({ field }: any) => (
                              <Form.Control
                                {...field}
                                variant="standard"
                                margin="normal"
                                value={number}
                                onChange={handleChangeNumber}
                                required
                              />
                            )}
                          />
                          <ErrorMessage
                            errors={errors}
                            name="number"
                            render={({ message }) => (
                              <div
                                style={{
                                  width: "100%",
                                  backgroundColor: "#f8d7da",
                                  padding: "0 0.75rem",
                                }}
                              >
                                <small style={{ color: "red" }}>
                                  {message}
                                </small>
                              </div>
                            )}
                          />
                        </>
                      ) : (
                        <Form.Text className="text-muted text-user-info">
                          {number}
                        </Form.Text>
                      )}
                    </Form.Group>

                    <Form.Group as={Col} md="4" className="mb-4" controlId="">
                      <Form.Label>Complemento</Form.Label>
                      {editProfile === true ? (
                        <>
                          <Controller
                            control={control}
                            name="complement"
                            render={({ field }: any) => (
                              <Form.Control
                                {...field}
                                variant="standard"
                                margin="normal"
                                value={complement}
                                onChange={handleChangeComplement}
                                required
                              />
                            )}
                          />
                          <ErrorMessage
                            errors={errors}
                            name="complement"
                            render={({ message }) => (
                              <div
                                style={{
                                  width: "100%",
                                  backgroundColor: "#f8d7da",
                                  padding: "0 0.75rem",
                                }}
                              >
                                <small style={{ color: "red" }}>
                                  {message}
                                </small>
                              </div>
                            )}
                          />
                        </>
                      ) : (
                        <Form.Text className="text-muted text-user-info">
                          {complement}
                        </Form.Text>
                      )}
                    </Form.Group>
                  </>
                ) : (
                  <></>
                )}

                <Form.Group as={Col} md="4" className="mb-4" controlId="">
                  <Form.Label>{t("profile.address.country")}</Form.Label>
                  {/* <input type="text" id="cpf" value={cpf} onChange={handleChangeCPF} /> */}
                  {editProfile === true ? (
                    <>
                      <Controller
                        control={control}
                        name="country"
                        render={({ field }: any) => (
                          <Form.Control
                            {...field}
                            variant="standard"
                            margin="normal"
                            value={country}
                            onChange={handleChangeCountry}
                            required
                          />
                        )}
                      />
                      <ErrorMessage
                        errors={errors}
                        name="country"
                        render={({ message }) => (
                          <div
                            style={{
                              width: "100%",
                              backgroundColor: "#f8d7da",
                              padding: "0 0.75rem",
                            }}
                          >
                            <small style={{ color: "red" }}>{message}</small>
                          </div>
                        )}
                      />
                    </>
                  ) : (
                    <Form.Text className="text-muted text-user-info">
                      {country}
                    </Form.Text>
                  )}
                </Form.Group>

                <h4 className="mt-3 mb-3">{t("profile.infoLogin.title")}</h4>
                <Form.Group as={Col} md="4" className="mb-0" controlId="">
                  <Form.Label>{t("profile.infoLogin.email")}</Form.Label>
                  <Form.Text className="text-muted text-user-info">
                    {email}
                  </Form.Text>
                </Form.Group>

                <h4 className="mt-3 mb-0">Informações Adicionais</h4>

                <ApprovedTerms
                  control={control}
                  setValue={setValue}
                  setPrivacy={setPrivacy}
                  privacy={privacy}
                  errors={errors}
                  editProfile={editProfile}
                  aceptPrivacy={aceptPrivacy}
                  aceptWpp={aceptWpp}
                  profile={profile}
                />

                <h4 className="mt-3 mb-2">{t("profile.password.title")}</h4>

                <a href="/nova-senha" className="w-auto">
                  {t("profile.password.changePassword")}
                </a>
              </Row>
              {editProfile === true ? (
                <Row className="d-flex justify-content-end mx-0 mt-5 mb-4">
                  <div className="w-auto px-0">
                    <Button
                      type="button"
                      className="form-button w-auto px-4 py-2 mr-3"
                      variant="outline-primary"
                      onClick={() => {
                        setEditProfile(!editProfile);
                        ScrollTop();
                      }}
                    >
                      {t("profile.cancelBtn")}
                    </Button>
                  </div>
                  <ModalDefault
                    name={t("profile.editBtn")}
                    header="no"
                    class="modal-confirm "
                    btnLeave="yes"
                    modalContent={modalContent}
                  >
                    <div className="modal-body text-center">
                      {modalContent !== "loading" ? (
                        <>
                          <div>
                            {log === 1 || log === "1" ? (
                              <FontAwesomeIcon
                                icon={["fal", "times-circle"]}
                                size="5x"
                                style={{ fontSize: "7.5em", color: "#FF6600" }}
                              />
                            ) : (
                              <FontAwesomeIcon
                                icon={["fal", "check-circle"]}
                                size="5x"
                                style={{ fontSize: "7.5em", color: "#FF6600" }}
                              />
                            )}
                          </div>
                          <div>{sucess}</div>
                          <div></div>
                        </>
                      ) : (
                        <>
                          <div className="text-center">
                            <div className="load"></div>
                          </div>
                          <div>
                            <span>Processando</span>
                          </div>
                          <div></div>
                        </>
                      )}
                    </div>
                  </ModalDefault>
                </Row>
              ) : (
                <></>
              )}
            </Form>
          </Container>

          {/* <div className="profile-container d-flex justify-content-evenly p-4">
                    <div className="profile-box profile-pic">
                        <img src={ProfilePic} alt="" />
                    </div>
                    <div className="profile-box profile-data">
                        <h5>DADOS PESSOAIS</h5>
                        <div className="form-group ">
                            <div className="form-group-container">
                                <label htmlFor="firstName">Nome:  </label>
                                <input type="text" id="firstName" value={firstName} onChange={handleChangeFirstName} />
                            </div>
                            <div className="btn-container">
                                <button id="fisrtNameBtn" className="btn" onClick={() => handleClickBtn("fisrtNameBtn")}>edit</button>
                            </div>
                        </div>
                        <div className="form-group">
                            <div className="form-group-container">
                                <label htmlFor="lastName">Sobrenome:  </label>
                                <input type="text" id="lastName" value={lastName} onChange={handleChangeLastName} />
                            </div>
                            <div className="btn-container">
                                <button id="lastNameBtn" className="btn" onClick={() => handleClickBtn("lastNameBtn")}>edit</button>
                            </div>
                        </div>
                        <div className="form-group">
                            <div className="form-group-container">
                                <label htmlFor="cpf">CPF:  </label>
                                <input type="text" id="cpf" value={cpf} onChange={handleChangeCpf} />
                            </div>
                            <div className="btn-container">
                                <button id="cpfBtn" className="btn" onClick={() => handleClickBtn("cpfBtn")}>edit</button>
                            </div>
                        </div>

                    </div>
                    <div className="profile-box profile-tel">
                        <h5>TELEFONE</h5>
                        <div className="form-group">
                            <div className="form-group-container">
                                <label htmlFor="phone">Celular:  </label>
                                <input type="text" id="phone" value={phone} onChange={handleChangePhone} />
                            </div>
                            <div className="btn-container">
                                <button id="phoneBtn" className="btn" onClick={() => handleClickBtn("phoneBtn")}>edit</button>
                            </div>
                        </div>
                        <div className="form-group">
                            <div className="form-group-container">
                                <label htmlFor="whatsapp">Whatsapp:  </label>
                                <input type="text" id="whatsapp" value={whatsapp} onChange={handleChangeWhatsapp} />
                            </div>
                            <div className="btn-container">
                                <button id="whatsappBtn" className="btn" onClick={() => handleClickBtn("whatsappBtn")}>edit</button>
                            </div>
                        </div>
                    </div>
                    <div className="profile-box profile-email">
                        <h5>E-MAIL</h5>
                        <p className="email">c2rio@c2rio.travel</p>
                        <p></p>
                        <h5>SENHA</h5>
                        <p>**********</p>
                    </div>
                </div> */}
          {/* <div className="save-data">
                    <button>Salvar Dados</button>
                </div> */}
        </div>
      </PageDafault>
    </>
  );
}

export default Profile;
