import React, { useState, useEffect } from 'react';

import Slider from "react-slick";

import { Row, Col } from "react-bootstrap";

import bgGallery01 from "../../../assets/img/gallery/galeria_1.jpg";
import bgGallery02 from "../../../assets/img/gallery/galeria_3.jpg";
import bgGallery03 from "../../../assets/img/gallery/galeria_4.jpg";
import bgGallery04 from "../../../assets/img/gallery/galeria_5.jpg";
import bgGallery05 from "../../../assets/img/gallery/galeria_6.jpg";
import bgGallery06 from "../../../assets/img/gallery/galeria_7.jpg";
import bgGallery07 from "../../../assets/img/gallery/galeria_8.jpg";
import bgGallery08 from "../../../assets/img/gallery/galeria_9.jpg";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./GalleryPark.scss";

function GalleryPark() {

    const [nav1, setNav1] = useState<any>();
    const [nav2, setNav2] = useState<any>();
    const [slider1, setSlider1] = useState<any>();
    const [slider2, setSlider2] = useState<any>();

    if(false){ console.log(nav1, setSlider2)}

    useEffect(() => {
        setNav1(slider1);
        setNav2(slider2);
    }, [slider1, slider2]);

    const settings:any = {
        slidesToShow: 4,
        slidesToScroll: 1,
        arrows: true,
        fade: false,
        asNavFor: '.slider-nav',
        lazyLoad: 'ondemand', // Lazy load images
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    initialSlide: 2
                }
            }
        ]
    };

    return (
        <div className="tourSlider">

            <div className="slider-wrapper">

                <Slider
                    {...settings}
                    asNavFor={nav2}
                    ref={slider => (setSlider1(slider))}
                >
                    <div className='px-2'>
                        <Row>
                            <Col md={12}>
                                <div className="slider-img-park" style={{ backgroundImage: `url(${bgGallery01})` }}></div>
                            </Col>
                        </Row>
                    </div>
                    <div className='px-2'>
                        <Row>
                            <Col md={12}>
                                <div className="slider-img-park" style={{ backgroundImage: `url(${bgGallery02})` }}></div>
                            </Col>
                        </Row>
                    </div>
                    <div className='px-2'>
                        <Row>
                            <Col md={12}>
                                <div className="slider-img-park" style={{ backgroundImage: `url(${bgGallery03})` }}></div>
                            </Col>
                        </Row>
                    </div>
                    <div className='px-2'>
                        <Row>
                            <Col md={12}>
                                <div className="slider-img-park" style={{ backgroundImage: `url(${bgGallery04})` }}></div>
                            </Col>
                        </Row>
                    </div>
                    <div className='px-2'>
                        <Row>
                            <Col md={12}>
                                <div className="slider-img-park" style={{ backgroundImage: `url(${bgGallery05})` }}></div>
                            </Col>
                        </Row>
                    </div>
                    <div className='px-2'>
                        <Row>
                            <Col md={12}>
                                <div className="slider-img-park" style={{ backgroundImage: `url(${bgGallery06})` }}></div>
                            </Col>
                        </Row>
                    </div>
                    <div className='px-2'>
                        <Row>
                            <Col md={12}>
                                <div className="slider-img-park" style={{ backgroundImage: `url(${bgGallery07})` }}></div>
                            </Col>
                        </Row>
                    </div>
                    <div className='px-2'>
                        <Row>
                            <Col md={12}>
                                <div className="slider-img-park" style={{ backgroundImage: `url(${bgGallery08})` }}></div>
                            </Col>
                        </Row>
                    </div>
                </Slider>
            </div>
        </div>
    );
}

export default GalleryPark;