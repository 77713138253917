import React from 'react';

function PrivacyNoticeCaracolES() {
  return (
    <>
      <br />
      <strong>QUIENES SOMOS</strong>
      <br />

      <p className="px-3">
        Nosotros vamos más allá de lindas vistas. Más que uno de los más bellos
        puntos turísticos de Brasil, nuestra misión es transportar personas
        hacia un mundo de encanto, ocio y entretenimiento, generando
        experiencias felices, sostenibles, inolvidables y que valoren la cultura
        brasileña. Invertimos constantemente en seguridad, comodidad e
        innovación. Surgimos de un deseo visionario e incontrolable de conectar
        a las personas con este espectáculo de la naturaleza. Somos la
        combinación de un trabajo en equipo: naturaleza y hombre juntos en
        perfecta armonía. Somos un hito natural, geológico, histórico y
        turístico de la ciudad de Río de Janeiro. Hito natural, por ser una
        referencia para los navegadores que llegan a la Bahía de Guanabara. Hito
        geológico, pues es un sitio geológico con fechas precisas sobre la
        historia del planeta. Hito histórico, porque Estácio de Sá, a sus pies,
        fundó la Ciudad de São Sebastião do Rio de Janeiro el 1º de marzo de
        1565. E, hito turístico, porque la inauguración del teleférico de Pão de
        Açúcar proyectó el nombre de Brasil en el exterior. Fuimos el primer
        teleférico instalado en Brasil y el tercero en el mundo.
      </p>

      <br />
      <strong>1. OBJETIVO</strong>
      <br />

      <p className="px-3">
        Este Aviso rige su acceso y uso del sitio web, dentro de Brasil, puesto
        a disposición, controlado y operado por{' '}
        <b>
          PARQUE BONDINHO PÃO DE AÇÚCAR®, COMPANHIA CAMINHO AÉREO PÃO DE AÇÚCAR
          S A (“CCAPA” o “PARQUE BONDINHO”)
        </b>
        , con sede en Avenida Pasteur nº 520, Urca Ciudad y Estado de Rio de
        Janeiro, CP: 22.290-240.
      </p>

      <p className="px-3">
        Este Aviso de Privacidad tiene el objetivo de informar a nuestros
        Usuarios, tanto en el ambiente digital (sitio web), como en el ambiente
        físico (instalaciones de PARQUE BONDINHO), cómo tratamos sus datos
        personales, para que pueda transitar con tranquilidad y sepa que su
        privacidad es una de nuestras preocupaciones.{' '}
      </p>

      <p className="px-3">
        Su privacidad es importante para nosotros de PARQUE BONDINHO. Por eso,
        protegemos sus datos personales. Elaboramos este aviso de privacidad,
        que establece la forma como PARQUE BONDINHO trata los datos personales
        de los colaboradores, clientes, proveedores y demás stakeholders. Estos
        datos pueden estar almacenados físicamente en oficinas o de modo digital
        en sistemas de información.
      </p>

      <p className="px-3">
        Este Aviso describe la forma como PARQUE BONDINHO trata y protege sus
        Datos Personales, recolectados directamente por nosotros, recibidos de
        terceros y/o transferidos a terceros. Se efectiva el compromiso de
        actuar con transparencia y ética en todas las operaciones, extendiéndose
        a los organismos y entidades públicas con las que interactúa PARQUE
        BONDINHO.
      </p>

      <p className="px-3">
        Para algunas actividades de tratamiento, dependiendo de la finalidad,
        utilizamos operadores de tratamiento para ayudarnos con el tratamiento
        de sus datos personales. Esto ocurre, por ejemplo, con algunas
        actividades contables, de logística o marketing. Nosotros elaboramos una
        sesión dedicada a informarle sobre nuestra relación con estos operadores
        y sobre sus derechos en dicha situación. Desde ya, es importante que
        sepa que adoptamos todas las medidas necesarias y usuales en el mercado
        para proteger la seguridad de sus datos en estas situaciones.
      </p>

      <p className="px-3">
        En relación al tratamiento de sus datos personales, en determinadas
        ocasiones definimos las finalidades y medios respectivos, o sea,
        actuamos como CONTROLADORES.
      </p>

      <p className="px-3">
        PARQUE BONDINHO, es una empresa integrante del GRUPO ITER, que se
        compone de las empresas listadas a continuación, donde consta
        información de identificación y estructura, caso desee contactarnos :
      </p>

      <dl>
        <dd>
          <table className="table-page" width="50%" cellSpacing="0">
            <tbody>
              <tr>
                <td
                  width="70%"
                  height="14"
                  style={{ backgroundColor: '#c0c0c0' }}
                >
                  <p className="mb-0 px-2">
                    <span>
                      <strong>RAZÓN SOCIAL </strong>
                    </span>
                  </p>
                </td>
                <td width="30%" style={{ backgroundColor: '#c0c0c0' }}>
                  <p className="mb-0 px-2">
                    <span>
                      <strong>CNPJ</strong>
                    </span>
                  </p>
                </td>
              </tr>
              <tr>
                <td width="55" height="4">
                  <p className="my-2 px-2">
                    <span>ITER PARTICIPAÇÕES S.A.</span>
                  </p>
                </td>
                <td width="116">
                  <p className="my-2 px-2">
                    <span>43.697.470/0001-69</span>
                  </p>
                </td>
              </tr>
              <tr>
                <td width="55" height="4">
                  <p className="my-2 px-2">
                    <span>TRAVEL ACTIVITIES HOLDING. </span>
                  </p>
                </td>
                <td width="116">
                  <p className="my-2 px-2">
                    <span>44.317.977/0001-02</span>
                  </p>
                </td>
              </tr>
              <tr>
                <td width="55" height="4">
                  <p className="my-2 px-2">
                    <span>C2RIO VIAGENS E TURISMO LTDA</span>
                  </p>
                </td>
                <td width="116">
                  <p className="my-2 px-2">
                    <span>05.873.416/0001-38</span>
                  </p>
                </td>
              </tr>
              <tr>
                <td width="55" height="4">
                  <p className="my-2 px-2">
                    <span>DESTINOW VIAGENS E TURISMO LTDA. </span>
                  </p>
                </td>
                <td width="116">
                  <p className="my-2 px-2">
                    <span>44.743.360/0002-30</span>
                  </p>
                </td>
              </tr>
              <tr>
                <td width="55" height="4">
                  <p className="my-2 px-2">
                    <span>DESTITECH TECNOLOGIA DA INFORMAÇÃO LTDA.</span>
                  </p>
                </td>
                <td width="116">
                  <p className="my-2 px-2">
                    <span>45.759.751/0001-24</span>
                  </p>
                </td>
              </tr>
              <tr>
                <td width="55" height="4">
                  <p className="my-2 px-2">
                    <span>PÃO DE AÇÚCAR EMPREENDIMENTOS TURÍSTICO LTDA. </span>
                  </p>
                </td>
                <td width="116">
                  <p className="my-2 px-2">
                    <span>42.274.233/0001-22</span>
                  </p>
                </td>
              </tr>
              <tr>
                <td width="55" height="4">
                  <p className="my-2 px-2">
                    <span>NOVO CARACOL E TAINHAS S.A.</span>
                  </p>
                </td>
                <td width="116">
                  <p className="my-2 px-2">
                    <span>48.255.552/0001-77</span>
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
        </dd>
      </dl>

      {/* <div className="mx-3 p-4 text-center mb-3" style={{ border: "1px solid red" }}><strong>Al final de este Aviso está disponible el canal de contacto específico para atención al público sobre estas cuestiones.</strong></div><br /> */}

      <strong>2. ALCANCE</strong>
      <br />

      <p className="px-3">
        Este Aviso se aplica a los clientes, proveedores, visitantes, demás
        stakeholders de PARQUE BONDINHO, debiendo implementarse en la Plataforma
        y podrá ser leída por todos los clientes, aliados y terceros
        interesados.
      </p>

      <p className="px-3">
        La lectura y reconocimiento de este Aviso podrá realizarse a través de
        checkbox. Esto indicará que está enterado de la forma como tratamos y
        utilizamos sus datos e información. Este documento debe ser leído con
        nuestros Términos y Condiciones de Uso.
      </p>
      <br />

      <strong>3. SIGLAS Y DEFINICIONES</strong>
      <br />

      <p className="px-3">
        <b>3.1. Datos personales:</b> Información relacionada a la persona
        natural identificada, identificable, o sea, es cualquier información que
        identifique a la persona física de forma directa o indirecta, como: CPF
        (Registro de Persona Física), dirección, voz, imagen, placa de auto y
        huella digital. De acuerdo con la Ley General de Protección de Datos
        Personales – LGPD – los datos personales poseen protección legal y sus
        titulares tienen todos los derechos sobre ellos y qué hacer con ellos.
        Si entrega sus datos personales a PARQUE BONDINHO es nuestro deber
        darles el tratamiento adecuado y hacer que su almacenaje sea realizado
        de forma segura.
      </p>

      <p className="px-3">
        <b>3.2. Titular de Datos:</b> Se trata de persona física a la que se
        refieren los datos personales. O sea: Usted. Pudiendo ser, además, un
        candidato a la vacante, un visitante de nuestro sitio web, visitante de
        nuestro Parque, etc.
      </p>

      <p className="px-3">
        <b>3.3. Datos Personales Sensibles:</b> Se refieren a los datos
        personales relativos a la convicción religiosa origen racial o étnica,
        opiniones políticas, la pertenencia a un sindicato o a una organización
        de carácter religioso, filosófico o político, los datos relativos a la
        salud o a la vida sexual, los datos genéticos o biométricos, cuando
        estén asociados a una persona física;
      </p>

      <p className="px-3">
        <b>3.4. Controlador:</b> Es la persona natural o jurídica, de derecho
        público o privado, a quien corresponde tomar decisiones relativas al
        Tratamiento de Datos Personales, que en este caso, somos nosotros,
        PARQUE BONDINHO;
      </p>

      <p className="px-3">
        <b>3.5. Operador:</b> Nuestros aliados, proveedores y prestadores de
        servicios, que realizan el tratamiento de datos personales en nombre de
        PARQUE BONDINHO, ayudando e el tratamiento de los datos personales que
        recolectamos para ofrecer nuestros servicios y ofrecer la mejor
        experiencia a nuestro usuario.
      </p>

      <p className="px-3">
        <b>
          3.6. Encargado (a) del Tratamiento de Datos Personales o DPO (Data
          Protection Officer):
        </b>{' '}
        Es la persona designada por el controlador y el operador para actuar
        como canal de comunicación entre el controlador, los titulares de los
        datos y la Autoridad Nacional de Protección de Datos (ANPD).
      </p>

      <p className="px-3">
        <b>3.7. Tratamiento:</b> Es toda y cualquier operación realizada con
        Datos Personales, como las que se refieren a recolección, producción,
        recepción, clasificación, utilización, acceso, reproducción,
        transmisión, distribución, procesamiento, archivo, almacenaje,
        eliminación, evaluación o control de la información, modificación,
        comunicación, transferencia, difusión o extracción.
      </p>

      <p className="px-3">
        <b>3.8. Plataformas:</b> Es el sitio web, e-commerce, medios sociales y
        canales de comunicación de PARQUE BONDINHO.
      </p>

      <p className="px-3">
        <b>3.9. Cookies:</b> Los Cookies son pequeños archivos de texto que, a
        través del navegador de internet (browser) registran información de
        accesos y localización de los visitantes que accedan a nuestro website.
        Dicha información es almacenada en los dispositivos (móviles o no) de
        los visitantes, permitiendo que la experiencia y acceso sean más
        rápidos.
      </p>

      <p className="px-3">
        <b>3.10. Terceros:</b> Incluyen, pero no se limitan a clientes,
        proveedores, candidatos a una vacante, visitantes y usuarios en general
        del sitio web de PARQUE BONDINHO (dichas partes son referidas aquí
        individualmente como “Tercero” o colectivamente como “Terceros”).
      </p>

      <p className="px-3">
        <b>3.11. Base de Datos:</b> conjunto estructurado de Datos Personales,
        establecido en una o varias ubicaciones, en soporte electrónico o
        físico;
      </p>

      <p className="px-3">
        <b>3.12. “Transferencia Internacional de Datos”:</b> Es la transferencia
        de datos personales a país extranjero u organismo internacional del cual
        el país sea miembro.
      </p>

      <p className="px-3">
        <b>3.13. Ley General de Protección de Datos Personales (LGPD):</b> La
        Ley nº 13.709 del 14 de agosto de 2018, también conocida como Ley
        General de Protección de Datos Personales, o también LGPD, dispone
        legalmente sobre el tratamiento de Datos Personales en Brasil, tanto por
        medios digitales y físicos, por persona natural o por persona jurídica
        de derecho público o privado, con el objetivo de proteger los derechos
        fundamentales de libertad y de privacidad y el libre desarrollo de la
        personalidad de la persona natural.
      </p>
      <br />

      <strong>4. DATOS SUMINISTRADOS A PARQUE BONDINHO </strong>
      <br />

      <p className="px-3">
        <b>4.1. </b> Los Datos Personales suministrados se utilizarán para
        posibilitar la prestación de servicio, ofrecer beneficios y cumplir las
        obligaciones previstas en leyes o reglamentaciones específicas, y cuando
        se constata la necesidad, tras el fin del tratamiento, o según lo
        designado por normativa vigente, se realizará el descarte con el cuidado
        específico.
      </p>

      <p className="px-3">
        <b>4.2. </b>Así, la recolección de Datos Personales se realiza por
        diferentes medios, dependiendo de cuál servicio ofrecido por PARQUE
        BONDINHO será utilizado. A continuación, se apuntan cuales operaciones
        recolectan datos personales, así como cuáles tipos de datos se
        recolectan a través de estas:
      </p>

      <p className="px-3 mx-4">
        <b>a) REGISTRO:</b> Email, nombre, País, Estado/provincia, CPF,
        Pasaporte, teléfono;
      </p>
      <p className="px-3 mx-4">
        <b>b) COMPRA DE ENTRADAS ON LINE:</b> Email, CPF, Pasaporte, País,
        Estado/provincia, teléfono, datos de la tarjeta de crédito;
      </p>
      <p className="px-3 mx-4">
        <b>c) COMPRA DE ENTRADAS EN EL TÓTEM DE AUTOSERVICIO:</b> Nombre y
        email;
      </p>
      <p className="px-3 mx-4">
        <b>d) COMPRA DE ENTRADAS EN EL AMBIENTE FÍSICO:</b> RG (Documento de
        Identidad), datos de la tarjeta de crédito, comprobante de domicilio,
        documento comprobante de estudiante, y otros documentos necesarios para
        comprobación del beneficio;
      </p>
      <p className="px-3 mx-4">
        <b>e) NEWSLETTER:</b> Nombre y email
      </p>
      <p className="px-3 mx-4">
        <b>f) EDUCA BONDINHO:</b> Nombre, email y teléfono del profesor o
        director responsable, foto y video del estudiante;
      </p>
      <p className="px-3 mx-4">
        <b>g) CABINA EXCLUSIVA:</b> Nombre, email, teléfono;
      </p>
      <p className="px-3 mx-4">
        <b>h) VISITAS FÍSICAS AL PARQUE TURÍSTICO (MONTAÑISTAS):</b> Nombre, CPF
        y teléfono;
      </p>
      <p className="px-3 mx-4">
        <b>i) NEGOCIOS Y ALIANZAS:</b> Nombre, email, teléfono;
      </p>
      <p className="px-3 mx-4">
        <b>j) DECLARACIONES:</b> Email, nombre, imagen;
      </p>
      <p className="px-3 mx-4">
        <b>k) CONTÁCTENOS:</b> Nombre, email, teléfono;
      </p>
      <p className="px-3 mx-4">
        <b>l) CAMBIOS Y DEVOLUCIONES:</b> factura, nombre completo, CPF/RG;
      </p>
      <p className="px-3 mx-4">
        <b>m) CONTACTOS POR WHATSAPP:</b> nombre, email y celular;
      </p>
      <p className="px-3 mx-4">
        <b>n) CONTACTOS POR REDES SOCIALES:</b> nombre y email.
      </p>
      <p className="px-3 mx-4">
        <b>o) SIG (Sistema Integrado de Gestión):</b> email, CPF, pasaporte,
        país, estado/provincia, teléfono, dirección y celular;
      </p>
      <p className="px-3 mx-4">
        <b>p)TRABAJE CON NOSOTROS :</b> Nombre, fecha de nacimiento, email,
        currículo;
      </p>
      <p className="px-3 mx-4">
        <b>q) MÓDULO AGENCIA :</b> Razón social, nombre fantasía, CNPJ, email de
        la empresa, teléfono de la empresa, inscripción municipal y/o
        provincial, nombre del representante, email del representante, celular
        del representante, CPF, RG y fecha de nacimiento del representante
        legal;
      </p>
      <p className="px-3 mx-4">
        <b>r) CANCELACIÓN DE ENTRADAS Y/O RESERVA:</b> Nombre, email,
        CPF/Pasaporte, banco, agencia, cuenta bancaria;
      </p>
      <p className="px-3 mx-4">
        <b>
          s) CANAL DE CONDUCTA E ÉTICA (en la hipótesis que el titular opte por
          identificarse):
        </b>{' '}
        Nombre, CPF, email, fecha de nacimiento, teléfono, celular;
      </p>
      <p className="px-3 mx-4">
        <b>t) PORTAL DEL TITULAR (LGPD):</b> Nombre, email y CPF;
      </p>
      <br />

      <strong>5. DATOS RECOLECTADOS POR PARQUE BONDINHO </strong>
      <br />

      <p className="px-3">
        <b>5.1.</b> Se suministra la información anterior reunida al PARQUE
        BONDINHO, pero hay datos que pueden ser recolectados automáticamente,
        cuando se utilizan los servicios, por ejemplo:
      </p>

      <p className="px-3 mx-4">
        <b>a)</b> Datos de dispositivos móviles a partir de los accesos a la
        plataforma digital y otra información como el tipo y versión del
        navegador utilizado;
      </p>
      <p className="px-3 mx-4">
        <b>b)</b> Datos de logs;
      </p>
      <p className="px-3 mx-4">
        <b>c)</b> La dirección IP;
      </p>
      <p className="px-3 mx-4">
        <b>d)</b> Otros datos recogidos a través de cookies, como se describe en
        la Política de Cookies.
      </p>

      <p className="px-3">
        <b>5.2.</b> La finalidad del uso de estos datos es mejorar el desempeño,
        seguridad y funcionalidad de nuestras plataformas, vehicular
        publicidades en los entornos digitales y mantener el registro de acceso
        a los sitios web.
      </p>
      <br />

      <strong>6. FINALIDAD DEL TRATAMIENTO </strong>
      <br />

      <p className="px-3">
        <b>6.1.</b> El PARQUE BONDINHO trata su información personal para
        cumplir obligaciones legales, contractuales y ofrecerle nuestros
        productos y servicios. Nunca recolectaremos datos personales
        innecesarios y no trataremos su información de cualquier otra forma que
        no sea la especificada en este Aviso.
      </p>

      <p className="px-3">
        <b>6.2.</b> PARQUE BONDINHO recolecta Datos Personales insertados por
        Terceros en el sitio de PARQUE BONDINHO, por medio de cookies, como se
        especifica en la Política de Cookies, o suministrados por cualquier otra
        forma, como: verbal, escrita o llamadas telefónicas/WhatsApp, durante
        cualquier operación comercial, administrativa o promocional.
      </p>

      <p className="px-3">
        <b>6.3.</b> Si el Titular de Datos compra entradas e el sitio web de
        PARQUE BONDINHO, utilizar los canales de comunicación como
        “contáctenos”, “SAC”, “Chat”, “Regístrese”; “Trabaje Con nosotros”,
        redes sociales, y demás medios de contactos disponibles en el sitio web,
        los tipos de Datos Personales y la forma como los recolectamos podrá
        variar. Además, tenemos las siguientes hipótesis de finalidades:
      </p>

      <p className="px-3 mx-4">
        <b>a)</b> Para la venta de entradas de PARQUE BONDINHO, cancelación y/o
        reprogramación de entradas, así como para la verificación y concesión de
        beneficios previstos por Ley;
      </p>

      <p className="px-3 mx-4">
        <b>b)</b> Para identificar y/o enviar contenido relevante sobre su
        determinada preferencia o interés manifestado a PARQUE BONDINHO,
        incluyendo, newsletters, eventos, invitaciones, promociones,
        publicaciones en redes sociales, mensajes a través de plataformas
        digitales, recordatorios, notas de agradecimiento, entre otros;
      </p>

      <p className="px-3 mx-4">
        <b>c)</b> Para proveerle y a nuestros aliados de negocios, una
        experiencia más consistente en la interacción con la empresa,
        principalmente aprendiendo con la forma como utiliza e interactúa con
        los sitios web, aplicaciones (APP) y otras soluciones tecnológicas. Esto
        podrá incluir: (i) personalizarlos de acuerdo con sus preferencias y sus
        intereses, dejándolos más compatibles con su tecnología o facilitando su
        utilización de otra forma; (ii) mantener la seguridad de estos y
        protegerlos de cualquier otra forma; y (iii) desarrollar nuevos sitios
        web, aplicaciones (App), productos y servicios de PARQUE BONDINHO;
      </p>

      <p className="px-3 mx-4">
        <b>d)</b> Para tratamiento y almacenaje de datos de colaboradores,
        pasantes y terceros prestadores de servicios en PARQUE BONDINHO.
        Solamente trataremos los datos personales necesarios para atender la
        finalidad exigida por la relación laboral y para que la ejecución del
        contrato de trabajo o de prestación de servicios sea posible,
        considerándose la legislación vigente;
      </p>

      <p className="px-3 mx-4">
        <b>e)</b> Para la defensa y administración de los intereses de PARQUE
        BONDINHO, de sus socios y aliados de negocio, incluso para cumplimiento
        de determinaciones legales, regulaciones de organismos gubernamentales,
        autoridades fiscales, poder judicial y/u otra autoridad competente;
      </p>

      <p className="px-3 mx-4">
        <b>f)</b> Para el tratamiento y almacenamiento de datos de clientes,
        proveedores y prestadores de servicios de PARQUE BONDINHO así como
        también para la celebración de contratos relacionados;
      </p>

      <p className="px-3 mx-4">
        <b>g)</b> Para prestar servicios o efectuar transacciones que soliciten
        o que sean solicitadas por un aliado de negocios; suministrando
        información sobre productos, servicios y transacciones de PARQUE
        BONDINHO;
      </p>

      <p className="px-3 mx-4">
        <b>h)</b> Para el cumplimiento de legislación aplicable al sector de
        turismo, ambiental, propiedad intelectual, entre otros sectores. Esto
        podrá incluir: (i) cumplir nuestras obligaciones de conservación de
        determinados registros durante períodos mínimos; (ii) establecer,
        ejercer o actuar en procedimientos legales y reclamaciones judiciales u
        otras; (iii) cumplir legislaciones, reglamentos, órdenes judiciales u
        otros procedimientos legales; (iv) detectar, prevenir y responder a
        fraudes, infracciones a nuestros contratos o acuerdos, otras
        utilizaciones indebidas de los sitios web, aplicaciones (App), productos
        o servicios de la empresa; y (v) proteger los derechos o la propiedad de
        PARQUE BONDINHO o la salud, seguridad, bienestar, derechos o propiedad
        del titular o de terceros;
      </p>

      <p className="px-3 mx-4">
        <b>i)</b> Para el cumplimiento de cualquier otra demanda que solicite a
        PARQUE BONDINHO, sea aclarando sus dudas, incluyendo pedidos de apoyo al
        cliente; o invitándolo a participar o informarlo sobre los resultados de
        encuestas de satisfacción o de mercado;
      </p>

      <p className="px-3 mx-4">
        <b>j)</b> El PARQUE BONDINHO también podrá usar su información personal
        para otros efectos, de forma consistente con el contexto en el cual la
        información fue recolectada, o con el consentimiento que exprese.
      </p>

      <p className="px-3 mx-4">
        <b>j)</b> Para registro y/o relato en el Canal de Conducta y Ética;
      </p>
      <br />

      <strong>7. DERECHOS DEL USUARIO</strong>
      <br />

      <p className="px-3">
        <b>7.1.</b> En cumplimento de la reglamentación aplicable sobre el
        tratamiento de Datos Personales, PARQUE BONDINHO respeta y garantiza al
        titular, a través de solicitud, los siguientes derechos, según previsión
        del Art. 18 de la Ley 13.709/2018:
      </p>

      <p className="px-3 mx-4">
        <b>a) Derecho de Confirmación y Acceso:</b> Este derecho permite que
        confirme si PARQUE BONDINHO utiliza sus datos personales, y en caso
        positivo, tener acceso a un ejemplar de los datos utilizados;
      </p>

      <p className="px-3 mx-4">
        <b>b) Derecho de Rectificación:</b> Este derecho permite que solicite la
        corrección y/o rectificación de su información personal que estén
        incompletos, inexactos o desactualizados, caso identifique esta
        necesidad;
      </p>

      <p className="px-3 mx-4">
        <b>
          c) Derecho a solicitar la Anonimización, el Bloqueo o la Eliminación:
        </b>{' '}
        Este derecho permite que nos solicite: (i) anonimización de sus datos
        personales, de modo que estos no puedan más ser relacionados al
        individuo, y así dejando de ser Datos Personales; (ii) el bloqueo de sus
        datos personales, suspendiéndolos temporalmente; y (iii) la eliminación
        de sus datos personales, si se opone al uso de sus datos, momento en que
        el PARQUE BONDINHO eliminará todos sus datos, sin posibilidad de
        reversión, salvo si hubiera cualquier otra razón para su mantenimiento,
        como eventual obligación legal de retención de datos o necesidad de
        preservación de estos para protección de derechos de aplicación;
      </p>

      <p className="px-3 mx-4">
        <b>d) Derecho a la Portabilidad:</b> El titular de datos tiene el
        derecho de obtener de PARQUE BONDINHO la transmisión de sus datos para
        otro responsable o incluso a la parte interesada, por medio de un
        formato estructurado de uso habitual y lectura mecánica, dependiente de
        reglamentación de la Autoridad Nacional de Protección de Datos;
      </p>

      <p className="px-3 mx-4">
        <b>
          e) Derecho sobre la Posibilidad de No Dar su Consentimiento y sus
          Consecuencias:
        </b>{' '}
        Este derecho permite que tenga información clara y completa sobre la
        posibilidad y las consecuencias de no proveer el consentimiento. En la
        hipótesis de que el consentimiento solicitado sea negado, podrá ocurrir
        una limitación de acceso a los servicios ofrecidos por PARQUE BONDINHO;
      </p>

      <p className="px-3 mx-4">
        <b>f)Derecho a Retirar su Consentimiento:</b> Tiene derecho de retirar
        su consentimiento referente a los términos de este Aviso de Privacidad.
        Entretanto, esto no afectará la legalidad de cualquier procesamiento
        realizado anteriormente. Si quita su consentimiento, tal vez no podamos
        proveer determinados servicios de PARQUE BONDINHO;
      </p>

      <p className="px-3 mx-4">
        <b>g) Derecho de Oposición al Procesamiento:</b> El titular de los datos
        tiene derecho a oponerse al procesamiento de sus datos por razones
        relacionadas a su situación particular. En esta hipótesis, podrá
        contestar dónde y en qué contexto estamos tratando sus datos personales
        para diferentes finalidades, y oponerse, solicitando la interrupción,
        por medio del canal de contacto del DPO informado en la sesión
        específica;
      </p>

      <p className="px-3 mx-4">
        <b>
          h) Presentar petición a la Autoridad Nacional de Protección de Datos
          (ANPD):
        </b>{' '}
        El titular de datos podrá demandar directamente a ANPD para garantizar
        sus Derechos.
      </p>

      <p className="px-3">
        <b>7.2.</b> Para validar los derechos de los titulares de los datos
        personales, será necesario solicitar su información específica, para
        ayudarnos a confirmar su identidad y garantizar su derecho y seguridad
        de sus datos personales. En estos casos, solicitamos contacto directo
        con responsable por el tratamiento de datos, a través del canal:{' '}
        <a href="https://portaldotitular.bondinho.com.br/titular_bondinho">
          https://portaldotitular.bondinho.com.br/titular_bondinho
        </a>
        .
      </p>

      <p className="px-3">
        <b>7.3.</b> PARQUE BONDINHO responderá a todas las solicitudes
        legítimas, en el plazo razonable, excepto en los casos en los que el
        servicio no será posible debido al cumplimiento de obligaciones legales
        o reglamentarias, así como para ejercer o proteger derechos o prevenir
        fraudes.
      </p>

      <p className="px-3">
        <b>7.4.</b> PARQUE BONDINHO aclara que caso sus datos personales, sean
        solicitados judicialmente, nos reservamos el derecho de proveer sus
        datos e información de acuerdo con las leyes nacionales.
      </p>

      <p className="px-3">
        <b>7.5.</b> Podemos solicitar algunos Datos Personales adicionales para
        comprobar su identidad, buscando evitar incidentes y fraudes y asegurar,
        siempre, la privacidad de nuestros clientes.
      </p>

      <p className="px-3">
        <b>7.6.</b> Los Usuarios podrán ejercer cualquier otro derecho previsto
        por ley, aunque no esté no incluido aquí. Como excepción, es posible que
        su solicitud no sea atendida, pero si llega a suceder le explicaremos el
        motivo. Entretanto, adelantamos que esto solamente ocurrirá caso haya
        fundamento legal.
      </p>
      <br />

      <strong>8. PARTICIÓN DE DATOS PERSONALES </strong>
      <br />

      <p className="px-3">
        <b>8.1.</b> Compartimos los datos personales bajo nuestro control de
        manera lícita y transparente. Note que todos los operadores que actúan
        en nuestro nombre sólo tratan sus datos de acuerdo con nuestras
        instrucciones y cumplen integralmente este Aviso de Privacidad, así como
        las legislaciones de protección de datos y cualquier otra medida de
        confidencialidad y seguridad apropiada.
      </p>

      <p className="px-3">
        <b>8.2.</b> Algunos datos personales pueden ser compartidos con otras
        empresas del grupo o empresas aliadas y prestadoras de servicios para
        realizar nuestras operaciones comerciales diarias y para permitirnos que
        mantengamos una relación adecuada. Bajo ninguna hipótesis
        comercializamos los datos e información que nos provee. En algunos
        casos, sus datos personales son suministrados a nuestros proveedores o
        aliados para posibilitar una determinada prestación del servicio o para
        mejorar la experiencia de los clientes y comerciantes, como, por
        ejemplo, para empresas que procesan los pagos de la compra de la entrada
        para que disfrute nuestros servicios; empresas/agencias de publicidad,
        para, entre otros, desarrollar sitios web, seleccionar y producir
        anuncios consistentes con su perfil, según lo autorizado.
      </p>

      <p className="px-3">
        <b>8.3.</b> Es bueno que sepa que nosotros sólo trabajamos con aliados
        que puedan garantizar un nivel adecuado de protección a sus datos
        personales. Estas particiones están protegidas por los contratos que
        poseemos con estos aliados. Hay casos en que somos obligados, por ley o
        reglamento, a compartir sus datos personales con terceros, por ejemplo:
        para el suministro de información personal a organismos gubernamentales,
        autoridades fiscales, Poder Judicial y/u otra autoridad competente para
        cumplir con las obligaciones existentes por ley.
      </p>

      <p className="px-3">
        <b>8.4.</b> Es posible que, en algunas situaciones, tengamos que
        divulgar sus datos personales a terceros. Pero esté tranquilo, la
        partición de Datos Personales con terceros ocurrirá sólo para lograr
        alguna finalidad que le será legítima, específica y previamente
        informada. Lo importante es que sepa que siempre seremos transparentes
        con relación a sus datos.
      </p>
      <br />

      <strong>9. TRANSFERENCIA INTERNACIONAL DE DATOS</strong>
      <br />

      <p className="px-3">
        <b>9.1.</b> PARQUE BONDINHO no practica Transferencias Internacionales
        de datos, como regla. Ningún dato personal será transferido a terceros
        localizados fuera del país, excepto por intermedio de consentimiento
        expreso del titular o para el cumplimiento de obligación legal,
        reguladora o contractual, debidamente informado al titular.
      </p>

      <p className="px-3">
        <b>9.2.</b> Para ser transparentes y garantizar un servicio de calidad a
        usted y a nuestros socios, contratamos algunos sistemas de información
        que alojan datos personales en nubes localizadas fuera de Brasil. Así,
        hay casos de alojamiento de datos personales en otros países para que
        podamos cumplir con nuestra misión, como es el caso de las empresas
        Google y Microsoft.
      </p>
      <br />

      <strong>10. ALMACENAJE Y DESCARTE DE DATOS </strong>
      <br />

      <p className="px-3">
        <b>10.1.</b> Toda la información o Dato Personal suministrado observará
        os estándares de seguridad necesarios para cumplir las previsiones
        legales, además, se hacen esfuerzos para garantizar que esta información
        no esté sujeta a destrucción, pérdida, alteración o difusión. Solo
        almacenamos su información personal en la medida en que la necesitemos
        para poder usarla de acuerdo con el propósito para el cual fue
        recopilada y de acuerdo con la base legal para su tratamiento.
      </p>

      <p className="px-3">
        <b>10.2.</b> Mantendremos su información personal mientras su usuario en
        nuestra plataforma esté activo mientras sea necesario para conclusión de
        nuestras transacciones realizadas mientras haya una relación
        contractual, comercial o institucional, o también mientras no ejerza su
        derecho de suprimir, cancelar o limitar el procesamiento de sus datos.
      </p>

      <p className="px-3">
        <b>10.3.</b> Inactivado su usuario en nuestras plataformas, sus datos
        serán excluidos de nuestros sistemas, siempre que no haya fundamento
        para almacenarlos para fines legítimos, así definidos por ley, para
        cumplir las obligaciones legales o reguladoras, así como para ejercer o
        resguardar derechos o prevenir fraudes.
      </p>

      <p className="px-3">
        <b>10.4.</b> Para garantizar que la información y los Datos Personales
        sean retenidos sólo por el tiempo necesario, se cumplen los requisitos y
        reglamentos necesarios de los plazos en dispositivos legales y
        contractuales. Por lo tanto, además del constante cumplimiento y
        actualización de estas normativas, independientemente de su
        consentimiento o solicitud de exclusión, la Ley General de Protección de
        Datos Personales permite conservar los Datos Personales en las
        siguientes situaciones:
      </p>

      <p className="px-3 mx-4">
        <b>a)</b> Cumplimiento de obligaciones legales o reglamentarias;
      </p>
      <p className="px-3 mx-4">
        <b>b)</b> Transferencia a terceros, respetando sus derechos y previsión
        en las leyes de protección de datos y privacidad;
      </p>
      <p className="px-3 mx-4">
        <b>c)</b> Para uso exclusivo, prohibido el acceso por terceros, y
        siempre que los datos sean anónimos.
      </p>

      <p className="px-3">
        <b>10.5.</b> Entretanto, existe siempre su derecho de solicitar la
        eliminación de los Datos. Garantizamos que analizaremos esta solicitud
        de acuerdo con los requisitos legales.
      </p>
      <br />

      <strong>11. RESPONSABILIDAD DEL USUARIO</strong>
      <br />

      <p className="px-3">
        <b>11.1.</b> El usuario es el único responsable del uso de la aplicación
        y deberá respetar las reglas de este Aviso de Privacidad, así como la
        legislación aplicable.
      </p>

      <p className="px-3">
        <b>11.2.</b> El usuario garantiza la veracidad de todos los datos
        informados, siendo responsable por mantener su información personal
        actualizada.
      </p>

      <p className="px-3">
        <b>11.3.</b> PARQUE BONDINHO no será responsabilizado por daños directos
        o indirectos que resulten de, o que tengan relación con el acceso, uso o
        la incapacidad de acceder o utilizar la aplicación.
      </p>

      <p className="px-3">
        <b>11.4.</b> Es responsabilidad del usuario mantener su login y
        contraseña seguros y fuera del alcance de terceros. De esta manera, el
        usuario se responsabiliza por todas las acciones realizadas en su
        cuenta.
      </p>

      <p className="px-3">
        <b>11.5.</b> Teniendo en cuenta las características inherentes al
        ambiente de internet, la aplicación no se responsabiliza por
        interrupciones o suspensiones de conexión, transmisiones de computadora
        incompletas o que fallen, así como por falla técnica de cualquier tipo,
        incluyendo, pero no limitándose al mal funcionamiento electrónico de
        cualquier red, hardware o software.
      </p>

      <p className="px-3">
        <b>11.6.</b> Es responsabilidad del usuario mantener el ambiente de su
        dispositivo (computadora, celular, tableta, entre otros) seguro, con el
        uso de herramientas disponibles para garantizar la seguridad y también
        la utilización de redes de internet confiables.
      </p>

      <p className="px-3">
        <b>11.7.</b> El usuario declara que es legalmente capaz. En el caso de
        eventual acceso de usuario menor de 18 (dieciocho) años, con o sin
        autorización del responsable legal, este será responsable por todos los
        actos por aquellos practicados, como consta en la ley.
      </p>
      <br />

      <strong>12. CANAL DE COMUNICACIÓN</strong>
      <br />

      <p className="px-3">
        <b>12.1.</b> En el caso de dudas, para ejercer sus derechos o cualquier
        comunicación, PARQUE BONDINHO, en cumplimiento al artículo 41 de la Ley
        General de Protección de Datos, indica el contacto de la Encargada de
        Datos Personales, Louana Costa, a través del siguiente email:{' '}
        <a href="mailto:dpo@bondinho.com.br">dpo@bondinho.com.br</a>.
      </p>
      <br />

      <strong>13. LEY APLICABLE / FORO</strong>
      <br />

      <p className="px-3">
        <b>13.1.</b> Este Aviso de Privacidad se rige e interpreta de acuerdo
        con las Leyes de la República Federativa de Brasil.
      </p>

      <p className="px-3">
        <b>13.2.</b> Queda elegido el Foro de la Comarca de Rio Grande do Sul
        como el competente para dirimir cualquier cuestión que surja del
        presente documento, con expresa renuncia a cualquier otro, por más
        privilegiado que sea.
      </p>
      <br />

      <strong>14. ACTUALIZACIONES DE ESTE AVISO</strong>
      <br />

      <p className="px-3">
        <b>14.1.</b> PARQUE BONDINHO se reserva el derecho de alterar este Aviso
        de Privacidad siempre que sea necesario, para garantizar la
        funcionalidad, mejoras y actualizaciones de los servicios ofrecidos, no
        siendo obligado a mantener una estructura de la aplicación.
      </p>
      <br />

      <p>
        Nuestro Aviso de Privacidad siempre contará con la indicación de la
        fecha de actualización o versión actual.
      </p>

      <dl>
        <dd>
          <table className="table-page" width="100%" cellSpacing="0">
            <tbody>
              <tr>
                <td
                  width="55"
                  height="14"
                  style={{ backgroundColor: '#c0c0c0' }}
                >
                  <p className="mb-0 px-2">
                    <span>
                      <strong>Versión</strong>
                    </span>
                  </p>
                </td>
                <td width="156" style={{ backgroundColor: '#c0c0c0' }}>
                  <p className="mb-0 px-2">
                    <span>
                      <strong>Elemento modificado</strong>
                    </span>
                  </p>
                </td>
                <td width="299" style={{ backgroundColor: '#c0c0c0' }}>
                  <p className="mb-0 px-2">
                    <span>
                      <strong>Motivo</strong>
                    </span>
                  </p>
                </td>
                <td width="113" style={{ backgroundColor: '#c0c0c0' }}>
                  <p className="mb-0 px-2">
                    <span>
                      <strong>Fecha</strong>
                    </span>
                  </p>
                </td>
              </tr>
              <tr>
                <td width="55" height="4">
                  <p className="my-2 px-2">
                    <span>01</span>
                  </p>
                </td>
                <td width="116">
                  <p className="my-2 px-2">
                    <span>Versión original</span>
                  </p>
                </td>
                <td valign="top" width="299">
                  <p className="my-2 px-2">
                    <span>N/A</span>
                  </p>
                </td>
                <td valign="top" width="113">
                  <p className="my-2 px-2">
                    <span>27/07/2021</span>
                  </p>
                </td>
              </tr>
              <tr>
                <td width="55" height="4">
                  <p className="my-2 px-2">
                    <span>02</span>
                  </p>
                </td>
                <td width="116">
                  <p className="my-2 px-2">
                    <span>Versión original</span>
                  </p>
                </td>
                <td valign="top" width="299">
                  <p className="my-2 px-2">
                    <span>Actualización</span>
                  </p>
                </td>
                <td valign="top" width="113">
                  <p className="my-2 px-2">
                    <span>28/12/2023</span>
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
        </dd>
      </dl>
    </>
  );
}

export default PrivacyNoticeCaracolES;
