/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from "react";

import api from "../../services/api";
import { useTranslation } from "react-i18next";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import iconTimes from "../../assets/icons/svg/fa-times.svg";

import { useAppSelector, useAppDispatch } from "../../store/hooks";
import {
  removeItemCart,
  removeTotalCart,
  removeCupomCart,
  updateCart
} from "../../store/cart";

import "./CartHeader.scss";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import verifyPriceOrder from "../../infra/verifyPriceOrder";

function CartHeader() {
  const { t } = useTranslation();

  const cartBPA: any = JSON.parse(JSON.stringify(useAppSelector((state: any) => state.cart)));

  const dispatch = useAppDispatch();

  const [, setResTourCode] = useState<any>(1);

  useEffect(() => {
    const urlCode = window.location.href.split('/code/');

    function verifyTourCode() {
      if (urlCode.length >= 2) {
        localStorage.setItem("tour_code", urlCode[1])
        validateTourCode();
      }
    }

    const validateTourCode = async () => {
      try {
        const { data } = await api.get(`/api/TourCode/ValidateTourCode/${urlCode[1]}`);
        if (data.status !== 400) {
          if (data.data.typeDiscount === 3) {
            verifyAllCart();
          } if (data.data.typeDiscount === 2) {
            cartBPA.discount = data.data.valueDiscount;
            cartBPA.tourCode.code = urlCode[1];
            dispatch(updateCart(cartBPA));
          }
          if (false) { }
          setResTourCode(data.data);
        }

      } catch (error: any) {
        if (error?.response?.status === 400) {
        }
      }
    }


    const verifyAllCart = () => {
      var dados: any = [...cartBPA.dados];

      cartBPA.TourCode = {
        code: urlCode[1]
      }
      cartBPA.dados.forEach((cItem: any, index: any) => {
        const validateTourCodeProduct = async () => {
          try {
            const { data } = await api.get(`/api/TourCode/ValidateTourCodeProduct/${urlCode[1]}/${cItem.productCode}`);
            if (data.status !== 400) {
              cItem.discountBool = true;
              cItem.discount = data.data.valueDiscount;
            } else {
              cItem.discountBool = false;
              cItem.discount = 0;
            }

            if (index === dados.length - 1) {
              dispatch(updateCart(cartBPA));
            }

          } catch (error: any) {
            if (error?.response?.status === 400) {
            }
          }
        }
        validateTourCodeProduct();
      });

      cartBPA.discount = 0;
      dispatch(updateCart(cartBPA));
    }

    verifyTourCode();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClearCart = () => {
    dispatch(removeTotalCart(cartBPA.dados));
  };

  //let totalCart = 0;
  if (cartBPA.dados.length >= 1) {
    const labelArrayDiscount = verifyPriceOrder(cartBPA)[0];

    return (
      <li className="nav-item dropdown carrinho nav-item-c2" data-cart="item">
        <a
          className="nav-link text-white text-center"
          data-cart="btn"
          href="#"
          id="navbarDropdownCarrinho"
          role="button"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <FontAwesomeIcon
            icon={["fal", "shopping-cart"]}
            size="1x"
            style={{ color: "#ffffff", width: "28px", fontSize: "28px" }}
          />
          <div className="d-inline-block" id="number-circle">
            <span
              id="numeroItens"
              className="badge badge-pill badge-primary text-white"
            >
              {cartBPA.dados.length}
            </span>
          </div>
        </a>
        <div
          className="dropdown-menu px-4 py-2 mb-0"
          aria-labelledby="navbarDropdownCarrinho"
          data-cart="divMenu"
        >
          <h4 className="text-primary py-2 pb-2">{t("cartHeader.title")}</h4>
          <div className="row bg-white p-3 pb-2" id="itemsCarts">
            {cartBPA.dados.length >= 1
              ? cartBPA.dados.map((cItem: any, index: any) => {
                let isCupomProduct: any = cartBPA?.cupom?.ticketLimit === 0 ? cartBPA?.cupom?.products?.find((elem: any) => { return (cItem.productCode === elem.productCode) }) : false;

                let priceProduct = (
                  (Number(cItem.adults) * Number(cItem.priceAdults))
                  + (Number(cItem.childs) * Number(cItem.priceChilds))
                  + (Number(cItem.infants) * Number(cItem.priceInfants))
                  + (Number(cItem.elders) * Number(cItem.priceElders))
                  + (Number(cItem.student) * Number(cItem.priceStudent))
                  + (Number(cItem.priceGlobalPeople))
                );

                const discountLabel = (code: string) => {
                  let auxValue: any = [];

                  if (labelArrayDiscount?.length > 0) {
                    labelArrayDiscount.forEach((elem: any) => {
                      if (elem.productCode === code) {
                        auxValue.push(elem.value);
                      }
                    });
                    return auxValue.reduce((acum: any, value: any) => acum + value, 0);
                  }
                }

                let priceProductCupom: any = 0;

                if (cartBPA?.cupom?.ticketLimit === 0) {
                  priceProductCupom = cartBPA?.cupom?.type === 2 ? (priceProduct - cartBPA?.cupom.value) : priceProduct - ((priceProduct * (cartBPA?.cupom.value / 100)));;
                } else {
                  priceProductCupom = priceProduct;
                }

                return (
                  <div className="border-bottom row mb-2 py-2 px-0 m-0" key={index}>
                    <div className="col-4 p-0" style={{ position: "relative" }}>
                      <img
                        src={
                          cItem.productType === 1
                            ?
                            cItem.imagesBaseUrl + cItem.imgCart
                            :
                            cItem.imagesBaseUrl === 4
                              ?
                              cItem.productType + cItem.imgCart
                              :
                              cItem.imagesBaseUrl + cItem.imgCart
                        }
                        className="card-img"
                        alt="..."
                      />
                    </div>
                    <div className="col-7 mb-0">
                      <h6 className="text-primary">{cItem.productName}</h6>
                      <div className="row">
                        <div className="col-6">
                          <small>
                            {/* <i className="fal fa-user fa-lg text-primary"></i> */}
                          </small>
                          <small className="pt-0 pb-0 m-0 text-dark pr-2">
                            {(Number(cItem.adults) + Number(cItem.childs) + Number(cItem.infants) + Number(cItem.elders) + Number(cItem.student) + Number(cItem.totalPeople | 0))}{" "}
                            {t("cartHeader.numberPeople")}
                          </small>
                        </div>
                        <div className="col-6">
                          <small>
                            {/* <i className="fal fa-calendar fa-lg text-primary"></i> */}
                          </small>
                          <small className="p-0 m-0 text-dark">
                            {" "}
                            {String(cItem.date).split('-').reverse().join('/')}
                          </small>
                        </div>
                      </div>
                      <p className="p-0 m-0">
                        {
                          isCupomProduct
                            ?
                            <span className="float-left p-0 d-flex gap-1">
                              R${" "}
                              {(priceProductCupom).toFixed(2).split(".").join(",")}

                              <OverlayTrigger
                                placement={'bottom'}
                                overlay={
                                  <Tooltip id="tooltip">
                                    {labelArrayDiscount.length > 0 ?
                                      labelArrayDiscount?.map((item: any) => {
                                        if (item.productCode === cItem.productCode) {
                                          return <><small>{`${item.label} -${item?.value.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}`}</small><br /></>;
                                        } else {
                                          return "";
                                        }
                                      }) : ""}
                                  </Tooltip>
                                }>
                                <small style={{ fontSize: ".75rem", textDecoration: "line-through", cursor: 'pointer' }}>R$ {priceProduct.toFixed(2).split(".").join(",")}</small>
                              </OverlayTrigger>
                            </span>
                            :
                            <span className="float-left p-0 d-flex gap-1">
                              R${" "}
                              {discountLabel(cItem.productCode) ? (priceProduct - discountLabel(cItem.productCode)).toFixed(2).split(".").join(",") : priceProduct.toFixed(2).split(".").join(",")}

                              {
                                discountLabel(cItem.productCode) ?
                                  <OverlayTrigger
                                    placement={'bottom'}
                                    overlay={
                                      <Tooltip id="tooltip">
                                        {labelArrayDiscount.length > 0 ?
                                          labelArrayDiscount?.map((item: any) => {
                                            if (item.productCode === cItem.productCode) {
                                              return <><small>{`${item.label} -${item?.value.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}`}</small><br /></>;
                                            } else {
                                              return "";
                                            }
                                          }) : ""}
                                      </Tooltip>
                                    }>
                                    <small style={{ fontSize: ".75rem", textDecoration: "line-through", cursor: 'pointer' }}>R$ {priceProduct.toFixed(2).split(".").join(",")}</small>
                                  </OverlayTrigger>
                                  :
                                  ""
                              }
                            </span>
                        }
                      </p><br />
                    </div>
                    <div className="col-1 col-md-1 order-1 order-md-2" style={{ position: "relative", right: "10px" }}>
                      <button
                        onClick={() => {
                          dispatch(removeItemCart(cItem));
                        }}
                        className="p-0 m-0 text-muted"
                        style={{ cursor: "pointer" }}
                      >
                        <i
                          className="text-muted btn-exclui btn btn-circle bg-light text-dark btn-exclui-carrinho"
                        >
                          <img src={iconTimes} alt="" width={10} />
                        </i>
                      </button>
                    </div>
                  </div>
                );
              })
              : ""}
          </div>

          {cartBPA.cupom?.type === 2 ?
            <div className="row bg-primary pb-0 mb-0" style={{ color: "#FFF" }}>
              <div style={{ fontSize: "12px" }} className="col-12 pt-3 px-3">
                <small style={{ display: "flex", justifyContent: "space-between" }}><span>{cartBPA.cupom.codeName}</span>       <span>R$ {cartBPA.cupom.value.toFixed(2).split(".").join(",")}
                  <button
                    onClick={() => {
                      dispatch(removeCupomCart());
                    }}
                    className="p-0 m-0 text-muted"
                    style={{ cursor: "pointer" }}
                  >
                    <i className="text-muted btn-exclui text-dark btn-exclui-carrinho ml-2">
                      <FontAwesomeIcon style={{ color: "#FFF" }} icon={["fal", "times"]} />
                    </i>
                  </button>

                </span> </small>
              </div>
            </div>
            : ''}

          {cartBPA.cupom?.type === 1 ?
            <div className="row bg-primary pb-0 mb-0" style={{ color: "#FFF" }}>
              <div style={{ fontSize: "12px" }} className="col-12 pt-3 px-3">
                <small style={{ display: "flex", justifyContent: "space-between" }}><span>{cartBPA.cupom.codeName}</span>       <span> {cartBPA?.cupom?.value + '%'}
                  <button
                    onClick={() => {
                      dispatch(removeCupomCart());
                    }}
                    className="p-0 m-0 text-muted"
                    style={{ cursor: "pointer" }}
                  >
                    <i className="text-muted btn-exclui text-dark btn-exclui-carrinho ml-2">
                      <FontAwesomeIcon style={{ color: "#FFF" }} icon={["fal", "times"]} />
                    </i>
                  </button>
                </span> </small>
              </div>
            </div>
            : ''}

          <div className={cartBPA.cupom?.type === 2 || cartBPA.cupom?.type === 1 ?
            "row bg-primary p-3 pt-2 mt-0"
            :
            "row bg-primary p-3  mt-0"} id="row-total" style={{ color: "#FFF" }}>

            <div className="col-3 p-0" id="div-total">
              {t("cartHeader.total")}:
            </div>
            <div className="col-9 text-right p-0" id="totalTic">
              <div className="" data-price="total">R$ {(cartBPA?.totalCart).toFixed(2).split(".").join(",")}</div>
            </div>
          </div>

          <div className="dropdown-divider"></div>
          <div className="text-right d-flex mb-1" id="btn-div">
            <div className="w-50 px-1">
              <button
                title=""
                onClick={() => handleClearCart()}
                className="btn btn-outline-primary w-100"
                id="clear-cart"
              >
                {" "}
                {t("cartHeader.clean")}
              </button>
            </div>
            <div className="w-50 px-1">
              <a
                href="/checkout"
                className="btn btn-secondary w-100"
                id="btn-fechar"
              >
                {" "}
                {t("cartHeader.close")}
              </a>
            </div>
          </div>
        </div>
      </li>
    );
  } else {
    return (
      <li className="nav-item dropdown carrinho nav-item-c2" data-cart="item">
        <a
          className="nav-link text-primary text-center"
          data-cart="btn"
          href="#"
          id="navbarDropdownCarrinho"
          role="button"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <FontAwesomeIcon
            icon={["fal", "shopping-cart"]}
            size="1x"
            style={{ color: "#ffffff", width: "28px", fontSize: "28px" }}
          />
          <div className="d-inline-block" id="number-circle">
            <span
              id="numeroItens"
              className="badge badge-pill badge-primary text-white"
            >
              0
            </span>
          </div>
        </a>
        <div
          className="dropdown-menu px-4 py-2"
          aria-labelledby="navbarDropdownCarrinho"
          data-cart="divMenu"
        >
          <h4 className="text-primary py-2 pb-2"> {t("cartHeader.title")}</h4>
          <div className="row bg-white p-3 text-muted" id="itemsCarts">
            {t("cartHeader.notItem")}.
          </div>
        </div>
      </li>
    );
  }
}

export default CartHeader;
