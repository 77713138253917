import React from "react";

function AcessRulesEN() {
    return (
        <>
            {/* <p>Schedule your visit safely and without unforeseen events.</p> */}

            <h6 className="my-4"><b>Not allowed:</b></h6>

            <ul className="list-acess-rules">
                <li>Filming or photographing for commercial purposes, without prior authorization from the company.</li>
                <li>Porting or using Drones.</li>
                <li>Access with large materials (eg tripod, musical instruments, umbrella, beach chair, cooler/styrofoam, bulky costumes, suitcases or equipment over 25 x 35 x 55 cm).</li>
                <li>Access or remain shirtless, in bathing suits or barefoot.</li>
                <li>Eat or drink inside the trams or bring food without a sealed package.</li>
                <li>Access with skateboard, scooter, skates or shoes with built-in wheels, bicycle or similar.</li>
                <li>Access with a ball or balloon.</li>
                <li>Children or teenagers under 16 (sixteen) years old access or stay in the park unaccompanied by their parents or legal guardians. Those responsible for minors must present documentation proving the kinship relationship of up to third degree (fathers, mothers, brothers, uncles, nephews, grandparents), or parental authorization, as provided by law.</li>
                <li>Plant or remove species of flora.</li>
                <li>Practice any type of depredation of the environment.</li>
                <li>Feeding, approaching or hunting the animals.</li>
                <li>Carrying firearms, ammunition, self-defense devices (pepper spray, stun guns), sharp or pointed objects.</li>
                <li>Access with flammable or explosive objects or liquids.</li>
                <li>Loud sound, using horns, whistles, large megaphones or artificial devices that emit noise, as well as playing musical or other instruments.</li>
                <li>Carry out various manifestations.</li>
                <li>Access with animals, except guide dogs or emotional support dogs with supporting documentation.</li>
                <li>Use the park's restrooms for bathing.</li>
                <li>Access using helmets or covers that hide the face - LAW 6717/14.</li>
                <li>Commercialize any type of product or service without prior authorization from the company.</li>
                <li>Carry out any apology for crime.</li>
                <li>Access the Park with bottles, cups, or glass containers, whether containing alcoholic or non-alcoholicbeverages.</li>
            </ul>
        </>
    )
}

export default AcessRulesEN;