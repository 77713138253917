import React from "react";

import Modal from 'react-bootstrap/Modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from "react-i18next";
//import "./ModalApiError.scss";

export interface propContent {
    apiErrorMessage: any,
    setApiErrorMessage: any,
}

const ModalApiError: React.FC<propContent> = ({
    apiErrorMessage, setApiErrorMessage
}: propContent) => {
    const { t } = useTranslation();

    return (
        <>
            <Modal
                size="lg"
                show={apiErrorMessage !== ''}
                onHide={() => setApiErrorMessage('')}
                aria-labelledby="contained-modal-title-vcenter"
                className="modal-confirm"
            >
                <Modal.Body className='modal-body text-center sucess-pos'>
                    <>
                        <div className='space-modal'>
                            <FontAwesomeIcon
                                icon={["fal", "times-circle"]}
                                size="5x"
                                style={{ fontSize: "7.5em", color: "#FF6600" }}
                            />

                        </div>
                        <div className='space-modal'>
                            <div className="sucess-pos" id="wrapper" >
                                <div className="text-center">

                                    <h4 className='mt-4'>{t('modalApiError.somethingWrong')}</h4>
                                    <p>{apiErrorMessage}</p>
                                </div>
                            </div>
                        </div>
                        <div className='space-modal'>
                            <button className="btn btn-outline-primary mx-2" onClick={() => { setApiErrorMessage(''); }}>{t('modalApiError.close')} </button>
                            <button className="btn btn-outline-primary mx-2" onClick={() => { window.location.href = '/' }}>{t('modalApiError.linkBegin')} </button>
                        </div>
                    </>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default ModalApiError;