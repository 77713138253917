// import React, { useEffect } from "react";

import { Container, Row, Col } from "react-bootstrap";

// import ReCAPTCHA from "react-google-recaptcha";

import { useTranslation } from "react-i18next";

// import Form from "react-bootstrap/Form"
// import Button from "react-bootstrap/Button"

import "./Contact.scss";
import PageDafault from "../../templates/PageDafault";

function Contact() {
    const [t] = useTranslation();

    // useEffect(() => {
    //     let select: any = document.querySelector('#formGridName');
    //     if (window.location.search === "?bonde-exclusivo") {
    //         select.value = 5
    //     } else {
    //         select.value = 'default'
    //     }
    // }, [])

    /* function onChange(value: any) {
        
    } */

    return (
        <>
            <PageDafault>
                <Container className="page-static py-5">
                    <h1 className="mb-5 text-center display-2">{t("contact.title")}</h1>
                    <div className="card py-4 px-3">
                        <div className="p-3">
                            <Row className="pb-5">
                                <Col sm={6} md={6} lg={3} className="text-left text-sm-center pb-4 pb-lg-1">
                                    <p className="mb-1"><strong>{t("contact.subtitle01")}</strong></p>
                                    <a href="mailto:sac@bondinho.com.br">sac@bondinho.com.br</a>
                                </Col>
                                <Col sm={6} md={6} lg={3} className="text-left text-sm-center pb-4 pb-lg-1">
                                    <p className="mb-1"><strong>{t("contact.subtitle02")}</strong></p>
                                    <a href="mailto:negocios@bondinho.com.br">negocios@bondinho.com.br</a>
                                </Col>
                                <Col sm={6} md={6} lg={3} className="text-left text-sm-center pb-4 pb-lg-1">
                                    <p className="mb-1"><strong>{t("contact.subtitle03")}</strong></p>
                                    <a href="mailto:marketing@bondinho.com.br">marketing@bondinho.com.br</a>
                                </Col>
                                <Col sm={6} md={6} lg={3} className="text-left text-sm-center pb-4 pb-lg-1">
                                    <p className="mb-1"><strong>{t("contact.subtitle04")}</strong></p>
                                    <a href="mailto:dpo@bondinho.com.br">dpo@bondinho.com.br</a>
                                </Col>
                            </Row>
                            {/* <Row className="px-1 px-md-2">
                                <Form>
                                    <Row>
                                        <Col md={12} className="mb-3">
                                            <Form.Group controlId="formGridName">
                                                <Form.Label>{t("contact.select")}</Form.Label>
                                                <Form.Select>
                                                    <option value="default">{t("contact.default")}</option>
                                                    <option value="1">{t("contact.value01")}</option>
                                                    <option value="2">{t("contact.value02")}</option>
                                                    <option value="3">{t("contact.value03")}</option>
                                                    <option value="4">{t("contact.value04")}</option>
                                                    <option value="5">{t("contact.value05")}</option>
                                                    <option value="6">{t("contact.value06")}</option>
                                                    <option value="7">{t("contact.value07")}</option>
                                                    <option value="8">{t("contact.value08")}</option>
                                                </Form.Select>
                                            </Form.Group>
                                        </Col>
                                        <Col md={12} className="mb-3">
                                            <Form.Group controlId="formGridName">
                                                <Form.Label>{t("contact.name")}</Form.Label>
                                                <Form.Control type="text" required placeholder={`${t("contact.placeholderName")}`} />
                                            </Form.Group>
                                        </Col>
                                        <Col md={12} className="mb-3">
                                            <Form.Group controlId="formGridEmail">
                                                <Form.Label>{t("contact.email")}</Form.Label>
                                                <Form.Control type="email" required placeholder="email@email.com" />
                                            </Form.Group>
                                        </Col>
                                        <Col md={12} className="mb-3">
                                            <Form.Group controlId="formGridPhone">
                                                <Form.Label>{t("contact.cellphone")}</Form.Label>
                                                <Form.Control type="text" required placeholder="+55" />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Form.Group as={Col} className="mb-4" controlId="formGridTextArea">
                                            <Form.Label>{t("contact.msg")}</Form.Label>
                                            <Form.Control as="textarea" required rows={3} />
                                        </Form.Group>
                                    </Row>
                                    <div className="d-flex flex-column align-items-end">
                                        <div className="mb-3">
                                            <ReCAPTCHA
                                                sitekey="6LcEIb4UAAAAAJP0ES_uICrmE4jYnBESJjmK-OQw"
                                                onChange={onChange}
                                            />
                                        </div>
                                        <div>
                                            <Button variant="primary" type="submit" className="px-5 text-uppercase">
                                                {t("contact.send")}
                                            </Button>
                                        </div>
                                    </div>
                                </Form>
                            </Row> */}
                        </div>
                    </div>
                </Container>
            </PageDafault>
        </>
    )
}

export default Contact;