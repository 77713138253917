import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import api from '../../services/api';

import Form from 'react-bootstrap/Form';
import { useAppSelector } from '../../store/hooks';

export interface propPlus {
  propsId: number;
  actionPickup: any;
  alert: any;
  index: any;
}

const GetPickupsSIG: React.FC<propPlus> = ({
  propsId, actionPickup, alert, index
}: propPlus) => {
  const cartBPA: any = JSON.parse(JSON.stringify(useAppSelector((state: any) => state.cart)));

  const [info, setInfo] = useState<any>(null);

  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

  const [check, setCheck] = useState<any>(true);

  const onMenuOpen = () => {
    if (isMenuOpen === false) {
      setIsMenuOpen(true);
    } else {
      setIsMenuOpen(false);
    }
  }

  useEffect(() => {
    const token = localStorage.getItem("c2tourAuth") || "{}";
    const config = {
      headers: { Authorization: `Bearer ${JSON.parse(token).token}` },
    };
    async function listPickups() {
      try {
        const { data } = await api.get(`/api/Products/ListPickupLocationSigXSig?ProductCode=${cartBPA.dados[index].productCode}&ProductModCode=${cartBPA.dados[index].productModCode}`, config);
        if (data.status !== 400) {
          setInfo(data.data.data);
        }
      } catch (error: any) {
        if (error.response.status === 401) {
          window.location.href = window.location.origin + "/";
        }
      }
    }
    listPickups();
  }, []);

  const handleChange = (options: any) => {
    actionPickup(options, index, true);
    setCheck(false)
  };

  if (info !== null) {
    return (
      <>
        <h6>Local de Embarque</h6>
        {
          cartBPA.dados[index].pickupInfo === undefined
            ?
            <>
              <Select
                aria-labelledby="aria-label"
                inputId="aria-example-input"
                name="aria-live-color"
                onMenuOpen={onMenuOpen}
                onMenuClose={onMenuOpen}
                onChange={handleChange}
                placeholder={"Selecione"}
                options={Array.from(info)}
                getOptionLabel={(option: any) => `${option.nomePickup}: ${option.address}`}
              />
              {
                check === true
                  ?
                  alert
                  :
                  ""
              }
            </>
            :
            <Select
              aria-labelledby="aria-label"
              inputId="aria-example-input"
              className="endpoint"
              name="aria-live-color"
              placeholder="Selecione"
              onMenuOpen={onMenuOpen}
              onMenuClose={onMenuOpen}
              onChange={handleChange}
              options={Array.from(info)}
              getOptionLabel={(option: any) => `${option.nomePickup}: ${option.address}`}
              defaultValue={{
                "idPickupLocation": cartBPA.dados[index].pickup,
                "nomePickup": `${cartBPA.dados[index].pickupInfo.nomePickup}: ${cartBPA.dados[index].pickupInfo.address}`
              }}
            />
        }
      </>
    );
  } else {
    return (
      <>
        <h6>Local de Embarque</h6>
        <Form.Select>
          <option value='' disabled></option>
        </Form.Select>
      </>
    )

  }
};

export default GetPickupsSIG;
