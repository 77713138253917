import React, { useState, useEffect } from 'react';

import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import "./ProductSliderSigxSig.scss";

import { Col, Row } from 'react-bootstrap';

export interface arrayImages { arrayImages: any, imagesBaseUrl: any };

const TourSlider: React.FC<arrayImages> = ({
  arrayImages, imagesBaseUrl
}: arrayImages) => {
  const [nav1, setNav1] = useState<any>();
  const [nav2, setNav2] = useState<any>();
  const [slider1, setSlider1] = useState<any>();
  const [slider2, setSlider2] = useState<any>();

  useEffect(() => {
    setNav1(slider1);
    setNav2(slider2);
  }, [slider1, slider2]);

  const settingsMain: any = {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    fade: true,
    swipeToSlide: true,
    focusOnSelect: true,
    asNavFor: ".slider-nav",
    lazyLoad: 'ondemand', // Lazy load images
  };

  const settingsThumbs: any = {
    slidesToShow: 4,
    slidesToScroll: 1,
    asNavFor: ".slider-for",
    dots: false,
    centerMode: false,
    infinite: false,
    vertical: true,
    focusOnSelect: true,
    verticalSwiping: false,
    arrows: false,
    lazyLoad: 'ondemand', // Lazy load images
  };

  return (
    <div className="tourSlider mb-5">
      <div className="slider-wrapper">
        <Row>
          <Col md={3} className="p-0 m-0 d-none d-lg-block" style={{ overflow: 'hidden' }}>
            <div className="thumbnail-slider-wrap-sigxsig">
              <Slider
                {...settingsThumbs}
                asNavFor={nav1}
                ref={(slider) => setSlider2(slider)}
              >
                {arrayImages.length > 0
                  ? arrayImages.map((img: any, index: any) => (
                    <div className="slick-slide" style={{ width: '100%' }} key={index}>
                      <img
                        className="slick-slide-image"
                        src={`${imagesBaseUrl}${img}`}
                        alt="..."
                      />
                    </div>
                  ))
                  : ""}
              </Slider>
            </div>
          </Col>
          <Col lg={9} className="m-0" style={{ paddingRight: "0" }}>
            <Slider
              {...settingsMain}
              asNavFor={nav2}
              ref={(slider) => setSlider1(slider)}
            >
              {arrayImages.length > 0
                ? arrayImages.map((img: any, index: any) => (
                  <div className="slick-slide" key={index} >
                    <div style={{ width: '100%', backgroundImage: `url(${imagesBaseUrl}${img})`, backgroundPosition: 'center', aspectRatio: '713 / 625', backgroundSize: "cover" }}></div>
                  </div>
                ))
                : ""}
            </Slider>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default TourSlider;