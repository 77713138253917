import React from 'react';
import i18next from 'i18next';

import PrivacyNoticeCaracolEN from '../../pages/PrivacyNotice/PrivacyNoticeCaracol/components/PrivacyNoticeCaracolEN/PrivacyNoticeCaracolEN';
import PrivacyNoticeCaracolES from '../../pages/PrivacyNotice/PrivacyNoticeCaracol/components/PrivacyNoticeCaracolES/PrivacyNoticeCaracolES';
import PrivacyNoticeCaracolPT from '../../pages/PrivacyNotice/PrivacyNoticeCaracol/components/PrivacyNoticeCaracolPT/PrivacyNoticeCaracolPT';

import './PrivacyTerms.scss';

function PrivacyTerms() {
  return (
    <>
      {i18next.language === 'pt' ? (
        <PrivacyNoticeCaracolPT />
      ) : i18next.language === 'en' ? (
        <PrivacyNoticeCaracolEN />
      ) : i18next.language === 'es' ? (
        <PrivacyNoticeCaracolES />
      ) : (
        <PrivacyNoticeCaracolPT />
      )}
      {/* <div dangerouslySetInnerHTML={{ __html: t('privacy.text') }}></div> */}
    </>
  );
}

export default PrivacyTerms;
