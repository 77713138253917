import React from "react";

function ExchangePolicyEN() {
    return (
        <div className="bg-exchange-policy p-2">
            <h4 className="text-center mb-2 mb-md-4">Parque Bondinho Pão de Açúcar stores</h4>
            <div>
                <h6>Chilli Beans</h6>
                <p className="mb-1">Exchange at any store in Brazil upon presentation of the CPF used for the purchase.</p>
                <p className="mb-0">Contact the store at: +55 21 2541-6446 or check the addresses on the website: <a href="https://loja.chillibeans.com.br" target="_blank" rel="noreferrer">loja.chillibeans.com.br</a>.</p>
            </div>
            <div>
                <h6>Espaço Rubro Negro</h6>
                <p className="mb-1">Exchange at any store in Brazil upon presentation of NF and tag on the item.</p>
                <p className="mb-0">Contact the store at: +55 21 99085-2145 or check the addresses on the website: <a href="https://www.espacorubronegro.com.br" target="_blank" rel="noreferrer">www.espacorubronegro.com.br</a>.</p>
            </div>
            <div>
                <h6>Estúdio Alma</h6>
                <p className="mb-0">Exchange in store upon presentation of the product with the store label, contact must be made via the numbers: +55 21 98104-0303 or via Instagram: <a href="https://www.instagram.com/sigaestudioalma/" target="_blank" rel="noreferrer">@sigaestudioalma</a>.</p>
            </div>
            <div>
                <h6>Havaianas</h6>
                <p className="mb-1">Exchange at any Havaianas store in Brazil upon presentation of the exchange coupon or invoice.</p>
                <p className="mb-0">Check the addresses on the website: <a href="https://www.havaianas.com.br" target="_blank" rel="noreferrer">www.havaianas.com.br</a>.</p>
            </div>
            <div>
                <h6>Palhas de Buriti</h6>
                <p className="mb-1">Exchanges only for manufacturing defects and within 7 days of purchase at any Palhas de Buriti store upon presentation of invoice.</p>
                <p className="mb-0">Contact the store at: +55 21 98354-1119 or via email: <a href="mailto:atendimentopbrj@gmail.com">atendimentopbrj@gmail.com</a> or via Instagram: <a href="https://www.instagram.com/palhasburitirj/" target="_blank" rel="noreferrer">@palhasburitirj</a>.</p>
            </div>
            <div>
                <h6>Pierim</h6>
                <p className="mb-0">Exchange in store by contacting management by phone: +55 21 97197-9878 or by email: <a href="mailto:pierim.rio@pierim.com.br">pierim.rio@pierim.com.br</a>.</p>
            </div>
            <div>
                <h6>Oazis</h6>
                <p className="mb-1">Exchange at any Oazis store upon presentation of the tax coupon. Contact the store by number: +55 21 9 7375-9821 or email: <a href="mailto:oazis.rj@gmail.com" target="_blank" rel="noreferrer">oazis.rj@gmail.com</a></p>
            </div>
            <div>
                <h6>Sauer</h6>
                <p className="mb-1">Exchange within 30 days at any store in Brazil upon presentation of invoice.</p>
                <p className="mb-0">For more information, contact the store at: +55 21 2525-0000 or consult the addresses on the website: <a href="https://www.sauer1941.com" target="_blank" rel="noreferrer">www.sauer1941.com</a>.</p>
            </div>
        </div>
    )
}

export default ExchangePolicyEN;