import React from "react";
import { Container } from "react-bootstrap";
import PageDafault from "../../templates/PageDafault";

import { useTranslation } from "react-i18next";
import AcessRules from "../../components/AccessRules/AccessRules";

import "./AccessRulesPage.scss";
import { Helmet } from "react-helmet";
import i18next from "i18next";

function AccessRulesPage() {
    const [t] = useTranslation();

    return (
        <>
            <Helmet>
                <html lang={i18next.language} />
                <title>Regras de Acesso | Parque Bondinho</title>
                <meta name="description" content="Fique por dentro de todas as regras para ter acesso ao Parque Bondinho Pão de Açúcar e desfrute da experiência mais inesquecível da sua vida. Confira!" />
                <meta name="googlebot" content={process.env.REACT_APP_SERVER_ROBOTS} />

                {/* Essential META Tags */}
                <meta property="og:title" content="Regras de Acesso | Parque Bondinho" />
                <meta property="og:type" content="TouristAttraction" />
                <meta property="og:image" content={`${process.env.REACT_APP_SERVER_URL}${process.env.REACT_APP_SERVER_LOGO}`} />
                <meta property="og:url" content={process.env.REACT_APP_SERVER_URL} />

                {/* Non-Essential, But Recommended */}
                <meta property="og:description" content="Fique por dentro de todas as regras para ter acesso ao Parque Bondinho Pão de Açúcar e desfrute da experiência mais inesquecível da sua vida. Confira!" />
                <meta property="og:site_name" content={process.env.REACT_APP_SERVER_NAME} />

                <meta name="robots" content={process.env.REACT_APP_SERVER_ROBOTS} />
                <link rel="canonical" href={process.env.REACT_APP_SERVER_URL} />
            </Helmet>

            <PageDafault>
                <div className="bg-fundo">
                    <Container className="page-static bg-how-to-get py-5">
                        <h1 className="mb-5 text-center display-2">{t("navHeader.sideBar.acessRules")}</h1>
                        <div className="card">
                            <AcessRules />
                        </div>
                    </Container>
                </div>
            </PageDafault>
        </>
    )
}

export default AccessRulesPage;