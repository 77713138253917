import { ErrorMessage } from '@hookform/error-message';
import React from 'react';
import { Row, Col } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { Controller } from 'react-hook-form';
import { useTranslation } from "react-i18next";
import i18next from 'i18next';


import ModalPrivacy from '../Modal/ModalPrivacy/ModalPrivacy';
import PrivacyTerms from '../PrivacyTermsNotice/PrivacyTerms';

import TermsUse from '../TermsUse/TermsUse';
import TermsOfPurchase from '../../pages/TermsOfPurchase/TermsOfPurchase';
import TermsOfPurchasePT from '../../pages/TermsOfPurchase/TermsOfPurchasePT';
import TermsOfPurchaseEN from '../../pages/TermsOfPurchase/TermsOfPurchaseEN';
import TermsOfPurchaseES from '../../pages/TermsOfPurchase/TermsOfPurchaseES';

export interface props {
    control: any;
    setValue: any;
    setPrivacy: any;
    privacy: any;
    errors: any;
    editProfile?: any;
    aceptPrivacy?: any;
    aceptWpp?: any;
    profile?: any;
    showTerms?: boolean;
    showInfos?: boolean;
    showBuyTerms?: boolean;
}

const ApprovedTerms: React.FC<props> = ({
    control,
    setValue,
    setPrivacy,
    privacy,
    errors,
    aceptPrivacy,
    aceptWpp,
    editProfile = true,
    profile = false,
    showTerms = true,
    showInfos = true,
    showBuyTerms = false

}: props) => {
    const [t] = useTranslation();

    if (editProfile === true) {
        return (
            <>

                {showTerms === true ? <Row className="mt-3 mb-0">
                    <Form.Group as={Col} controlId="BR" className="d-flex">
                        <label htmlFor="chackPolice">
                            <Form.Group>
                                <Controller
                                    control={control}
                                    name="checkPolice"
                                    rules={{ required: { value: profile !== true ? true : false, message: t("profileNew.errorCheck") } }}
                                    render={({ field, e }: any) => (
                                        <Form.Check
                                            {...field}
                                            id="chackPolice"
                                            name="check"
                                            className="mr-2"
                                            defaultChecked={aceptPrivacy}
                                            disabled={profile}
                                            required
                                        />
                                    )}
                                />
                            </Form.Group>
                        </label>
                        <div className={profile === true ? 'opacity-50' : 'opacity-100'}>
                            {t("checkoutSteps.acept")}
                            <ModalPrivacy
                                type="privacy"
                                name={t("checkoutSteps.privacy")}
                                header={t("checkoutSteps.privacy")}
                                footer={profile === true ? `no` : `yes`}
                                close={t("privacyTerms.refuse")}
                                save={t("privacyTerms.accept")}
                                class="btn-privacy d-flex"
                                setValue={setValue}
                                setPrivacy={setPrivacy}
                            >
                                <div className="modal-checkout">
                                    <PrivacyTerms />
                                </div>
                            </ModalPrivacy>
                            {t("checkoutSteps.and")}
                            <ModalPrivacy
                                type="privacy"
                                name={t("checkoutSteps.terms")}
                                header={t("checkoutSteps.terms")}
                                footer={profile === true ? `no` : `yes`}
                                close={t("privacyTerms.refuse")}
                                save={t("privacyTerms.accept")}
                                class="btn-privacy d-flex"
                                setValue={setValue}
                                setPrivacy={setPrivacy}
                            >
                                <div className="modal-checkout">
                                    <TermsUse />
                                </div>
                            </ModalPrivacy>
                        </div>
                    </Form.Group>
                    {
                        privacy === false && errors.checkPolice !== undefined // Validação Checkbox
                            ?
                            <ErrorMessage
                                errors={errors}
                                name="checkPolice"
                                render={({ message }) => <small style={{ color: "red", marginLeft: "25px" }}>{message}</small>}
                            />
                            :
                            <></>
                    }
                </Row> : <></>
                }

                {showBuyTerms === true ? <Row className="mt-3 mb-0">
                    <Form.Group as={Col} controlId="BR" className="d-flex">
                        <label htmlFor="chackPolice">
                            <Form.Group>
                                <Controller
                                    control={control}
                                    name="checkPolice"
                                    rules={{ required: { value: profile !== true ? true : false, message: t("profileNew.errorCheck") } }}
                                    render={({ field, e }: any) => (
                                        <Form.Check
                                            {...field}
                                            id="chackPolice"
                                            name="check"
                                            className="mr-2"
                                            defaultChecked={aceptPrivacy}
                                            disabled={profile}
                                            required
                                        />
                                    )}
                                />
                            </Form.Group>
                        </label>
                        <div className={profile === true ? 'opacity-50' : 'opacity-100'}>
                            {t("checkoutSteps.acept")}
                            <ModalPrivacy
                                type="privacy"
                                name={t("checkoutSteps.termsBuy")}
                                header={t("checkoutSteps.privacy")}
                                footer={profile === true ? `no` : `yes`}
                                close={t("privacyTerms.refuse")}
                                save={t("privacyTerms.accept")}
                                class="btn-privacy d-flex"
                                setValue={setValue}
                                setPrivacy={setPrivacy}
                            >
                                <div className="modal-checkout">
                                {i18next.language === "pt" ? <TermsOfPurchasePT /> : i18next.language === "en" ? <TermsOfPurchaseEN /> : i18next.language === "es" ? <TermsOfPurchaseES /> : <TermsOfPurchasePT />}
                                </div>
                            </ModalPrivacy>
                        </div>
                    </Form.Group>
                    {
                        privacy === false && errors.checkPolice !== undefined // Validação Checkbox
                            ?
                            <ErrorMessage
                                errors={errors}
                                name="checkPolice"
                                render={({ message }) => <small style={{ color: "red", marginLeft: "25px" }}>{message}</small>}
                            />
                            :
                            <></>
                    }
                </Row> : <></>
                }

                {showInfos === true ? <Row className="mt-2 mb-4">
                    <Form.Group as={Col} controlId="BR" className="d-flex">
                        <label htmlFor="checkWpp">
                            <Form.Group>
                                <Controller
                                    control={control}
                                    name="checkNew"
                                    render={({ field, e }: any) => (
                                        <Form.Check
                                            {...field}
                                            id="checkNew"
                                            name="check"
                                            className="mr-2"
                                            defaultChecked={aceptWpp}
                                            label={t("checkoutSteps.aceptNew")}
                                        />
                                    )}
                                />
                            </Form.Group>
                        </label>
                    </Form.Group>
                </Row> : <></>}


            </>
        )
    } else {
        return (
            <>
                <Row className="mt-3 mb-0">
                    <Form.Group as={Col} controlId="BR" className="d-flex">
                        <label htmlFor="chackPolice">
                            <Form.Group>
                                <Controller
                                    control={control}
                                    name="checkPolice"
                                    render={({ field, e }: any) => (
                                        <Form.Check
                                            {...field}
                                            id="chackPolice"
                                            name="check"
                                            className="mr-2"
                                            defaultChecked={aceptPrivacy}
                                            disabled
                                            required
                                        />
                                    )}
                                />
                            </Form.Group>
                        </label>
                        <div style={{ opacity: 0.5 }}>
                            {t("checkoutSteps.acept")}
                            <ModalPrivacy
                                type="privacy"
                                name={t("checkoutSteps.privacy")}
                                header={t("checkoutSteps.privacy")}
                                footer={profile === true ? `no` : `yes`}
                                close={t("privacyTerms.refuse")}
                                save={t("privacyTerms.accept")}
                                class="btn-privacy d-flex"
                            >
                                <div className="modal-checkout">
                                    <PrivacyTerms />
                                </div>
                            </ModalPrivacy>
                            {t("checkoutSteps.and")}
                            <ModalPrivacy
                                type="privacy"
                                name={t("checkoutSteps.terms")}
                                header={t("checkoutSteps.terms")}
                                footer={profile === true ? `no` : `yes`}
                                close={t("privacyTerms.refuse")}
                                save={t("privacyTerms.accept")}
                                class="btn-privacy d-flex"
                            >
                                <div className="modal-checkout">
                                    <TermsUse />
                                </div>
                            </ModalPrivacy>
                        </div>
                    </Form.Group>
                </Row>

                <Row className="mt-2 mb-4">
                    <Form.Group as={Col} controlId="BR" className="d-flex">
                        <label htmlFor="checkWpp">
                            <Form.Group>
                                <Controller
                                    control={control}
                                    name="checkNew"
                                    render={({ field, e }: any) => (
                                        <Form.Check
                                            {...field}
                                            id="checkNew"
                                            name="check"
                                            className="mr-2"
                                            defaultChecked={aceptWpp}
                                            disabled
                                            label={t("checkoutSteps.aceptNew")}
                                        />
                                    )}
                                />
                            </Form.Group>
                        </label>
                    </Form.Group>
                </Row>
            </>
        )
    }
}

export default ApprovedTerms;