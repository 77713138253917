const verifyPriceOrder = (cart: any) => {
    let labelArrayDiscount: any = 0;
    let pricePerLabel: any = [];

    if (cart.dados.length >= 1) {
        // eslint-disable-next-line array-callback-return
        cart.dados.map((cItem: any) => {
            cart?.cupom?.labelsApplied?.forEach((item: any) => {
                if (item?.productApplied === cItem?.productCode) {
                    for (let i = 0; i < item.adults; i++) {
                        pricePerLabel.push({ value: cItem.priceAdults, productCode: cItem.productCode, label: cItem?.ranges?.labelAdult });
                    }

                    for (let i = 0; i < item.childs; i++) {
                        pricePerLabel.push({ value: cItem.priceChilds, productCode: cItem.productCode, label: cItem?.ranges?.labelChild });
                    }

                    for (let i = 0; i < item.infants; i++) {
                        pricePerLabel.push({ value: cItem.priceInfants, productCode: cItem.productCode, label: cItem?.ranges?.labelInfant });
                    }

                    for (let i = 0; i < item.elders; i++) {
                        pricePerLabel.push({ value: cItem.priceElders, productCode: cItem.productCode, label: cItem?.ranges?.labelElders });
                    }

                    for (let i = 0; i < item.students; i++) {
                        pricePerLabel.push({ value: cItem.priceStudent, productCode: cItem.productCode, label: cItem?.ranges?.labelStudent });
                    }
                }
            })
        })

        let newArrayPricePerLabel = pricePerLabel.filter((value: any) => value.value !== null);
        let orderLabel: any = newArrayPricePerLabel.sort((a: any, b: any) => b.value - a.value);

        if (cart.cupom.type === 2) { // Fixo
            labelArrayDiscount = orderLabel.slice(0, cart.cupom.ticketLimit > 0 ? pricePerLabel?.length : cart.cupom.ticketLimit).map((price: any) => ({ value: cart.cupom.value, productCode: price.productCode, label: price.label }));
        } else if (cart.cupom.type === 1) { // Percentual

            labelArrayDiscount = orderLabel.slice(0, pricePerLabel?.length).map((price: any) => ({ value: price.value * (cart.cupom.value / 100), productCode: price.productCode, label: price.label }));
        }
    }

    return [labelArrayDiscount, pricePerLabel.length];
}

export default verifyPriceOrder;
