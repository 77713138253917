import React from 'react';

import { useTranslation } from "react-i18next";

export interface propTour {
    transfers: any,
};

const CheckoutSummartTransfer: React.FC<propTour> = ({
    transfers
}: propTour) => {
    const { t } = useTranslation();
    let totalCart = 0;
    return (
        <div>
            {Array.from(transfers).length > 0 ? Array.from(transfers).map((transfer: any, index) => {
                totalCart = Number(totalCart) + (
                    (Number(transfer.adults) * Number(transfer.priceAdults))
                    + (Number(transfer.childs) * Number(transfer.priceChilds))
                    + (Number(transfer.infants) * Number(transfer.priceInfants))
                    + (Number(transfer.elders) * Number(transfer.priceElders))
                    + (Number(transfer.student) * Number(transfer.priceStudent))
                    + (Number(transfer.globalPeople) * Number(transfer.priceGlobalPeople))
                );
                return (
                    <div key={index}>
                        <div className="row border-bottom">
                            <div className="col-12 text-left mt-4">
                                <h6 className="m-0 p-0 text-uppercase">{transfer.productName} </h6>
                                <small> {t("checkoutCards.cardTransfer.data")}: {transfer.date.split("-").reverse().join("/")}</small>
                            </div>
                        </div>
                        <div className="row border-bottom py-2">
                            <div className="col-12 text-left ">
                                {/* <i className="fal fa-users mr-2 pb-2 text-primary" aria-hidden="true"></i> */}
                                <small className="d-block d-md-inline-block"> {transfer.globalPeople} {t("checkoutCards.cardTransfer.pessoas_veiculos")}</small>
                            </div>
                            <div className="col-12 text-left ">
                                {/* <i className="fal fa-suitcase mr-2 pb-2 text-primary" aria-hidden="true"></i> */}
                                <small className="d-block d-md-inline-block"> {transfer.bigSuitcase} {t("checkoutCards.cardTransfer.mala_pessoa")}</small>
                            </div>
                            <div className="col-12 text-left ">
                                {/* <i className="fal fa-suitcase-rolling mr-2 pb-2 text-primary" aria-hidden="true"></i> */}
                                <small className="d-block d-md-inline-block"> {transfer.smallSuitcase} {t("checkoutCards.cardTransfer.mala_mao_pessoa")} </small>
                            </div>
                        </div>
                        <div className="row border-bottom py-2 small">
                            <div className="col-12 text-left ">
                                <strong>{t("checkoutCards.cardTransfer.origem")}: </strong> {transfer.commingSource}</div>
                            <div className="col-12 text-left ">
                                <strong>{t("checkoutCards.cardTransfer.bordTime")}: </strong> {transfer.time}
                            </div>
                            <div className="col-12 text-left ">
                                <strong>{t("checkoutCards.cardTransfer.destino")}: </strong>{transfer.goingSource}  </div>
                        </div>
                        <div className="row border-bottom py-2 small">
                            <div className="col-5 text-left">{t("checkoutCards.cardTransfer.passageiros")}</div>
                            <div className="col-2 text-center px-0">{transfer.globalPeople} x</div>
                            <div className="col-5 text-right ">R$ {transfer.price.toFixed(2).split('.').join(',')}</div>
                        </div>
                        <div className="row border-bottom py-2 small">
                            <div className="col-12 text-right ">
                                <strong data-price="subtotal">R$ {transfer.price.toFixed(2).split('.').join(',')}</strong>
                            </div>
                        </div>
                    </div>
                )
            }) : ''}
        </div>
    );
}

export default CheckoutSummartTransfer;