import React, { useState } from "react";

import api from '../../../services/api';

import { Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useTranslation } from "react-i18next";
import Button from "@restart/ui/esm/Button";
import Modal from "react-bootstrap/Modal";
//import GoogleLogin from "react-google-login";
//import FacebookLogin from "react-facebook-login";

import { OAuth2Client } from 'google-auth-library';

import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';

import '../../../components/Modal/ModalRegistration/ModalRegistration.scss';

import ReCAPTCHA from "react-google-recaptcha";
import monitoringAPI from "../../../services/monitoring";

export interface propAction {
    isCheckoutStep?: any;
    action?: any;
}

const Account: React.FC<propAction> = ({
    isCheckoutStep = false, action
}: propAction) => {

    const { t } = useTranslation();

    const [errorMessage, setErrorMessage] = useState<any>(null);
    const [loading, setLoading] = useState<any>(false);
    const [alertBool, setAlertBool] = useState<any>(false);
    const [alertMessage, setAlertMessage] = useState<any>("");

    const [isRecaptcha, setIsRecaptcha] = useState<any>(null);

    async function verify(client_id: any, jwtToken: any) {
        const client = new OAuth2Client(client_id);
        // Call the verifyIdToken to
        // varify and decode it
        const ticket = await client.verifyIdToken({
            idToken: jwtToken,
            audience: client_id,
        });

        // Get the JSON with all the user info
        const payload = ticket.getPayload();
        // This is a JSON object that contains
        // all the user info

        responseGoogle(payload);

        return payload;
    }

    const responseGoogle = (response: any) => {

        const profileObj: any = response;

        if (response !== undefined) {
            const givenName = profileObj !== undefined ? profileObj.given_name : null;
            const familyName = profileObj !== undefined ? profileObj.family_name : null;
            const email = profileObj !== undefined ? profileObj.email : null;
            const googleId = profileObj !== undefined ? profileObj.sub : null;
            const imageUrl = profileObj !== undefined ? profileObj.picture : null;

            let req: any = { ///LOGIN API
                "firstName": givenName,
                "lastName": familyName,
                "email": email,
                "cellphone": "",
                "password": "",
                "googleId": googleId,
                "facebookId": "",
                "origem": 0,
                "photo": imageUrl
            }

            const getAuthenticateGoogleId = async () => {
                try {
                    const { data } = await api.post('/api/LoginSite/AccountCreate', req);
                    if (data.status !== 400) {
                        localStorage.setItem("bpasiteAuth", JSON.stringify(data.data));
                        if (window.location.pathname === "/login") {
                            window.location.href = "/";
                        } else if (data.data.log === 1) {
                            setAlertMessage(data.data.texto);
                            setAlertBool(true);
                            /* alert(data.data.texto) */
                            monitoringAPI(400, 'LoginSite', 'AccountCreate', data.data.texto || 'Retornado status diferente de 200', '', '/api/LoginSite/AccountCreate', req);
                        } else {
                            monitoringAPI(200, 'LoginSite', 'AccountCreate', 'Conta google criada com sucesso', '', '/api/LoginSite/AccountCreate', req);
                            window.location.reload();
                        }
                    } else {
                        monitoringAPI(400, 'LoginSite', 'AccountCreate', data.data.texto || 'Retornado status diferente de 200', '', '/api/LoginSite/AccountCreate', req);
                    }

                } catch (error: any) {
                    setLoading(false)
                    if (error?.response?.status === 400) {
                        setErrorMessage(error.response.data.errorMessage)
                    }
                    let message: any = "";

                    if(error?.response?.status === 404) {
                        message = "Erro 404 - API não encontrada"
                    } else if(error?.message) {
                      message = error?.message;
                    } else {
                      message = error?.response?.data?.data === null ? error?.response?.data?.errorMessage : error?.response?.data?.errorMessage + ": " + error?.response?.data?.data[0]?.errors[0];
                    }

                    monitoringAPI(400, 'LoginSite', 'AccountCreate', message, '', '/api/LoginSite/AccountCreate', req);

                }
            }

            getAuthenticateGoogleId();
        }
    };

    const onSubmit = (e: any) => {
        e.preventDefault();

        if (isRecaptcha !== null) {
            const getAuthenticate = async () => {
                let req: any = { ///LOGIN API
                    "email": e.target.email.value,
                    "password": e.target.cust_pass.value,
                    "facebookId": '',
                    "googleId": '',
                }

                try {
                    const { data } = await api.post('/api/LoginSite/Authenticate', req);
                    if (data.status !== 400) {
                        localStorage.setItem("bpasiteAuth", data.data !== null ? JSON.stringify(data.data) : '{}');
                        if (data.statusCode === 400) {
                            setLoading(false)
                            setErrorMessage(data.errorMessage);
                            setIsRecaptcha(null);
                            monitoringAPI(400, 'LoginSite', 'Authenticate', data.errorMessage || 'Retornado status diferente de 200', '', '/api/LoginSite/Authenticate', req);
                        } else if (window.location.pathname === "/login") {
                            monitoringAPI(200, 'LoginSite', 'Authenticate', 'Login efetuado com sucesso.', '', '/api/LoginSite/Authenticate', req);
                            window.location.href = "/";
                        } else if (data.data.log === 1) {
                            setAlertMessage(data.data.texto);
                            setAlertBool(true);
                            setLoading(false);
                            setIsRecaptcha(null);
                            monitoringAPI(400, 'LoginSite', 'Authenticate', data.data.texto || 'Retornado status diferente de 200', '', '/api/LoginSite/Authenticate', req);
                        } else {
                            monitoringAPI(200, 'LoginSite', 'Authenticate', 'Login efetuado com sucesso.', '', '/api/LoginSite/Authenticate', req);
                            if (isCheckoutStep === true) {
                                action(data);
                            } else {
                                window.location.reload();
                            }
                        }
                    } else {
                        monitoringAPI(400, 'LoginSite', 'Authenticate', t('modalApiError.sorry') || 'Retornado status diferente de 200', '', '/api/LoginSite/Authenticate', req);
                        setLoading(false);
                        setErrorMessage(t('modalApiError.sorry'));
                    }

                } catch (error: any) {
                    setLoading(false);

                    if (error?.response?.status === 400) {
                        setErrorMessage(error.response.data.errorMessage);
                    }else if(error?.response?.status === 404){
                        setErrorMessage(t('modalApiError.errorAPI'));
                    }

                    let message: any = "";

                    if(error?.response?.status === 404) {
                        message = "Erro 404 - API não encontrada"
                    } else if(error?.message) {
                      message = error?.message;
                    } else {
                      message = error?.response?.data?.data === null ? error?.response?.data?.errorMessage : error?.response?.data?.errorMessage + ": " + error?.response?.data?.data[0]?.errors[0];
                    }

                    monitoringAPI(400, 'LoginSite', 'Authenticate', message, '', '/api/LoginSite/Authenticate', req);
                }
            }

            getAuthenticate();
        } else {
            setLoading(false);
            alert(t("profileNew.alert06"))
        }
    };

    function handleRacaptcha(value: any) {
        setIsRecaptcha(value);
    }

    return (
        <>
            <div className="modal-new-account">
                <form className="user" name="login_form" onSubmit={(e) => onSubmit(e)}>
                    <div className="form-group mb-2">
                        <label htmlFor="exampleInputEmail">{t("navHeader.login.email")}</label>
                        <input type="email" name="email" className="form-control" id="exampleInputEmail" aria-describedby="emailHelp" autoComplete="off" />
                    </div>
                    <div className="form-group mb-2 mb-md-3">
                        <label htmlFor="exampleInputPassword">{t("navHeader.login.password")}</label>
                        <input type="password" name="cust_pass" className="form-control" id="exampleInputPassword" autoComplete="off" />
                        {errorMessage === null ?
                            <></>
                            :
                            <div className="invalid form-group">
                                <div className="invalid-feedback text-small" style={{ display: 'block' }}>{errorMessage}</div>
                            </div>
                        }
                    </div>



                    {/* <div className="form-group mb-1 mb-md-0">
                        <div className="custom-control custom-checkbox small pb-0 pb-md-2" style={{ display: "flex", alignItems: "center" }}>
                            <input type="checkbox" name="remember" className="form-check-input m-0" value="1" id="customCheck" style={{ width: "16px", height: "16px" }} />
                            <label className="custom-control-label px-2 m-0" htmlFor="customCheck" style={{ fontSize: "14px" }}>{t("navHeader.login.check")}</label>
                        </div>
                    </div> */}
                    <div className="d-flex flex-column">
                        <div className="mb-3 bg-profile-recaptcha">
                            {
                                loading === false
                                    ?
                                    <ReCAPTCHA
                                        sitekey="6LcrMYYgAAAAAMt8yWH41E4YDEofQRARG1X43TLw" //Key Bondinho
                                        //sitekey="6LcPv5ogAAAAALl93I2Mlj0robC4vgG8jV72abwP" //Key Test Matheus
                                        // sitekey="6LcEIb4UAAAAAJP0ES_uICrmE4jYnBESJjmK-OQw" //Key C2
                                        onChange={handleRacaptcha}
                                    />
                                    :
                                    <></>
                            }
                        </div>
                    </div>
                    <button type="submit" className="btn btn-primary rounded btn-block w-100 login" onClick={() => { setLoading(true) }} >{loading === true ? <div className="load small white"></div> : t("navHeader.login.submitButton")}</button>
                </form>

                <div className="mt-2 mt-md-3">
                    <a className="forgot-link" href={'/esqueci-senha'}>{t("navHeader.login.forgotPass")}</a>
                </div>



                <hr style={{ margin: "20px 0 " }} />

                {/* Media Social Mobile */}
                <Row className="d-flex justify-content-center">
                    <div className="mb-2 mb-md-3 p-0 d-flex justify-content-center">
                        <small className="new-account-login mt-0 text-center p-0" style={{ color: "#333333", fontSize: "14px" }}>{t("navHeader.login.DontHaveAccount")} <a href={'/nova-conta'}><strong>{t("navHeader.login.CreateYourAccont")}</strong></a></small>
                    </div>
                    {/* <div className="media-social col-6">
                        <FacebookLogin
                            appId="1063704611154644"
                            // bd6215aa6da5d6a66061adfbf2157c24
                            // 1b22b7bb9de29b3246f0cf8552ed7d7e
                            autoLoad={false}
                            fields="name,email,picture"
                            scope="public_profile,email,user_birthday"
                            icon="fal fa-facebook"
                            textButton={"Facebook"}
                            cssClass="btnFacebook rounded"
                            callback={responseFacebook}
                        />
                    </div> */}
                    <div className="media-social col-6" style={{ display: "flex", justifyContent: "center" }}>
                        {/* <GoogleLogin
                            clientId={`${process.env.REACT_APP_GOOGLE_CLIENTE_ID}`}
                            buttonText="Google"
                            onSuccess={responseGoogle}
                            onFailure={responseGoogle}
                            className='rounded'
                        /> */}
                        <GoogleOAuthProvider
                            clientId={`${process.env.REACT_APP_GOOGLE_CLIENTE_ID}`}
                        >
                            <GoogleLogin
                                onSuccess={response => {
                                    verify(response.clientId, response.credential);
                                }}
                                onError={() => {
                                    alert('Login Failed');
                                }}
                                shape="pill"
                                theme="outline"
                                text="signin"
                                size="large"
                                useOneTap
                            />
                        </GoogleOAuthProvider>
                    </div>
                    {/* <small className="new-account-login mt-0 text-center" style={{ color: "#333333", fontSize: "14px" }}>{t("navHeader.login.DontHaveAccount")} <Link to={'/nova-conta'}><strong>{t("navHeader.login.CreateYourAccont")}</strong></Link></small> */}
                </Row>
                {/* Media Social Mobile */}
            </div>
            <Modal
                className="modal-confirm"
                show={alertBool}
                onHide={() => {
                    setAlertBool(false)
                }}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
            >
                <div className='modal-body text-center'>
                    <div>
                        <FontAwesomeIcon
                            icon={["fal", "times-circle"]}
                            size="5x"
                            style={{ fontSize: "7.5em", color: "#FF6600" }}
                        />

                    </div>
                    <div className="px-md-5" style={{ padding: "0 3rem" }}>
                        {alertMessage}
                    </div>
                    <div>
                        <Button type="submit" className="btn btn-primary form-button px-4 py-2" onClick={() => setAlertBool(false)}>Fechar</Button>
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default Account;