import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Modal } from "react-bootstrap";

import './PostShipping.scss';

export interface propPost {
    modalSuccess: any,
    setModalSuccess: any,
    modalContent?: any,
    log?: any,
    responseText?: any,
    redirectOnSuccess?: any
};

const PostShipping: React.FC<propPost> = ({
    modalSuccess, setModalSuccess, modalContent, log, responseText, redirectOnSuccess = ''
}: propPost) => {

    /* function handleReload() {
        window.location.reload();
    } */

    function handleClosed() {
        setModalSuccess(false)
    }

    return (
        <>
            <Modal
                className={"modal-confirm loading-modal modal-validation"}
                show={modalSuccess}
                onHide={() => {
                    if(modalContent === 'success' && redirectOnSuccess !== ''){
                        window.location.href = redirectOnSuccess;
                    }
                }}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <>
                    <Modal.Body className="modal-body text-center sucess-pos modal-default d-flex flex-column justify-content-evenly px-4">
                        {modalContent !== "loading" ? (
                            <>
                                <div>
                                    {log === 1 || log === "1" ? (
                                        <FontAwesomeIcon
                                            icon={["fal", "times"]}
                                            size="5x"
                                        //style={{ fontSize: "7.5em", color: "#6731FF" }}
                                        />
                                    ) : log === 0 || log === "0" ? (
                                        <FontAwesomeIcon
                                            icon={["fal", "check"]}
                                            size="5x"
                                        //style={{ fontSize: "7.5em", color: "#6731FF" }}
                                        />
                                    ) : (
                                        <FontAwesomeIcon
                                            icon={["fal", "question"]}
                                            size="5x"
                                        //style={{ fontSize: "7.5em", color: "#6731FF" }}
                                        />
                                    )}
                                </div>
                                <div>
                                    <h5 className="mb-1">{log === 1 ? "Erro na validação!" : log === 0 ? "Sucesso!" : "Esperando validação!"}</h5>
                                    <small style={{ color: "#707070" }}>{responseText}</small>
                                </div>
                                <div className="d-flex justify-content-center pt-3">
                                    {log === 0 || log === "0" ? (
                                        <button
                                            onClick={()=>{
                                                if(modalContent === 'success' && redirectOnSuccess !== ''){
                                                    window.location.href = redirectOnSuccess;
                                                }
                                            }}
                                            className="btn btn-primary mx-2 w-25"
                                        >
                                            Confirmar
                                        </button>
                                    ) : (
                                        <button
                                            onClick={handleClosed}
                                            className="btn btn-primary mx-2 w-25"
                                        >
                                            Confirmar
                                        </button>
                                    )}
                                </div>
                            </>
                        ) : (
                            <div className="modal-body">
                                <div className="text-center">
                                    <div className="load"></div>
                                </div>
                                <div>
                                    <h5>Esperando validação!</h5>
                                    <small>Processando</small>
                                </div>
                                <div></div>
                            </div>
                        )}
                    </Modal.Body>
                </>
            </Modal>
        </>
    )
}

export default PostShipping;