import React from "react";
import { useTranslation } from "react-i18next";
import { Container } from "react-bootstrap";
import Location from "../../components/Location/Location";
import PageDafault from "../../templates/PageDafault";

import './HowToGet.scss';
import i18next from "i18next";
import { Helmet } from "react-helmet";

function HowToGet() {
    const [t] = useTranslation();

    return (
        <>
            <Helmet>
                <html lang={i18next.language} />
                <title>Como Chegar ao Parque Bondinho</title>
                <meta name="description" content="Saiba como chegar no Parque do Bondinho com segurança e tranquilidade. Acesse nossa página e encontre rotas de metrô, bicicleta, carro e mais!" />
                <meta name="googlebot" content={process.env.REACT_APP_SERVER_ROBOTS} />

                {/* Essential META Tags */}
                <meta property="og:title" content="Como Chegar ao Parque Bondinho" />
                <meta property="og:type" content="TouristAttraction" />
                <meta property="og:image" content={`${process.env.REACT_APP_SERVER_URL}${process.env.REACT_APP_SERVER_LOGO}`} />
                <meta property="og:url" content={process.env.REACT_APP_SERVER_URL} />

                {/* Non-Essential, But Recommended */}
                <meta property="og:description" content="Saiba como chegar no Parque do Bondinho com segurança e tranquilidade. Acesse nossa página e encontre rotas de metrô, bicicleta, carro e mais!" />
                <meta property="og:site_name" content={process.env.REACT_APP_SERVER_NAME} />

                <meta name="robots" content={process.env.REACT_APP_SERVER_ROBOTS} />
                <link rel="canonical" href={process.env.REACT_APP_SERVER_URL} />
            </Helmet>
            
            <PageDafault>
                <div className="bg-fundo">
                    <Container className="page-static bg-how-to-get py-5">
                        <h1 className="mb-5 text-center display-2">{t("howtoget.title01")}<br />{t("howtoget.title02")}</h1>
                        <Location />
                    </Container>
                </div>
            </PageDafault>
        </>
    )
}

export default HowToGet;