import React, { useState } from "react";

import api from "../../../../services/api";

import { Container, Modal/* , Row  */ } from "react-bootstrap";
//import FormGuideAddress from "./components/FormGuideAddress/FormGuideAddress";
import FormGuidePersonal from "./components/FormGuidePersonal/FormGuidePersonal";
//import { useTranslation } from "react-i18next";

import './FormGuides.scss';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
function FormGuides() {
    /* Success Modal (Inserir) */
    const [modalShow, setModalShow] = useState<any>(false);
    const [responseText, setResponseText] = useState<any>();
    const [modalContent, setModalContent] = useState<any>();
    const [log, setLog] = useState<any>();
    /* END - Success Modal (Inserir) */

    const handleNext = (newObject: any) => {
        handleFinished(newObject);
    };

    //const groupId: any = localStorage.getItem("GroupId");

    const handleFinished = (objectFinished: any) => {
        setModalShow(true);
        setModalContent("loading");

        if (objectFinished.cadasturExpirationDate) {
            sendAffiliates(objectFinished);
        } else {
            setModalContent("error");
            setLog(1);
            setResponseText("Por favor, informa a data de validade do CADASTUR");
        }
    }

    const sendAffiliates = async (obj: object) => {
        try {
            const str: any = { ...obj };
            str.workingState = str?.workingState.description;
            str.workingCity = str?.workingCity.description;
            str.cadasturExpirationDate = str?.cadasturExpirationDate?.split("/").reverse().join("-")

            const { data } = await api.post('/api/Guide/InsertGuide', str);

            if (data.statusCode !== 400) {
                setResponseText(data.data.texto);
                setLog(data.data.log);

                if (data.data.log === 0) {
                    setModalContent("success");
                    setTimeout(() => {
                        window.location.reload();
                    }, 2500);
                } else {
                    setModalContent("error");
                }
            } else {
                console.log(data)
                setResponseText(data.errorMessage);
                setLog(1);
                setModalContent("error");
            }

        } catch (error: any) {
            /* if (error?.response?.status === 400) { */
            setResponseText(error?.response?.data?.errorMessage || "Erro ao processar informações, entre em contato com o nosso suporte!");
            setLog(1);
            setModalContent("error");
        }
    }

    /* useEffect(() => {
        setGuideControlObject((prevState: any) => {
            return { ...prevState, ...guideObject };
        });
    }, [guideObject]); */

    const handleReload = () => {
        setModalShow(false);
        //setStepCtrl(0);
    }
    return (
        <>
            <Container className="steps-guide">
                {/* <Row>
                    <div className="steps d-flex justify-content-center px-3 pb-1 px-md-0 col">
                        <div className="step complete">
                            1
                            <br />
                            <span className="complete">
                            {t("guides.personalData")}
                            </span>
                        </div>
                        <div
                            className={
                                stepCtrl > 0 ? "stepline complete" : "stepline "
                            }
                        ></div>
                        <div className={stepCtrl > 0 ? "step complete" : "step "}>
                            2
                            <br />
                            <span className={stepCtrl > 0 ? "complete" : ""}>
                            {t("guides.address")}
                            </span>
                        </div>
                      
                    </div>
                </Row> */}
                {/* <div className="forms-affiliates">
                    {stepCtrl === 0 ? (
                        <FormGuidePersonal
                            action={handleNext}
                            guideControlObject={guideControlObject}
                        />
                    ) : (
                        "   "
                    )}
                    {stepCtrl === 1 ? (
                        <FormGuideAddress
                            action={handleNext}
                            back={setStepCtrl}
                            setModalShow={setModalShow}
                            guideControlObject={guideControlObject}
                        />
                    ) : (
                        " "
                    )}                    
                </div> */}

                <div className="forms-affiliates">
                    <FormGuidePersonal
                        action={handleNext}
                    //guideControlObject={guideControlObject}
                    />
                </div>

                <Modal
                    size="lg"
                    show={modalShow}
                    onHide={handleReload}
                    aria-labelledby="contained-modal-title-vcenter"
                    className="modal-confirm loading-modal"
                >
                    <Modal.Body className='modal-body text-center sucess-pos'>
                        {
                            modalContent !== "loading"
                                ?
                                <>
                                    <div className="my-4">
                                        {
                                            log === 1 || log === "1"
                                                ?
                                                <FontAwesomeIcon
                                                    icon={["fal", "times-circle"]}
                                                    size="5x"
                                                    style={{ fontSize: "7.5em", color: "#FF6600" }}
                                                />
                                                :
                                                log === 0 || log === "0"
                                                    ?
                                                    <FontAwesomeIcon
                                                        icon={["fal", "check-circle"]}
                                                        size="5x"
                                                        style={{ fontSize: "7.5em", color: "#FF6600" }}
                                                    />
                                                    :
                                                    <FontAwesomeIcon
                                                        icon={["fal", "question-circle"]}
                                                        size="5x"
                                                        style={{ fontSize: "7.5em", color: "#FF6600" }}
                                                    />
                                        }
                                    </div>
                                    <div className="my-5">
                                        {responseText}
                                    </div>
                                    <div className="d-flex justify-content-center my-4">

                                        <button
                                            onClick={handleReload}
                                            className="btn btn-primary mx-2 w-25"
                                        >
                                            Confirmar
                                        </button>

                                    </div>
                                </>
                                :
                                <div className="modal-body">
                                    <div className="text-center my-4">
                                        <FontAwesomeIcon
                                            icon={["fal", "spinner-third"]}
                                            size="5x"
                                            style={{ fontSize: "7.5em", color: "#FF6600", width: '120px', height: '120px' }}
                                            className="load"
                                        />
                                    </div>
                                    <div className=" my-5">
                                        <span>Processando</span>
                                    </div>
                                    <div></div>
                                </div>
                        }
                    </Modal.Body>
                </Modal>


            </Container>
        </>
    )
}

export default FormGuides;