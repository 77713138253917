import React, { useEffect, useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';
import { Helmet } from 'react-helmet';

import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/pro-solid-svg-icons'
import { fal } from '@fortawesome/pro-light-svg-icons';
import { fad } from '@fortawesome/pro-duotone-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';

import { Provider } from 'react-redux';
import store from './store/cart';

import './assets/sass/global.scss'

import ScrollToTop from "./infra/ScrollToTop/ScrollToTop";

import Routes from './routes';
import { CroctProvider } from '@croct/plug-react';

function App() {
  library.add(fas, fal, fad, fab);
  const [isScriptLoaded, setIsScriptLoaded] = useState(false);

  useEffect(() => {
    // Define uma função para carregar o script
    const loadScript = () => {
      setIsScriptLoaded(true);
    };

    // Adiciona um ouvinte de evento para o carregamento da página
    window.addEventListener("load", loadScript);

    // Remove o ouvinte de evento ao desmontar o componente
    return () => {
      window.removeEventListener("load", loadScript);
    };
  }, []);

  return (
    <div className="App">
      <CroctProvider appId={`${process.env.REACT_APP_CROCT_ID}`}>
        <Provider store={store} >
          <I18nextProvider i18n={i18n}>
            <BrowserRouter>
              {
                isScriptLoaded &&
                <Helmet>
                    <script
                      id="ze-snippet"
                      src="https://static.zdassets.com/ekr/snippet.js?key=e570d45a-23a3-42fd-a704-bbd9050d26e0"
                      defer
                    ></script>
                    <meta name="adopt-website-id" content="b296e363-6129-456a-b99a-b44d114aafe5" />
                    <script
                      src="//tag.goadopt.io/injector.js?website_code=b296e363-6129-456a-b99a-b44d114aafe5"
                      className="adopt-injector"
                      defer
                    ></script>
                    <link rel="shortcut icon" href="bondinho-favicon.ico" type="image/x-icon" />
                </Helmet>
              }

              <ScrollToTop />
              <Routes />
            </BrowserRouter>
            {/* <CookieAlert /> */}
          </I18nextProvider>
        </Provider>
      </CroctProvider>
    </div >
  );
}

export default App;