import React from "react";

import { Row, Col } from "react-bootstrap";

import { useTranslation } from "react-i18next";
import i18next from "i18next";

import Modal from "react-bootstrap/Modal";

import './ModalAlert.scss';
import ModalAlertPT from "./ModalAlertPT/ModalAlertPT";
import ModalAlertEN from "./ModalAlertEN/ModalAlertEN";
import ModalAlertES from "./ModalAlertES/ModalAlertES";

declare global {
    interface Window {
        alertModal?: any;
    }
}

function ModalAlert(props: any) {
    const [t] = useTranslation();
    const [modalShow, setModalShow] = React.useState(true);
    const customClasses: string = "modal-custom modalAuth " + props.class;

    if (window.alertModal === undefined) {
        window.alertModal = false;

        return (
            <div className="w-auto px-0">
                <Modal
                    {...props}
                    className={customClasses}
                    show={modalShow}
                    onHide={() => {
                        setModalShow(false)
                    }}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <>
                        {
                            props.header === "no"
                                ?
                                ""
                                :
                                <Modal.Header closeButton></Modal.Header>
                        }
                        {
                            props.class === "modal-default"
                                ?
                                <>
                                    <div className="bg-top-modal" style={{ backgroundImage: `url(${process.env.REACT_APP_SERVER_MODAL_DEFAULT})` }}>
                                        <img src={`${process.env.REACT_APP_SERVER_LOGO}`} alt="logo modal" />
                                    </div>
                                    <div className="bg-bottom-modal m-auto">
                                        {
                                            i18next.language === "pt"
                                                ?
                                                <ModalAlertPT />
                                                :
                                                i18next.language === "en"
                                                    ?
                                                    <ModalAlertEN />
                                                    :
                                                    i18next.language === "es"
                                                        ?
                                                        <ModalAlertES />
                                                        :
                                                        <ModalAlertPT />
                                        }
                                        <button
                                            className="btn btn-primary"
                                            onClick={() => {
                                                setModalShow(false)
                                            }}>
                                            Ok
                                        </button>
                                    </div>
                                </>
                                :
                                <>
                                    <Row className="h-100 mx-0">
                                        <Col md={5} className="p-0">
                                            <div className="bg-top-modal" style={{ backgroundImage: `url(${process.env.REACT_APP_SERVER_MODAL_DEFAULT})` }}>
                                                <img src={`${process.env.REACT_APP_SERVER_LOGO}`} alt="logo modal" />
                                            </div>
                                        </Col>
                                        <Col md={7} className="p-0 m-auto">
                                            <div className="bg-bottom-modal m-auto">
                                                <h3 className="">{t("modal.alert.title")}</h3>
                                                <p className="">{t("modal.alert.text")}</p>
                                                <button
                                                    className="btn btn-primary"
                                                    onClick={() => {
                                                        setModalShow(false)
                                                    }}>
                                                    {t("modal.alert.btn")}
                                                </button>
                                            </div>
                                        </Col>
                                    </Row>
                                </>
                        }
                        {
                            props.btnLeave !== "yes"
                                ?
                                ""
                                :
                                props.modalContent !== "loading"
                                    ?
                                    <div className="btn-custom-modal d-flex justify-content-center">
                                        <button
                                            className="btn btn-primary"
                                            onClick={() => {
                                                setModalShow(false)
                                            }}>
                                            OK
                                        </button>
                                    </div>
                                    :
                                    ""
                        }
                    </>
                </Modal>
            </div >
        );
    } else {
        return (<></>)
    }

}

export default ModalAlert;
