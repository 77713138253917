import React, { useState, useEffect } from 'react';

import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { useTranslateHoursRange } from '../../../utils/useTranslateHoursRange';

export interface propTour {
  tour: any,
  discountLabel: any,
  labelArrayDiscount: any
};

const CheckoutSummartTour: React.FC<propTour> = ({
  tour, discountLabel, labelArrayDiscount
}: propTour) => {
  const translateHour = useTranslateHoursRange(tour.timeRange);
  const [language, setLanguage] = useState<any>();
  const { t } = useTranslation();

  useEffect(() => {
    setLanguage(i18next.language)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18next.language]);

  const displayDiscount = (faixa: any) => {
    let qtdDiscountLabel: number = 0;

    if (labelArrayDiscount.length > 0 && discountLabel(tour.productCode) > 0) {
      qtdDiscountLabel = labelArrayDiscount.filter((item: any) => item.productCode === tour.productCode && item.label === tour.ranges[`label${faixa}`]).length;

      const firstItem = labelArrayDiscount.find(
        (item: any) =>
          item.productCode === tour.productCode &&
          item.label === tour.ranges[`label${faixa}`]
      );

      if (firstItem) {
        return (
          <div key={firstItem.productCode} className="d-flex justify-content-between" style={{ fontSize: ".65rem" }}>
            <small>Desconto: </small>
            <small>{qtdDiscountLabel}x</small>
            <small>{`-${firstItem?.value?.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}`}</small>
          </div>
        );
      }
    }
    return null;
  };

  return (
    <div>
      <div className="row border-bottom align-items-center">
        <div className="col-12 text-left mt-4">
          <h6 className="m-0 p-0 text-uppercase">{language === "pt" ? tour.productNameBR : language === "en" ? tour.productNameEN : language === "es" ? tour.productNameES : tour.productNameBR}</h6>
          <small>{t("checkoutSteps.date")}: {tour.date.split("-").reverse().join("/")} {translateHour}{" "}
          </small>
        </div>

        {
          tour?.reservationSystem === 99
            ?
            <div className='my-2'>
              <small><strong>Local de Embarque:</strong> {tour?.pickupInfo?.address}</small>
            </div>
            :
            <></>
        }
      </div>

      {
        tour?.ranges?.labelsRealSorted.map((faixa: any, i: any) => {
          return (
            <div key={i}>
              {
                (tour[`${faixa.toLowerCase()}`] | tour[`${faixa.toLowerCase()}s`]) > 0
                  ?
                  faixa !== "Private"
                    ?
                    <div className="row border-bottom py-2 small align-items-center">
                      <div className="col-5 text-left">{tour.ranges[`label${faixa}`]}: </div>
                      <div className="col-2 text-center px-0">{(tour[`${faixa.toLowerCase()}`] | tour[`${faixa.toLowerCase()}s`])} x</div>
                      <div className="col-5 text-right ">R$ {(Number(tour[`price${faixa}`]) | Number(tour[`price${faixa}s`])).toFixed(2).split('.').join(',')}</div>

                      {displayDiscount(faixa)}
                    </div>
                    :
                    <div className="row border-bottom py-2 small align-items-center">
                      <div className="col-5 text-left">{tour.ranges[`labelPrivate`]}: </div>
                      <div className="col-2 text-center px-0">{tour[`totalPeople`]} x</div>
                      <div className="col-5 text-right ">R$ {Number(tour[`priceGlobalPeople`]).toFixed(2).split('.').join(',')}</div>
                    </div>
                  :
                  ""
              }
            </div>
          )
        })}
      {tour.discountBool === true
        ?
        <div className="row border-bottom py-2 small align-items-center">
          <div className="col-5 text-left">Desconto: </div>
          <div className="col-2 text-center px-0"> x</div>
          <div className="col-5 text-right ">{Number(tour.discountValue)}%</div>
        </div>
        :
        ''
      }
      {/* <div className="row border-bottom py-2 small">
        <div className="col-12 text-right "><strong data-price="subtotal">R$ {tour.totalPrice.toFixed(2).split('.').join(',')}</strong></div>
      </div> */}
    </div>
  );
}

export default CheckoutSummartTour;