import React/* , { useState } */ from 'react';
import { Container/* , Tab, Tabs */ } from "react-bootstrap";

import ListReservations from '../../components/Reservations/ListReservations/ListReservations';

import { useTranslation } from "react-i18next";

import './MyReservations.scss';
import PageDafault from '../../templates/PageDafault';
//import ListReservationsLugano from '../../components/Reservations/ListReservationsLugano/ListReservationsLugano';

function MyReservations() {
    const [t] = useTranslation();

    //const [isLugano, setIsLugano] = useState<boolean>(false);

    return (
        <>
            <PageDafault>
                <Container className="my-reservations py-5">
                    <h1 className="mb-5 text-center display-2">{t("myReservations.title")}</h1>

                    {/* {
                        isLugano !== false
                            ?
                            <Tabs defaultActiveKey={1} id="uncontrolled-tab-example">
                                <Tab eventKey={1} title="Reservas">
                                    <ListReservations />
                                </Tab>
                                <Tab eventKey={2} title="Parceria Lugano">
                                    <ListReservationsLugano />
                                </Tab>
                            </Tabs>
                            : */}
                            <ListReservations />
                    {/* } */}

                </Container>
            </PageDafault>
        </>
    );
}

export default MyReservations;