import React from "react";

function ExchangePolicyES() {
    return (
        <div className="bg-exchange-policy p-2">
            <h4 className="text-center mb-2 mb-md-4">Parque Bondinho Pão de Açúcar stores</h4>
            <div>
                <h6>Chilli Beans</h6>
                <p className="mb-1">Canje en cualquier tienda de Brasil previa presentación del CPF utilizado para la compra.</p>
                <p className="mb-0">Contacte la tienda al: +55 21 2541-6446 o consulte las direcciones en el sitio web: <a href="https://loja.chillibeans.com.br" target="_blank" rel="noreferrer">loja.chillibeans.com.br</a>.</p>
            </div>
            <div>
                <h6>Espaço Rubro Negro</h6>
                <p className="mb-1">Canje en cualquier tienda de Brasil previa presentación del NF y etiqueta en el artículo.</p>
                <p className="mb-0">Contacte la tienda al: +55 21 99085-2145 o consulte las direcciones en el sitio web: <a href="https://www.espacorubronegro.com.br" target="_blank" rel="noreferrer">www.espacorubronegro.com.br</a>.</p>
            </div>
            <div>
                <h6>Estúdio Alma</h6>
                <p className="mb-0">Cambio en tienda previa presentación del producto con la etiqueta de la tienda, el contacto debe realizarse a través de los números: +55 21 98104-0303 o vía Instagram: <a href="https://www.instagram.com/sigaestudioalma/" target="_blank" rel="noreferrer">@sigaestudioalma</a>.</p>
            </div>
            <div>
                <h6>Havaianas</h6>
                <p className="mb-1">Canje en cualquier tienda Havaianas de Brasil previa presentación del cupón de cambio o factura.</p>
                <p className="mb-0">Consulte las direcciones en el sitio web: <a href="https://www.havaianas.com.br" target="_blank" rel="noreferrer">www.havaianas.com.br</a>.</p>
            </div>
            <div>
                <h6>Palhas de Buriti</h6>
                <p className="mb-1">Cambios únicamente por defectos de fabricación y dentro de los 7 días posteriores a la compra en cualquier tienda Palhas de Buriti previa presentación de factura.</p>
                <p className="mb-0">Contacta a la tienda al: +55 21 98354-1119 o vía correo electrónico: <a href="mailto:atendimentopbrj@gmail.com">atendimentopbrj@gmail.com</a> o vía Instagram: <a href="https://www.instagram.com/palhasburitirj/" target="_blank" rel="noreferrer">@palhasburitirj</a>.</p>
            </div>
            <div>
                <h6>Pierim</h6>
                <p className="mb-0">Cambio en tienda contactando a la gerencia por teléfono: +55 21 97197-9878 o por correo electrónico: <a href="mailto:pierim.rio@pierim.com.br">pierim.rio@pierim.com.br</a>.</p>
            </div>
            <div>
                <h6>Oazis</h6>
                <p className="mb-1">Canje en cualquier tienda Oazis previa presentación del cupón fiscal. Comuníquese con la tienda al número: +55 21 9 7375-9821 o al correo electrónico: <a href="mailto:oazis.rj@gmail.com" target="_blank" rel="noreferrer">oazis.rj@gmail.com</a></p>
            </div>
            <div>
                <h6>Sauer</h6>
                <p className="mb-1">Cambio dentro de los 30 días en cualquier tienda de Brasil previa presentación de la factura.</p>
                <p className="mb-0">Para más información, comuníquese con la tienda al: +55 21 2525-0000 o consultar las direcciones en la página web: <a href="https://www.sauer1941.com" target="_blank" rel="noreferrer">www.sauer1941.com</a>.</p>
            </div>
        </div>
    )
}

export default ExchangePolicyES;