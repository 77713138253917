import React from "react";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import Helmet from 'react-helmet';

import MailchimpSubscribe from "react-mailchimp-subscribe";

import "./Home.scss";

import BannerHomeCroct from "../../components/Banners/BannerHomeCroct/BannerHomeCroct";

import ProductsCarrousel from "../../components/Products/ProductsCarrousel/ProductsCarrousel";

//import Partners from "../../components/Partners/Partners";
import Newsletter from "../../components/Newsletter/Newsletter";

//import Explore from "../../components/Explore/Explore";
import OurPark from "../../components/OurPark/OurPark";
import PageDafault from "../../templates/PageDafault";
import ParkType from "./components/ParkType";

const Home = () => {
  const [t] = useTranslation();
  const postUrl = `https://c2rio.us14.list-manage.com/subscribe/post?u=f4575b66d53bf434464e4b9ae&id=d64793f209`;

  return (
    <div style={{ backgroundColor: "#EEEEEE" }}>
      <Helmet>
        <html lang={i18next.language} />
        <title>{process.env.REACT_APP_SERVER_NAME}</title>
        <meta name="description" content="Cartão-postal do Rio de Janeiro, o Parque Bondinho Pão de Açúcar permite experiências de tirar o fôlego. Acesse nosso site e conheça mais!" />
        <meta name="googlebot" content={process.env.REACT_APP_SERVER_ROBOTS} />

        {/* Essential META Tags */}
        <meta property="og:title" content={process.env.REACT_APP_SERVER_NAME} />
        <meta property="og:type" content="TouristAttraction" />
        <meta property="og:image" content={`${process.env.REACT_APP_SERVER_URL}${process.env.REACT_APP_SERVER_LOGO}`} />
        <meta property="og:url" content={process.env.REACT_APP_SERVER_URL} />

        {/* Non-Essential, But Recommended */}
        <meta property="og:description" content="Cartão-postal do Rio de Janeiro, o Parque Bondinho Pão de Açúcar permite experiências de tirar o fôlego. Acesse nosso site e conheça mais!" />
        <meta property="og:site_name" content={process.env.REACT_APP_SERVER_NAME} />

        <meta name="robots" content={process.env.REACT_APP_SERVER_ROBOTS} />
        <link rel="canonical" href={process.env.REACT_APP_SERVER_URL} />
      </Helmet>

      <PageDafault>
        <div className="content-header">
          <BannerHomeCroct />
        </div>
        <div
          id="buy-product"
          className="container container-atividades my-outside mb-5 pb-4 mb-5 pb-4 pb-lg-0 mb-lg-0"
        >
          <div data-category="listCategorie" className="">
            <h1 className="text-primary text-center my-inside">
              Parque Bondinho Pão de Açúcar
            </h1>
            <ProductsCarrousel codCategory={process.env.REACT_APP_CATEGORIES?.split(",")}></ProductsCarrousel>
          </div>
        </div>

        <div className="container-md my-outside my-outside-our-park mb-5">
          <h2
            className="title-our-park text-primary text-center"
          >
            {t("home.ourPark.title")}
          </h2>
          <p className="subtitle-our-park text-center mt-3 mb-5 mx-auto">{t("home.ourPark.subtitle")}</p>
          <OurPark />
        </div>

        <div className="container-md my-outside my-outside-our-park my-3 my-md-5">
          <ParkType />
        </div>

        <MailchimpSubscribe
          url={postUrl}
          render={({ subscribe, status, message }: any) => (
            <Newsletter
              status={status}
              message={message}
              onValidated={(formData: any) => subscribe(formData)}
            />
          )}
        />
      </PageDafault>
    </div>
  );
}

export default Home;