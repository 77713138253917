import React from 'react';
import { useTranslation } from "react-i18next";

import Newsletter from '../../components/Newsletter/Newsletter';

import bgPark from '../../assets/img/banner_principal.jpg';
//import bgMapPark from '../../assets/img/bondinho-pao-de-acucar-background_mapas-6f0cf62.jpg';

import './ThePark.scss';
//import MapPark from '../../components/Sliders/MapPark/MapPark';
import GalleryPark from '../../components/Sliders/GalleryPark/GalleryPark';

import MailchimpSubscribe from "react-mailchimp-subscribe";
import PageDafault from '../../templates/PageDafault';
import { Helmet } from 'react-helmet';
import i18next from 'i18next';

function ThePark() {
    const [t] = useTranslation();
    const postUrl = `https://c2rio.us14.list-manage.com/subscribe/post?u=f4575b66d53bf434464e4b9ae&id=d64793f209`;
    //const [check, setCheck] = useState<boolean>(true);

    return (
        <>
            <Helmet>
                <html lang={i18next.language} />
                <title>Conheça o Parque Bondinho Pão de Açúcar</title>
                <meta name="description" content="O Parque Bondinho é formado pelos morros da Urca e do Pão de Açúcar que, ligados pelo teleférico, permitem experiências de tirar o fôlego. Saiba mais!" />
                <meta name="googlebot" content={process.env.REACT_APP_SERVER_ROBOTS} />

                {/* Essential META Tags */}
                <meta property="og:title" content="Conheça o Parque Bondinho Pão de Açúcar" />
                <meta property="og:type" content="TouristAttraction" />
                <meta property="og:image" content={`${process.env.REACT_APP_SERVER_URL}${process.env.REACT_APP_SERVER_LOGO}`} />
                <meta property="og:url" content={process.env.REACT_APP_SERVER_URL} />

                {/* Non-Essential, But Recommended */}
                <meta property="og:description" content="O Parque Bondinho é formado pelos morros da Urca e do Pão de Açúcar que, ligados pelo teleférico, permitem experiências de tirar o fôlego. Saiba mais!" />
                <meta property="og:site_name" content={process.env.REACT_APP_SERVER_NAME} />

                <meta name="robots" content={process.env.REACT_APP_SERVER_ROBOTS} />
                <link rel="canonical" href={process.env.REACT_APP_SERVER_URL} />
            </Helmet>

            <PageDafault>

                <div className="top-park mb-3" style={{ backgroundImage: `url(${bgPark})` }}>
                    <div className='shadow-park h-100'>
                        <h1>{t("thePark.title")}</h1>
                    </div>
                </div>

                <div className='bg-fundo'>
                    <div className='park-content'>
                        <div className='py-5 px-3'>
                            <p>
                                {t("thePark.section01.text01")}
                            </p>
                            <p>
                                {t("thePark.section01.text02")}
                            </p>
                            <p>
                                {t("thePark.section01.text03")}
                            </p>
                            <p>
                                {t("thePark.section01.text04")}
                            </p>
                        </div>
                        <div className='d-flex justify-content-center mt-0 mt-md-4 mb-0 mb-md-5' style={{ padding: "0 1rem" }}>
                            <iframe width="100%" height="315" src="https://www.youtube.com/embed/NhgwNJxUTvc?si=XSfRNFF2qadPfOGw" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen className='bg-iframe-park'></iframe>
                        </div>
                    </div>
                    <div className="gallery-park py-3 py-md-5">
                        <div className='container'>
                            <h5 className='title-park'>{t("thePark.gallery")}</h5>
                            <GalleryPark />
                        </div>
                    </div>

                    <MailchimpSubscribe
                        url={postUrl}
                        render={({ subscribe, status, message }: any) => (
                            <Newsletter
                                status={status}
                                message={message}
                                onValidated={(formData: any) => subscribe(formData)}
                            />
                        )}
                    />
                </div>
            </PageDafault>
        </>
    )
}

export default ThePark;