import React from "react";

import SlowBuy from "../../components/SlowBuy/SlowBuy";

import PageDafault from "../../templates/PageDafault";

function SlowBuyPage() {
    return (
        <>
            <PageDafault>
                <SlowBuy />
            </PageDafault>
        </>
    )
}

export default SlowBuyPage;