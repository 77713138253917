import React, { useEffect, useRef, useState } from "react";
import { Row, Col } from "react-bootstrap";
import "./OurPark.scss";

function OurPark() {
    const [iframeLoaded, setIframeLoaded] = useState(false);
    const iframeRef = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        setIframeLoaded(true);
                        observer.disconnect();
                    }
                });
            },
            { threshold: 0.25 } // Load iframe when 25% of it is in the viewport
        );

        if (iframeRef.current) {
            observer.observe(iframeRef.current);
        }

        return () => {
            if (iframeRef.current) {
                // eslint-disable-next-line react-hooks/exhaustive-deps
                observer.unobserve(iframeRef.current);
            }
        };
    }, []);

    return (
        <>
            <Row className="our-park d-flex justify-content-center">
                <Col md={9} className="flex-column">
                    <div className="embed-responsive embed-responsive-16by9" ref={iframeRef}>
                        {iframeLoaded ? (
                            <iframe
                                src="https://rio.panomax.com/sugarloaf"
                                className="real-time-video"
                                allowFullScreen
                                title="Video Real Time"
                            ></iframe>
                        ) : (
                            <div className="animated-background h-100 d-flex align-items-center justify-content-center">
                                {/* <div className="spinner-border" role="status">
                                    <span className="sr-only">Loading...</span>
                                </div> */}
                            </div>
                        )}
                    </div>
                </Col>
            </Row>
        </>
    );
}

export default OurPark;