import React, { useState } from 'react';
import PageDafault from '../../templates/PageDafault';
import { Helmet } from 'react-helmet';
import i18next from 'i18next';
import { Button, Col, Row } from "react-bootstrap";

import './Transparency.scss';
import Slider from 'react-slick';

import pdfTransparency01 from '../../assets/img/pdfTransparency/pdfTransparency01.jpg'
import pdfTransparency02 from '../../assets/img/pdfTransparency/pdfTransparency02.jpg'


import { useTranslation } from 'react-i18next';

function Transparency() {
   

    const settings: any = {
        draggable: true,
        infinite: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: false,
        speed: 500,
        initialSlide: 0,
        cssEase: "ease-out",
        lazyLoad: 'ondemand', // Lazy load images
    };

    const [selectedReport, setSelectedReport] = useState('primeiro');  // Selecionar entre 1º ou 2º semestre

    const handleReportChange = (e: any) => {
        setSelectedReport(e.target.value);
    };

    const imageSelected = () => {
        return selectedReport === 'primeiro' ? pdfTransparency01 : pdfTransparency02;
    };

    const imageSrc = imageSelected();

    return (
        <>
            <Helmet>
                <html lang={i18next.language} />
                <title>Portal de Transparência | Parque Bondinho </title>
                <meta name="description" content="Para prevenir, detectar e tratar desvios ou algum tipo de inconformidade, temos o departamento de Transparência no Parque do Bondinho. Acesse e confira!" />
                <meta name="googlebot" content="noindex, nofollow" />

                {/* Essential META Tags */}
                <meta property="og:title" content="Portal de Transparência | Parque Bondinho" />
                <meta property="og:type" content="TouristAttraction" />
                <meta property="og:image" content={`${process.env.REACT_APP_SERVER_URL}${process.env.REACT_APP_SERVER_LOGO}`} />
                <meta property="og:url" content={process.env.REACT_APP_SERVER_URL} />

                {/* Non-Essential, But Recommended */}
                <meta property="og:description" content="Para prevenir, detectar e tratar desvios ou algum tipo de inconformidade, temos o departamento de Transparência no Parque do Bondinho. Acesse e confira!" />
                <meta property="og:site_name" content={process.env.REACT_APP_SERVER_NAME} />

                <meta name="robots" content="noindex, nofollow" />
                <link rel="canonical" href={process.env.REACT_APP_SERVER_URL} />
            </Helmet>
            <PageDafault>
            <div className="container py-5 compliance">
                <h1 className="mb-5 text-center display-2">Portal de Transparência e Igualdade Salarial de Mulheres e Homens</h1>
                <div className="card py-4 px-3">
                <Row className="py-4 text-center justify-content-center">
                        <Col md={3} lg={3} className="mb-3 mb-md-0">
                                <p  className={`btn-bondinho btn btn-primary btn-outline-primary`} > Parque Bondinho </p>
                        </Col>
                    </Row>
                    <Row className="py-4 text-center justify-content-center">
                        <Col md={3} lg={3} className="mb-3 mb-md-0">
                            <select 
                                className="btn-custom text-center" 
                                value={selectedReport} 
                                onChange={handleReportChange}
                            >
                                <option value="primeiro" className="btn-custom">Primeiro semestre</option>
                                <option value="segundo" className="btn-custom">Segundo semestre</option>
                            </select>
                        </Col>
                    </Row>

                    {imageSrc && (
                        <div className="w-100 m-auto my-2 bg-compliance">
                            <img src={imageSrc} alt="Relatório de Transparência" width='90%' />
                        </div>
                    )}
                </div>
            </div>
        </PageDafault>
        </>
    )
}

export default Transparency;    