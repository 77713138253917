import React from "react";

import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";

function PageDafault(props: any) {
    return (
        <>
            { !props.removeHeader && <Header />}
            {props.children}
            <Footer />
        </>
    )
}

export default PageDafault;