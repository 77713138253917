import React from 'react'
import { Container } from "react-bootstrap";

import { useTranslation } from 'react-i18next';

import LogoBondinho from "../../assets/img/logo-bondinho.svg"

import './NotFound.scss'

function NotFound() {
    const [t] = useTranslation();
    const home = window.location.origin;

    return (
        <Container className="body-not-found" fluid>
            <Container className="d-flex justify-content-center align-items-center not-found">
                <div className="text-center">
                    <img src={LogoBondinho} alt="Logo Bondinho"></img>
                    <h3 className="my-3">{t('notFound.title')}</h3>
                    <a href="/" className="link-home">{home}</a>
                </div>
            </Container>
        </Container>
    )
}

export default NotFound;