import i18next from "i18next";
import { useTranslation } from "react-i18next";

export const useTranslateHoursRange = (range: string) => {
    const { t } = useTranslation();
    const lng = i18next.language === "pt" ? "BR" : i18next.language === "en" ? "EN" : i18next.language === "es" ? "ES" : "BR";
    
    const isRange: boolean = range?.split(" ")[1] ? true : false;

    function translate() {
        if(lng === "EN" || lng === "ES") {
            return " - ";
        } else {
            return t('hours.to');
        }
    }

    if(isRange) {
        return `${range?.split(" ")[0]} ${translate()} ${range?.split(" ")[2]}`;
    }

    return range;
}