import React, { /* useState, useEffect, */ Component } from "react";
import Slider from "react-slick";
import i18next from "i18next";

import { Link as LinkRoll } from "react-scroll";

import { Container, Row } from "react-bootstrap";
/* Imagens do Slider 01 */
import BannerBondinhoPT from "../../../../assets/img/bannerHome/Banner_Trilha_desk_PT.png";
import BannerBondinhoEN from "../../../../assets/img/bannerHome/Banner_Trilha_desk_EN.png";
import BannerBondinhoES from "../../../../assets/img/bannerHome/Banner_Trilha_desk_ES.png";

import BannerBondinhoMobilePT from "../../../../assets/img/bannerHome/Banner_Home_mobile_PT.png";
import BannerBondinhoMobileEN from "../../../../assets/img/bannerHome/Banner_Home_mobile_EN.png";
import BannerBondinhoMobileES from "../../../../assets/img/bannerHome/Banner_Home_mobile_ES.png";
/* END - Imagens do Slider 01 */


import "./BannerHome.scss";

export default class BannerHome extends Component {
  render() {
    const settings: any = {
      draggable: false,
      useTransform: true,
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      speed: 1500,
      autoplaySpeed: 5500,      
      cssEase: "ease-out",
      lazyLoad: 'ondemand', // Lazy load images
    };

    const typeDevice = window.innerWidth < 480 ? 'mobile' : 'desktop';
    //const lng = i18next.language === "pt" ? "BR" : i18next.language === "en" ? "EN" : i18next.language === "es" ? "ES" : 'BR';

    return (
      <div className="bg-main-banner">
        <Slider {...settings}>
          <Container className="bg-secondary banner-home p-0 m-0" fluid>
            <Container className="p-0 m-0" fluid>
              <LinkRoll
                activeClass="active"
                to="main-product"
                spy={true}
                smooth={true}
                offset={-250}
                duration={500}
              >
                <Row className="p-0 m-0">
                  {typeDevice === 'desktop' ?
                    <img
                      className="p-0 m-0"
                      src={
                        i18next.language === "pt"
                          ?
                          BannerBondinhoPT
                          :
                          i18next.language === "en"
                            ?
                            BannerBondinhoEN
                            :
                            i18next.language === "es"
                              ?
                              BannerBondinhoES
                              :
                              BannerBondinhoPT
                      }
                      alt="default banner"
                    />
                    :
                    <img
                      className="p-0 m-0"
                      src={
                        i18next.language === "pt"
                          ?
                          BannerBondinhoMobilePT
                          :
                          i18next.language === "en"
                            ?
                            BannerBondinhoMobileEN
                            :
                            i18next.language === "es"
                              ?
                              BannerBondinhoMobileES
                              :
                              BannerBondinhoMobilePT
                      }
                      alt="default banner"
                    />

                  }
                </Row>
              </LinkRoll>
            </Container>
          </Container> 

        
        </Slider>
      </div >
    );
  }
}
