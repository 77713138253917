import React, { Key } from 'react';

import { useTranslation } from "react-i18next";
import CheckoutSummaryTicket from './CheckoutSummaryTicket';

//Components
import CheckoutSummaryTour from './CheckoutSummaryTour';
import CheckoutSummaryTransfer from './CheckoutSummaryTransfer';

import { useAppSelector } from "../../../store/hooks";
import verifyPriceOrder from '../../../infra/verifyPriceOrder';

function CheckoutSummary() {
  const { t } = useTranslation();
  const cartItem: any = JSON.parse(JSON.stringify(useAppSelector((state: any) => state.cart)));

  if (cartItem.dados && cartItem.dados.length >= 1) {
    let totalCart = 0;
    const labelArrayDiscount = verifyPriceOrder(cartItem)[0];

    return (
      <div className="col-12 col-md-4 col-lg-4 col-xl-4 aside order-2 order-md-1" id="asideCheck02">
        <div className=" my-4" style={{ position: "sticky", top: "0" }}>
          <h1 className="ml-2 h5">{t("checkoutSteps.summary")}</h1>

          <div className="bg-white p-3 mb-2 rounded" data-checkout="summary">
            {cartItem.dados.length >= 1 ? cartItem.dados.map((cItem: any, index: Key) => {
              let priceProduct = (
                (Number(cItem.adults) * Number(cItem.priceAdults))
                + (Number(cItem.childs) * Number(cItem.priceChilds))
                + (Number(cItem.infants) * Number(cItem.priceInfants))
                + (Number(cItem.elders) * Number(cItem.priceElders))
                + (Number(cItem.student) * Number(cItem.priceStudent))
                + (Number(cItem.priceGlobalPeople))
              );

              totalCart = Number(totalCart) + (priceProduct - (priceProduct * (cItem.discount / 100)));

              const discountLabel = (code: string) => {
                let auxValue: any = [];

                if (labelArrayDiscount?.length > 0) {
                  labelArrayDiscount.forEach((elem: any) => {
                    if (elem.productCode === code) {
                      auxValue.push(elem.value);
                    }
                  });
                  return auxValue.reduce((acum: any, value: any) => acum + value, 0);
                }

              }

              if (cItem.productType === 1) {
                return (
                  <CheckoutSummaryTour tour={cItem} key={index} discountLabel={discountLabel} labelArrayDiscount={labelArrayDiscount}></CheckoutSummaryTour>
                )
              } else if (cItem.productType === 4) {
                return (
                  <CheckoutSummaryTicket ticket={cItem} key={index} discountLabel={discountLabel} labelArrayDiscount={labelArrayDiscount}></CheckoutSummaryTicket>
                )
              } else if (cItem.productType === 2) {
                return (
                  <CheckoutSummaryTransfer transfers={[cItem]} key={index}></CheckoutSummaryTransfer>
                )
              } else {
                return (
                  <div key={index}></div>
                )
              }
            }) : ''}
          </div>


          <div className="bg-black-10 p-3 mb-2">
            {cartItem.cupom?.type === 2 ?
              <small style={{ display: "flex", justifyContent: "space-between" }}><span>{cartItem.cupom.codeName}</span>       <span>R$ {cartItem.cupom?.value.toFixed(2).split(".").join(",")}</span></small>
              : <></>}
            {cartItem.cupom?.type === 1 ?
              <small style={{ display: "flex", justifyContent: "space-between" }}><span>{cartItem.cupom.codeName}</span>       <span>{cartItem.cupom?.value + '%'}</span></small>
              : <></>}
            <div className="row mx-0">

              <div className="col-6 text-left p-0 m-0">
                TOTAL
              </div>
              {/* <div className="col-6 text-right font-weight-bold h5 p-0 m-0" data-price="total">R$ {(totalCart - window.cart?.cupom?.value).toFixed(2).split(".").join(",")}</div> */}
              <div className="col-6 text-right font-weight-bold text-primary h5 p-0 m-0" data-price="total">R${(cartItem.totalCart).toFixed(2).split(".").join(",")}</div>
            </div>
          </div>

        </div>
      </div>
    )
  } else {
    return (<></>)
  }
}

export default CheckoutSummary;