import React from 'react';

import './TermsOfPurchase.scss';
import { Link } from 'react-router-dom';

function TermsOfPurchasePT() {
  return (
    <div className='bg-terms-of-purchase'>
      <h5>Introdução:</h5>

      <ul>
        <li>
          O(s) termo(s) “Evento(s)” referem-se as atividades de lazer promovidas pelo Parque Bondinho Pão de Açúcar, sejam estas: acesso ao Parque e Bondinho, shows de música, eventos de esporte, festivais, exposições, ou qualquer outro conteúdo cultural e de entretenimento, cuja entrada requeira um passe de acesso.
        </li>
        <li>
          O(s) termo(s) “Ingresso(s)” referem-se ao direito de acesso ao(s) evento(s) ou serviço(s), ao Parque ou ao Bondinho. Este acesso por se dar por meio de um papel, de forma digital, ou outros métodos que contenham uma forma de validação com código de barras, biometria ou QR Codes emitidos de forma exclusiva pelo Parque Bondinho Pão de Açúcar e, comercializados ou distribuídos por meio de nossa plataforma digital ou bilheterias físicas.
        </li>
        <li>
          O(s) termo(s) “Usuário(s)”, referem-se a qualquer pessoa (i) que efetue cadastro na plataforma do Parque Bondinho Pão de Açúcar, após conhecer e concordar com os Termos de Uso, para a utilização de qualquer uma das ferramentas disponíveis; (ii) aqueles adquirentes de Ingresso/Produto/Pacote/Experiência, produtos ou serviços por meio de qualquer outro canal de vendas disponível, inclusive a Bilheteria Oficial (vendas físicas); ou (iii) visitantes em geral.
        </li>
        <li>
          O(s) termo(s) “Pacote(s)”, referem-se aos pacotes oferecidos para venda de um Ingresso e um ou mais produtos ou serviços adicionais, como Visita Guiada, entre outros.
        </li>
        <li>
          Os termos “Produto(s)”, “Serviço(s)” e “Experiência(s)”, referem-se aos produtos/serviços/experiências oferecidos para venda nas plataformas físicas ou on-line do Parque Bondinho Pão de Açúcar.
        </li>
        <li>
          O(s) termo(s) “Voucher(s)”, referem-se aos comprovantes de compras de produtos, serviços ou experiências.
        </li>
        <li>
          Esta “Política de Compra” estabelece os termos e condições aplicáveis às compras de Ingressos.
        </li>
      </ul>

      <h6>1.	Contrato de vinculação legal:</h6>

      <p>1.	Nesta Política de Compra, nos referimos a quaisquer produtos e serviços oferecidos para venda realizada por nós.</p>

      <p>1.1.	Para fazer uma compra de Ingresso/Produto/Pacote/Experiência, seja através de Bilheteria ou de nossa plataforma on-line, o Usuário precisa ter pelo menos 18 (dezoito) anos e ser capaz de celebrar contratos juridicamente vinculativos. Se o Usuário estiver realizando uma compra on-line, também precisa ter uma conta no site www.bondinho.com.br, de modo que estará igualmente submetido aos Termos de Uso deste.</p>
      <p>1.2.	Qualquer compra em nossa plataforma (física ou on-line) constitui um contrato juridicamente vinculativo que está sujeito a: (i) esta Política de Compra; (ii) quaisquer termos e condições especiais declarados aplicáveis a um Item e/ou Evento específico; (iii) outros termos e condições do(s) Parceiro(s) de eventuais Evento(s) que ocorram no Parque Bondinho Pão de Açúcar. O Usuário deve ler esta Política de Compra cuidadosamente antes de realizar uma compra.</p>
      <p>1.3.	Ao comprar um ou mais Itens em nossa plataforma (física ou on-line), o Usuário reconhece e concorda em ficar vinculado aos termos e condições desta Política de Compra. No caso de discordância com esta Política de Compra ou quaisquer outros termos e condições aplicáveis, ou se não puder cumprir com algum deles, o Usuário não deverá realizar uma compra.</p>
      <p>1.4.	Reservamo-nos o direito de, periodicamente, fazer alterações nesta Política de Compra. Sempre que fizermos tais alterações, publicaremos a versão atualizada desta Política de Compra em nosso site. Portanto, recomendamos que o Usuário verifique esta Política de Compra regularmente para se manter informado sobre seus termos e condições atuais. Todas as compras estão sujeitas à versão aplicável desta Política de Compra que foi publicada no momento da compra. Se o Usuário não concordar com qualquer versão revisada desta Política de Compra, ou se não puder cumpri-la, não deverá realizar uma compra.</p>
      <p>1.5.	O site de venda de Ingresso/Produto/Pacote/Experiência pertence ao Parque Bondinho Pão de Açucar Tecnologia Eletrônica LTDA. que concedeu licença de uso à Companhia Caminho Aéreo Pão De Açúcar.</p>
      <p>1.6.	A plataforma de vendas dos Ingresso/Produto/Pacote/Experiência é de propriedade do Parque Bondinho Pão de Açúcar Tecnologia Eletrônica Ltda., prestadora de serviços da Companhia Caminho Aéreo Pão De Açúcar.</p>
      <p>1.7.	O Parque Bondinho Pão de Açucar presta serviços de venda e divulgação dos eventos, não fazendo parte da produção ou organização dos passeios divulgados no site.</p>
      <p>1.8.	As regras de compra e cancelamento são estabelecidas pela Companhia Caminho Aéreo Pão de Açúcar.</p>

      <h6>2.	Preços, Pagamentos e Pedidos:</h6>

      <p>2.1.	Todos os preços dos Ingressos, Pacotes, Produtos e Experiências comercializados por meio dos nossos canais oficiais incluem tributos incidentes na operação, conforme legislação vigente.</p>
      <p>2.2.	Os métodos de pagamentos aceitos incluem cartões de débito e dinheiro (apenas para compras presenciais), cartões de crédito (Visa / Elo / Mastercard / Diners / Amex / Hipercard / Discover / JCB) e transferências via PIX.</p>
      <p>2.3.	Todas as compras estão sujeitas à verificação do cartão de crédito ou débito (se aplicável), ou outras verificações de segurança, de modo que poderão ser solicitados documentos de comprovação de cadastro para finalização de seu pedido. O pedido pode ser cancelado se não for aprovado em nosso processo de verificação ou se o pagamento não for recebido integralmente. Em raras circunstâncias, se o pagamento for cancelado pelo banco ou provedor de pagamento associado, nos reservamos o direito de cancelar e reembolsar qualquer pedido para o qual uma confirmação tenha sido enviada.</p>
      <p>2.4.	Reservamo-nos o direito de cancelar quaisquer pedidos que razoavelmente suspeitemos terem sido feitos de forma fraudulenta, sem qualquer aviso ao Usuário, e todos e quaisquer Itens obtidos como parte de tais pedidos serão anulados. Certifique-se de ler os detalhes completos da descrição do item e conferir a sua seleção antes da compra.</p>
      <p>2.5. A compra estará sujeita à disponibilidade de Ingresso/Produto/Pacote/Experiência e à aprovação da operadora de seu cartão de crédito ou compensação do boleto bancário, que poderá levar até 5 (cinco) dias. O cliente somente receberá a confirmação do agendamento do passeio após a confirmação do pagamento.</p>

      <h6>3.	Entrega:</h6>

      <p>3.1.	Se a compra for efetivada através de nosso site (www.bondinho.com.br) e a opção de entrega online do Ingresso/Produto/Pacote/Experiência estiver disponível, o Usuário é responsável por (i) se assegurar de fazer o login em sua conta e baixar seus Ingressos/Vouchers em tempo hábil antes do embarque/evento; ou (ii) fornecer um endereço de e-mail válido para entrega on-line de Ingressos/Vouchers e se assegurar de poder receber a entrega destes por e-mail (por exemplo, se assegurando que sua caixa de e-mail não rejeite, devolva ou impeça qualquer e-mail de ser entregue), de modo que também deve verificar sua caixa de e-mail de forma regular – incluindo lixo eletrônico e SPAM.</p>
      <p>3.2.	O Usuário não terá direito a qualquer reembolso quando o Ingresso/Voucher for perdido, não resgatado, não recebido em sua caixa de e-mail ou por sua falha em realizar o download de Ingressos/Vouchers através de sua conta em nosso site (www.bondinho.com.br), por resultado de sua falha em fornecer informações de entregas corretas e completas e/ou falha em garantir a sua posse com relação ao Ingresso/Voucher.</p>
      <p>3.3.	O Usuário deve entrar em contato conosco através de nosso SAC (<Link to={{ pathname: "https://sac.bondinho.com.br/hc/pt-br" }} target='_blank'>https://sac.bondinho.com.br/hc/pt-br</Link>) imediatamente se houver qualquer engano ou erro com seu Ingresso/Voucher, ou se não receber seus Ingresso/Voucher conforme solicitado e/ou conforme descrito na confirmação do pedido.</p>

      <h6>4.	Direitos e Obrigações do Usuário:</h6>

      <p>4.1.	Qualquer compra de Ingresso/Produto/Pacote/Experiência por meio de nossos canais oficiais (Site www.bondinho.com.br ou Bilheteria física) concede uma licença pessoal de acesso ao evento/local, esse passível de cancelamento nos termos deste instrumento.</p>
      <p>4.2.	Ao receber ou retirar seus Ingressos/Vouchers, guarde-os em local seguro. Não seremos responsáveis por quaisquer Ingresso/Voucher perdidos, roubados, extraviados, danificados ou destruídos após o recebimento pelo Usuário. A exposição à luz solar ou calor podem danificar os ingressos/vouchers físicos. Além disso, recomendamos que o Usuário não publique fotos ou detalhes de seus Ingressos/vouchers online, pois isso pode permitir que terceiros falsifiquem seus ingressos/vouchers ou comprometam a integridade destes e não seremos responsáveis se sua entrada for recusada como resultado.</p>
      <p>4.3.	Confira atentamente os dados de seu pedido (agendamento, dia, período, quantidades) antes da confirmação de sua compra. Não será permitida a troca do seu ingresso/voucher para outra data agendada, salvo nas hipóteses previstas na Política de Cancelamento (Cláusula 9). O seu Ingresso/Produto/Pacote/Experiência é um produto único, ou seja, após sua compra ele não estará mais disponível para venda.</p>
      <p>4.4.	Para compras com cartão de crédito de terceiros, é indispensável a apresentação de uma cópia do documento oficial de identificação com foto do titular do cartão de crédito utilizado na compra e uma cópia somente da frente do cartão. A não apresentação dos documentos fica sob pena de não ser autorizada a entrada.</p>
      <p>4.5.	Nas hipóteses de compra de Ingresso/Produto/Pacote/Experiência via internet, você pode apresentar seu Ingresso/Voucher em formato de papel (imprimindo o mesmo) ou em formato digital (pelo telefone celular ou tablet, por exemplo). Estes documentos conterão um 'QR Code' que será identificado nas catracas.</p>
      <p>4.6.	Ingressos e/ou Vouchers obtidos de fontes não autorizadas podem ser inválidos, perdidos, roubados ou falsificados; o Parque Bondinho Pão de Açucar, em verificando tal prática poderá revogá-los, anulá-los ou cancelá-los, sem direito a qualquer reembolso.</p>
      <p>4.7.	O Parque Bondinho Pão de Açúcar atenderá e/ou prestará suporte apenas ao Usuário, ou seja, a pessoa física ou jurídica devidamente cadastrada, e exclusivamente em aquisições que comprovadamente tenham ocorrido através da conta daquele Usuário junto ao site de venda de Ingresso/Produto/Pacote/Experiência, ou, pelo próprio comprador do ingresso/voucher físico, no caso da compra realizada na bilheteria.</p>
      <p>4.8.	A violação de qualquer um dos Termos e Condições do Parque ou Regras de Acesso, além de qualquer comportamento inaceitável que possa causar danos, incômodos ou ferimentos, dará ao Parque Bondinho Pão de Açucar o direito de expulsar o Usuário do local.</p>

      <h6>5.	Restrições de Ingressos/Vouchers:</h6>

      <p>5.1.	Os Ingressos/Vouchers podem ser vendidos sujeitos a certas restrições de entrada ou uso, como idade mínima para entrada. Quaisquer restrições serão exibidas ou notificadas ao Usuário antes ou no momento da sua reserva. É uma responsabilidade do Usuário verificar tais informações importantes exibidas durante o processo de compra. O Parque Bondinho Pão de Açúcar não será responsável se o Usuário ou qualquer convidado em sua reserva for recusado a admissão devido a uma falha em atender ou comprovar que este(s) atende(m) a quaisquer restrições (por exemplo, um requisito de idade mínima).</p>
      <p>5.2.	Deverão ser observadas as Regras de Descontos dispostas no site www.bondinho.com.br no ato da compra pelo Usuário.</p>
      <p>5.3.	Meia-Entrada e Gratuidade: as pessoas que adquirirem ingressos do tipo meia entrada deverão se dirigir à Bilheteria para verificação dos documentos originais/impressos, que validem o direito ao benefício e receberão pulseiras de identificação.</p>

      <h6>6.	Horários e Entradas de Evento(s)/ ou Acesso ao Parque:</h6>

      <p>6.1.	Observe que os horários de início do(s) Evento(s) ou serviços anunciados – tais como: Visita Guiada, Café da Manhã, entre outros – estão sujeitos a alterações.</p>
      <p>6.2.	Ao participar de um Evento, o Usuário concorda em ser fotografado, filmado e/ou gravado em relação ao Evento e/ou para proteção e segurança, incluindo filmagem pela polícia. Ademais, concorda que as fotografias, vídeos e gravações de áudio e/ou gravações audiovisuais resultantes podem ser usadas em toda e qualquer mídia para qualquer finalidade a qualquer momento em todo o mundo pelo Parque Bondinho Pão de Açucar e seus parceiros.</p>

      <h6>7.	Cancellation and Changes to Events/Services by Parque Bondinho Pão de Açúcar®:</h6>

      <p>7.1.	If an Event or Service is canceled, rescheduled, or substantially altered, we will make every reasonable effort to disclose this information. However, we cannot guarantee that the User will be informed of such changes, as they may occur at any time, including due to a public authority decision, unforeseen circumstances, and/or force majeure.</p>
      <p>7.2.	If an Event for which the User has purchased Tickets/Vouchers or Packages is fully canceled and not rescheduled, the order will be canceled, and the User will receive a refund for the amount paid for their ticket or package.</p>
      <p>7.3.	Rescheduling: If an Event or Service/Experience for which the User purchased a Ticket/Product/Package/Experience is rescheduled, the Tickets/Vouchers and Packages will be validated for the new date. However, if the User notifies us within the specified timeframe, according to the situation, that they are not interested or unable to attend the rescheduled date, the order may be canceled, and a full refund of the sales price for their Tickets/Vouchers or Packages may be issued.</p>
      <p>7.3.1.	If no specific timeframe is mentioned for the situation, the default timeframe will be 48 hours before the rescheduled Event or Service date.</p>
      <p>7.4.	Failure by the User to notify us within the applicable timeframe that they will not attend the rescheduled Event or Service/Experience will be considered a reconfirmation of their order for Tickets/Vouchers or Packages for the Event, and the User will not be eligible for a refund.</p>
      <p>7.5.	To request a refund under Clause 7.3, the User must follow the instructions provided in the notification of the Event or Service change or submit a written request via the Parque Bondinho Pão de Açúcar® Customer Service Center (“SAC”) at (<Link to={{ pathname: "https://sac.bondinho.com.br/hc/pt-br/requests/new" }} target='_blank'>https://sac.bondinho.com.br/hc/pt-br/requests/new</Link>) or via email at (<a href={ "mailto:sac@bondinho.com.br"}>sac@bondinho.com.br</a>).</p>
      <p>7.6.	Refunds will be processed using the same payment method as the initial purchase.</p>
      
      <h6>8.	Limitação de responsabilidade:</h6>

      <p>8.1.	Na extensão máxima permitida por lei, o Parque Bondinho Pão de Açúcar e seus Parceiros não serão responsáveis por quaisquer perdas, ferimentos ou danos a qualquer pessoa (incluindo o Usuário) ou propriedade, nos seguintes termos:</p>
      <p>(a)	em quaisquer circunstâncias em que não haja violação de contrato ou dever legal de cuidado devido por nós ou pelo Parceiro de Evento ou Serviço/Experiência;</p>
      <p>(b)	em circunstâncias em que tal perda ou dano não seja diretamente resultado de qualquer violação; ou</p>
      <p>(c) na medida em que qualquer aumento em qualquer perda ou dano resulte da negligência, imprudência, imperícia ou violação, do Usuário ou Visitante, dos termos e condições aplicáveis e/ou a quaisquer leis ou regulamentos aplicáveis.</p>
      
      <h6>9.	Consumer Cancellation and Rescheduling Policy:</h6>
      
      <p>9.1.	Under Article 49 of the Consumer Defense Code, cancellation requests may be made within seven (7) calendar days for purchases made outside the Parque Bondinho Pão de Açúcar® premises. In this case, a full refund of the amount paid for the Ticket/Product/Package/Experience will be issued in the same payment method employed for the purchase.</p>
      <p>9.1.1	For purchases made on-site, whether through the physical ticket office or kiosks within the Parque Bondinho Pão de Açúcar® premises, if the User requests cancellation of the Ticket/Product/Package/Experience, a full refund will be guaranteed, provided the cancellation request is made no less than 24 hours before the Event/Boarding time indicated on the Ticket/Product/Package/Experience, as outlined in Clause 9.2.</p>
      <p>9.2.	If the Ticket/Product/Package/Experience is canceled 23h59min before the start time of the Event/Boarding as indicated on the Ticket/Product/Package/Experience, 20% of the amount paid for the Ticket/Product/Package/Experience will be withheld.</p>
      <p>9.3.	Cancellation requests made after the tour has started will not be eligible for refunds.</p>
      <p>9.4.	If rescheduling is necessary, visitors may request it more than 24 hours in advance of the scheduled tour start time, as long as there is availability for the desired new date and time. If the rescheduling request is made less than 24 hours before the tour time, it will be permitted only once, subject to the Park's availability. After the new time is set, no further rescheduling will be allowed.</p>
      <p>9.4.1. Tickets/Vouchers or Packages purchased through the ticket office or the website www.parquebondinho.com may be rescheduled.</p>
      <p>9.4.2. Rescheduling requests made after the date and time of Boarding or the Event will not be accepted.</p>
      <p>9.4.3. Only the User who made the purchase can request a rescheduling.</p>
      <p>9.5.	All refunds outlined here apply to Tickets or Packages purchased directly at the physical ticket office or online through www.parquebondinho.com.br.</p>
      <p>9.6.	For online purchases, cancellation requests must be submitted by the customer through the website www.parquebondinho.com.br or via our customer service channels. On-site purchases must be canceled directly at the Ticket Office or through our customer service channels.</p>
      <p>9.6.1.	Only the User who made the purchase can request the cancellation of the order.</p>
      <p>9.7.	Upon receipt of the email confirming the cancellation of the purchase, the full amount paid will be refunded, subject to the provisions of Clauses 9.1 to 9.3, using the same payment method employed in the original transaction, adhering to the following timelines and conditions:</p>
      <p>9.7.1. If the purchase was made in cash, the User will be refunded immediately at the Ticket Office, if the requirements from Clauses 9.1 to 9.3 of this Cancellation Policy are met.</p>
      <p>9.7.2. For credit card purchases, Parque Bondinho Pão de Açúcar® will immediately notify the Credit Card Administrator of the refund, which will occur within five (5) business days.</p>
      <p>9.7.3. The credit to the cardholder will depend on the processing by the issuing bank and the statement closing date. The refund may appear in up to three billing cycles, which is beyond the control of Parque Bondinho Pão de Açúcar® and depends on the internal processes of each credit card administrator. We recommend contacting the credit card issuer directly.</p>
      <p>9.7.4. For purchases paid with a debit card at the Ticket Office, Parque Bondinho Pão de Açúcar® will immediately notify the Financial Institution of the refund, which will occur within five (5) business days. We recommend contacting the responsible Financial Institution directly.</p>
      <p>9.7.5. For payments made via PIX, the refund process will begin within 24 hours (1 day) from the confirmation of the cancellation and will be completed within a maximum of 72 (seventy-two) hours, provided the User submits the correct bank account information to process the refund. The amount will be returned to the same account used for the payment.</p>
      <p>9.7.6. Parque Bondinho Pão de Açúcar® is not responsible for delays in the process outlined in Clause 9.7.5 if the User fails to provide accurate bank account information for the refund.</p>
      <p>9.8.	For Package purchases, canceling/rescheduling one product will result in the cancellation/rescheduling of the other. For example, in the Guided Tour Package, which includes a Ticket and a Guided Tour, if one of the services is canceled/rescheduled, the other will also be canceled/rescheduled. Users wishing only to access the Park must purchase the corresponding Ticket separately.</p>
      <p>9.9.	If the User or others for whom Tickets, Packages, or Vouchers were purchased fail to appear at the designated time and place for the start of Boarding or the Event without prior notice, this will be considered a "No Show." In addition to Clause 9.3, no refund will be issued.</p>
      <p>9.10. In the event of abandonment or withdrawal from the Event after it has started, no refund or compensation will be provided for unused services, as the operators and guides will have already prepared all the contracted infrastructure.</p>
    </div>
  );
}

export default TermsOfPurchasePT;