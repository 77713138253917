import React from "react";

function ArraiaPopupPT() {
    return (
        <>
            <p>
                Nascidos e moradores de todo o Estado do Rio de Janeiro devem apresentar
                documento comprobatório no dia da visita.
            </p>
            <ul>
                <li>
                    <p>
                        <b>Adultos Moradores: </b>Comprovante de residência em seu nome
                        (água, gás, luz, celular, internet, cartão ou telefone) e documento
                        de identificação oficial com foto (digital ou impresso).
                    </p>
                </li>
                <li>
                    <p>
                        <b>Adultos Nascidos: </b>Documento de identificação oficial com
                        foto, (digital ou impresso).
                    </p>
                </li>
                <li>
                    <p>
                        <b>Crianças Moradoras (3 a 12 anos): </b>Certidão de nascimento
                        original (ou cópia autenticada), ou documento oficial de
                        identificação com foto, que comprove a filiação do titular da
                        residência, (digital ou impresso).
                    </p>
                </li>
                <li>
                    <p>
                        <b>Crianças Nascidas (3 a 12 anos): </b>Certidão de nascimento
                        original (ou cópia autenticada) ou documento oficial de
                        identificação com foto, (digital ou impresso).
                    </p>
                </li>
            </ul>
        </>
    );
}

export default ArraiaPopupPT;
