import pt from './default.json';
import en from './default.en.json';
import es from './default.es.json';

//Pages
import homePt from '../../pages/Home/locales/default.json';
import homeEn from '../../pages/Home/locales/default.en.json';
import homeEs from '../../pages/Home/locales/default.es.json';

import aboutusPt from '../../pages/AboutUs/locales/default.json';
import aboutusEn from '../../pages/AboutUs/locales/default.en.json';
import aboutusEs from '../../pages/AboutUs/locales/default.es.json';

import affiliatesPt from '../../pages/Affiliates/locales/default.json';
import affiliatesEn from '../../pages/Affiliates/locales/default.en.json';
import affiliatesEs from '../../pages/Affiliates/locales/default.es.json';

import guidesPt from '../../pages/Guides/locales/default.json';
import guidesEn from '../../pages/Guides/locales/default.en.json';
import guidesEs from '../../pages/Guides/locales/default.es.json';

import contactPt from '../../pages/Contact/locales/default.json';
import contactEn from '../../pages/Contact/locales/default.en.json';
import contactEs from '../../pages/Contact/locales/default.es.json';

import cookiesPt from '../../pages/Cookies/locales/default.json';
import cookiesEn from '../../pages/Cookies/locales/default.en.json';
import cookiesEs from '../../pages/Cookies/locales/default.es.json';

import privacyPt from '../../pages/PrivacyNotice/locales/default.json';
import privacyEn from '../../pages/PrivacyNotice/locales/default.en.json';
import privacyEs from '../../pages/PrivacyNotice/locales/default.es.json';

import termsOfPurchasePt from '../../pages/TermsOfPurchase/locales/default.json';
import termsOfPurchaseEn from '../../pages/TermsOfPurchase/locales/default.en.json';
import termsOfPurchaseEs from '../../pages/TermsOfPurchase/locales/default.es.json';

import termsOfUsePt from '../../pages/TermsOfUse/locales/default.json';
import termsOfUseEn from '../../pages/TermsOfUse/locales/default.en.json';
import termsOfUseEs from '../../pages/TermsOfUse/locales/default.es.json';

import tourPt from '../../pages/Tour/locales/default.json';
import tourEn from '../../pages/Tour/locales/default.en.json';
import tourEs from '../../pages/Tour/locales/default.es.json';

import myReservationsPt from '../../pages/MyReservations/locales/default.json';
import myReservationsEn from '../../pages/MyReservations/locales/default.en.json';
import myReservationsEs from '../../pages/MyReservations/locales/default.es.json';

import downloadCenterPt from '../../pages/DownloadCenter/locales/default.json';
import downloadCenterEn from '../../pages/DownloadCenter/locales/default.en.json';
import downloadCenterEs from '../../pages/DownloadCenter/locales/default.es.json';

import educateCableCarPt from '../../pages/EducateCableCar/locales/default.json';
import educateCableCarEn from '../../pages/EducateCableCar/locales/default.en.json';
import educateCableCarEs from '../../pages/EducateCableCar/locales/default.es.json';

import exclusiveBondePt from '../../pages/ExclusiveBonde/locales/default.json';
import exclusiveBondeEn from '../../pages/ExclusiveBonde/locales/default.en.json';
import exclusiveBondeEs from '../../pages/ExclusiveBonde/locales/default.es.json';

import ourHistoryPt from '../../pages/OurHistory/locales/default.json';
import ourHistoryEn from '../../pages/OurHistory/locales/default.en.json';
import ourHistoryEs from '../../pages/OurHistory/locales/default.es.json';

import sustainabilityPt from '../../pages/Sustainability/locales/default.json';
import sustainabilityEn from '../../pages/Sustainability/locales/default.en.json';
import sustainabilityEs from '../../pages/Sustainability/locales/default.es.json';

import profileNewPt from '../../pages/ProfileNew/locales/default.json';
import profileNewEn from '../../pages/ProfileNew/locales/default.en.json';
import profileNewEs from '../../pages/ProfileNew/locales/default.es.json';

import storePt from '../../pages/Stores/locales/default.json';
import storeEn from '../../pages/Stores/locales/default.en.json';
import storeEs from '../../pages/Stores/locales/default.es.json';

import theParkPt from '../../pages/ThePark/locales/default.json';
import theParkEn from '../../pages/ThePark/locales/default.en.json';
import theParkEs from '../../pages/ThePark/locales/default.es.json';

// import toursListPt from '../../pages/ToursList/locales/default.json';
// import toursListEn from '../../pages/ToursList/locales/default.en.json';
// import toursListEs from '../../pages/ToursList/locales/default.es.json';

// import transferResultPt from '../../pages/TransferResult/locales/default.json';
// import transferResultEn from '../../pages/TransferResult/locales/default.en.json';
// import transferResultEs from '../../pages/TransferResult/locales/default.es.json';

import notFoundPt from '../../pages/NotFound/locales/default.json';
import notFoundEn from '../../pages/NotFound/locales/default.en.json';
import notFoundEs from '../../pages/NotFound/locales/default.es.json';

import LookoutPt from '../../pages/LookoutCircuit/locales/default.json';
import LookoutEn from '../../pages/LookoutCircuit/locales/default.en.json';
import LookoutEs from '../../pages/LookoutCircuit/locales/default.es.json';

import ProfilePasswordPt from '../../pages/ProfilePassword/locales/default.json';
import ProfilePasswordEn from '../../pages/ProfilePassword/locales/default.en.json';
import ProfilePasswordEs from '../../pages/ProfilePassword/locales/default.es.json';

import ProfilePt from '../../pages/Profile/locales/default.json';
import ProfileEn from '../../pages/Profile/locales/default.en.json';
import ProfileEs from '../../pages/Profile/locales/default.es.json';

import faqPt from '../../pages/FAQ/locales/default.json';
import faqEn from '../../pages/FAQ/locales/default.en.json';
import faqEs from '../../pages/FAQ/locales/default.es.json';

import promotionPt from '../../pages/Promotion/locales/default.json';
import promotionEn from '../../pages/Promotion/locales/default.en.json';
import promotionEs from '../../pages/Promotion/locales/default.es.json';

import traineePt from '../../pages/Trainee/locales/default.json';
import traineeEn from '../../pages/Trainee/locales/default.en.json';
import traineeEs from '../../pages/Trainee/locales/default.es.json';

import compliancePt from '../../pages/Compliance/locales/default.json';
import complianceEn from '../../pages/Compliance/locales/default.en.json';
import complianceEs from '../../pages/Compliance/locales/default.es.json';

//Components
import footerPt from '../../components/Footer/locales/default.json';
import footerEn from '../../components/Footer/locales/default.en.json';
import footerEs from '../../components/Footer/locales/default.es.json';

import cookieAlertPt from '../../components/CookieAlert/locales/default.json';
import cookieAlertEn from '../../components/CookieAlert/locales/default.en.json';
import cookieAlertEs from '../../components/CookieAlert/locales/default.es.json';

import navHeaderPt from '../../components/Header/NavHeader/locales/default.json';
import navHeaderEn from '../../components/Header/NavHeader/locales/default.en.json';
import navHeaderEs from '../../components/Header/NavHeader/locales/default.es.json';

import cartHeaderPt from '../../components/Cart/locales/default.json';
import cartHeaderEn from '../../components/Cart/locales/default.en.json';
import cartHeaderEs from '../../components/Cart/locales/default.es.json';

import tabSearchPt from '../../components/Searchs/TabSearchs/locales/default.json';
import tabSearchEn from '../../components/Searchs/TabSearchs/locales/default.en.json';
import tabSearchEs from '../../components/Searchs/TabSearchs/locales/default.es.json';

import productOptionsPt from '../../components/ProductOptions/locales/default.json';
import productOptionsEn from '../../components/ProductOptions/locales/default.en.json';
import productOptionsEs from '../../components/ProductOptions/locales/default.es.json';

import partnersPt from '../../components/Partners/locales/default.json';
import partnersEn from '../../components/Partners/locales/default.en.json';
import partnersEs from '../../components/Partners/locales/default.es.json';

import newsletterPt from '../../components/Newsletter/locales/default.json';
import newsletterEn from '../../components/Newsletter/locales/default.en.json';
import newsletterEs from '../../components/Newsletter/locales/default.es.json';

import CheckoutStepsPt from '../../components/CheckoutSteps/locales/default.json';
import CheckoutStepsEn from '../../components/CheckoutSteps/locales/default.en.json';
import CheckoutStepsEs from '../../components/CheckoutSteps/locales/default.es.json';

import CheckoutCardsPt from '../../components/CheckoutSteps/CheckoutCards/locales/default.json';
import CheckoutCardsEn from '../../components/CheckoutSteps/CheckoutCards/locales/default.en.json';
import CheckoutCardsEs from '../../components/CheckoutSteps/CheckoutCards/locales/default.es.json';

import ProductsCarrouselPt from '../../components/Products/ProductsCarrousel/locales/default.json';
import ProductsCarrouselEn from '../../components/Products/ProductsCarrousel/locales/default.en.json';
import ProductsCarrouselEs from '../../components/Products/ProductsCarrousel/locales/default.es.json';

import ProductsReviewsPt from '../../components/Products/ProductsReviews/locales/default.json';
import ProductsReviewsEn from '../../components/Products/ProductsReviews/locales/default.en.json';
import ProductsReviewsEs from '../../components/Products/ProductsReviews/locales/default.es.json';

import PrivacyTermsPt from '../../components/PrivacyTermsNotice/locales/default.json';
import PrivacyTermsEn from '../../components/PrivacyTermsNotice/locales/default.en.json';
import PrivacyTermsEs from '../../components/PrivacyTermsNotice/locales/default.es.json';

import slowBuyPt from '../../components/SlowBuy/locales/default.json';
import slowBuyEn from '../../components/SlowBuy/locales/default.en.json';
import slowBuyEs from '../../components/SlowBuy/locales/default.es.json';

import modalPt from '../../components/Modal/locales/default.json';
import modalEn from '../../components/Modal/locales/default.en.json';
import modalEs from '../../components/Modal/locales/default.es.json';

import exchangePolicyPt from '../../components/StoresList/ExchangePolicy/locales/default.json';
import exchangePolicyEn from '../../components/StoresList/ExchangePolicy/locales/default.en.json';
import exchangePolicyEs from '../../components/StoresList/ExchangePolicy/locales/default.es.json';

import howtogetPT from '../../pages/HowToGet/locales/default.json';
import howtogetEN from '../../pages/HowToGet/locales/default.en.json';
import howtogetES from '../../pages/HowToGet/locales/default.es.json';

import blackfridayPT from '../../pages/BlackFriday/components/BFProduct/locales/default.json';
import blackfridayEN from '../../pages/BlackFriday/components/BFProduct/locales/default.en.json';
import blackfridayES from '../../pages/BlackFriday/components/BFProduct/locales/default.es.json';

import TopHeaderPt from '../../components/TopHeader/locales/default.json';
import TopHeaderEn from '../../components/TopHeader/locales/default.en.json';
import TopHeaderEs from '../../components/TopHeader/locales/default.es.json';

import RefundRulesPt from '../../components/RefundRules/locales/default.json';
import RefundRulesEn from '../../components/RefundRules/locales/default.en.json';
import RefundRulesEs from '../../components/RefundRules/locales/default.es.json';

import TransparencyPt from '../../pages/Transparency/locales/default.json';
import TransparencyEn from '../../pages/Transparency/locales/default.en.json';
import TransparencyEs from '../../pages/Transparency/locales/default.es.json';

import ModalApiErrorPt from '../../components/Modal/ModalApiError/locales/default.json';
import ModalApiErrorEn from '../../components/Modal/ModalApiError/locales/default.en.json';
import ModalApiErrorEs from '../../components/Modal/ModalApiError/locales/default.es.json';

import AlertPt from '../../components/Alert/locales/default.json';
import AlertEn from '../../components/Alert/locales/default.en.json';
import AlertEs from '../../components/Alert/locales/default.es.json';

import HoursPt from '../../components/DropdownHours/locales/default.json';
import HoursEn from '../../components/DropdownHours/locales/default.en.json';
import HoursEs from '../../components/DropdownHours/locales/default.es.json';

import ModalConfirmPt from '../../components/Modal/ModalConfirm/locales/default.json';
import ModalConfirmEn from '../../components/Modal/ModalConfirm/locales/default.en.json';
import ModalConfirmEs from '../../components/Modal/ModalConfirm/locales/default.es.json';

pt['pt-BR'].home = homePt['pt-BR'];
en['en'].home = homeEn['en'];
es['es'].home = homeEs['es'];

pt['pt-BR'].aboutus = aboutusPt['pt-BR'];
en['en'].aboutus = aboutusEn['en'];
es['es'].aboutus = aboutusEs['es'];

pt['pt-BR'].affiliates = affiliatesPt['pt-BR'];
en['en'].affiliates = affiliatesEn['en'];
es['es'].affiliates = affiliatesEs['es'];

pt['pt-BR'].guides = guidesPt['pt-BR'];
en['en'].guides = guidesEn['en'];
es['es'].guides = guidesEs['es'];

pt['pt-BR'].contact = contactPt['pt-BR'];
en['en'].contact = contactEn['en'];
es['es'].contact = contactEs['es'];

pt['pt-BR'].cookies = cookiesPt['pt-BR'];
en['en'].cookies = cookiesEn['en'];
es['es'].cookies = cookiesEs['es'];

pt['pt-BR'].privacy = privacyPt['pt-BR'];
en['en'].privacy = privacyEn['en'];
es['es'].privacy = privacyEs['es'];

pt['pt-BR'].termsOfPurchase = termsOfPurchasePt['pt-BR'];
en['en'].termsOfPurchase = termsOfPurchaseEn['en'];
es['es'].termsOfPurchase = termsOfPurchaseEs['es'];

pt['pt-BR'].termsOfUse = termsOfUsePt['pt-BR'];
en['en'].termsOfUse = termsOfUseEn['en'];
es['es'].termsOfUse = termsOfUseEs['es'];

pt['pt-BR'].tour = tourPt['pt-BR'];
en['en'].tour = tourEn['en'];
es['es'].tour = tourEs['es'];

pt['pt-BR'].myReservations = myReservationsPt['pt-BR'];
en['en'].myReservations = myReservationsEn['en'];
es['es'].myReservations = myReservationsEs['es'];

pt['pt-BR'].downloadCenter = downloadCenterPt['pt-BR'];
en['en'].downloadCenter = downloadCenterEn['en'];
es['es'].downloadCenter = downloadCenterEs['es'];

pt['pt-BR'].educateCableCar = educateCableCarPt['pt-BR'];
en['en'].educateCableCar = educateCableCarEn['en'];
es['es'].educateCableCar = educateCableCarEs['es'];

pt['pt-BR'].exclusiveBonde = exclusiveBondePt['pt-BR'];
en['en'].exclusiveBonde = exclusiveBondeEn['en'];
es['es'].exclusiveBonde = exclusiveBondeEs['es'];

pt['pt-BR'].ourHistory = ourHistoryPt['pt-BR'];
en['en'].ourHistory = ourHistoryEn['en'];
es['es'].ourHistory = ourHistoryEs['es'];

pt['pt-BR'].sustainability = sustainabilityPt['pt-BR'];
en['en'].sustainability = sustainabilityEn['en'];
es['es'].sustainability = sustainabilityEs['es'];

pt['pt-BR'].profileNew = profileNewPt['pt-BR'];
en['en'].profileNew = profileNewEn['en'];
es['es'].profileNew = profileNewEs['es'];

pt['pt-BR'].store = storePt['pt-BR'];
en['en'].store = storeEn['en'];
es['es'].store = storeEs['es'];

pt['pt-BR'].faq = faqPt['pt-BR'];
en['en'].faq = faqEn['en'];
es['es'].faq = faqEs['es'];

pt['pt-BR'].thePark = theParkPt['pt-BR'];
en['en'].thePark = theParkEn['en'];
es['es'].thePark = theParkEs['es'];

pt['pt-BR'].notFound = notFoundPt['pt-BR'];
en['en'].notFound = notFoundEn['en'];
es['es'].notFound = notFoundEs['es'];

pt['pt-BR'].lookout = LookoutPt['pt-BR'];
en['en'].lookout = LookoutEn['en'];
es['es'].lookout = LookoutEs['es'];

pt['pt-BR'].profile = ProfilePt['pt-BR'];
en['en'].profile = ProfileEn['en'];
es['es'].profile = ProfileEs['es'];

pt['pt-BR'].faq = faqPt['pt-BR'];
en['en'].faq = faqEn['en'];
es['es'].faq = faqEs['es'];

pt['pt-BR'].promotion = promotionPt['pt-BR'];
en['en'].promotion = promotionEn['en'];
es['es'].promotion = promotionEs['es'];

pt['pt-BR'].trainee = traineePt['pt-BR'];
en['en'].trainee = traineeEn['en'];
es['es'].trainee = traineeEs['es'];

pt['pt-BR'].compliance = compliancePt['pt-BR'];
en['en'].compliance = complianceEn['en'];
es['es'].compliance = complianceEs['es'];

pt['pt-BR'].profilePassword = ProfilePasswordPt['pt-BR'];
en['en'].profilePassword = ProfilePasswordEn['en'];
es['es'].profilePassword = ProfilePasswordEs['es'];

pt['pt-BR'].footer = footerPt['pt-BR'];
en['en'].footer = footerEn['en'];
es['es'].footer = footerEs['es'];

pt['pt-BR'].cookieAlert = cookieAlertPt['pt-BR'];
en['en'].cookieAlert = cookieAlertEn['en'];
es['es'].cookieAlert = cookieAlertEs['es'];

pt['pt-BR'].navHeader = navHeaderPt['pt-BR'];
en['en'].navHeader = navHeaderEn['en'];
es['es'].navHeader = navHeaderEs['es'];

pt['pt-BR'].cartHeader = cartHeaderPt['pt-BR'];
en['en'].cartHeader = cartHeaderEn['en'];
es['es'].cartHeader = cartHeaderEs['es'];

pt['pt-BR'].tabSearch = tabSearchPt['pt-BR'];
en['en'].tabSearch = tabSearchEn['en'];
es['es'].tabSearch = tabSearchEs['es'];

pt['pt-BR'].productOptions = productOptionsPt['pt-BR'];
en['en'].productOptions = productOptionsEn['en'];
es['es'].productOptions = productOptionsEs['es'];

pt['pt-BR'].partners = partnersPt['pt-BR'];
en['en'].partners = partnersEn['en'];
es['es'].partners = partnersEs['es'];

pt['pt-BR'].newsletter = newsletterPt['pt-BR'];
en['en'].newsletter = newsletterEn['en'];
es['es'].newsletter = newsletterEs['es'];

pt['pt-BR'].checkoutSteps = CheckoutStepsPt['pt-BR'];
en['en'].checkoutSteps = CheckoutStepsEn['en'];
es['es'].checkoutSteps = CheckoutStepsEs['es'];

pt['pt-BR'].checkoutCards = CheckoutCardsPt['pt-BR'];
en['en'].checkoutCards = CheckoutCardsEn['en'];
es['es'].checkoutCards = CheckoutCardsEs['es'];

pt['pt-BR'].productsCarrousel = ProductsCarrouselPt['pt-BR'];
en['en'].productsCarrousel = ProductsCarrouselEn['en'];
es['es'].productsCarrousel = ProductsCarrouselEs['es'];

pt['pt-BR'].productsReviews = ProductsReviewsPt['pt-BR'];
en['en'].productsReviews = ProductsReviewsEn['en'];
es['es'].productsReviews = ProductsReviewsEs['es'];

pt['pt-BR'].privacyTerms = PrivacyTermsPt['pt-BR'];
en['en'].privacyTerms = PrivacyTermsEn['en'];
es['es'].privacyTerms = PrivacyTermsEs['es'];

pt['pt-BR'].slowBuy = slowBuyPt['pt-BR'];
en['en'].slowBuy = slowBuyEn['en'];
es['es'].slowBuy = slowBuyEs['es'];

pt['pt-BR'].modal = modalPt['pt-BR'];
en['en'].modal = modalEn['en'];
es['es'].modal = modalEs['es'];

pt['pt-BR'].exchangePolicy = exchangePolicyPt['pt-BR'];
en['en'].exchangePolicy = exchangePolicyEn['en'];
es['es'].exchangePolicy = exchangePolicyEs['es'];

pt['pt-BR'].howtoget = howtogetPT['pt-BR'];
en['en'].howtoget = howtogetEN['en'];
es['es'].howtoget = howtogetES['es'];

pt['pt-BR'].blackfriday = blackfridayPT['pt-BR'];
en['en'].blackfriday = blackfridayEN['en'];
es['es'].blackfriday = blackfridayES['es'];

pt['pt-BR'].topHeader = TopHeaderPt['pt-BR'];
en['en'].topHeader = TopHeaderEn['en'];
es['es'].topHeader = TopHeaderEs['es'];

pt['pt-BR'].refundRules = RefundRulesPt['pt-BR'];
en['en'].refundRules = RefundRulesEn['en'];
es['es'].refundRules = RefundRulesEs['es'];

pt['pt-BR'].transparency = TransparencyPt['pt-BR'];
en['en'].transparency = TransparencyEn['en'];
es['es'].transparency = TransparencyEs['es'];

pt['pt-BR'].modalApiError = ModalApiErrorPt['pt-BR'];
en['en'].modalApiError = ModalApiErrorEn['en'];
es['es'].modalApiError = ModalApiErrorEs['es'];

pt['pt-BR'].alert = AlertPt['pt-BR'];
en['en'].alert = AlertEn['en'];
es['es'].alert = AlertEs['es'];

pt['pt-BR'].hours = HoursPt['pt-BR'];
en['en'].hours = HoursEn['en'];
es['es'].hours = HoursEs['es'];

pt['pt-BR'].modalConfirm = ModalConfirmPt['pt-BR'];
en['en'].modalConfirm = ModalConfirmEn['en'];
es['es'].modalConfirm = ModalConfirmEs['es'];

export {
  pt,
  en,
  es,
};