import React from "react";

import { Container } from "react-bootstrap";

import { useTranslation } from "react-i18next";

import PageDafault from "../../templates/PageDafault";
/* import Newsletter from "../../components/Newsletter/Newsletter"; */

import "./OurHistory.scss";
import { Helmet } from "react-helmet";
import i18next from "i18next";

function OurHistory() {
    const { t } = useTranslation();

    return (
        <>
            <Helmet>
                <html lang={i18next.language} />
                <title>Quem Somos | Parque Bondinho</title>
                <meta name="description" content="Mais do que um dos mais belos pontos turísticos do Brasil, a missão do Parque Bondinho é gerar experiências inesquecíveis. Acesse nosso site e confira!" />
                <meta name="googlebot" content={process.env.REACT_APP_SERVER_ROBOTS} />

                {/* Essential META Tags */}
                <meta property="og:title" content="Quem Somos | Parque Bondinho" />
                <meta property="og:type" content="TouristAttraction" />
                <meta property="og:image" content={`${process.env.REACT_APP_SERVER_URL}${process.env.REACT_APP_SERVER_LOGO}`} />
                <meta property="og:url" content={process.env.REACT_APP_SERVER_URL} />

                {/* Non-Essential, But Recommended */}
                <meta property="og:description" content="Mais do que um dos mais belos pontos turísticos do Brasil, a missão do Parque Bondinho é gerar experiências inesquecíveis. Acesse nosso site e confira!" />
                <meta property="og:site_name" content={process.env.REACT_APP_SERVER_NAME} />

                <meta name="robots" content={process.env.REACT_APP_SERVER_ROBOTS} />
                <link rel="canonical" href={process.env.REACT_APP_SERVER_URL} />
            </Helmet>

            <PageDafault>
                <Container className="page-static py-5">
                    <h1 className="mb-5 text-center display-2">{t("ourHistory.title")}</h1>
                    <div className="card py-4 px-3">
                        <div className="p-3">
                            <p>
                                {t("ourHistory.text01")}
                            </p>

                            <p>
                                {t("ourHistory.text02")}
                            </p>

                            <p>
                                {t("ourHistory.text03")}
                            </p>

                            <p>
                                {t("ourHistory.text04")}
                            </p>
                        </div>
                    </div>
                </Container>
            </PageDafault>
        </>
    )
}

export default OurHistory;