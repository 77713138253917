import { useState, useEffect } from 'react';

import { useTranslation } from 'react-i18next';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import api from '../../../../services/api';

import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./ProductsCarrousel.scss";

import i18next from 'i18next';
import ModalApiError from '../../../../components/Modal/ModalApiError/ModalApiError';
import ChanceUrlTicket from '../../../../infra/ChangeUrlTicket';

export interface propAction {
    codCategory: any,
};

const ProductsCarrousel: React.FC<propAction> = ({
    codCategory
}: propAction) => {

    const [t] = useTranslation();
    const lng = i18next.language === "pt" ? "BR" : i18next.language === "en" ? "EN" : i18next.language === "es" ? "ES" : 'BR';

    const [nav1, setNav1] = useState<any>();
    const [nav2, setNav2] = useState<any>();
    const [slider1, setSlider1] = useState<any>();
    const [slider2] = useState<any>();

    const [apiErrorMessage, setApiErrorMessage] = useState<string>('');
    const [resProduct, setResProduct] = useState<any>(null);
    const [layout, setLayout] = useState<any>(window.innerWidth);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [seeMore, setSeeMore] = useState<any>(
        layout < 575
            ?
            10
            :
            layout < 768
                ?
                2
                :
                layout < 1024
                    ?
                    3
                    :
                    4
    );

    if (false) { console.log(nav1); }

    //Tamanho da Tela
    window.addEventListener('resize', function () {
        var newWidth = window.innerWidth;
        setLayout(newWidth)
    });
    //Tamanho da Tela

/*     function HandleSeeMore() {
        if (layout < 575) {
            if (seeMore <= resProduct.length) {
                setSeeMore(seeMore + 4);
            } else {
                setSeeMore(1);
            }
        } else if (layout < 768) {
            if (seeMore <= resProduct.length) {
                setSeeMore(seeMore + 4);
            } else {
                setSeeMore(2);
            }
        } else if (layout < 1024) {
            if (seeMore <= resProduct.length) {
                setSeeMore(seeMore + 4);
            } else {
                setSeeMore(3);
            }
        } else {
            if (seeMore <= resProduct.length) {
                setSeeMore(seeMore + 4);
            } else {
                setSeeMore(4);
            }
        }
    }

    function scrollView() {
        var scrollElement = document.getElementById("buy-product");
        scrollElement?.scrollIntoView(true)
    } */

    useEffect(() => {
        setNav1(slider1);
        setNav2(slider2);

        async function getProducts() {
            try {
                const res: any = await api.post(`${process.env.REACT_APP_SERVER_URL_API}/api/Products/GetProductsFromCategories`,
                    {
                        "categoryCode": codCategory,
                        "lang": lng,
                        "channel": 7
                    }
                );

                if (res.status === 200) {
                    if(res.data.statusCode === 200){
                        setResProduct(res.data.data);
                    } else {
                        setResProduct([]);
                        setApiErrorMessage(t('modalApiError.errorAPI'));
                    }
                }else{
                    setResProduct([]);
                    setApiErrorMessage(t('modalApiError.errorAPI'));
                }
            } catch (error) {
                setResProduct([]);
                setApiErrorMessage(t('modalApiError.errorAPI'));
            }
        }

        getProducts();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [slider1, slider2, codCategory, lng]);

    const settingsMain: any = {
        dots: false,
        arrows: true,
        infinite: false,
        speed: 500,
        slidesToShow: 10,
        slidesToScroll: 1,
        lazyLoad: 'ondemand', // Lazy load images
        responsive: [
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    centerMode: true,
                    centerPadding: "60px",
                    infinite: false,
                    dots: false,
                }
            }
        ]
    };

    return (
        <>
            {resProduct?.length > 0
                ?
                <div id="main-product" className="atividadesDestacadas trilhas w-100">
                    <Slider
                        className='pb-3'
                        {...settingsMain}
                        asNavFor={nav2}
                        ref={slider => (setSlider1(slider))}
                    >
                        {resProduct.slice(0, seeMore).map((item: any, index: any) => {
                            return (
                                <div key={index} className="col-12 col-sm-6 col-md-4 col-lg-4 p-2 product-slider">
                                    <a href={
                                        item.productType === "TOUR"
                                            ?
                                            "/compra-rapida/" + item.productSlug + "?type=tour?trilha"
                                            :
                                            "/compra-rapida/" + item.productSlug + `?type=${ChanceUrlTicket(lng)}?trilha`
                                    } key={item.categoryCode} className="card card-slider rounded overflow-hidden ">
                                        <div className="activ-img" style={{ backgroundImage: `url(${item.imagesBaseUrl}medium_${item.productImg})` }} />
                                        <div className="card-body d-flex flex-column justify-content-between" style={{ minHeight: "330px" }}>
                                            <div className='mb-0 mb-md-2'>
                                                <p className="tourName mb-2">{item.productName}</p>
                                                <div className='px-2'>
                                                    {item.productIncludeItem.map(
                                                        (items: any, index: any) => {
                                                            return (
                                                                <p
                                                                    key={index}
                                                                    className="tourDescript"
                                                                    title={items.split("•	").join("")}
                                                                >
                                                                    <FontAwesomeIcon
                                                                        icon={["fad", "ticket"]}
                                                                        size="1x"
                                                                        style={{ marginRight: "5px", color: "#ff6600" }}
                                                                    />
                                                                    <span>{items.split("•	").join("")}</span>
                                                                </p>
                                                            );
                                                        }
                                                    )}
                                                </div>
                                            </div>
                                            <div className="product-content">
                                                <div>
                                                    <div className="" style={{ marginTop: '15px' }}>
                                                        {/* <small className="text-muted">A partir de:</small> */}
                                                        {/* <p className="card-text h4">
                                                        <small className="text-uppercase">r$</small>{item.price.toFixed(2).split('.').join(',')}
                                                    </p> */}
                                                    </div>
                                                    <div className="buy-btn centerCenter">
                                                        <object style={{ width: "100%" }}>
                                                            <a href={
                                                                item.productType === "TOUR"
                                                                    ?
                                                                    "/compra-rapida/" + item.productSlug + "?type=tour?trilha"
                                                                    :
                                                                    "/compra-rapida/" + item.productSlug + `?type=${ChanceUrlTicket(lng)}?trilha`
                                                            } className="btn btn-primary my-1 p-0 rounded btn-lg " type="button" >
                                                                {t('productsCarrousel.buttonBuy')}
                                                                {/* <ModalDefault name={t('productsCarrousel.buttonBuy')} class={"fast-buy-resize"}>
                                                        <FastBuy info={item} />
                                                    </ModalDefault> */}
                                                            </a>
                                                        </object>
                                                        <object>
                                                            <a href={
                                                                item.productType === "TOUR"
                                                                    ?
                                                                    "/tour/" + item.productSlug + "?trilha"
                                                                    :
                                                                    `/${ChanceUrlTicket(lng)}/` + item.productSlug + "?trilha"
                                                            } className='viewDetailsButton mt-3'>{t('productsCarrousel.seeDetails')}</a>
                                                        </object>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            );
                        })
                        }
                    </Slider>
                    {/* {
                    layout < 576
                        ?
                        ""
                        :
                        <div className='d-flex justify-content-center seeMoreSection' onClick={HandleSeeMore}>
                            {
                                seeMore < resProduct.length
                                    ?
                                    <button className="btn rounded btn-lg " type="button" >{t("productsCarrousel.seeMore")}</button>
                                    :
                                    <button className="btn rounded btn-lg " type="button" onClick={scrollView} >{t("productsCarrousel.collect")}</button>
                            }
                        </div>
                } */}
                </div>
                : resProduct === null
                    ?
                    <>
                        <div id="main-product" className="atividadesDestacadas trilhas w-100">
                            <Slider
                                {...settingsMain}
                                asNavFor={nav2}
                                ref={slider => (setSlider1(slider))}
                            >
                                <div className="col-3 product-slider">
                                    <div className="card rounded overflow-hidden">
                                        <div className="activ-img animated-background">

                                        </div>
                                        <div className="card-body">
                                            <p className="animated-background" style={{ maxHeight: '16px', maxWidth: '150px' }}>

                                            </p>
                                            <p className="animated-background" style={{ maxHeight: '16px', marginBottom: '5px' }}></p>
                                            <p className="animated-background" style={{ maxHeight: '16px', marginBottom: '5px' }}></p>
                                            <p className="animated-background" style={{ maxHeight: '16px', marginBottom: '5px' }}></p>

                                            <div className="" style={{ maxHeight: '16px', marginTop: '25px', marginBottom: '30px' }}>
                                                <p className="card-text h4 text-primary animated-background" style={{ maxHeight: '16px', marginBottom: '5px' }}></p>
                                                <p className="card-text h4 text-primary animated-background" style={{ maxHeight: '16px', marginBottom: '5px' }}></p>
                                            </div>

                                            <div className="centerCenter">
                                                <button className="btn btn-primary py-2 rounded btn-lg " type="button" >Comprar</button>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div className="col-3 product-slider">
                                    <div className="card rounded overflow-hidden ">
                                        <div className="activ-img animated-background">

                                        </div>
                                        <div className="card-body">
                                            <p className="animated-background" style={{ maxHeight: '16px', maxWidth: '150px' }}>

                                            </p>
                                            <p className="animated-background" style={{ maxHeight: '16px', marginBottom: '5px' }}></p>
                                            <p className="animated-background" style={{ maxHeight: '16px', marginBottom: '5px' }}></p>
                                            <p className="animated-background" style={{ maxHeight: '16px', marginBottom: '5px' }}></p>

                                            <div className="" style={{ maxHeight: '16px', marginTop: '25px', marginBottom: '30px' }}>
                                                <p className="card-text h4 text-primary animated-background" style={{ maxHeight: '16px', marginBottom: '5px' }}></p>
                                                <p className="card-text h4 text-primary animated-background" style={{ maxHeight: '16px', marginBottom: '5px' }}></p>
                                            </div>

                                            <div className="centerCenter">
                                                <button className="btn btn-primary py-2 rounded btn-lg " type="button" >Comprar</button>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div className="col-3 product-slider">
                                    <div className="card rounded overflow-hidden ">
                                        <div className="activ-img animated-background">

                                        </div>
                                        <div className="card-body">
                                            <p className="animated-background" style={{ maxHeight: '16px', maxWidth: '150px' }}>

                                            </p>
                                            <p className="animated-background" style={{ maxHeight: '16px', marginBottom: '5px' }}></p>
                                            <p className="animated-background" style={{ maxHeight: '16px', marginBottom: '5px' }}></p>
                                            <p className="animated-background" style={{ maxHeight: '16px', marginBottom: '5px' }}></p>

                                            <div className="" style={{ maxHeight: '16px', marginTop: '25px', marginBottom: '30px' }}>
                                                <p className="card-text h4 text-primary animated-background" style={{ maxHeight: '16px', marginBottom: '5px' }}></p>
                                                <p className="card-text h4 text-primary animated-background" style={{ maxHeight: '16px', marginBottom: '5px' }}></p>
                                            </div>

                                            <div className="centerCenter">
                                                <button className="btn btn-primary py-2 rounded btn-lg " type="button" >Comprar</button>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div className="col-3 product-slider">
                                    <div className="card rounded overflow-hidden ">
                                        <div className="activ-img animated-background">

                                        </div>
                                        <div className="card-body">
                                            <p className="animated-background" style={{ maxHeight: '16px', maxWidth: '150px' }}>

                                            </p>
                                            <p className="animated-background" style={{ maxHeight: '16px', marginBottom: '5px' }}></p>
                                            <p className="animated-background" style={{ maxHeight: '16px', marginBottom: '5px' }}></p>
                                            <p className="animated-background" style={{ maxHeight: '16px', marginBottom: '5px' }}></p>

                                            <div className="" style={{ maxHeight: '16px', marginTop: '25px', marginBottom: '30px' }}>
                                                <p className="card-text h4 text-primary animated-background" style={{ maxHeight: '16px', marginBottom: '5px' }}></p>
                                                <p className="card-text h4 text-primary animated-background" style={{ maxHeight: '16px', marginBottom: '5px' }}></p>
                                            </div>

                                            <div className="centerCenter">
                                                <button className="btn btn-primary py-2 rounded btn-lg " type="button" >Comprar</button>
                                            </div>
                                        </div>

                                    </div>
                                </div>


                            </Slider>
                        </div>
                    </>
                    :
                    <>
                        <p className='subtitle-our-park text-center mt-3 mb-5 mx-auto'>{t('modalApiError.notFoundProduct')}</p>
                    </>
            }
            <ModalApiError apiErrorMessage={apiErrorMessage} setApiErrorMessage={setApiErrorMessage} />
        </>
    );
}

export default ProductsCarrousel;