import React from "react";

function ArraiaPopupEN() {
    return (
        <>
            <p>
                Born and residents of the entire state of Rio de Janeiro must present a supporting document on the day of the visit.
            </p>
            <ul>
                <li>
                    <p><b>Resident Adults: </b>Proof of residence in the name of the visitor (water, gas, light, mobile phone, internet, card, or phone bills) and official identification document with a photo (digital or printed).</p>
                </li>
                <li>
                    <p><b>Born Adults: </b>Official ID with a photo (digital or printed).</p>
                </li>
                <li>
                    <p><b>Resident Children (3 to 12 years): </b>Original birth certificate (or certified copy), or official ID with a photo, which proves the affiliation of the holder of the residence (digital or printed).</p>
                </li>
                <li>
                    <p><b>Born Children (3 to 12 years): : </b>Original birth certificate (or certified copy), or official ID with a photo (digital or printed).</p>
                </li>
            </ul>
        </>
    );
}

export default ArraiaPopupEN;
