function ExperienceRulesES() {
    return (
        <ul className="mt-4">
            <li><p>Es necesario realizar la compra de esta experiencia con al menos 3 días de anticipación.</p></li>
            <li><p>Evento sujeto a cancelación debido a las condiciones meteorológicas. El Parque Bondinho Pão de Açúcar® no se hace responsable del pronóstico del tiempo en el día de la visita.</p></li>
            <li><p>La cancelación se puede realizar hasta la fecha y hora de la experiencia. Si la cancelación se produce con menos de 48 horas de antelación a la experiencia, se realizará una retención del 20% del importe pagado.</p></li>
            <li><p>No se reembolsarán las solicitudes de cancelación efectuadas (i) después de la fecha y hora del Embarque o Evento; o (ii) después de que haya tenido lugar el recorrido.</p></li>
            <li><p>Las solicitudes de cancelación para compras en el sitio web deberán hacerse al SAC contactando al correo electrónico <a href="mailto:sac@bondinho.com.br">sac@bondinho.com.br</a>.</p></li>
            <li><p>Es posible reprogramar la experiencia hasta 7 días después de la fecha original.</p></li>
            <li><p>En el caso de abandono o renuncia de la experiencia, después de haber comenzado, no habrá derecho a reembolso o compensación por los servicios no utilizados, ya que los operadores y guías ya habrán preparado toda la infraestructura contratada.</p></li>
            <li><p>Si el usuario y las demás personas que hayan adquirido el Amanecer en el Parque Bondinho no se presentan en la hora y lugar marcados para el inicio de la experiencia (5:30 AM), sin comunicación previa, se considerará "No-Show," es decir, no se reembolsarán los valores pagados.</p></li>
            <li><p>No hay restricción de edad para este paseo.</p></li>
            <li><p>Los niños menores de 3 años no pagan por la experiencia.</p></li>
            <li><p>El desayuno disponible en el Amanecer en el Parque Bondinho termina a las 8:30 AM, pero los visitantes pueden permanecer en el Restaurante Clássico Sunset Club para consumir del menú tradicional (no incluido en el producto) y también en el Parque Bondinho para visita libre.</p></li>
            <li><p>Si hay alguna restricción alimentaria, es necesario comunicarlo con al menos 3 días de anticipación.</p></li>
        </ul>
    )
}

export default ExperienceRulesES;