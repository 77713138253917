import React, { Suspense, useState } from "react";
import Slider from "react-slick";
import i18next from "i18next";

import { Link as LinkRoll } from "react-scroll";

import "./BannerHome.scss";
import { useContent } from "@croct/plug-react";

function BannerHomeCroct() {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [changeBanner, setChangeBanner] = useState<boolean>();
  //const [active, setActive] = useState<boolean>(false);


  const lng = i18next.language === "pt" ? "portuguese" : i18next.language === "en" ? "english" : i18next.language === "es" ? "spanish" : 'portuguese';

  const settings = {
    draggable: false,
    useTransform: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 1500,
    autoplaySpeed: 5500,
    initialSlide: 0 /* Math.floor(Math.random() * 2) */,
    cssEase: "ease-out"
  };

  const typeDevice = window.innerWidth < 480 ? 'mobile' : 'desktop';

  type HomeBannerContent = {
    banners: any
  };

  const fallbackBanner: HomeBannerContent = {
    banners: 'Default banners'
  };

  const HomeBanner = () => {
    const {
      banners
    } = useContent<HomeBannerContent>('home-banner', { fallback: fallbackBanner });

    return (
      banners[0].image !== undefined
        ?
        <Slider {...settings}>
          {
            banners.map((banner: any, index: any) => {
              if (banner.image.language === lng) {
                if (banner.link !== "#buy-product") {
                  return (
                    <a
                      key={index}
                      href={`${banner.link}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {
                        typeDevice === 'desktop'
                          ?
                          <img
                            src={banner.image.desktop}
                            alt={`Banner Desktop ${index}`}
                            width="100%"
                            height="100%"
                            loading={"lazy"}
                          />
                          :
                          <img
                            src={banner.image.mobile}
                            alt={`Banner Mobile ${index}`}
                            width="100%"
                            height="100%"
                            loading={"lazy"}
                          />
                      }
                    </a>
                  )
                } else {
                  return (
                    <LinkRoll
                      activeClass="active"
                      to={banner.link.replaceAll("#", "")}
                      spy={true}
                      smooth={true}
                      offset={-250}
                      duration={500}
                    >
                      {
                        typeDevice === 'desktop'
                          ?
                          <img
                            src={banner.image.desktop}
                            alt={`Banner Desktop ${index}`}
                            width="100%"
                            height="100%"
                            loading={"lazy"}
                          />
                          :
                          <img
                            src={banner.image.mobile}
                            alt={`Banner Mobile ${index}`}
                            width="100%"
                            height="100%"
                            loading={"lazy"}
                          />
                      }
                    </LinkRoll>
                  )
                }
              } else {
                return false;
              }
            })
          }
        </Slider>
        :
        <></>
    )
  }

  return (
    <div className="bg-main-banner">
      <Suspense fallback="Carregando...">
        <HomeBanner />
      </Suspense>
    </div >
  );

}

export default BannerHomeCroct;
