//import { useTranslation } from "react-i18next";
import SimpleAlert from "../components/Alert/SimpleAlert/SimpleAlert";

const VerifyAlertStock = (numberStockTotal:number, dateTour:any, index:number, t: any) => {
  if (numberStockTotal >= 6) {
    return (
      <SimpleAlert alertType="danger">
        <small>
          {t("alert.message01")}{" "}
          <a href="https://api.whatsapp.com/send?phone=5521982350821" target="_blank" rel="noreferrer">
            {t("alert.sac")}
          </a>
          .
        </small>
      </SimpleAlert>
    );
  } else if (numberStockTotal >= dateTour[index].maxStockNumber) {
    return (
      <SimpleAlert alertType="danger">
        <small>{t("alert.message02")}</small>
      </SimpleAlert>
    );
  }
  return null; // Retorne null se não houver alerta para exibir
};

export default VerifyAlertStock;