import React, { useState } from 'react';
import DatePicker from "react-multi-date-picker";
import { useTranslation } from "react-i18next";
import configData from "../../config/config.json";
import i18next from 'i18next';

import './Calendar.scss';


const weekDays = i18next.language === "en" ? ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"] : i18next.language === "es" ? ["Dom", "Lun", "Mar", "Mie", "Jue", "Vie", "Sab"] : ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sab"];

const months = i18next.language === "en" ? ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"] : i18next.language === "es" ? ["Enero", "Febrero", "Marzo", "April", "Mayo", "Junio", "July", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"] : ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"];

const CustomMultipleInput = ({ openCalendar, value, date, setValue, errors, startTimeLocal }: any) => {

  function convertDate(cell: any) {
    if (cell !== null && cell !== undefined) {
      var aux: any = cell.split("T")[0].split("-");
      var date: any = `${aux[2]}/${aux[1]}/${aux[0]}`;
      return date;
    }
  }
  setValue(value);

  return (
    <input
      className={errors?.dataPgto ? "form-control bg-white frm-sm w-100 endpoint-error" : "form-control bg-white frm-sm w-100"}
      onFocus={openCalendar}
      value={value}
      readOnly
      id={'singleCalendarId'}
      placeholder={startTimeLocal ? convertDate(startTimeLocal) : "Quando?"}
      autoComplete='off'
    />
  )
}

const SingleCalendar = ({ date, setValue = () => { }, field, errors = undefined, startTimeLocal = undefined, disableFutureDates = false }: any) => {
  const { t } = useTranslation();
  const [aux, ] = useState<any>(date);

  const maxDate: any = new Date();

  return (
    <DatePicker
      {...field}
      //value={new Date(defaultDate)}
      //onChange={(e: any) => {setAux(e.year + "-" + e.month + "-" + e.day) }}
      weekDays={weekDays}
      months={months}
      format={configData.DATE.PT}
      calendarPosition={`top`}
      maxDate={disableFutureDates === true ? maxDate : ''}
      render={<CustomMultipleInput date={aux} setValue={setValue} errors={errors} startTimeLocal={startTimeLocal} />}
    />
  )
}

export default SingleCalendar;