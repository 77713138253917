function DiscountRulesTourMaravilhaEN(props: any) {
    return(
        <>
            <p className="my-2">In all cases, <b>supporting documentation is required.</b>.</p>
            <p>Discounts are granted to those who were born or those who live in {props.productCode === process.env.REACT_APP_CARIOCA_STATE ? "state of Rio de Janeiro," : "Rio de Janeiro and Grande Rio,"} upon individual presentation of the following original documents (or certified copy), digital or printed.</p>
            <ul>
                <li>
                    <p><b>Resident Adults: </b>Proof of residence in the name of the visitor (water, gas, light, mobile phone, internet, card, or phone bills) and official identification document with a photo (digital or printed).</p>
                </li>
                <li>                    
                    <p><b>Born Adults: </b>Official ID with a photo (digital or printed).</p>
                </li>
                <li>                    
                    <p><b>Resident Children (3 to 12 years): </b>Original birth certificate (or certified copy), or official ID with a photo, which proves the affiliation of the holder of the residence (digital or printed).</p>
                </li>
                <li>                    
                    <p><b>Born Children (3 to 12 years): : </b>Original birth certificate (or certified copy), or official ID with a photo (digital or printed).</p>
                </li>
                <li>                    
                    <p><b>Spouse: </b>Presentation of the original marriage or commom-law marriage certificate (or certified copy), official ID with a photo (or certified copy) and proof of residence in the name of the spouse (digital or printed).</p>
                </li>
            </ul>
            <p>Discounts are personal and non-transferable.</p>
            <p>The company reserves the right to suspend promotions on event days in the Park, in case of sell-out, for reasons of maintenance or force majeure. Promotion valid while stocks last.</p>

        </>
    )
}

export default DiscountRulesTourMaravilhaEN;