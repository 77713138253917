import React from "react";
import i18next from "i18next";

import { useTranslation } from "react-i18next";

import PageDafault from "../../templates/PageDafault";
import WorkUsPT from "./components/WorkUsPT/WorkUsPT";
import WorkUsEN from "./components/WorkUsEN/WorkUsEN";
import WorkUsES from "./components/WorkUsES/WorkUsES";
import { Helmet } from "react-helmet";

function WorkUs() {
    const [t] = useTranslation();

    return (
        <>
            <Helmet>
                <html lang={i18next.language} />
                <title>Trabalhe Conosco | Parque Bondinho</title>
                <meta name="description" content="Muito além de um parque, o Parque Bondinho Pão de Açúcar é uma plataforma integrada de experiências sustentáveis em turismo, mídia e mais. Confira!" />
                <meta name="googlebot" content={process.env.REACT_APP_SERVER_ROBOTS} />

                {/* Essential META Tags */}
                <meta property="og:title" content="Trabalhe Conosco | Parque Bondinho" />
                <meta property="og:type" content="TouristAttraction" />
                <meta property="og:image" content={`${process.env.REACT_APP_SERVER_URL}${process.env.REACT_APP_SERVER_LOGO}`} />
                <meta property="og:url" content={process.env.REACT_APP_SERVER_URL} />

                {/* Non-Essential, But Recommended */}
                <meta property="og:description" content="Muito além de um parque, o Parque Bondinho Pão de Açúcar é uma plataforma integrada de experiências sustentáveis em turismo, mídia e mais. Confira!" />
                <meta property="og:site_name" content={process.env.REACT_APP_SERVER_NAME} />

                <meta name="robots" content={process.env.REACT_APP_SERVER_ROBOTS} />
                <link rel="canonical" href={process.env.REACT_APP_SERVER_URL} />
            </Helmet>

            <PageDafault>
                <div className="container py-5">
                    <h1 className="mb-5 text-center display-2">{t('footer.menu.workus')}</h1>
                    <div className="card py-4 px-3">
                        {
                            i18next.language === "pt"
                                ?
                                <WorkUsPT />
                                :
                                i18next.language === "en"
                                    ?
                                    <WorkUsEN />
                                    :
                                    i18next.language === "es"
                                        ?
                                        <WorkUsES />
                                        :
                                        <WorkUsPT />
                        }
                    </div>
                </div>
            </PageDafault>
        </>
    )
}

export default WorkUs;