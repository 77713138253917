import React from "react";

function ArraiaPopupES() {
    return (
        <>
            <p>
                Los nacidos y residentes en todo el Estado de Río de Janeiro deben presentar un documento acreditativo el día de la visita.
            </p>
            <ul>
                <li>
                    <p><b>Adultos Residentes: </b>Comprobante de residencia en su nombre (agua, gas, luz, celular, internet, tarjeta o teléfono) y documento de identificación oficial con foto (digital o impreso).</p>
                </li>
                <li>
                    <p><b>Adultos nacidos: </b>Documento de identificación oficial con foto, (digital o impreso).</p>
                </li>
                <li>
                    <p><b>Niños Residentes (3 a 12 años): </b>Certificado de nacimiento original (o copia certificada), o documento oficial de identificación con foto, que acredite la filiación del titular de la residencia, (digital o impreso).</p>
                </li>
                <li>
                    <p><b>Niños nacidos (3 a 12 años): </b>Certificado de nacimiento original (o copia certificada) o documento oficial de identificación con foto (digital o impreso).</p>
                </li>
            </ul>
        </>
    );
}

export default ArraiaPopupES;
