import React, { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

//Components
import CheckoutCardTour from "../CheckoutCards/CheckoutCardTour";
import CheckoutCardTransfer from "../CheckoutCards/CheckoutCardTransfer";

import "./StepBook.scss";
import CheckoutCardTicket from "../CheckoutCards/CheckoutCardTicket";
import { useAppSelector, useAppDispatch } from "../../../store/hooks";
import SimpleAlert from "../../Alert/SimpleAlert/SimpleAlert";
import DiscountCoupon from "../../DiscountCoupon/DiscountCoupon";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { removeCupomCart, updateDadosCart } from "../../../store/cart";

import { scroller } from "react-scroll";
import api from "../../../services/api";

import AuthLogin from "../../Authenticated/AuthLogin/AuthLogin";
import verifyPriceOrder from "../../../infra/verifyPriceOrder";

export interface propAction {
  action: any;
  stepCtrl: any;
}

const StepBook: React.FC<propAction> = ({ action, stepCtrl }: propAction) => {
  const { userModel }: any = JSON.parse(localStorage.getItem('bpasiteAuth') || '{}');
  let auth = localStorage.getItem("bpasiteAuth") || "{}";

  const { t } = useTranslation();
  const [alert, setAlert] = useState<any>();

  const [, setPickupSelect] = useState<any>(null);

  const getAuthenticateAccount = async () => {
    const token = localStorage.getItem("bpasiteAuth") || "{}";
    const config = {
      headers: { Authorization: `Bearer ${JSON.parse(token)?.token?.token}` },
    };

    try {
      const { data } = await api.post("/api/LoginSite/GetPerfil", "", config);
      if (data.status !== 400) {
        /* setFirstName(data.data.firstName);
        setLastName(data.data.lastName);
        setCpf(data.data.cpf);
        setPhone(data.data.phone);
        setWhatsapp(data.data.whatsapp);
        setEmail(data.data.email);
        setCEP(data.data.userComplement.postCode);
        setState(data.data.userComplement.stateDesc);
        setCity(data.data.userComplement.city);
        setAddress(data.data.userComplement.address);
        setCountry(
          data.data.userComplement.country === null ||
            data.data.userComplement.country === "Brasil"
            ? "BR"
            : data.data.userComplement.country
        );
        setAddressNumber(data.data.userComplement.number);
        setAddressComplement(data.data.userComplement.complement);
        setNacionalityBr(data.data.cpf === null ? false : "checked"); */

        /* if (data.data.cpf !== null && data.data.foreign !== 2) {
                CpfValidator(data.data.cpf);
            } else if (data.data.foreign === 2) {
                setCpfValidation(true);
            } */

        /* if (data.data.cpf !== null) {
          CpfValidator(data.data.cpf);
        } else {
          setCpfValidation(true);
        }

        setDisableCpf(!(data.data.cpf === null || data.data.cpf === ""));
        setDisablePassport(
          !(data.data.passport === null || data.data.passport === "")
        );
        setDisableCep(
          !(
            data.data.userComplement.postCode === null ||
            data.data.userComplement.postCode === ""
          )
        );
        setDisableState(
          !(
            data.data.userComplement.state === null ||
            data.data.userComplement.state === ""
          )
        );
        setDisableCity(
          !(
            data.data.userComplement.city === null ||
            data.data.userComplement.city === ""
          )
        );
        setDisableAddress(
          !(
            data.data.userComplement.address === null ||
            data.data.userComplement.address === ""
          )
        );
        setDisableNumber(
          !(
            data.data.userComplement.number === null ||
            data.data.userComplement.number === ""
          )
        );
        setDisableComplement(
          !(
            data.data.userComplement.complement === null ||
            data.data.userComplement.complement === ""
          )
        );
        setDisablePhone(!(data.data.phone === null || data.data.phone === ""));

        setValue("firstName", data.data.firstName);
        setValue("lastName", data.data.lastName);
        setValue("cpf", data.data.cpf);
        setValue("passport", data.data.passport);
        setValue("tel", data.data.phone);
        setValue("whatsapp", data.data.whatsapp);

        setValue("email", data.data.email);
        setValue("cep", data.data.userComplement.postCode);
        setValue("state", data.data.userComplement.state);
        setValue("city", data.data.userComplement.city);
        setValue("address", data.data.userComplement.address);
        setValue("nacionality", data.data.userComplement.country);
        setValue("number", data.data.userComplement.number);
        setValue("complement", data.data.userComplement.complement); */
      } else {
      }
    } catch (error: any) {
      if (error?.response?.status === 400) {
      } else if (error?.response?.status === 401) {
        // window.location.href = window.location.origin + '/login';
        localStorage.removeItem("bpasiteAuth");
      }
    }
  };

  useEffect(() => {
    if (userModel !== undefined) {
      getAuthenticateAccount();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const cartBPA: any = JSON.parse(
    JSON.stringify(useAppSelector((state: any) => state.cart))
  );
  const dispatch = useAppDispatch();

  const addPickup = (objPickup: any, indexP: any, integration: any) => {
    setPickupSelect(objPickup.id);

    if (integration === true) {
      cartBPA.dados[indexP].IdPickupDestino = objPickup.idPickupLocation;
      cartBPA.dados[indexP].AntecedenciaPickupDestino = objPickup.minutesPrior;
      cartBPA.dados[indexP].NomeListaPickupDestino = objPickup.nomeListaPickup;
      cartBPA.dados[indexP].ListaPickupDestino = objPickup.pickupIdStranger;
      cartBPA.dados[indexP].NomePickupDestino = objPickup.nomePickup;
    }

    cartBPA.dados[indexP].pickup = objPickup.id;
    cartBPA.dados[indexP].pickupInfo = objPickup;
    cartBPA.dados[
      indexP
    ].meetingPoint = `${objPickup.name} - ${objPickup.address}`;

    dispatch(updateDadosCart(cartBPA.dados));
  };

  const validate = () => {
    var vali = true;
    cartBPA.dados.forEach((cItem: any, index: any) => {
      if (cItem.typePickup === "1" || cItem.typePickup === "0") {
        if (cItem.pickup === 0 && cItem.reservationSystem === 99) {
          vali = false;

          scroller.scrollTo(`card${index}`, {
            duration: 100,
            delay: 0,
            smooth: true,
            offset: 0,
          });
        }
      }
    });

    if (vali === true) {
      action();
      setAlert(null);
    } else {
      setAlert(
        <SimpleAlert alertType={"danger py-1 px-2 my-1"}>
          {t("checkoutSteps.error")}
        </SimpleAlert>
      );
      setTimeout(() => setAlert(null), 2000);
    }
  };

  function ScrollTop() {
    window.scrollTo(0, 0);
  }

  if (cartBPA.dados && cartBPA.dados.length >= 1) {
    let totalCart = 0;
    const labelArrayDiscount = verifyPriceOrder(cartBPA)[0];
    const numberLabels = verifyPriceOrder(cartBPA)[1];

    return (
      <>
        {auth === "{}" || auth === "null" ? (
          <div className="step-login my-4 bg-white p-3 mb-2 rounded col-12 col-md-4 col-lg-4 col-xl-4 aside order-2 order-md-1 m-auto">
            <h4 className="text-primary">{t("navHeader.login.title")}</h4>
            <p>{t("navHeader.login.subTitle")}</p>
            <AuthLogin isCheckoutStep={false} action={getAuthenticateAccount} />
          </div>
        ) : (
          <>
            <div className="row">
              <div
                className="col-12 col-md-8 col-lg-8 order-1"
                data-listproduct="checkout"
              >
                {cartBPA.dados.length >= 1
                  ? cartBPA.dados.map((cItem: any, index: any) => {
                    let priceProduct =
                      Number(cItem.adults) * Number(cItem.priceAdults) +
                      Number(cItem.childs) * Number(cItem.priceChilds) +
                      Number(cItem.infants) * Number(cItem.priceInfants) +
                      Number(cItem.elders) * Number(cItem.priceElders) +
                      Number(cItem.student) * Number(cItem.priceStudent) +
                      Number(cItem.priceGlobalPeople);
                    totalCart =
                      Number(totalCart) +
                      (priceProduct - priceProduct * (cItem.discount / 100));

                    const discountLabel = (code: string) => {
                      let auxValue: any = [];

                      if (labelArrayDiscount?.length > 0) {
                        labelArrayDiscount.forEach((elem: any) => {
                          if (elem.productCode === code) {
                            auxValue.push(elem.value);
                          }
                        });
                        return auxValue.reduce((acum: any, value: any) => acum + value, 0);
                      }
                    }

                    if (cItem.productType === 1) {
                      return (
                        <div key={index}>
                          <CheckoutCardTour
                            product={cItem}
                            indexP={index}
                            alert={alert}
                            addPickup={addPickup}
                            discountLabel={discountLabel}
                            labelArrayDiscount={labelArrayDiscount}
                            pricePerLabel={numberLabels}
                          ></CheckoutCardTour>
                        </div>
                      );
                    } else if (cItem.productType === 4) {
                      return (
                        <div key={index}>
                          <CheckoutCardTicket
                            product={cItem}
                            indexP={index}
                            alert={alert}
                            addPickup={addPickup}
                            discountLabel={discountLabel}
                            labelArrayDiscount={labelArrayDiscount}
                            pricePerLabel={numberLabels}
                          ></CheckoutCardTicket>
                        </div>
                      );
                    } else if (cItem.productType === 2) {
                      return (
                        <div key={index}>
                          <CheckoutCardTransfer
                            transfers={[cItem]}
                          ></CheckoutCardTransfer>
                        </div>
                      );
                    } else {
                      return <div key={index}></div>;
                    }
                  })
                  : ""}
              </div>

              <div
                className="col-12 col-md-4 col-lg-4 col-xl-4 order-2 aside"
                id="asideCheck01"
              >
                <div className="strickPrecoCkekout sticky-top mt-4">
                  <div className="bg-white p-2 mb-2 mb-md-4 asideCheck01total">
                    <div className="row bg-black-10 p-2 p-lg-3 m-0">
                      {cartBPA?.cupom?.type === 2 ? (
                        <small
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            paddingRight: '0',
                            paddingLeft: '0',
                            fontSize: '12px'
                          }}
                        >
                          <span>{cartBPA.cupom.codeName}</span>
                          <span>
                            R${" "}
                            {cartBPA?.cupom?.value
                              .toFixed(2)
                              .split(".")
                              .join(",")}
                            <button
                              onClick={() => {
                                dispatch(removeCupomCart());
                              }}
                              className="p-0 m-0 text-muted"
                              style={{ cursor: "pointer", marginRight: "12px" }}
                            >
                              <i className="text-muted btn-exclui text-dark btn-exclui-carrinho ml-2">
                                <FontAwesomeIcon
                                  style={{ color: "#212529" }}
                                  icon={["fal", "times"]}
                                />
                              </i>
                            </button>
                          </span>
                        </small>
                      ) : (
                        ""
                      )}

                      {cartBPA?.cupom?.type === 1 ? (
                        <small
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            paddingRight: "0",
                            paddingLeft: '0',
                            fontSize: "12px",
                          }}
                        >
                          <span>{cartBPA.cupom.codeName}</span>
                          <span>
                            {" "}
                            {cartBPA?.cupom?.value + "%"}
                            <button
                              onClick={() => {
                                dispatch(removeCupomCart());
                              }}
                              className="p-0 m-0 text-muted"
                              style={{ cursor: "pointer", marginRight: "12px" }}
                            >
                              <i className="text-muted btn-exclui text-dark btn-exclui-carrinho ml-2">
                                <FontAwesomeIcon
                                  style={{ color: "#212529" }}
                                  icon={["fal", "times"]}
                                />
                              </i>
                            </button>
                          </span>
                        </small>
                      ) : (
                        ""
                      )}

                      <div className="col-6 text-left" style={{ paddingLeft: "0" }}>TOTAL</div>

                      <div
                        className="col-6 text-right font-weight-bold text-primary h5 p-0 m-0"
                        data-price="total"
                      >
                        R$ {cartBPA.totalCart.toFixed(2).split(".").join(",")}
                      </div>
                    </div>

                    <div className="row mt-2">
                      <div className="col-6">
                        <a
                          href={window.location.origin}
                          title=""
                          className="btn btn-outline-primary btn-block text-primary btn-activities"
                        >
                          {t("checkoutSteps.reserve")}
                        </a>
                      </div>
                      <div className="col-6">
                        <button
                          className="btn btn-primary bg-primary text-white btn-block btn-compra w-100"
                          data-toggle="pill"
                          data-btn="step1"
                          onClick={() => {
                            validate();
                            ScrollTop();
                          }}
                        >
                          {t("checkoutSteps.buy")}
                        </button>
                      </div>
                    </div>
                  </div>
                  <DiscountCoupon stepCtrl={stepCtrl}></DiscountCoupon>
                </div>
              </div>
            </div>
          </>
        )}
      </>
    );
  } else {
    return (
      <div className="border-empty my-5 py-4 d-flex flex-column align-items-center">
        <h4>{t("checkoutSteps.emptyCart")} :(</h4>
        <div>
          <a className="btn btn-primary px-4" href={"/"}>
            {t("checkoutSteps.back")}
          </a>
        </div>
      </div>
    );
  }
};

export default StepBook;
