import React from "react";

function TermsOfUseCaracolES() {
    return (
        <>
            <p>¡Bienvenido(a)! ¡Gracias por utilizar el sitio web de <b>PARQUE DEL CARACOL</b>!</p>

            <p>Esta aplicación y este documento de Términos y Condiciones de Uso ("Término") rige su acceso y uso del sitio, dentro de Brasil, puestos a disposición, controlados y operados por el <b>PARQUE CARACOL - NUEVO CARACOL Y TAINHAS S/A. ("CARACOL" o "PARQUE DEL CARACOL")</b>, con sede en la Calle Batista Luzardo, 167 - Tienda 02, Centro - Canela/RS, CEP: 95.680-000.</p>

            <p>Este Término tiene por objeto definir las reglas a seguir para la utilización de la aplicación/site del <b>PARQUE DEL CARACOL</b>, sin perjuicio de la aplicación de la legislación vigente.</p>

            <p><b>ES IMPORTANTE RECORDAR QUE, AL UTILIZAR LA APLICACIÓN PARQUE DEL CARACOL", USTED TENDRÁ LA OPCIÓN DE ACEPTACIÓN Y CONOCIMIENTO DE ESTE TÉRMINO A TRAVÉS DE UN "CHECKBOX" PREVIAMENTE DESMARCADO, PARA QUE USTED LO MARQUE O NO, LIBREMENTE. EN CASO DE OPTAR POR MARCARLO, USTED TIENE CONOCIMIENTO DE ESTE TÉRMINO Y DE SUS REGLAS, SIENDO PLENAMENTE RESPONSABLE DE TODOS Y CUALQUIER ACTO REALIZADO POR USTED EN LA APLICACIÓN. SI USTED NO ACEPTA ESTE TÉRMINO, POR FAVOR NO ACCEDA/UTILICE ESTA APLICACIÓN.</b></p>

            <p>Señalamos que las disposiciones contenidas en este término pueden ser actualizadas en cualquier momento, siendo responsabilidad del usuario consultarlas siempre que interactúe con la aplicación.</p><br />

            <strong>1. ¿QUIÉNES SOMOS?</strong><br />

            <p>El Parque Estadual do Caracol es una unidad de conservación de 25,10 hectáreas, en el Estado de Rio Grande do Sul, en el Municipio de Canela.</p>

            <p>Su principal atracción es un verdadero espectáculo de la naturaleza: la Cascada del Caracol, con una caída libre de 131 metros, se puede apreciar en miradores y en el observatorio, que proporcionan una vista panorámica privilegiada.</p>

            <p>El Parque fue oficialmente fundado en 1973, pero desde 1954, cuando el Gobierno del Estado de Rio Grande do Sul decretó de utilidad pública las tierras donde se encuentra la Cascada del Caracol, hubo un aumento gradual del número de visitantes al lugar, lo que motivó la implementación de una infraestructura como la portería, restaurante, los baños públicos, el estacionamiento interno y el mirador de la cascada. Desde diciembre de 2022, el Parque está bajo concesión del Consorcio Nuevo Caracol y Tainhas.</p>

            <p>Además de los servicios principales, el Parque del Caracol proporciona caminatas por senderos ecológicos, paseo en trencito por la Estación del Sueño Vivo, visita al Observatorio – un mirador que está a una altura de 27m, con una vista 360º del parque –, además del Centro Histórico, parque infantil, canchas deportivas y tiendas.</p><br />

            <strong>2. ¿QUÉ HACEMOS?</strong><br />

            <p className="px-3"><b>a)</b> Venta de entradas de acceso al <b>PARQUE DEL CARACOL</b>;</p>

            <p className="px-3"><b>b)</b> Venta de tours dentro del <b>PARQUE DEL CARACOL</b>;</p>

            <p className="px-3"><b>c)</b> Información e historias sobre el Parque;</p>

            <p className="px-3"><b>d)</b> Acceso a puestos vacantes de la empresa;</p>

            <p className="px-3"><b>e)</b> Presentarse para un puesto vacante;</p>

            <p className="px-3"><b>f)</b> Canal de contacto con la empresa;</p>

            <p className="px-3"><b>g)</b> Canal de contacto sobre locación para eventos, medios, acciones comerciales. </p><br />

            <strong>3. ¿CÓMO SE ACCEDE AL SITIO WEB DEL “PARQUE DEL CARACOL”?</strong><br />

            <p className="px-3"><b>3.1.</b>	La navegación en la aplicación del <b>PARQUE DEL CARACOL</b> con estatus de Usuario se aplica a usuarios que desean comprar boletos de acceso al Parque, además de otros productos y servicios ofrecidos, como los que desean postularse a empleos en la empresa, saber sobre las medias, eventos y locaciones en el Parque. </p>

            <p className="px-3"><b>3.2.</b>	Para acceder al sitio web del <b>PARQUE DEL CARACOL</b> y utilizar sus funcionalidades, es necesario efectuar el registro del usuario para creación de login y contraseña de acceso. Para registrarse, el usuario deberá dirigirse al área de registro y rellenar el formulario informando: e-mail, nombre completo, país, estado, CPF, pasaporte (caso sea extranjero) celular y teléfono fijo. Después de completar los campos, simplemente haga clic en el botón de registro. </p>

            <p className="px-3"><b>3.3.</b>	Para su acceso, podemos solicitar información sobre sus datos personales como se describe en nuestro <a href="/aviso-de-privacidade" target="_blank">Aviso de Privacidad</a>.</p>

            <p className="px-3"><b>3.4.</b>	Para realizar la compra en la aplicación/sitio web del <b>PARQUE DEL CARACOL</b>, deberá tener por lo menos 18 (dieciocho) años. El acceso debe ser realizado solo por usted, titular de una cuenta. Si el usuario comprueba la irregularidad o la información falsa, el acceso o la cuenta puede ser bloqueado.</p>

            <p className="px-3"><b>3.5.</b>	Los contenidos proporcionados dentro del sitio web por el usuario serán o podrán estar protegidos por encriptación.</p>

            <p className="px-3"><b>3.6.</b>	En caso de sospecha de fraude, obtención de beneficio o ventaja de forma ilícita, o por el incumplimiento de cualesquiera condiciones previstas en estos Términos de Uso, el <b>PARQUE DEL CARACOL</b> se reserva el derecho de suspender o cancelar, en cualquier momento, su acceso a la aplicación, sin que se le deba ninguna indemnización; y el <b>PARQUE DEL CARACOL</b> tiene el derecho de promover acciones de retorno, si es necesario, por el incumplimiento de cualquier condición prevista en estas Condiciones de Uso.</p><br />

            <strong>4. RESPONSABILIDADES DEL USUARIO</strong><br />

            <p className="px-3"><b>4.1.</b> El usuario es el único responsable por el uso de la aplicación y deberá respetar las reglas de este Término, así como la legislación aplicable.</p>

            <p className="px-3"><b>4.2.</b> El usuario garantiza la veracidad de todos los datos informados, siendo responsable de mantener actualizada su información personal.</p>

            <p className="px-3"><b>4.3.</b> El <b>PARQUE DEL CARACOL</b> no será responsable por daños directos o indirectos que resulten o que tengan relación con el acceso, uso o la incapacidad de acceder o utilizar la aplicación.</p>

            <p className="px-3"><b>4.4.</b> Es responsabilidad del usuario mantener su login y contraseña seguros y fuera del alcance de terceros. Por lo tanto, el usuario es responsable por todas las acciones realizadas en su cuenta.</p>

            <p className="px-3"><b>4.5.</b> Teniendo en cuenta las características inherentes al entorno de Internet, la aplicación no se hace responsable de interrupciones o suspensiones de conexión, transmisiones de ordenador incompletas o que fallen, así como de fallos técnicos de cualquier tipo, incluidos, entre otros, al mal funcionamiento electrónico de cualquier red, hardware o software.</p>

            <p className="px-3"><b>4.6.</b> Es responsabilidad del usuario mantener el entorno de su dispositivo (computadora, teléfono móvil, tablet, entre otros) seguro, con el uso de herramientas disponibles para garantizar la seguridad.</p>

            <p className="px-3"><b>4.7.</b> El usuario declara que es civilmente capaz. En caso de eventual acceso de usuario menor de 18 (dieciocho) años, con o sin autorización del responsable legal, este será responsable por todos los actos por aquel practicados, como consta en ley.</p><br />


            <strong>5.	PRIVACIDAD DEL USUARIO</strong><br />

            <p className="px-3"><b>5.1.</b> El <b>PARQUE DEL CARACOL</b> se compromete a procesar su información y sus datos personales de conformidad con la Ley General de Protección de Datos (LGPD) y de conformidad con nuestro <a href="/aviso-de-privacidade" target="_blank">Aviso de Privacidad</a>.</p>

            <p className="px-3"><b>5.2.</b> El <a href="/aviso-de-privacidade" target="_blank">Aviso de Privacidad</a> es parte integrante e inseparable de este <b>Término de Uso.</b></p><br />


            <strong>6.	CÓMO SE REALIZAN LOS PAGOS</strong><br />

            <p className="px-3"><b>6.1.</b>	Los pagos efectuados para la compra online de los boletos en el <b>PARQUE DEL CARACOL</b>, deberán realizarse dentro de la aplicación, por medio de tarjeta de crédito o PIX, siendo recogidos los siguientes datos personales: correo electrónico, CPF, pasaporte, país, estado, teléfono, datos de la tarjeta de crédito (en caso de pago con tarjeta).</p>

            <p className="px-3"><b>6.2.</b>	La confirmación del pago a través de la aplicación dependerá de cada operador de tarjeta de crédito.</p>

            <p className="px-3"><b>6.3.</b> El <b>“PARQUE DEL CARACOL”</b> se reserva el derecho a establecer, remover y/o revisar el precio relacionado a todos los servicios o bienes obtenidos por medio del uso de la aplicación a cualquier momento. </p><br />


            <strong>7.	PROPIEDAD INTELECTUAL Y OTROS DERECHOS DEL “PARQUE DEL CARACOL”</strong><br />

            <p className="px-3"><b>7.1.</b>	Todos los derechos relativos al <b>PARQUE DEL CARACOL</b> y sus funcionalidades, producidas directa o indirectamente, son propiedad exclusiva de la aplicación.</p>

            <p className="px-3"><b>7.2.</b>	El <b>PARQUE DEL CARACOL</b> garantiza que todos los derechos, títulos e intereses (incluidos, entre otros, los derechos de autor y otros derechos de propiedad intelectual) sobre los servicios ofrecidos permanecerán bajo la titularidad de la aplicación.</p>

            <p className="px-3"><b>7.3.</b>	El usuario no adquirirá ningún derecho de propiedad sobre los servicios y contenidos del <b>PARQUE DEL CARACOL</b>.</p>

            <p className="px-3"><b>7.4.</b>	Queda prohibido usar, copiar, reproducir, modificar, traducir, publicar, transmitir, distribuir, ejecutar, subir, mostrar, licenciar, vender o explotar y realizar ingeniería inversa del Contenido del <b>PARQUE DEL CARACOL</b> sin el consentimiento previo y expreso de la aplicación. Cualquier uso no autorizado del Contenido del <b>PARQUE DEL CARACOL</b> será considerado una violación de los derechos de autor y de propiedad intelectual.</p>

            <p className="px-3"><b>7.5.</b>	Queda prohibido que el usuario descargue nuestro contenido con el fin de almacenarlo en bases de datos para ofrecer a terceros. También se prohíbe que el contenido que ofrecemos se utilice para crear una base de datos o un servicio que pueda competir de cualquier manera con nuestro negocio.</p><br />


            <strong>8.	VIOLACIÓN DE DERECHOS DE AUTOR</strong><br />

            <p className="px-3"><b>8.1.</b>	Las alegaciones de infracción de los derechos de autor de cualquier contenido disponible en la aplicación deben enviarse por correo electrónico: <a href="mailto:dpo@novocaracoltainhas.com.br">dpo@novocaracoltainhas.com.br</a></p><br />

            <strong>9.	MONITOREO Y SEGURIDAD</strong><br />

            <p className="px-3"><b>9.1.</b> EL <b>PARQUE DEL CARACOL</b> no mide los esfuerzos para garantizar la privacidad y seguridad de su aplicación en el entorno de Internet. </p>

            <p className="px-3"><b>9.2.</b> Nuestro sitio cuenta con medidas de seguridad comercialmente disponibles en el sector. Además, el proceso de pago funciona a través de un servidor seguro, utilizando el protocolo SSL (Secure Socket Layer). El servidor seguro establece una conexión de modo que la información transmitida sea cifrada, asegurando que solo sea inteligible para su computadora y el servidor del <b>PARQUE DEL CARACOL</b>.</p>

            <p className="px-3"><b>9.3.</b> Al aceptar este Término, usted acepta someterse al procedimiento de detección de posibles operaciones fraudulentas y, de la misma forma, autoriza al <b>PARQUE DEL CARACOL</b> para que, una vez suministrada la información y documentos adicionales antes referidos, se utilicen para operaciones futuras.</p>

            <p className="px-3"><b>9.4.</b> Para obtener más información sobre el almacenamiento y el tratamiento de la información, consulte nuestro <b><a href="/aviso-de-privacidade" target="_blank">Aviso de Privacidad</a></b>.</p><br />

            <strong>10. EVALUACIONES, COMENTARIOS, COMUNICACIONES Y OTROS CONTENIDOS</strong><br />

            <p className="px-3"><b>10.1.</b> Los visitantes de la Plataforma del <b>PARQUE DEL CARACOL</b> podrán publicar evaluaciones, comentarios, fotos y otros contenidos, enviar comunicaciones, sugerencias, ideas, comentarios, preguntas u otra información, siempre que el contenido no sea ilegal, obsceno, amenazante, difamatorio, invada la privacidad, infrinja derechos de autor o de propiedad intelectual o que de otro modo sea perjudicial para terceros o cuestionable, y siempre que no consista o contenga virus de software, campañas políticas, solicitudes comerciales, corrientes, correo masivo o cualquier otra forma de spam.</p>

            <p className="px-3"><b>10.2.</b> Usted no puede utilizar una dirección de correo electrónico falsa, pretender ser otra persona física o jurídica o proporcionar información falsa sobre el origen del contenido de cualquier otra manera. EL <b>PARQUE DEL CARACOL</b> se reserva el derecho (pero no la obligación) de eliminar o editar dicho contenido.</p>

            <p className="px-3"><b>10.3.</b> No nos hacemos responsables por ningún daño (indirecto, emergente, punitivo, lucro cesante u otras pérdidas), ya sea de cualquier naturaleza, que pueda derivarse a terceros como consecuencia de una conducta inapropiada, ofensiva o contraria a la legislación vigente y a las directrices de uso y manejo de los productos/servicios por el usuario.</p><br />


            <strong>11. CANAL DE COMUNICACIÓN</strong><br />

            <p className="px-3"><b>11.1.</b> Si tiene alguna pregunta, comentario o sugerencia, por favor contáctenos por correo electrónico: <a href="mailto:sac@novocaracoltainhas.com.br">sac@novocaracoltainhas.com.br</a>.</p>

            <p className="px-3"><b>11.2.</b> Y en caso de dudas, para ejercer sus derechos de titular de los datos o cualquier comunicación, el PARQUE DEL CARACOL, en cumplimiento del artículo 41 de la Ley General de Protección de Datos, indica el contacto de la Encargada de Datos Personales, Louana Costa a través del correo electrónico: <a href="mailto:dpo@novocaracoltainhas.com.br">dpo@novocaracoltainhas.com.br</a>.</p><br />

            <strong>12. DISPOSICIONES GENERALES</strong><br />

            <p className="px-3"><b>12.1.</b> EL <b>PARQUE DEL CARACOL</b> es titular de las plataformas www.parquecaracol.com.br y posee todos los derechos sobre la apariencia, diseño, funcionalidad y todos los demás aspectos de las plataformas, siendo que tales derechos no podrán ser utilizados, reproducidos y/o copiados sin su expresa autorización.</p>

            <p className="px-3"><b>12.2.</b> Ocasionalmente, la plataforma del <b>PARQUE DEL CARACOL</b> podrá ofrecer enlaces de acceso a sitios web de terceros, relacionados o no con el <b>PARQUE DEL CARACOL</b>. Destacamos que no somos responsables de examinar el contenido de estos sitios ni de las ofertas de cualquiera de estas empresas. Corresponde al Usuario revisar, siempre, las políticas de privacidad y condiciones de uso de estos terceros.</p>

            <p className="px-3"><b>12.3.</b> Las Partes declaran y acuerdan que la aceptación de este Término se realizará en formato electrónico. Las Partes reconocen la veracidad, autenticidad, integridad, validez y efectividad de este Acuerdo y sus términos, incluidos sus anexos, de conformidad con el art. 219 del Código Civil, en formato electrónico y/o firmado por las Partes por medio de certificados electrónicos, aunque certificados electrónicos no emitidos por la ICP-Brasil, en los términos del art. 10, § 2º, de la Medida Provisional n.º 2.200-2, de 24 de agosto de 2001 ("MP n.º 2.200-2").</p>

            <p className="px-3"><b>12.4.</b> Cualquier omisión o retraso de cualquiera de las Partes en exigir el cumplimiento de cualquier término o condición de este Término por la otra Parte, o en ejercer cualquier derecho, prerrogativa o recurso aquí previsto, no constituirá novación ni implicará renuncia a la posibilidad futura de exigir el cumplimiento de tal término, condición, derecho, prerrogativa o recurso.</p>

            <p className="px-3"><b>12.5.</b> Cada cláusula, artículo, letra y frase de este Término constituye un compromiso o disposición independiente y distinto. En la medida de lo posible, cada una de las cláusulas de este Acuerdo debe interpretarse de manera que sea válida y eficaz a la luz de la ley aplicable. En caso de que alguna de las cláusulas de este Término sea considerada ilícita, dicha cláusula deberá ser juzgada separadamente del resto del Término, y sustituida por cláusula lícita y similar, que refleje las intenciones originales, observando los límites de la ley, y todas las demás cláusulas continuarán en pleno vigor.</p>

            <p className="px-3"><b>12.6.</b> El <b>PARQUE DEL CARACOL</b> se reserva el derecho, en cualquier momento y a su exclusivo criterio, de modificar las cláusulas de este Término, y corresponde al Usuario verificar su contenido siempre que acceda a las plataformas.</p>

            <p className="px-3"><b>12.7.</b> Asimismo, el <b>PARQUE DEL CARACOL</b> se reserva el derecho, en cualquier momento y a su exclusivo criterio, de discontinuar, suspender, terminar o cambiar la forma de acceso a su dirección electrónica con respecto a cualquier contenido, período de disponibilidad y equipos necesarios para el acceso y/o uso del mismo, sin necesidad de previa notificación al usuario.</p><br />

            <strong>13. LEY APLICABLE Y FORO</strong><br />

            <p className="px-3"><b>13.1.</b> Este Término se rige e interpreta de acuerdo con las Leyes de la República Federativa de Brasil.</p>

            <p className="px-3"><b>13.2.</b> Queda elegido el Foro de la Comarca de Rio Grande do Sul, como el competente para dirimir cualquier cuestión que surja del presente documento, con expresa renuncia a cualquier otro, por más privilegiado que sea.</p><br />

            <strong>14. ACTUALIZACIONES DE ESTA POLÍTICA</strong><br />

            <p className="px-3"><b>14.1.</b> EL <b>PARQUE DEL CARACOL</b> se reserva el derecho de modificar este Término de Uso siempre que sea necesario, para garantizar la funcionalidad, mejoras y actualizaciones de los servicios ofrecidos, no quedando obligado a mantener una estructura de la aplicación.</p><br />

            <p className="pb-2">Nuestro Término de Uso siempre contará la indicación de la fecha de actualización o versión actual.</p>

            <dl>
                <dd>
                    <table className='table-page' width='100%' cellSpacing='0' >
                        <tbody>
                            <tr>
                                <td width='55' height='14' style={{ backgroundColor: "#c0c0c0" }}>
                                    <p className="mb-0 px-2">
                                        <span>
                                            <strong>Versión</strong>
                                        </span>
                                    </p>
                                </td>
                                <td width='156' style={{ backgroundColor: "#c0c0c0" }}>
                                    <p className="mb-0 px-2">
                                        <span>
                                            <strong>Elemento modificado</strong>
                                        </span>
                                    </p>
                                </td>
                                <td width='299' style={{ backgroundColor: "#c0c0c0" }}>
                                    <p className="mb-0 px-2">
                                        <span>
                                            <strong>Motivo</strong>
                                        </span>
                                    </p>
                                </td>
                                <td width='113' style={{ backgroundColor: "#c0c0c0" }}>
                                    <p className="mb-0 px-2">
                                        <span>
                                            <strong>Fecha</strong>
                                        </span>
                                    </p>
                                </td>
                            </tr>
                            <tr>
                                <td width='55' height='4'>
                                    <p className="my-2 px-2">
                                        <span>01</span>
                                    </p>
                                </td>
                                <td width='116'>
                                    <p className="my-2 px-2">
                                        <span>Versión original</span>
                                    </p>
                                </td>
                                <td valign='top' width='299'>
                                    <p className="my-2 px-2">
                                        <span>N/A</span>
                                    </p>
                                </td>
                                <td valign='top' width='113'>
                                    <p className="my-2 px-2">
                                        <span>18/01/2023</span>
                                    </p>
                                </td>
                            </tr>
                            {/* <tr>
                                <td width='55' height='3'>
                                    <br />
                                </td>
                                <td width='116'>
                                    <br />
                                </td>
                                <td valign='top' width='299'>
                                    <br />
                                </td>
                                <td valign='top' width='113'>
                                    <br />
                                </td>
                            </tr> */}
                        </tbody>
                    </table>
                </dd>
            </dl>

        </>
    )
}

export default TermsOfUseCaracolES;