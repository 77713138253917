function DiscountRulesTourMaravilha(props: any) {
    return(
        <>
            <p className="my-2">Em todos os casos, <b>é necessário apresentar documentação comprovativa</b>.</p>
            <p>Os descontos são concedidos para nascidos ou moradores do {props.productCode === process.env.REACT_APP_CARIOCA_STATE ? "Estado do RJ" : "Rio e Grande Rio,"} mediante apresentação individual dos seguintes documentos originais (ou cópia autenticada), digital ou impresso.</p>
            <ul>
                <li>
                    <p><b>Adultos Moradores: </b>Comprovante de residência em seu nome (água, gás, luz, celular, internet, cartão ou telefone) e documento de identificação oficial com foto (digital ou impresso).</p>
                </li>
                <li>                    
                    <p><b>Adultos Nascidos: </b>Documento de identificação oficial com foto, (digital ou impresso).</p>
                </li>
                <li>                    
                    <p><b>Crianças Moradoras (3 a 12 anos): </b>Certidão de nascimento original (ou cópia autenticada), ou documento oficial de identificação com foto, que comprove a filiação do titular da residência, (digital ou impresso).</p>
                </li>
                <li>                    
                    <p><b>Crianças Nascidas (3 a 12 anos): </b>Certidão de nascimento original (ou cópia autenticada) ou documento oficial de identificação com foto, (digital ou impresso).</p>
                </li>
                <li>                    
                    <p><b>Cônjuge: </b>Apresentação da certidão de casamento ou união estável original (ou cópia autenticada), documento oficial de identificação com foto (ou cópia autenticada) e comprovante de residência em titularidade do cônjuge (digital ou impresso).</p>
                </li>
            </ul>
            <p>Os descontos são pessoais e intransferíveis.</p>
            <p>A empresa se reserva no direito de suspender as promoções em dias de evento no Parque, em caso de lotação esgotada, por motivos de manutenção ou força maior. Promoção válida enquanto durarem os estoques.</p>
        </>
    )
}

export default DiscountRulesTourMaravilha;