import React from 'react';

import TermsUse from '../../components/TermsUse/TermsUse';
import { useTranslation } from "react-i18next";

import './TermsOfUse.scss';
import PageDafault from '../../templates/PageDafault';
import { Helmet } from 'react-helmet';
import i18next from 'i18next';

function TermsOfUse() {
  const { t } = useTranslation();

  return (
    <>
    <Helmet>
        <html lang={i18next.language} />
        <title>Termos de Uso | Parque Bondinho</title>
        <meta name="description" content="Antes de utilizar o site do Parque Bondinho Pão de Açúcar, conheça a política e termos de uso. Acesse nosso site e confira!" />
        <meta name="googlebot" content={process.env.REACT_APP_SERVER_ROBOTS} />

        {/* Essential META Tags */}
        <meta property="og:title" content="Termos de Uso | Parque Bondinho" />
        <meta property="og:type" content="TouristAttraction" />
        <meta property="og:image" content={`${process.env.REACT_APP_SERVER_URL}${process.env.REACT_APP_SERVER_LOGO}`} />
        <meta property="og:url" content={process.env.REACT_APP_SERVER_URL} />

        {/* Non-Essential, But Recommended */}
        <meta property="og:description" content="Antes de utilizar o site do Parque Bondinho Pão de Açúcar, conheça a política e termos de uso. Acesse nosso site e confira!" />
        <meta property="og:site_name" content={process.env.REACT_APP_SERVER_NAME} />

        <meta name="robots" content={process.env.REACT_APP_SERVER_ROBOTS} />
        <link rel="canonical" href={process.env.REACT_APP_SERVER_URL} />
      </Helmet>

      <PageDafault>
        <div className="container py-5">
          <h1 className="mb-5 text-center display-2">{t('termsOfUse.title')}</h1>
          <div className="card py-4 px-3">
            <TermsUse />
          </div>
        </div>
      </PageDafault>
    </>
  );
}

export default TermsOfUse;