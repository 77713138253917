import React from "react";

import { Container } from "react-bootstrap";

import FormGuides from "./components/FormAffiliates/FormGuides";


import "./Guides.scss";
import { Helmet } from "react-helmet";
import i18next from "i18next";
import PageDafault from "../../templates/PageDafault";
import { useTranslation } from "react-i18next";


function Guides() {
    const { t } = useTranslation();
    return (
        <>
            <Helmet>
                <html lang={i18next.language} />
                <title>{`Guias | ${process.env.REACT_APP_SERVER_NAME}`}</title>
                <meta name="googlebot" content="noindex, nofollow" />

                {/* Essential META Tags */}
                <meta property="og:title" content={`Guias | ${process.env.REACT_APP_SERVER_NAME}`} />
                <meta property="og:type" content="TravelAgency" />
                <meta property="og:image" content={`${process.env.REACT_APP_SERVER_URL}${process.env.REACT_APP_SERVER_IMG_DEST}`} />
                <meta property="og:url" content={process.env.REACT_APP_SERVER_URL} />

                {/* Non-Essential, But Recommended */}
                <meta property="og:site_name" content={process.env.REACT_APP_SERVER_NAME} />

                <meta name="robots" content="noindex, nofollow" />
                <link rel="canonical" href={process.env.REACT_APP_SERVER_URL} />

            </Helmet>

            <PageDafault>
              
                <div id="bg-form" className="bg-form-affiliates">
                    <Container className="container-content">
                        <div className="w-100 d-flex flex-column align-items-center pb-4">
                            <h3 className="pb-0">
                                {t("affiliates.startTodayTitle")}
                            </h3>
                            <p>
                                {t("affiliates.startTodaySubtitle")}
                            </p>
                        </div>
                        <div>
                            <FormGuides />
                        </div>
                    </Container>
                </div>
                {/* <div id="session-contacts">
                <Footer />
            </div> */}
            </PageDafault>
        </>
    )
}

export default Guides;