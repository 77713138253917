import React from 'react';
import { useTranslation } from 'react-i18next';

import './Partners.scss'

import { Container, Row } from "react-bootstrap";
import Parceiro01 from "../../assets/img/parceiro-01.png"
import Parceiro02 from "../../assets/img/parceiro-02.png"
import Parceiro03 from "../../assets/img/parceiro-03.png"
import Parceiro04 from "../../assets/img/parceiro-04.png"
import Parceiro05 from "../../assets/img/parceiro-05.png"
import Parceiro06 from "../../assets/img/parceiro-06.png"
import Parceiro07 from "../../assets/img/parceiro-07.png"

function Partners() {
  const [t] = useTranslation();
  return (
    <div className='my-outside'>
      <Container className="bg-white " fluid>
        <Container>
          <Row className="my-outside">
            <div className="col-12 px-0 my-inside">
              <h2 className="partners text-center text-uppercase">{t('partners.title')}</h2>
                <div className="d-flex justify-content-around flex-column flex-md-row px-0 px-lg-5 img-partners">
                  <img src={Parceiro01} alt="Parceiro 01" />
                  <img src={Parceiro02} alt="Parceiro 02" />
                  <img src={Parceiro03} alt="Parceiro 03" />
                  <img src={Parceiro04} alt="Parceiro 04" />
                  <img src={Parceiro05} alt="Parceiro 05" />
                  <img src={Parceiro06} alt="Parceiro 06" />
                  <img src={Parceiro07} alt="Parceiro 07" />
                </div>  
            </div>
          </Row>
        </Container>
      </Container>
    </div>
  );
}

export default Partners;