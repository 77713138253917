import { useState, MouseEvent, useEffect } from 'react';
import i18next from 'i18next';

import { Container, Col } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

import BRFlag from '../../../../assets/icons/brazil.svg';
import ESFlag from '../../../../assets/icons/spain.svg';
import ENFlag from '../../../../assets/icons/united-kingdom.svg';

import '../../../../components/Header/NavHeader/NavHeader.scss';

import Logo from '../../../../assets/img/PARQUE_BONDINHO_PAO_DE_ACUCAR_01_BRANCO.png';

export default function HeaderAffiliates() {
  const { t } = useTranslation();
  const [lng, setLng] = useState(i18next.language);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [activeFlag] = useState<any>({ pt: BRFlag, en: ENFlag, es: ESFlag });
  ///Remover header quando scroll chega na altura do footer
  useEffect(() => {
    const header: any = document.getElementById('header');
    const footer: any = document.getElementById('content-footer');
    const body: any = document.querySelector('body');
    const headerClassList: any = header?.classList;
    const footerPosition: any = footer.getBoundingClientRect();

    let timer1 = setTimeout(() => {
      if (headerClassList.contains('hide')) {
        headerClassList.remove('hide');
      }
    }, 50);

    window.addEventListener('scroll', () => {
      if (window.scrollY >= body.offsetHeight - (footerPosition.height + 300)) {
        if (!headerClassList.contains('hide')) {
          headerClassList.add('hide');
        }
      } else {
        if (headerClassList.contains('hide')) {
          headerClassList.remove('hide');
        }
      }
    });

    return () => {
      clearTimeout(timer1);
    };
  }, []);

  const changeLanguage = (e: MouseEvent<HTMLButtonElement>) => {
    var element = (e.target as HTMLButtonElement).value;
    setLng(element);
    i18next.changeLanguage(element);
  };

  ///***Remover header quando scroll chega na altura do footer
  return (
    <>
      <div className="header-sticky" id="header">
        <header id="main-header">
          <div className="header-content">
            <nav className="bg-header bg-header-affiliates navbar navbar-expand navbar-light py-0">
              <Container className="px-0" /* style={{ height: "80px" }} */>
                <Col sm={2}>
                  {/* Logo Site */}
                  <a href="/" className="navbar-brand brand-parceiros">
                    <img
                      alt="Logo Bondinho"
                      className="img-fluid"
                      src={Logo}
                    ></img>
                  </a>
                  {/* Logo Site */}
                </Col>
                <Col
                  sm={10}
                  className="d-flex flex-row justify-content-end align-items-center"
                >
                  <div className="px-0 text-center mx-2 mx-md-3">
                    {/* Language Dropdown */}
                    <div
                      className="navbar-collapse"
                      id="navbarSupportedContent"
                    >
                      <ul
                        className="navbar-nav ml-auto"
                        style={{ maxHeight: '80px', alignItems: 'center' }}
                      >
                        <li className="nav-item dropdown nav-item-c2">
                          <div
                            className="nav-link text-primary text-center p-0 d-flex align-items-center justify-content-center"
                            id="navbarDropdownLang"
                            role="button"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            <img
                              alt=""
                              src={
                                activeFlag[lng] === undefined
                                  ? activeFlag.pt
                                  : activeFlag[lng]
                              }
                              style={{
                                width: '30px',
                                height: '27.5px',
                                display: 'inline',
                              }}
                              className="d-block d-lg-none"
                            />
                            <span className="language-selected">
                              {lng === 'pt'
                                ? t('navHeader.language.portugues')
                                : lng === 'en'
                                ? t('navHeader.language.ingles')
                                : lng === 'es'
                                ? t('navHeader.language.espanhol')
                                : t('navHeader.language.portugues')}
                              <FontAwesomeIcon
                                icon={['fal', 'angle-down']}
                                size="1x"
                                style={{ marginLeft: '10px' }}
                              />
                            </span>
                          </div>

                          <div
                            className="dropdown-menu languages px-4 py-3 rounded"
                            aria-labelledby="navbarDropdownLang"
                            data-dropdown="navbarDropdownMinhaConta"
                            style={{ zIndex: 1040 }}
                          >
                            <button
                              className={lng === 'pt' ? 'pt active' : 'pt'}
                              onClick={changeLanguage}
                              value="pt"
                            >
                              {t('navHeader.language.portugues')}
                            </button>
                            <button
                              className={lng === 'en' ? 'en active' : 'en'}
                              onClick={changeLanguage}
                              value="en"
                            >
                              {t('navHeader.language.ingles')}
                            </button>
                            <button
                              className={lng === 'es' ? 'es active' : 'es'}
                              onClick={changeLanguage}
                              value="es"
                            >
                              {t('navHeader.language.espanhol')}
                            </button>
                          </div>
                        </li>

                        <li className="nav-item active nav-item-c2 acessar">
                          <a
                            className="nav-link text-center d-flex align-items-center"
                            target="_blank"
                            href={`${process.env.REACT_APP_SIG_URL}`}
                            rel="noreferrer"
                          >
                            <span className="acesso-parceiros">
                              {lng === 'en'
                                ? "I'm already a partner"
                                : lng === 'es'
                                ? 'Ya soy un socio'
                                : 'Já sou parceiro'}
                              <FontAwesomeIcon
                                icon={['fal', 'sign-in']}
                                size="1x"
                                style={{ marginLeft: '10px', color: 'red' }}
                              />
                            </span>
                          </a>
                        </li>
                      </ul>
                    </div>
                    {/* Language Dropdown */}
                  </div>
                </Col>
              </Container>
            </nav>
          </div>
        </header>
      </div>
    </>
  );
}
