import React from "react";
import { useTranslation } from "react-i18next";

import "./ReceiptAlert.scss";

//import { Link } from "react-router-dom";

export interface props {
    auth: any;
    option: any;
    textBtn?: any;
}

const ReceiptAlert: React.FC<props> = ({ auth, option, textBtn }: props) => {
    const { t } = useTranslation();
    
    return (
        <div className="receipt-alert modal-body">
            <div>
                <h3>{t("productOptions.alert.title")}</h3>
            </div>
            <p>{t("productOptions.alert.content")}</p>
            <div>
                <button className="btn btn-primary btn-add-cart-modal" style={{ width: "auto", borderRadius: ".65rem" }} onClick={() => auth(option)}>{t("productOptions.alert.btn")}</button>
            </div>
        </div>
    )
}

export default ReceiptAlert;