import React, { useState } from 'react';

import api from '../../services/api';

import { Container, Row, Col } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { Controller, useForm } from "react-hook-form";

import { ErrorMessage } from '@hookform/error-message';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ModalDefault from '../../components/Modal/ModalDefault/ModalDefault';

import '../../App.css';

import './ProfileResetPassword.scss';
import PageDafault from '../../templates/PageDafault';

import { useTranslation } from "react-i18next";

function ProfileResetPassword() {
    const { t } = useTranslation();
    const validated = false;

    var regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()\-_=+{}[\]|;:'",.<>/?])\S{8,}$/;
    var regexLetter = /^(?=.*[A-Za-z]{1})/;
    var regexNumber = /^(?=.*\d)/;
    var regexSymble = /^(?=.*[@$!%*#?&"'-/])/;

    const [sucess, setSucess] = useState<any>("Preencher informações");
    const [log, setLog] = useState<any>(1);

    const [modalContent, setModalContent] = useState<any>();

    const [passwordConfirmation, setPasswordConfirmation] = useState<boolean>(false);
    const [passwordValidation, setPasswordValidation] = useState<boolean>(false);   ///usado nos atributos "isValid" e "isInvald" dos inputs
    const [passwordSize, setPasswordSize] = useState<number>(0);   ///usado para verificar se o tamanho da senha digitada é 8
    const [, setPasswordText] = useState<any>('');  ///usado para a verificação do passord durante a digitação para trocar a cor do feedback quando a condição for atendida

    const [regexValid, setRegexValid] = useState<any>(false);
    const [passwordStr, setPasswordStr] = useState<any>(0);
    const [passwordStrColor, setPasswordStrColor] = useState<any>('#ccc');
    const [passwordStrText, setPasswordStrText] = useState<any>('');
    const [passwordErrorBlur, setPasswordErrorBlur] = useState<any>(false);

    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm();

    const onSubmit = (objData: any) => {

        setModalContent('loading');
        /* setLoading(true) */
        const getAuthenticateAccount = async () => {
            try {
                const { data } = await api.post('/api/LoginSite/AlterPassword', { ///LOGIN API
                    "password": objData.password,
                    "token": window.location.href.split('resetar-senha/')[1],
                });
                if (data.status !== 400) {
                    setSucess(data.data.texto);
                    setModalContent('sucesso');
                    setLog(data.data.log);
                    if (data.data.log === 1) {
                        setSucess(data.data.texto);
                        setModalContent('error');
                        setLog(data.data.log);
                    } else {
                        window.location.href = "/";
                    }
                } else {

                }

            } catch (error: any) {
                setModalContent('error');
                setSucess("Preencher informações")
                setLog(1);
                /* setLoading(false) */
                if (error?.response?.status === 400) {
                    setModalContent('error');
                    setSucess("Preencher informações")
                    setLog(1);
                }
            }
        }

        if (passwordConfirmation === true) {
            if (passwordSize >= 8 && passwordValidation === true) {
                setModalContent('loading');
                setRegexValid(false);
                getAuthenticateAccount();
            } else {
                setRegexValid(true);
                setSucess(t("profileNew.alert01"));
                setModalContent('error');
                setLog(1);
            }
        } else {
            setSucess(t("profileNew.alert02"));
            setModalContent('error');
            setLog(1);
        }

        //getAuthenticateAccount();
    };

    function Confirmation() {
        var password = (document.getElementById('password') as HTMLInputElement).value;
        var passwordConfirmation = (document.getElementById('confirmPassword') as HTMLInputElement).value;

        if (passwordConfirmation !== password) {
            setPasswordConfirmation(false);
        }
        else {
            setPasswordConfirmation(true);
        }
        return true;
    }

    function Validation() {
        var strength: any = 0;
        var password = (document.getElementById('password') as HTMLInputElement).value;
        setPasswordSize(password.length);
        setPasswordText(password);

        if (regexNumber.exec(password)) {
            strength += 1;
        }

        if (regexSymble.exec(password)) {
            strength += 1;
        }

        if (regexLetter.exec(password)) {
            strength += 1;
        }

        if (!regex.exec(password)) {
            setPasswordValidation(false);
        }
        else {
            strength = 4;
            setPasswordValidation(true);
        }

        if (strength === 0) {
            setPasswordStrColor('#ccc');
            setPasswordStrText('');
        } else if (strength === 1) {
            setPasswordStrColor('red');
            setPasswordStrText(t("profileNew.weak"));
        } else if (strength === 2 || strength === 3) {
            setPasswordStrColor('#e0e00d');
            setPasswordStrText(t("profileNew.medium"));
        } else {
            setPasswordStrColor('green');
            setPasswordStrText(t("profileNew.strong"));
        }

        setPasswordStr(strength);

        return true;
    }

    const verifyBlurPassword = () => {
        var password = (document.getElementById('password') as HTMLInputElement).value;
        var passwordConfirmation = (document.getElementById('confirmPassword') as HTMLInputElement).value;

        if (passwordConfirmation !== password && passwordConfirmation !== '') {
            setPasswordErrorBlur(true);
        } else {
            setPasswordErrorBlur(false);
        }
    };

    return (
        <>
            <PageDafault>
                <div className='container'>

                    <Container className="profile-container" fluid>
                        <Form noValidate validated={validated} onSubmit={handleSubmit(onSubmit)}>
                            <Row>
                                <div>
                                    <h2 className="display-5 mb-4">Mudar Senha</h2>
                                </div>
                            </Row>
                            <Row className="mb-3">
                                <Form.Group as={Col} md="6" className="mb-3" controlId="password">
                                    <Form.Label>
                                        Nova Senha
                                    </Form.Label>
                                    <Controller
                                        control={control}
                                        name="password"
                                        rules={{ required: { value: true, message: 'Por favor, informe a nova senha' } }}
                                        render={({ field }: any) => (
                                            <Form.Control
                                                {...field}
                                                type="password"
                                                variant="standard"
                                                margin="normal"
                                                onInput={() => { Validation(); Confirmation(); verifyBlurPassword() }}
                                                maxLength={20}
                                                autoComplete='new-password'
                                                required
                                            />
                                        )}
                                    />
                                    <ErrorMessage
                                        errors={errors}
                                        name="password"
                                        render={({ message }) => <div style={{ width: "100%", backgroundColor: "#f8d7da", padding: "0 0.75rem" }}><small style={{ color: "red" }}>{message}</small></div>}
                                    />
                                    <div className="password-strength-container row d-flex justify-content-evenly">
                                        <div className="password-strength col-3" style={{ borderColor: `${passwordStr >= 1 ? passwordStrColor : ''}` }}></div>
                                        <div className="password-strength col-3" style={{ borderColor: `${passwordStr >= 2 ? passwordStrColor : ''}` }}></div>
                                        <div className="password-strength col-3" style={{ borderColor: `${passwordStr >= 3 ? passwordStrColor : ''}` }}></div>
                                        <div className="password-strength col-3" style={{ borderColor: `${passwordStr >= 4 ? passwordStrColor : ''}` }}></div>
                                    </div>
                                    <div className="d-flex justify-content-center" style={{ color: `${passwordStrColor}` }}>
                                        {passwordStrText}
                                    </div>
                                </Form.Group>
                                <Form.Group as={Col} md="6" className="mb-3" controlId="confirmPassword">
                                    <Form.Label>
                                        Confirmar Senha
                                    </Form.Label>
                                    <Controller
                                        control={control}
                                        name="confirmPassword"
                                        rules={{ required: { value: true, message: 'Por favor, confirme a nova senha' } }}
                                        render={({ field }: any) => (
                                            <Form.Control
                                                {...field}
                                                type="password"
                                                variant="standard"
                                                margin="normal"
                                                defaultValue=""
                                                onInput={() => { Confirmation(); verifyBlurPassword() }}
                                                maxLength={20}
                                                autoComplete='new-password'
                                                required
                                            />
                                        )}
                                    />
                                    <ErrorMessage
                                        errors={errors}
                                        name="confirmPassword"
                                        render={({ message }) => <div style={{ width: "100%", backgroundColor: "#f8d7da", padding: "0 0.75rem" }}><small style={{ color: "red" }}>{message}</small></div>}
                                    />
                                    {passwordErrorBlur === true ? <small style={{ color: "red" }}>{t("profileNew.alert02")}</small> : <></>}
                                </Form.Group>
                                <Form.Group as={Col} md="12" controlId="">
                                    <small style={{ color: regexValid === true ? "red" : "black", fontSize: ".8rem", opacity: ".7" }}>{t("profileNew.textPass")}</small>
                                </Form.Group>
                            </Row>
                            <Row className="d-flex justify-content-end mx-0 mt-5 mb-4">
                                <ModalDefault
                                    name="Resetar"
                                    header="no"
                                    class="modal-confirm"
                                    btnLeave="yes"
                                    modalContent={modalContent}
                                >
                                    <div className='modal-body text-center'>
                                        {
                                            modalContent !== "loading"
                                                ?
                                                <>
                                                    <div>
                                                        {
                                                            log === 1 || log === "1"
                                                                ?
                                                                <FontAwesomeIcon
                                                                    icon={["fal", "times-circle"]}
                                                                    size="5x"
                                                                    style={{ fontSize: "7.5em" }}
                                                                    color='#ff6600'
                                                                />
                                                                :
                                                                <FontAwesomeIcon
                                                                    icon={["fal", "check-circle"]}
                                                                    size="5x"
                                                                    style={{ fontSize: "7.5em" }}
                                                                    color='#ff6600'
                                                                />
                                                        }
                                                    </div>
                                                    <div>
                                                        {sucess}
                                                    </div>
                                                    <div></div>
                                                </>
                                                :
                                                <>
                                                    <div className="text-center">
                                                        <div className="load"></div>
                                                    </div>
                                                    <div>
                                                        <span>Processando</span>
                                                    </div>
                                                    <div></div>
                                                </>
                                        }
                                    </div>
                                </ModalDefault>
                                {/* <Button type="submit" className="form-button w-auto px-4 py-2">{loading === true ? <div className="load small white"></div> : "Cadastrar"}</Button> */}
                            </Row>
                        </Form>
                    </Container>
                </div>
            </PageDafault>
        </>
    );
}

export default ProfileResetPassword;