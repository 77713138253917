import React, { Key, useEffect, useState } from "react";
import api from "../../services/api";

import { useTranslation } from "react-i18next";

import Form from "react-bootstrap/Form";
import Select from 'react-select';

export interface propPlus {
  propsField: any;
  propsLabel: any;
  propsErrors: any;
  getTheValue?: any;
  defaultCountry?: any;
  setCountry?: any;
}

const GetCountrys: React.FC<propPlus> = ({
  propsField,
  propsLabel,
  propsErrors,
  getTheValue = false,
  defaultCountry = null,
  setCountry
}: propPlus) => {
  const [info, setInfo] = useState<any>(null);
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

  const { t } = useTranslation();

  useEffect(() => {
    const token = localStorage.getItem("c2tourAuth") || "{}";
    const config = {
      headers: { Authorization: `Bearer ${JSON.parse(token).token}` },
    };
    async function listSuppliers() {
      try {
        const { data } = await api.get(`${process.env.REACT_APP_SERVER_URL_API}/api/DropDown/GetCountrysWithShortName`, config);
        if (data.status !== 400) {
          setInfo(data.data);
          setCountry(data.data.find((elem: any) => elem.description === defaultCountry || elem.id === defaultCountry).id || '');
        }
      } catch (error: any) {
        /* if (error.response.status === 401) {
          window.location.href = window.location.origin + "/";
        } */
      }
    }
    listSuppliers();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onMenuOpen = () => {
    if (isMenuOpen === false) {
      setIsMenuOpen(true);
    } else {
      setIsMenuOpen(false);
    }
  }

  const handleChange = (options: any) => {
    setCountry(getTheValue === true ? options.id : options.description)
  };

  if (info !== null) {
    return (
      <>
        <>
          <Select
            aria-labelledby="aria-label"
            inputId="aria-example-input"
            name="aria-live-color"
            onMenuOpen={onMenuOpen}
            onMenuClose={onMenuOpen}
            onChange={handleChange}
            options={Array.from(info)}
            getOptionLabel={(option: any) => `${option.description}`}
            defaultValue={info.find((elem: any) => elem.description === defaultCountry || elem.id === defaultCountry)/*        {description: "Malaysia", id: "MY"} */}
          />
        </>

        {/* <Form.Select
          defaultValue={{description: 'Malaysia', id: 'MY'}} 
          style={{padding: "8px 12px"}}
          {...propsField}
          required>
          <option value="" disabled>
            {t("checkoutSteps.nacionality")}
          </option>
          {info.length > 0
            ? info.map((info: any, index: Key) => (
              <option key={index} value={getTheValue === true ? info.id : info.description} selected={info.description === 'Malaysia'}>
                {info.description}
              </option>
            ))
            : ""}
        </Form.Select> */}
        {/* <div className="form-group py-2 mr-2">
          <label htmlFor="formPais" className="label-txt"></label>
          <select
          {...propsField}
          aria-invalid={propsErrors?.country ? "true" : ""}
          className="form-control"
          label={propsLabel}
          as="select"
          variant="standard"
          margin="normal"
          required
        >
          <option value="" disabled>
            ---- Select an option ----
          </option>
          {info.length > 0
            ? info.map((info: any, index: Key) => (
                <option key={index} value={info.id}>
                  {info.description}
                </option>
              ))
            : ""}
        </select>
        </div> */}
      </>
    );
  } else {
    return (
      <>
        <select>
          <option value="" disabled></option>
        </select>
      </>
    );
  }
};

export default GetCountrys;
