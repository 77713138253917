/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from "react";

import { Row, Col, InputGroup, Modal } from 'react-bootstrap';

import { Controller, useForm } from "react-hook-form";
import InputMask from "react-input-mask";
//import { ErrorMessage } from '@hookform/error-message';
import { useTranslation } from "react-i18next";
import Form from 'react-bootstrap/Form';

import SingleCalendar from "../../../../../../components/Calendar/SingleCalendarFutureDate";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ErrorMessage } from "@hookform/error-message";
import GetStates from "../../../../../../components/C2Points/GetStates";
import GetCitysByState from "../../../../../../components/C2Points/GetCitysByState";

export interface propForm {
    action: any
};

const FormGuidePersonal: React.FC<propForm> = React.memo(({
    action
}: propForm) => {
    const { t } = useTranslation();
    const [date, setDate] = useState<any>([]);
    //const [cpf, setCpf] = useState<any>([]);

    const [cpfValidation, setCpfValidation] = useState<boolean>(false);   ///usado nos atributos "isValid" e "isInvald" do cpf
    const [passwordStr, setPasswordStr] = useState<any>(0);
    const [passwordStrColor, setPasswordStrColor] = useState<any>('#ccc');
    const [passwordStrText, setPasswordStrText] = useState<any>('');
    const [passwordValidation, setPasswordValidation] = useState<boolean>(false);   ///usado nos atributos "isValid" e "isInvald" dos inputs

    const [modalShow, setModalShow] = useState<any>(false);
    const [modalMessage, setModalMessage] = useState<any>('Carregando');
    const [modalLog, setModalLog] = useState<any>(null);

    var regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()\-_=+{}[\]|;:'",.<>/?])\S{8,}$/;
    var regexLetter = /^(?=.*[A-Za-z]{1})/;
    var regexNumber = /^(?=.*\d)/;
    var regexSymble = /^(?=.*[@$!%*#?&])/;

    const validated = false;

    const {
        control,
        handleSubmit,
        watch,
        setValue,
        getValues,
        reset,
        formState: { errors },
    } = useForm({
        defaultValues: {
            name: '',
            surname: '',
            cadasturCode: '',
            cadasturExpirationDate: '',
            workingState: '',
            workingCity: '',
            email: '',
            cpf: '',
        }
    });

    const watchState: any = watch(`workingState`, '');

    useEffect(() => {
        setValue('workingCity', '');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [watchState])

    useEffect(() => {
        Validation();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    function Validation() {
        var strength: any = 0;
        var password = (document.getElementById('password') as HTMLInputElement)?.value;

        if (regexNumber.exec(password)) {
            strength += 1;
        }

        if (regexSymble.exec(password)) {
            strength += 1;
        }

        if (regexLetter.exec(password)) {
            strength += 1;
        }

        if (!regex.exec(password)) {
            setPasswordValidation(false);
        } else {
            strength = 4;
            setPasswordValidation(true);
        }

        if (strength === 0) {
            setPasswordStrColor('#ccc');
            setPasswordStrText('');
        } else if (strength === 1) {
            setPasswordStrColor('red');
            setPasswordStrText(t("guides.weak"));
        } else if (strength === 2 || strength === 3) {
            setPasswordStrColor('#e0e00d');
            setPasswordStrText(t("guides.medium"));
        } else {
            setPasswordStrColor('green');
            setPasswordStrText(t("guides.strong"));
        }

        setPasswordStr(strength);

        return true;

    }
    

    const onSubmit = (data: any) => {
        /* if (data.phone.replaceAll("_", "").replaceAll("(", "").replaceAll(")", "").replaceAll("-", "").replaceAll(" ", "").length < 10) {
            setModalShow(true);
            setModalMessage("Por favor, preencha um telefone válido!");
            setModalLog(1);
            return;
        } */


        if (date === undefined) {
            setModalShow(true);
            setModalMessage("Por favor, preencha a data de nascimento!");
            setModalLog(1);
            return;
        }
       

        if (CpfValidator(data.cpf) === false) {
            setModalShow(true);
            setModalMessage("CPF inválido!!");
            setModalLog(1);
            return;
        }
        data.cadasturExpirationDate  = date[0];  
       
        action(data);

    }

    function CpfValidator(strCPF: any) {
        strCPF = strCPF.replace(/\./g, '').replace(/-/g, '').replace(/_/g, '');  //remove ".", "-" e "-" que a máscara coloca
        var Soma;
        var Resto;
        Soma = 0;

        if (strCPF === "00000000000") {
            setCpfValidation(false)
            return false;
        }

        for (var i: any = 1; i <= 9; i++) Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i);
        Resto = (Soma * 10) % 11;

        if ((Resto === 10) || (Resto === 11)) Resto = 0;
        if (Resto !== parseInt(strCPF.substring(9, 10))) {
            setCpfValidation(false)
            return false;
        }

        Soma = 0;
        for (i = 1; i <= 10; i++) Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (12 - i);
        Resto = (Soma * 10) % 11;

        if ((Resto === 10) || (Resto === 11)) Resto = 0;
        if (Resto !== parseInt(strCPF.substring(10, 11))) {
            setCpfValidation(false)
            return false;
        }

        setCpfValidation(true)
        return true;
    }


    return (
        <>
            <div>
                <div className="d-flex justify-content-end">
                    <small style={{ color: 'red' }}>*{t("guides.warning")}</small>
                </div>

                <Form noValidate validated={validated} onSubmit={handleSubmit(onSubmit)} autoComplete="false">
                    <Row>
                        <Form.Group as={Col} md={6} controlId="formResourcesType" className="mb-3">
                            <Form.Label>
                                {t("guides.name")}
                            </Form.Label>
                            <Controller
                                control={control}
                                name="name"
                                rules={{ required: { value: true, message: t("guides.formError") } }}
                                render={({ field }: any) => (
                                    <Form.Control
                                        {...field}
                                        aria-invalid={errors?.name ? "true" : ""}
                                        variant="standard"
                                        margin="normal"
                                        autoComplete="new-password"
                                    />
                                )}
                            />
                            <ErrorMessage
                                errors={errors}
                                name="name"
                                render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                            />
                        </Form.Group>
                        <Form.Group as={Col} md={6} controlId="formVehiclesType" className="mb-3">
                            <Form.Label>
                                {t("guides.lastName")}
                            </Form.Label>
                            <Controller
                                control={control}
                                name="surname"
                                rules={{ required: { value: true, message: t("guides.formError") } }}
                                render={({ field }: any) => (
                                    <Form.Control
                                        {...field}
                                        aria-invalid={errors?.surname ? "true" : ""}
                                        variant="standard"
                                        margin="normal"
                                        autoComplete="new-password"
                                    />
                                )}
                            />
                            <ErrorMessage
                                errors={errors}
                                name="surname"
                                render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                            />
                        </Form.Group>
                    </Row>
                    <Row>
                    <Form.Group as={Col} md={6} controlId="formVehiclesType" className="mb-3">
                            <Form.Label>
                                {t("guides.cadasturNumber")}
                            </Form.Label>
                            <Controller
                                control={control}
                                name="cadasturCode"
                                rules={{ required: { value: true, message: t("guides.formError") } }}
                                render={({ field }: any) => (
                                    <Form.Control
                                        {...field}                                        
                                        aria-invalid={errors?.cadasturCode ? "true" : ""}
                                        variant="standard"
                                        margin="normal"
                                        autoComplete="new-password"
                                    />
                                )}
                            />
                            <ErrorMessage
                                errors={errors}
                                name="cadasturCode"
                                render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                            />
                        </Form.Group>
                        <Form.Group as={Col} md={6} className="d-flex flex-column mb-3">
                            <Form.Label>
                                {t("guides.cadasturDate")}
                            </Form.Label>
                            <InputGroup
                                className=""
                                size="sm"
                                placeholder="Quando?"
                            >
                                <SingleCalendar setValue={setDate} defaultTime={date} />
                            </InputGroup>
                        </Form.Group>
                    </Row>
                    
                    <Row>
                        <Form.Group as={Col} md={6} controlId="formVehiclesType" className="mb-3">
                            <Form.Label>
                                {t("guides.cpf")}
                            </Form.Label>
                            <Controller
                                control={control}
                                name="cpf"
                                rules={{ required: { value: true, message: t("guides.formError") } }}
                                render={({ field }: any) => (
                                    <InputMask mask="999.999.999-99" value={field?.value} onChange={field?.onChange} onInput={(e: any) => { CpfValidator(e.target.value) }}>
                                        {(inputProps: any) => (
                                            <Form.Control
                                                {...inputProps}
                                                aria-invalid={errors?.cpf ? "true" : ""}
                                                variant="standard"
                                                margin="normal"
                                                autoComplete="new-password"
                                            />
                                        )}
                                    </InputMask>
                                )}
                            />
                            <ErrorMessage
                                errors={errors}
                                name="cpf"
                                render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                            />
                        </Form.Group>
                        <Form.Group as={Col} md={6} controlId="formVehiclesType" className="mb-3">
                            <Form.Label>
                                {t("guides.email")}
                            </Form.Label>
                            <Controller
                                control={control}
                                name="email"
                                rules={{ required: { value: true, message: t("guides.formError") },
                                pattern: {
                                    value: /\S+@\S+.\S+/,
                                    message: t("guides.emailError"),
                               }, }}
                                render={({ field }: any) => (
                                    <Form.Control
                                        {...field}
                                        type="email"
                                        aria-invalid={errors?.email ? "true" : ""}
                                        variant="standard"
                                        margin="normal"
                                        autoComplete="new-password"
                                    />
                                )}
                            />
                            <ErrorMessage
                                errors={errors}
                                name="email"
                                render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                            />
                        </Form.Group>
                    </Row>
                    
                    <Row>                       
                        <Form.Group as={Col} md={6} controlId="formVehiclesType" className="mb-3">
                            <Form.Label>
                                {t("guides.workingState")}*
                            </Form.Label>
                            <Controller
                                control={control}
                                name="workingState"
                                rules={{ required: { value: true, message: t("guides.formError") } }}
                                render={({ field }: any) => (
                                    <GetStates propsField={field} propsErrors={errors} defaultValue={''} setValue={setValue} setValueString={'workingState'} />
                                )}
                            />
                            <ErrorMessage
                                errors={errors}
                                name="workingState"
                                render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                            />
                        </Form.Group>
                        <Form.Group as={Col} md={6} controlId="formResourcesType" className="mb-3">
                            <Form.Label className="notranslate">
                                {t("guides.workingCity")}*
                            </Form.Label>
                            <Controller
                                control={control}
                                name="workingCity"
                                rules={{ required: { value: true, message: t("guides.formError") } }}
                                render={({ field }: any) => (
                                    <GetCitysByState propsField={field} propsErrors={errors} uf={watchState?.id} defaultValue={''} setValue={setValue} setValueString={'workingCity'}/>
                                )}
                            />
                            <ErrorMessage
                                errors={errors}
                                name="workingCity"
                                render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                            />
                        </Form.Group>
                    </Row>
                   
                    <Row className="d-flex justify-content-end pt-5">
                        <Col md={3}>
                            <button type="submit" className="btn btn-primary">{t("guides.continue")}</button>
                        </Col>
                    </Row>

                </Form>
                <Modal
                    className="modalAuth modal-validation"
                    show={modalShow}
                    onHide={() => {
                        setModalLog(null);
                        setModalMessage("Carregando");
                        setModalShow(false);
                    }}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <>
                        <Modal.Body
                            className="modal-body text-center sucess-pos modal-default d-flex flex-column justify-content-evenly px-4"
                        >
                            {modalLog === null ? (
                                <>
                                    <div className="loading-modal">
                                        <div className="load"></div>
                                    </div>
                                </>
                            ) : (
                                <div>
                                    {modalLog === 0 ? (
                                        <FontAwesomeIcon
                                            icon={["fal", "check"]}
                                            size="5x"
                                        />
                                    ) : (
                                        <FontAwesomeIcon
                                            icon={["fal", "times"]}
                                            size="5x"
                                        />
                                    )}
                                </div>
                            )}
                            <div>
                                <h5 className="mb-1">{modalLog === 1 ? "Erro na validação!" : modalLog === 0 ? "Sucesso!" : "Esperando validação!"}</h5>
                                <small style={{ color: "#707070" }}>{modalMessage}</small>
                            </div>
                            <div className="d-flex justify-content-center pt-3">
                                <button
                                    onClick={() => {
                                        setModalLog(null);
                                        setModalMessage("Carregando");
                                        setModalShow(false);
                                    }}
                                    className="btn btn-primary mx-2 w-25"
                                >
                                    Confirmar
                                </button>
                            </div>
                        </Modal.Body>
                    </>
                </Modal>
            </div>
        </>
    )
});

export default FormGuidePersonal;