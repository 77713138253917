import i18next from "i18next";

import ExchangePolicyPT from "./components/ExchangePolicyPT";
import ExchangePolicyEN from "./components/ExchangePolicyEN";
import ExchangePolicyES from "./components/ExchangePolicyES";

function ExchangePolicy() {
    const lng = i18next.language === "pt" ? "BR" : i18next.language === "en" ? "EN" : i18next.language === "es" ? "ES" : 'BR';

    return (
        <>
            {
                lng === "BR"
                    ?
                    <ExchangePolicyPT />
                    :
                    lng === "EN"
                        ?
                        <ExchangePolicyEN />
                        :
                        lng === "ES"
                            ?
                            <ExchangePolicyES />
                            :
                            <ExchangePolicyPT />
            }
        </>
    )
}

export default ExchangePolicy;