import React from 'react';

function ModalAlertEN() {
  const [christmasAlert, setChristmasAlert] = React.useState<boolean>();

  React.useEffect(() => {
    let date = new Date().toLocaleString('pt-BR');

    let dateNow = date
      .split(' ')[0]
      .split('/')
      .reverse()
      .join('')
      .replaceAll(',', '');

    if (`${dateNow}` <= '20231225') {
      setChristmasAlert(true);
    } else {
      setChristmasAlert(false);
    }
  }, []);

  return (
    <>
      <div className="mb-3 en-text">
        <p>
          <strong>
            Parque Bondinho Pão de Açúcar has some{' '}
            <span>important information</span> for you.
          </strong>
        </p>
        <p>
          <strong>Hours of Operation</strong>
        </p>

        {christmasAlert === true ? (
          <div>
            <p>
              <strong>
                12/24 (Christmas Eve): <span>8 AM</span> to <span>5 PM</span>.
                Last boarding at <span>4 PM</span>.
              </strong>
            </p>
            <p>
              <strong>
                12/25 (Christmas): <span>8 AM</span> to <span>8 PM</span>. Last
                boarding at <span>6:30 PM</span>.
              </strong>
            </p>
          </div>
        ) : (
          <></>
        )}

        <p>
          <strong>
            12/31 (New Year's Eve): <span>8 AM</span> to <span>5 PM</span>. Last
            boarding at <span>4 PM</span>.
          </strong>
        </p>
        <p>
          <strong>
            01/01 (New Year): <span>9:30 AM</span> to <span>9 PM</span>. Last
            boarding at <span>7:30 PM</span>.
          </strong>
        </p>
        <p>
          <strong>
            From 12/26 to 1/7, opening hours will be from <span>8 AM </span>
            to <span>9 PM</span>. Last boarding at <span>7:30 PM</span>.
          </strong>
        </p>
        <p>
          <strong>See you soon!</strong>
        </p>
      </div>
      {/* <div>
                <p>Our dream has already begun!</p>
                <p>The Zipline, the long-awaited new attraction at Parque Bondinho, is coming. Therefore, during your visit, you may notice or hear the works of the Zipline that will have the most beautiful view in the world. </p>
                <p>We appreciate your understanding and hope to see you soon to enjoy a breathtaking new experience!</p>
            </div> */}
    </>
  );
}

export default ModalAlertEN;
