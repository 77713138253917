import React, { useState } from 'react';

import api from '../../services/api';

import { Container, Row, Col } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { Controller, useForm } from "react-hook-form";
//import Button from 'react-bootstrap/Button';

import { ErrorMessage } from '@hookform/error-message';


import '../../App.css';

import ModalDefault from '../../components/Modal/ModalDefault/ModalDefault';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from "react-i18next";

import './ProfilePassword.scss';
import PageDafault from '../../templates/PageDafault';
import monitoringAPI from '../../services/monitoring';

type Request = { 
    password: string,
    oldPassword: string
}

function ProfilePassword() {
    const { t } = useTranslation();
    const validated = false;

    const [sucess, setSucess] = useState<any>();
    const [icon, setIcon] = useState<any>();

    const [modalContent, setModalContent] = useState<any>();

    const [errorMessage, setErrorMessage] = useState<any>(null);

    const [loading, setLoading] = useState<any>(false);

    const [passwordStr, setPasswordStr] = useState<any>(0);
    const [passwordStrColor, setPasswordStrColor] = useState<any>('#ccc');
    const [passwordStrText, setPasswordStrText] = useState<any>('');
    const [passwordErrorBlur, setPasswordErrorBlur] = useState<any>(false);

    const [passwordConfirmation, setPasswordConfirmation] = useState<boolean>(false);
    const [passwordValidation, setPasswordValidation] = useState<boolean>(false);   ///usado nos atributos "isValid" e "isInvald" dos inputs
    const [passwordSize, setPasswordSize] = useState<number>(0);   ///usado para verificar se o tamanho da senha digitada é 8
    const [passwordText, setPasswordText] = useState<any>('');  ///usado para a verificação do passord durante a digitação para trocar a cor do feedback quando a condição for atendida
    const [regexValid, setRegexValid] = useState<any>(false);

    var regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()\-_=+{}[\]|;:'",.<>/?])\S{8,}$/;
    var regexLetter = /^(?=.*[A-Za-z]{1})/;
    var regexNumber = /^(?=.*\d)/;
    var regexSymble = /^(?=.*[@$!%*#?&"'-/])/;

    if (false) { console.log(errorMessage, regexValid, passwordText) }

/*     function Confirmation() {
        var password = (document.getElementById('password') as HTMLInputElement).value;
        var passwordConfirmation = (document.getElementById('confirmPassword') as HTMLInputElement).value;

        if (passwordConfirmation !== password) {
            setPasswordConfirmation(false);
        }
        else {
            setPasswordConfirmation(true);
        }

        return true;
    }

    function Validation() {
        var password = (document.getElementById('password') as HTMLInputElement).value;
        setPasswordSize(password.length);
        setPasswordText(password);
        if (!regex.exec(password)) {
            setPasswordValidation(false);
        }
        else {
            setPasswordValidation(true);
        }
        return true;
    } */


    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm();

    const onSubmit = (objData: any) => {
        const token = localStorage.getItem('bpasiteAuth') || '{}';
        const config = {
            headers: { 'Authorization': `Bearer ${JSON.parse(token)?.token?.token}` },
        };

        setModalContent('loading');
        //setLoading(true);
        const getAuthenticateAccount = async () => {
            let req: Request = { 
                "password": objData.password,
                "oldPassword": objData.oldPassword
            }

            try {
                const { data } = await api.post('/api/LoginSite/UpdatePassword', req, config);
                if (data.status !== 400) {
                    if (data.statusCode === 400) {
                        setSucess(data.errorMessage)
                        setErrorMessage(data.errorMessage);
                        setIcon(false)
                        setModalContent('error');
                        monitoringAPI(400, 'LoginSite', 'UpdatePassword', data?.errorMessage || 'Retornado status diferente de 200', '', '/api/LoginSite/UpdatePassword', req);
                    } else {
                        setModalContent('sucesso');
                        setSucess(data.data.texto)
                        setIcon(true);
                        setLoading(false);
                        monitoringAPI(200, 'LoginSite', 'UpdatePassword', data?.data?.texto || 'Sucesso', '', '/api/LoginSite/UpdatePassword', req);
                        //window.location.href = "/login";
                    }
                } else {
                    monitoringAPI(400, 'LoginSite', 'UpdatePassword', data?.errorMessage || 'Retornado status diferente de 200', '', '/api/LoginSite/UpdatePassword', req);
                }
            } catch (error: any) {
                monitoringAPI(400, 'LoginSite', 'UpdatePassword', error?.response?.data?.errorMessage || 'Retornado status diferente de 200', '', '/api/LoginSite/UpdatePassword', req);
                setLoading(false);
                setModalContent('error');
                if (error?.response?.status === 400) {
                    setModalContent('error');
                }else if (error?.response?.status === 401) {
                    window.location.href = window.location.origin + '/login';
                }
            }
        }

        if (passwordConfirmation === true) {
            if (passwordSize >= 8 && passwordValidation === true) {
                setLoading(true)
                setRegexValid(false);
                getAuthenticateAccount();
            } else {
                setRegexValid(true);
                setSucess(t("profileNew.alert01"));
                setModalContent('error');
                setIcon(false);
            }
        } else {
            setSucess(t("profileNew.alert02"));
            setModalContent('error');
            setIcon(false);
        }
    };

    function Confirmation() {
        var password = (document.getElementById('password') as HTMLInputElement).value;
        var passwordConfirmation = (document.getElementById('confirmPassword') as HTMLInputElement).value;

        if (passwordConfirmation !== password) {
            setPasswordConfirmation(false);
        }
        else {
            setPasswordConfirmation(true);
        }

        return true;
    }

    function Validation() {
        var strength: any = 0;
        var password = (document.getElementById('password') as HTMLInputElement).value;
        setPasswordSize(password.length);
        setPasswordText(password);

        if (regexNumber.exec(password)) {
            strength += 1;
        }

        if (regexSymble.exec(password)) {
            strength += 1;
        }

        if (regexLetter.exec(password)) {
            strength += 1;
        }

        if (!regex.exec(password)) {
            setPasswordValidation(false);
        }
        else {
            strength = 4;
            setPasswordValidation(true);
        }

        if (strength === 0) {
            setPasswordStrColor('#ccc');
            setPasswordStrText('');
        } else if (strength === 1) {
            setPasswordStrColor('red');
            setPasswordStrText(t("profileNew.weak"));
        } else if (strength === 2 || strength === 3) {
            setPasswordStrColor('#e0e00d');
            setPasswordStrText(t("profileNew.medium"));
        } else {
            setPasswordStrColor('green');
            setPasswordStrText(t("profileNew.strong"));
        }

        setPasswordStr(strength);

        return true;
    }

    const verifyBlurPassword = () => {
        var password = (document.getElementById('password') as HTMLInputElement).value;
        var passwordConfirmation = (document.getElementById('confirmPassword') as HTMLInputElement).value;

        if (passwordConfirmation !== password && passwordConfirmation !== '') {
            setPasswordErrorBlur(true);
        } else {
            setPasswordErrorBlur(false);
        }
    };

    return (
        <>
            <PageDafault>
                <div className='container'>

                    <Container className="profile-container" fluid>
                        <Form noValidate validated={validated} onSubmit={handleSubmit(onSubmit)}>
                            <Row>
                                <div>
                                    <h2 className="display-5 mb-4">{t("profileNew.changePassword.title")}</h2>
                                </div>
                            </Row>
                            <Row className="mb-3">
                                <Form.Group as={Col} md="6" className="mb-3" controlId="">
                                    <Form.Label>
                                        {t("profileNew.changePassword.passActual")}
                                    </Form.Label>
                                    <Controller
                                        control={control}
                                        name="oldPassword"
                                        rules={{ required: { value: true, message: t("profileNew.changePassword.errorPassActual") } }}
                                        render={({ field }: any) => (
                                            <Form.Control
                                                {...field}
                                                type="password"
                                                variant="standard"
                                                margin="normal"
                                                required
                                            />
                                        )}
                                    />
                                    <ErrorMessage
                                        errors={errors}
                                        name="oldPassword"
                                        render={({ message }) => <div style={{ width: "100%", backgroundColor: "#f8d7da", padding: "0 0.75rem" }}><small style={{ color: "red" }}>{message}</small></div>}
                                    />
                                </Form.Group>
                            </Row>
                            <Row className="mb-3">
                                <Form.Group as={Col} md="6" className="mb-3" controlId="password">
                                    <Form.Label>
                                        {t("profileNew.changePassword.passNew")}
                                    </Form.Label>
                                    <Controller
                                        control={control}
                                        name="password"
                                        rules={{ required: { value: true, message: t("profileNew.changePassword.errorPassNew") } }}
                                        render={({ field }: any) => (
                                            <Form.Control
                                                {...field}
                                                type="password"
                                                variant="standard"
                                                margin="normal"
                                                onInput={() => { Validation(); Confirmation(); verifyBlurPassword() }}
                                                maxLength={20}
                                                autoComplete='new-password'
                                                required
                                            />
                                        )}
                                    />
                                    <ErrorMessage
                                        errors={errors}
                                        name="password"
                                        render={({ message }) => <div style={{ width: "100%", backgroundColor: "#f8d7da", padding: "0 0.75rem" }}><small style={{ color: "red" }}>{message}</small></div>}
                                    />
                                    <div className="password-strength-container row d-flex justify-content-evenly">
                                        <div className="password-strength col-3" style={{ borderColor: `${passwordStr >= 1 ? passwordStrColor : ''}` }}></div>
                                        <div className="password-strength col-3" style={{ borderColor: `${passwordStr >= 2 ? passwordStrColor : ''}` }}></div>
                                        <div className="password-strength col-3" style={{ borderColor: `${passwordStr >= 3 ? passwordStrColor : ''}` }}></div>
                                        <div className="password-strength col-3" style={{ borderColor: `${passwordStr >= 4 ? passwordStrColor : ''}` }}></div>
                                    </div>
                                    <div className="d-flex justify-content-center" style={{ color: `${passwordStrColor}` }}>
                                        {passwordStrText}
                                    </div>
                                </Form.Group>
                                <Form.Group as={Col} md="6" className="mb-3" controlId="confirmPassword">
                                    <Form.Label>
                                        {t("profileNew.changePassword.passConfirm")}
                                    </Form.Label>
                                    <Controller
                                        control={control}
                                        name="confirmPassword"
                                        rules={{ required: { value: true, message: t("profileNew.changePassword.errorPassConfirm") } }}
                                        render={({ field }: any) => (
                                            <Form.Control
                                                {...field}
                                                type="password"
                                                variant="standard"
                                                margin="normal"
                                                defaultValue=""
                                                onInput={() => { Confirmation(); verifyBlurPassword() }}
                                                maxLength={20}
                                                autoComplete='new-password'
                                                required
                                            />
                                        )}
                                    />
                                    <ErrorMessage
                                        errors={errors}
                                        name="confirmPassword"
                                        render={({ message }) => <div style={{ width: "100%", backgroundColor: "#f8d7da", padding: "0 0.75rem" }}><small style={{ color: "red" }}>{message}</small></div>}
                                    />
                                    {passwordErrorBlur === true ? <small style={{ color: "red" }}>{t("profileNew.alert02")}</small> : <></>}
                                </Form.Group>
                                <Form.Group as={Col} md="12" controlId="">
                                    <small style={{ color: regexValid === true ? "red" : "black", fontSize: ".8rem", opacity: ".7" }}>{t("profileNew.textPass")}</small>
                                </Form.Group>
                            </Row>
                            <Row className="d-flex justify-content-end mx-0 mt-5 mb-4">
                                <ModalDefault
                                    name={t("profileNew.changePassword.btn")}
                                    header="no"
                                    class="modal-confirm"
                                    btnLeave="yes"
                                    modalContent={modalContent}
                                    loading={loading}
                                    setLoading={setLoading}
                                    sucess={sucess}
                                >
                                    <div className='modal-body text-center'>
                                        {
                                            modalContent !== "loading"
                                                ?
                                                <>
                                                    <div>
                                                        {icon === true || sucess === "Alterado com sucesso"
                                                            ?
                                                            <FontAwesomeIcon
                                                                icon={["fal", "check-circle"]}
                                                                size="5x"
                                                                style={{ fontSize: "7.5em" }}
                                                            />
                                                            :
                                                            <FontAwesomeIcon
                                                                icon={["fal", "times-circle"]}
                                                                size="5x"
                                                                style={{ fontSize: "7.5em" }}
                                                            />
                                                        }
                                                    </div>
                                                    <div>
                                                        {
                                                            sucess === undefined
                                                                ?
                                                                "Erro: Preencha os campos"
                                                                :
                                                                sucess
                                                        }
                                                    </div>
                                                    <div></div>
                                                </>
                                                :
                                                <div className="modal-body">
                                                    <div className="text-center">
                                                        <div className="load"></div>
                                                    </div>
                                                    <div>
                                                        <span>Processando</span>
                                                    </div>
                                                    <div></div>
                                                </div>
                                        }
                                    </div>
                                </ModalDefault>
                            </Row>
                            {/*<Row className="d-flex justify-content-end mx-0 mt-5 mb-4">
            <Button type="submit" className="form-button w-auto px-4 py-2">{loading === true ? <div className="load small white"></div> : "Cadastrar"}</Button>
        </Row> */}
                        </Form>
                    </Container>
                </div>
            </PageDafault>
        </>
    );
}

export default ProfilePassword;