import React, { useState, useEffect } from "react";

import { Row, Col } from 'react-bootstrap';

import { Controller, useForm } from "react-hook-form";
//import { ErrorMessage } from '@hookform/error-message';

import apiGoogleMaps from "../../../../../../services/apiGoogleMaps";
import configData from "../../../../../../config/config.json";

import Form from 'react-bootstrap/Form';
//import GetCountrysAffiliates from "../../../../../../components/C2Points/GetCountrysAffiliates";
import GetCitysByState from "../../../../../../components/C2Points/GetCitysByState";
import { useTranslation } from "react-i18next";
import { ErrorMessage } from "@hookform/error-message";
import GetStates from "../../../../../../components/C2Points/GetStates";
import GetDistrictCode from "../../../../../../components/C2Points/GetDistrictCode";
import GetStreetCode from "../../../../../../components/C2Points/GetStreetCode";

export interface propForm {
    action: any,
    back: any,
    supplierControlObject: any
};

const FormAddress: React.FC<propForm> = ({
    action, back, supplierControlObject
}: propForm) => {
    const { t } = useTranslation();

    const [cep] = useState('' || supplierControlObject.cep);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [state, setState] = useState('' || supplierControlObject.state);
    //const [city, setCity] = useState('' || supplierControlObject.city);
    const [address, setAddress] = useState('' || supplierControlObject.address);
    const [district, setDistrict] = useState('' || supplierControlObject.district);
    const validated = false;

    const {
        control,
        handleSubmit,
        register,
        formState: { errors },
        setValue,
        watch,
    } = useForm({
        defaultValues: {
            country: supplierControlObject?.country ? { id: supplierControlObject.country, description: supplierControlObject.countryDesc } : '',
            cep: supplierControlObject ? supplierControlObject.cep : '',
            address: supplierControlObject ? supplierControlObject.address : '',
            streetCode: supplierControlObject?.streetCode ? { id: supplierControlObject.streetCode, description: supplierControlObject.streetDesc } : '',
            district: supplierControlObject ? supplierControlObject.district : '',
            districtCode: supplierControlObject?.districtCode ? { id: supplierControlObject.districtCode, description: supplierControlObject.districtDesc} : '',
            city: supplierControlObject?.city ? { id: supplierControlObject.city, description: supplierControlObject.city } : '',
            state: supplierControlObject?.state ? { id: supplierControlObject.state, description: supplierControlObject.stateDesc } : '',
            number: supplierControlObject ? supplierControlObject.number : '',
            complement: supplierControlObject ? supplierControlObject.complement : '',
        },
    });

    const watchState: any = watch(`state`, '');

    useEffect(() => {
        setValue('city', '')
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [watchState])

    useEffect(() => {
        setValue('district', district)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [district])

    useEffect(()=>{
        if(supplierControlObject){
            
        }

    },[supplierControlObject])

    const onSubmit = (data: any) => {
        data.countryDesc = data.country === undefined ? 'Brasil' : data.country.description;
        data.country = '30';//data.country === undefined ? 30 : Number(data.country.id);
        data.address = address;
        //data.city = data.city?.description;
        data.state = watchState?.id || watchState;//state;
        data.stateDesc = watchState?.description;//state;
        data.district = district;
        data.number = Number(data.number);

        data.streetDesc = data.streetCode.description;
        data.streetCode = parseInt(data.streetCode.id);
        data.districtDesc = data.districtCode.description;
        data.districtCode = parseInt(data.districtCode.id);
        
        data.cityCode = parseInt(data.city.id);
        data.city = data.city?.description;

        action(data);
    }

    const searchCEP = async (term: any) => {
        const response = await apiGoogleMaps.get("/geocode/json", {
            params: {
                address: term,
                key: configData.GOOGLE_KEYS.GOOGLE_MAPS,
            },
        });

        if (response.data.status === "OK") {
            response.data.results[0].address_components.forEach(function (place: any) {
                switch (place.types[0]) {
                    case "route":
                        setValue('address', place.long_name);
                        setAddress(place.long_name);
                        (
                            document.getElementById(
                                "FormControlInput1Address"
                            ) as HTMLInputElement
                        ).value = place.long_name;
                        (
                            document.querySelector(
                                '[for="FormControlInput1Address"]'
                            ) as HTMLElement
                        ).classList.add("label-active");
                        break;

                    case "administrative_area_level_2":
                        //setValue('city', place.long_name);
                        //setCity(place.long_name);
                        /* (
                            document.getElementById(
                                "FormControlInput1Cidade"
                            ) as HTMLInputElement
                        ).value = place.long_name;
                        (
                            document.querySelector(
                                '[for="FormControlInput1Cidade"]'
                            ) as HTMLElement
                        ).classList.add("label-active"); */
                        break;

                    case "political":
                        setValue('district', place.long_name);
                        setDistrict(place.long_name);
                        /* (
                            document.getElementById(
                                "FormControlInput1Cidade"
                            ) as HTMLInputElement
                        ).value = place.long_name;
                        (
                            document.querySelector(
                                '[for="FormControlInput1Cidade"]'
                            ) as HTMLElement
                        ).classList.add("label-active"); */
                        break;

                    case "administrative_area_level_1":
                        //setValue('state', place.long_name);
                        setState(place.short_name);
                        (
                            document.getElementById(
                                "FormControlInput1Estado"
                            ) as HTMLInputElement
                        ).value = place.short_name;
                        (
                            document.querySelector(
                                '[for="FormControlInput1Estado"]'
                            ) as HTMLElement
                        ).classList.add("label-active");
                        break;

                    default:
                }
            });
        }
    };

    const handleBlurCEP = (event: any) => {
        searchCEP(event.target.value);
    };

    return (
        <>
            <div>
                <Form noValidate validated={validated} onSubmit={handleSubmit(onSubmit)} autoComplete="false">
                    <Row>
                        <Form.Group as={Col} md={6} controlId="formResourcesType" className="mb-3">
                            <Form.Label>
                                {t("affiliates.formCountry")}
                            </Form.Label>
                            <Controller
                                control={control}
                                name="country"
                                render={({ field }: any) => (
                                    <Form.Control
                                        {...field}
                                        variant="standard"
                                        margin="normal"
                                        autoComplete="new-password"
                                        value={'Brasil'}
                                        disabled
                                    />
                                )}
                            />
                        </Form.Group>
                        <Form.Group as={Col} md={6} controlId="formVehiclesType" className="mb-3">
                            <Form.Label>
                                {t("affiliates.formZipcode")}
                            </Form.Label>
                            <Controller
                                control={control}
                                name="cep"
                                rules={{ required: { value: true, message: t("affiliates.formError") } }}
                                render={({ field }: any) => (
                                    <Form.Control
                                        {...field}
                                        {...register("cep")}
                                        aria-invalid={errors?.cep ? "true" : ""}
                                        variant="standard"
                                        margin="normal"
                                        autoComplete="new-password"
                                        onBlur={handleBlurCEP}
                                        defaultValue={cep !== null ? cep : ""}
                                    />
                                )}
                            />
                            <ErrorMessage
                                errors={errors}
                                name="cep"
                                render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                            />
                        </Form.Group>
                    </Row>
                    <Row>
                        <Form.Group as={Col} md={6} htmlFor="FormControlInput1Estado" className="mb-3">
                            <Form.Label>
                                {t("affiliates.formState")}
                            </Form.Label>
                            <Controller
                                control={control}
                                name="state"
                                rules={{ required: { value: true, message: t("affiliates.formError") } }}
                                render={({ field }: any) => (
                                    <GetStates propsField={field} propsErrors={errors} defaultValue={supplierControlObject?.state} setValue={setValue} />
                                )}
                            />
                            <ErrorMessage
                                errors={errors}
                                name="state"
                                render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                            />
                        </Form.Group>
                        <Form.Group as={Col} md={6} htmlFor="FormControlInput1Cidade" className="mb-3">
                            <Form.Label>
                                {t("affiliates.formCity")}
                            </Form.Label>
                            <Controller
                                control={control}
                                name="city"
                                rules={{ required: { value: true, message: t("affiliates.formError") } }}
                                render={({ field }: any) => (
                                    <GetCitysByState propsField={field} propsErrors={errors} uf={watchState?.id} defaultValue={supplierControlObject?.city} setValue={setValue} />
                                )}
                            />
                            <ErrorMessage
                                errors={errors}
                                name="city"
                                render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                            />
                        </Form.Group>
                    </Row>

                    <Row>
                        <Form.Group as={Col} md={2} htmlFor="FormControlInput1Address" className="mb-3">
                            <Form.Label>
                                {t("affiliates.formStreetType")}
                            </Form.Label>
                            <Controller
                                control={control}
                                name="streetCode"
                                rules={{ required: { value: true, message: t("affiliates.formError") } }}
                                render={({ field }: any) => (
                                    <GetStreetCode propsField={field} propsErrors={errors} setValue={setValue}/>
                                )}
                            />
                            <ErrorMessage
                                errors={errors}
                                name="streetCode"
                                render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                            />
                        </Form.Group>
                        <Form.Group as={Col} md={4} htmlFor="FormControlInput1Address" className="mb-3">
                            <Form.Label>
                                {supplierControlObject.compType === 1 ? t("affiliates.formCompanyAddress") : t("affiliates.formAddress")}
                            </Form.Label>
                            <Controller
                                control={control}
                                name="address"
                                rules={{ required: { value: true, message: t("affiliates.formError") } }}
                                render={({ field }: any) => (
                                    <Form.Control
                                        {...field}
                                        {...register("address")}
                                        aria-invalid={errors?.address ? "true" : ""}
                                        variant="standard"
                                        id="FormControlInput1Address"
                                        margin="normal"
                                        autoComplete="new-password"
                                        //defaultValue={address !== null ? address : ""}
                                        value={address /* || supplierControlObject.address */}
                                        onChange={(e: any) => { setAddress(e.target.value) }}
                                    />
                                )}
                            />
                            <ErrorMessage
                                errors={errors}
                                name="address"
                                render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                            />
                        </Form.Group>
                        <Form.Group as={Col} md={2} controlId="formVehiclesType" className="mb-3">
                            <Form.Label>
                                {t("affiliates.formTypeDistrict")}
                            </Form.Label>
                            <Controller
                                control={control}
                                name="districtCode"
                                rules={{ required: { value: true, message: t("affiliates.formError") } }}
                                render={({ field }: any) => (
                                    <GetDistrictCode propsField={field} propsErrors={errors} setValue={setValue}/>

                                )}
                            />
                            <ErrorMessage
                                errors={errors}
                                name="districtCode"
                                render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                            />
                        </Form.Group>
                        <Form.Group as={Col} md={4} controlId="formVehiclesType" className="mb-3">
                            <Form.Label>
                                {t("affiliates.formDistrict")}
                            </Form.Label>
                            <Controller
                                control={control}
                                name="district"
                                rules={{ required: { value: true, message: t("affiliates.formError") } }}
                                render={({ field }: any) => (
                                    <Form.Control
                                        {...field}
                                        aria-invalid={errors?.district ? "true" : ""}
                                        variant="standard"
                                        margin="normal"
                                        autoComplete="new-password"
                                        defaultValue={district !== null ? district : ""}
                                        value={district}
                                        onChange={(e: any) => { setDistrict(e.target.value) }}
                                    />
                                )}
                            />
                            <ErrorMessage
                                errors={errors}
                                name="district"
                                render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                            />
                        </Form.Group>
                    </Row>
                    <Row>
                        <Form.Group as={Col} md={6} controlId="formResourcesType" className="mb-3">
                            <Form.Label>
                                {t("affiliates.formNumber")}
                            </Form.Label>
                            <Controller
                                control={control}
                                name="number"
                                rules={{ required: { value: true, message: t("affiliates.formError") } }}
                                render={({ field }: any) => (
                                    <Form.Control
                                        {...field}
                                        type='number'
                                        aria-invalid={errors?.number ? "true" : ""}
                                        variant="standard"
                                        margin="normal"
                                        autoComplete="new-password"
                                    />
                                )}
                            />
                            <ErrorMessage
                                errors={errors}
                                name="number"
                                render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                            />
                        </Form.Group>
                        <Form.Group as={Col} md={6} controlId="formVehiclesType" className="mb-3">
                            <Form.Label>
                                {t("affiliates.formComplement")}
                            </Form.Label>
                            <Controller
                                control={control}
                                name="complement"
                                //rules={{ required: { value: true, message: t("affiliates.formError") } }}
                                render={({ field }: any) => (
                                    <Form.Control
                                        {...field}
                                        aria-invalid={errors?.complement ? "true" : ""}
                                        variant="standard"
                                        margin="normal"
                                        autoComplete="new-password"
                                    />
                                )}
                            />
                            <ErrorMessage
                                errors={errors}
                                name="complement"
                                render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                            />
                        </Form.Group>
                    </Row>
                    <Row className="d-flex justify-content-end pt-5">
                        <Col md={3}>
                            <button className="btn btn-outline-primary mb-3 mb-md-0" onClick={() => back(0)}>{t("affiliates.formBack")}</button>
                        </Col>
                        <Col md={3}>
                            <button type="submit" className="btn btn-primary">{t("affiliates.formContinue")}</button>
                        </Col>
                    </Row>
                </Form>
            </div>
        </>
    )
}

export default FormAddress;