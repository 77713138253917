import React from 'react';

function PrivacyNoticeCaracolEN() {
  return (
    <>
      <br />
      <strong>ABOUT US</strong>
      <br />

      <p className="px-3">
        We go beyond beautiful views. More than one of the most beautiful
        tourist spots in Brazil, our mission is to transport people to a world
        of enchantment, leisure and entertainment, creating happy, sustainable,
        unforgettable experiences that value Brazilian culture. We constantly
        invest in safety, comfort and innovation. We arose from a visionary and
        uncontrollable desire to connect people to this spectacle of nature. We
        are the combination of teamwork: nature and man together in perfect
        harmony. We are a natural, geological, historical and tourist landmark
        in the city of Rio de Janeiro. A natural landmark, because it is a
        reference point for navigators who come to Guanabara Bay. A geological
        landmark, because it is a geological site with precise dates from the
        planet’s history. A historical landmark, because on March 1st, 1565,
        Estácio de Sá founded the city of São Sebastião do Rio de Janeiro at its
        feet. And, a tourist landmark, because the inauguration of the Sugar
        Loaf cable car projected Brazil's name abroad. We were the first cable
        car to be installed in Brazil and the third in the world.
      </p>

      <br />
      <strong>1. PURPOSE</strong>
      <br />

      <p className="px-3">
        This Notice governs your access to and use of the website, in Brazil,
        made available, controlled and operated by{' '}
        <b>
          PARQUE BONDINHO PÃO DE AÇÚCAR®, COMPANHIA CAMINHO AÉREO PÃO DE AÇÚCAR
          S/A (“CCAPA” or “PARQUE BONDINHO”)
        </b>{' '}
        with registered office at Avenida Pasteur 520, Urca, City and State of
        Rio de Janeiro, CEP: 22.290-240.
      </p>

      <p className="px-3">
        The purpose of this Privacy Notice is to report our Users, whether in
        the digital environment (website) or in the physical environment (PARQUE
        BONDINHO premises), on how we treat their personal data, so that they
        can move around with peace of mind and know that their privacy is one of
        our concerns.
      </p>

      <p className="px-3">
        Your privacy is important to us at <b>BONDINHO PARK</b>. Therefore, we
        protect your personal data. We have produced this privacy notice, which
        sets out how <b>BONDINHO PARK</b> handles personal data of employees,
        customers, suppliers, and other stakeholders. This data can be stored
        physically in offices or digitally in information systems.
      </p>

      <p className="px-3">
        This Notice describes how <b>BONDINHO PARK</b> processes and protects
        your Personal Data, whether collected directly by us, sent by third
        parties and/or sent to third parties.
      </p>

      <p className="px-3">
        We are committed to acting transparently and ethically in all our
        operations, and this extends to the public bodies and entities with
        which <b>PARQUE BONDINHO</b> interacts.
      </p>

      {/* <div
        className="mx-3 p-4 text-center mb-3"
        style={{ border: '1px solid red' }}
      >
        <strong>
          Please find at the end of this Notice the specific contact channel for
          public assistance.
        </strong>
      </div> */}
      <br />

      <dl>
        <dd>
          <table className="table-page" width="50%" cellSpacing="0">
            <tbody>
              <tr>
                <td
                  width="70%"
                  height="14"
                  style={{ backgroundColor: '#c0c0c0' }}
                >
                  <p className="mb-0 px-2">
                    <span>
                      <strong>Corporate Name</strong>
                    </span>
                  </p>
                </td>
                <td width="30%" style={{ backgroundColor: '#c0c0c0' }}>
                  <p className="mb-0 px-2">
                    <span>
                      <strong>CNPJ</strong>
                    </span>
                  </p>
                </td>
              </tr>
              <tr>
                <td width="55" height="4">
                  <p className="my-2 px-2">
                    <span>ITER PARTICIPAÇÕES S.A.</span>
                  </p>
                </td>
                <td width="116">
                  <p className="my-2 px-2">
                    <span>43.697.470/0001-69</span>
                  </p>
                </td>
              </tr>
              <tr>
                <td width="55" height="4">
                  <p className="my-2 px-2">
                    <span>TRAVEL ACTIVITIES HOLDING. </span>
                  </p>
                </td>
                <td width="116">
                  <p className="my-2 px-2">
                    <span>44.317.977/0001-02</span>
                  </p>
                </td>
              </tr>
              <tr>
                <td width="55" height="4">
                  <p className="my-2 px-2">
                    <span>C2RIO VIAGENS E TURISMO LTDA</span>
                  </p>
                </td>
                <td width="116">
                  <p className="my-2 px-2">
                    <span>05.873.416/0001-38</span>
                  </p>
                </td>
              </tr>
              <tr>
                <td width="55" height="4">
                  <p className="my-2 px-2">
                    <span>DESTINOW VIAGENS E TURISMO LTDA. </span>
                  </p>
                </td>
                <td width="116">
                  <p className="my-2 px-2">
                    <span>44.743.360/0002-30</span>
                  </p>
                </td>
              </tr>
              <tr>
                <td width="55" height="4">
                  <p className="my-2 px-2">
                    <span>DESTITECH TECNOLOGIA DA INFORMAÇÃO LTDA.</span>
                  </p>
                </td>
                <td width="116">
                  <p className="my-2 px-2">
                    <span>45.759.751/0001-24</span>
                  </p>
                </td>
              </tr>
              <tr>
                <td width="55" height="4">
                  <p className="my-2 px-2">
                    <span>PÃO DE AÇÚCAR EMPREENDIMENTOS TURÍSTICO LTDA. </span>
                  </p>
                </td>
                <td width="116">
                  <p className="my-2 px-2">
                    <span>42.274.233/0001-22</span>
                  </p>
                </td>
              </tr>
              <tr>
                <td width="55" height="4">
                  <p className="my-2 px-2">
                    <span>NOVO CARACOL E TAINHAS S.A.</span>
                  </p>
                </td>
                <td width="116">
                  <p className="my-2 px-2">
                    <span>48.255.552/0001-77</span>
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
        </dd>
      </dl>

      <strong>2. SCOPE</strong>
      <br />

      <p className="px-3">
        This Notice applies to <b>PARQUE BONDINHO's</b> clients, suppliers,
        visitors and other stakeholders. It must be implemented on the Platform
        and can be read by all clients, partners and interested third parties.
      </p>

      <p className="px-3">
        You can check the box to read and acknowledge this Notice. This
        indicates that you are aware of how we process and use your data and
        information. This document should be read in conjunction with our Terms
        and Conditions of Use.
      </p>
      <br />

      <strong>3. ACRONYMS AND DEFINITIONS</strong>
      <br />

      <p className="px-3">
        <b>3.1. Personal data:</b> Information related to an identified or
        identifiable natural person, that is, information that directly or
        indirectly identifies the natural person, such as: CPF, address, voice,
        image, car license plate and fingerprint. According to the General
        Personal Data Protection Law – LGPD –, the personal data is legally
        protected, and its owners have rights over it and what to do with it. If
        you provide your personal data to PARQUE BONDINHO it is our duty to
        treat it appropriately and ensure that it is stored securely.
      </p>

      <p className="px-3">
        <b>3.2. Data Subject:</b> it is an individualwho the personal data
        refers to. That is: You. It may also be an applicant to the vacancy, a
        visitor to our website, a visitor to our Park, etc.
      </p>

      <p className="px-3">
        <b>3.3. Sensitive Personal Data:</b> Means Personal Data that deals with
        racial or ethnic origin, religious belief, political opinion, union
        membership or organization of a religious, philosophical or political
        nature, data related to health or sexual life, genetic or biometric
        data, when linked to a natural person.
      </p>

      <p className="px-3">
        <b>3.4. Controller:</b> Means a natural person or legal entity, governed
        by public or private law, who is responsible for decisions concerning
        the Processing of Personal Data who, in this case, are we, PARQUE
        BONDINHO.
      </p>

      <p className="px-3">
        <b>3.5. Operator:</b> These are our partners, suppliers and service
        providers, who process personal data on behalf of PARQUE BONDINHO,
        helping to process the personal data we collect in order to offer our
        services and provide the best experience for our users.
      </p>

      <p className="px-3">
        <b>3.6. Data Protection Officer (DPO):</b> Is the person appointed by
        the controller and operator to act as a communication channel between
        the controller, data subjects and the National Data Protection Authority
        (ANPD).
      </p>

      <p className="px-3">
        <b>3.7. Processing:</b> Any operation carried out with Personal Data,
        such as those related to the collection, production, reception,
        classification, use, access, reproduction, transmission, distribution,
        processing, filing, storage, deletion, evaluation or control of the
        information, modification, communication, transfer, diffusion or
        extraction.
      </p>

      <p className="px-3">
        <b>3.8. Platforms:</b> This is the website, e-commerce, social media and
        communication channels of PARQUE BONDINHO.
      </p>

      <p className="px-3">
        <b>3.9. Cookies:</b> Cookies are small text files that, through the
        Internet browser, record access and location information of visitors to
        our website. This information is stored on visitors' devices (mobile or
        not) and can make the experience and access faster.
      </p>

      <p className="px-3">
        <b>3.10. Third parties:</b> Include, but are not limited to, customers,
        suppliers, job applicants and visitors and users in general of the
        PARQUE BONDINHO website (such parties are referred to herein
        individually as "Third Party” or collectively as "Third Parties").
      </p>

      <p className="px-3">
        <b>3.11. Database:</b> Structured set of personal data, established in
        one or more locations, in electronic or physical format;
      </p>

      <p className="px-3">
        <b>3.12. “International Transfer of Data”:</b> This is the transfer of
        personal data to a foreign country or international agency of which the
        country is a member.
      </p>

      <p className="px-3">
        <b>3.13. General Personal Data Protection Law (LGPD):</b> Law No.
        13,709,of August 14, 2018, also known as the General Personal Data
        Protection Law, or LGPD, legally regulates the processing of personal
        data in Brazil, both by digital and physical means, by natural persons
        or legal entities governed by public or private law, with the purpose of
        protecting the fundamental rights of freedom and privacy and the free
        development of the personality of natural persons.
      </p>
      <br />

      <strong>4. DATA PROVIDED TO PARQUE BONDINHO </strong>
      <br />

      <p className="px-3">
        <b>4.1. </b> The Personal Data provided will be used to enable the
        provision of the service, offer benefits, and comply with the
        obligations set forth in specific laws or regulations, and when
        necessary, after the end of the treatment, or as designated by current
        regulations, the disposal will be made with specific care.
      </p>

      <p className="px-3">
        <b>4.2. </b> Personal Data is collected by different means, depending on
        the service offered by PARQUE BONDINHO. Below are the operations that
        collect personal data, as well as the types of data collected through
        them:
      </p>

      <p className="px-3 mx-4">
        <b>a) REGISTRATION:</b> E-mail, name, Country, State, CPF, Passport,
        telephone;
      </p>
      <p className="px-3 mx-4">
        <b>b) ONLINE TICKETS PURCHASE:</b> Email, CPF, Passport, Country, State,
        telephone, credit card details;
      </p>
      <p className="px-3 mx-4">
        <b>c) BUYING OF TICKETS AT THE SELF-SERVICE TOTEM:</b> Name and email
        address;
      </p>
      <p className="px-3 mx-4">
        <b>d) BUYING TICKETS IN THE PHYSICAL ENVIRONMENT:</b> ID, credit card
        details, e-mail, proof of residence, student card and other documents
        required to prove the benefit;
      </p>
      <p className="px-3 mx-4">
        <b>e) NEWSLETTER:</b> Name, e-mail;
      </p>
      <p className="px-3 mx-4">
        <b>f) EDUCA BONDINHO:</b> Name, email and telephone number of the
        teacher or director in charge, photo and video of the student;
      </p>
      <p className="px-3 mx-4">
        <b>g) EXCLUSIVE CABLE CAR:</b> Name, email address, telephone number;
      </p>
      <p className="px-3 mx-4">
        <b>h) PHYSICAL VISITS TO THE TOURIST PARK (MOUNTAINEERS): </b> Name, CPF
        and telephone number;
      </p>
      <p className="px-3 mx-4">
        <b>i) BUSINESS AND PARTNERSHIPS:</b> Name, email address, telephone
        number;
      </p>
      <p className="px-3 mx-4">
        <b>j) TESTIMONIALS:</b> Email, name, image
      </p>
      <p className="px-3 mx-4">
        <b>k) CONTACT US:</b> Name, email address, telephone number;
      </p>
      <p className="px-3 mx-4">
        <b>l) EXCHANGES AND RETURNS:</b> invoice, full name, CPF/RG (ID);
      </p>
      <p className="px-3 mx-4">
        <b>m) CONTACTS VIA WHATSAPP:</b> name, email address and cell phone
        number
      </p>
      <p className="px-3 mx-4">
        <b>n) CONTACT VIA SOCIAL MEDIA:</b> name and email address.
      </p>
      <p className="px-3 mx-4">
        <b>o) SIG (Integrated Management System):</b> email, CPF, passport,
        country, state, telephone, address and cell phone.
      </p>
      <p className="px-3 mx-4">
        <b>p) WORK WITH US:</b> Name, date of birth, email, CV;
      </p>
      <p className="px-3 mx-4">
        <b>q) AGENCY MODULE :</b> Corporate name, fictitious name, CNPJ, company
        email, company telephone number, municipal and/or state registration,
        representative's name, representative's email, representative's cell
        phone, CPF, ID and legal representative's date of birth.
      </p>
      <p className="px-3 mx-4">
        <b>r) CANCELLATION OF TICKETS AND/OR RESERVATION:</b> Name, email,
        CPF/Passport, bank, branch, bank account;
      </p>
      <p className="px-3 mx-4">
        <b>
          s) CONDUCT AND ETHICS CHANNEL (in the event the data subject chooses
          to identify themselves):
        </b>{' '}
        Name, CPF, email, date of birth, telephone number, cell phone number
      </p>
      <p className="px-3 mx-4">
        <b>t) SUBJECT PORTAL (LGPD):</b> Name, email and CPF.
      </p>
      <br />

      <strong>5. DATA COLLECTED BY PARQUE BONDINHO </strong>
      <br />

      <p className="px-3">
        <b>5.1.</b> The information listed above is provided to PARQUE BONDINHO,
        but there is data that may be collected automatically when using the
        services, such as for example:
      </p>

      <p className="px-3 mx-4">
        <b>a)</b> Mobile device data from accesses to the digital platform and
        other information such as the type and version of browser used;
      </p>
      <p className="px-3 mx-4">
        <b>b)</b> Log data;
      </p>
      <p className="px-3 mx-4">
        <b>c)</b> The IP address;
      </p>
      <p className="px-3 mx-4">
        <b>d)</b> Other data collected through Cookies, as described in the
        Cookie Policy.
      </p>

      <p className="px-3">
        <b>5.2.</b> The purpose of using this data is to improve the
        performance, security and functionality of our platforms, to serve
        advertisements in digital environments and to keep track of access to
        websites.
      </p>
      <br />

      <strong>6. PURPOSE OF PROCESSING</strong>
      <br />

      <p className="px-3">
        <b>6.1.</b> PARQUE BONDINHO processes your personal information to
        fulfill legal and contractual obligations and to provide you with our
        products and services. We will never collect unnecessary personal data
        and we will not process your information in any way other than that
        specified in this Notice.
      </p>

      <p className="px-3">
        <b>6.2.</b> PARQUE BONDINHO collects Personal Data entered by Third
        Parties on the PARQUE BONDINHO website, by means of cookies, as
        specified in the Cookies Policy, or provided by any other means, such
        as: verbal, written or telephone/WhatsApp calls, during any commercial,
        administrative or promotional operation.
      </p>

      <p className="px-3">
        <b>6.3.</b> If the Data Subject buys a ticket on the website of PARQUE
        BONDINHO, using communication channels such as "Contact Us", "Customer
        Service", "Chat", "Register"; "Work with Us", social media, and other
        means of contact available on the website, the types of Personal Data
        and the way in which we collect them may vary. In addition, we have the
        following purpose assumptions:
      </p>

      <p className="px-3 mx-4">
        <b>a)</b> For the selling of tickets of PARQUE BONDINHO, the canceling
        and/or rescheduling of tickets, as well as checking and granting the
        benefits provided for by law;
      </p>

      <p className="px-3 mx-4">
        <b>b)</b> To identify and/or send relevant content about a particular
        preference or interest expressed by you to PARQUE BONDINHO, including
        newsletters, events, invitations, promotions, social media posts,
        messages via digital platforms, reminders, thank-you notes, among
        others;
      </p>

      <p className="px-3 mx-4">
        <b>c)</b> To provide you and our business partners with a more
        consistent experience when interacting with the company, mainly by
        learning from the way you use and interact with the websites,
        applications (App) and other technological solutions. This may include:
        (i) customizing them according to your preferences and interests, making
        them more compatible with your technology or otherwise facilitating
        their use; (ii) maintaining their security and otherwise protecting
        them; and (iii) developing new websites, applications (App), products
        and services of PARQUE BONDINHO;
      </p>

      <p className="px-3 mx-4">
        <b>d)</b> For processing and storing the data of employees, trainees and
        third-party service providers in PARQUE BONDINHO. We will only process
        the personal data necessary to fulfill the purpose required by the
        employment relationship and or the execution of the employment or
        service contract to be possible, taking into account the legislation in
        force;
      </p>

      <p className="px-3 mx-4">
        <b>e)</b> To protect and manage the interests of PARQUE BONDINHO, of its
        members and business partners, including to comply withlegal
        stipulations, regulations by government bodies, fiscal authorities,
        Judiciary and/or other relevant authority;
      </p>

      <p className="px-3 mx-4">
        <b>f)</b> For processing and storage of data of customers, suppliers and
        service providers of PARQUE BONDINHO as well as to enter into related
        agreements;
      </p>

      <p className="px-3 mx-4">
        <b>g)</b> To provide services or carry out transactions requested by you
        or a business partner; providing information about products, services
        and transactions of PARQUE BONDINHO;
      </p>

      <p className="px-3 mx-4">
        <b>h)</b> To comply with legislation applicable to the tourism,
        environmental and intellectual property sectors, among others. This may
        include: (i) complying with our obligations to retain certain records
        for minimum periods; (ii) establishing, exercising or acting on legal
        proceedings and legal or other claims; (iii) complying with laws,
        regulations, court orders or other legal proceedings; (iv) detecting,
        preventing and responding to fraud, breaches of our contracts or
        agreements, other misuse of the Company's websites, applications (App),
        products or services; and (v) protecting the rights or property of
        PARQUE BONDINHO or the health, safety, well-being, rights or property of
        the data subject or third parties;
      </p>

      <p className="px-3 mx-4">
        <b>i)</b> For the fulfillment of any other demand made by you to PARQUE
        BONDINHO, either by answering your questions, including customer support
        requests; or by inviting you to participate in or informing you about
        the results of satisfaction surveys or market research;
      </p>

      <p className="px-3 mx-4">
        <b>j)</b> PARQUE BONDINHO may also use your personal information for
        other purposes, in a manner consistent with the context in which the
        information was collected, or with the consent expressed by you.
      </p>

      <p className="px-3 mx-4">
        <b>k)</b> For registration and/or reporting in the Conduct and Ethics
        Channel;
      </p>
      <br />

      <strong>7. USER’S RIGHTS </strong>
      <br />

      <p className="px-3">
        <b>7.1.</b> In compliance with applicable regulations regarding the
        processing of Personal Data, PARQUE BONDINHOrespects and guarantees the
        following rights to the data subject, upon request, as provided for in
        Article 18 of Law 13.709/2018:
      </p>

      <p className="px-3 mx-4">
        <b>a) Right of Confirmation and Access:</b> This right allows you to
        confirm ifPARQUE BONDINHO uses your personal data and, if so, to have
        access to a copy of the data used;
      </p>

      <p className="px-3 mx-4">
        <b>b) Right of Rectification:</b> This right allows you to request the
        correction and/or rectification of your personal information that is
        incomplete, inaccurate or outdated, if you identify this need;
      </p>

      <p className="px-3 mx-4">
        <b>c) Right to request Anonymization, Blocking or Deletion:</b>This
        right allows you to request from us: (i) anonymization of your personal
        data, so that they can no longer be related to the individual, and thus
        cease to be Personal Data; (ii) the blocking of your personal data,
        temporarily suspending them; and (iii) the deletion of your personal
        data, if you have objected to the use of your data, at which point
        PARQUE BONDINHO will delete all your data, with no possibility of
        reversal, unless there is any other reason for maintaining them, such as
        any legal obligation to retain data or the need to preserve them to
        safeguard the rights of the application;
      </p>

      <p className="px-3 mx-4">
        <b>d) Right to Portability:</b> The data subject has the right to obtain
        from PARQUE BONDINHO the transmission of their data to another
        responsible party or even to the interested party, by means of a
        structured format of habitual use and mechanical reading, depending on
        the regulations of the National Data Protection Authority;
      </p>

      <p className="px-3 mx-4">
        <b>
          e) Right to the Possibility of Not Providing Consent and its
          Consequences:
        </b>{' '}
        This right allows you to have clear and complete information about the
        possibility and consequences of not providing such consent. In the event
        that the requested consent is denied, access to the services offered by
        PARQUE BONDINHO may be limited;
      </p>

      <p className="px-3 mx-4">
        <b>f) Right to Withdraw your Consent:</b> You have the right to withdraw
        your consent in relation to the terms of this Privacy Notice. This,
        however, will not affect the lawfulness of any processing carried out
        previously. If you withdraw your consent, we may not be able to provide
        certain PARQUE BONDINHO services;
      </p>

      <p className="px-3 mx-4">
        <b>g) Right to Object to Processing:</b> The data subject has the right
        to object to the processing of their data on grounds relating to their
        particular situation. In this case, you will be able to contest where
        and in what context we are processing your personal data for different
        purposes, and object by requesting the interruption, through the contact
        channel of the DPO informed in the specific session;
      </p>

      <p className="px-3 mx-4">
        <b>
          h) Submit a petition to the National Data Protection Authority (ANPD):
        </b>{' '}
        The data subject may directly demand the ANPD to guarantee their Rights.
      </p>

      <p className="px-3">
        <b>7.2.</b> In order to validate the rights of personal data subjects,
        it will be necessary to request specific information from them to help
        us confirm their identity and guarantee their rights and the security of
        their personal data. In such instance, we ask you to contact the data
        controller directly by the following channel:{' '}
        <a href="https://portaldotitular.bondinho.com.br/titular_bondinho">
          https://portaldotitular.bondinho.com.br/titular_bondinho
        </a>
        .
      </p>

      <p className="px-3">
        <b>7.3.</b> PARQUE BONDINHO will respond to all legitimate requests
        within a reasonable time, except in cases where it will not be possible
        to respond due to compliance with legal or regulatory obligations, as
        well as to exercise or safeguard rights or prevent frauds.
      </p>

      <p className="px-3">
        <b>7.4.</b> PARQUE BONDINHO clarifies that in the event of a court
        request for your personal data, we reserve the right to provide your
        data and information about you in compliance with the national laws.
      </p>

      <p className="px-3">
        <b>7.5.</b> We may request some additional Personal Data to prove your
        identity, in order to prevent incidents and fraud and always ensure the
        privacy of our customers.
      </p>

      <p className="px-3">
        <b>7.6.</b> Users may exercise any other right provided for by law, even
        if not listed here. As an exception, it is possible that your request
        will not be met, but if this happens, we will explain why. However, we
        would like to point out that this will only happen if there are any
        legal grounds.
      </p>
      <br />

      <strong>8. SHARING PERSONAL DATA</strong>
      <br />

      <p className="px-3">
        <b>8.1.</b> We share personal data under our control in a lawful and
        transparent manner. Please note that all operators acting on our behalf
        only process your data in accordance with our instructions and fully
        comply with this Privacy Notice, as well as data protection legislation
        and any other appropriate confidentiality and security measures.
      </p>

      <p className="px-3">
        <b>8.2.</b> Some personal data may be shared with other group companies
        or partner companies and service providers to carry out our day-to-day
        business operations and to enable us to maintain a proper relationship
        with you. Under no circumstances do we market the data and information
        you provide to us. In some cases, your personal data is provided to our
        suppliers or partners to make a certain service possible or to improve
        the experience of customers and shopkeepers, for example, to companies
        that process payments for the purchase of the ticket for you to enjoy
        our services; advertising companies/agencies, to, among others, develop
        websites, select and produce ads consistent with your profile, as
        authorized.
      </p>

      <p className="px-3">
        <b>8.3.</b> You should know that we only work with partners who can
        guarantee an appropriate level of protection for your personal data.
        This sharing is protected by the contracts we have with these partners.
        There are cases in which we are compelled by law or regulation to share
        your personal data with third parties, for example: for the provision of
        personal information to government agencies, tax authorities, the
        Judiciary and/or other relevant authority for the fulfillment of
        existing legal obligations.
      </p>

      <p className="px-3">
        <b>8.4.</b> It is possible that, in some situations, we may need to
        disclose your personal data to third parties. But rest assured, the
        sharing of Personal Data with third parties will only take place in
        order to achieve some legitimate and specific purpose that has been
        previously informed to you. The important thing for you to know is that
        we will always be transparent with you regarding your data.
      </p>
      <br />

      <strong>9. INTERNATIONAL TRANSFER OF DATA </strong>
      <br />

      <p className="px-3">
        <b>9.1.</b> PARQUE BONDINHO does not make international data transfers
        as a rule. No personal data will be transferred to third parties located
        outside the country, except through the express consent of the data
        subject or for compliance with a legal, regulatory or contractual
        obligation, duly informed to the data subject.
      </p>

      <p className="px-3">
        <b>9.2.</b> In order to be transparent and ensure a quality service to
        you and our partners, we have contracted some information systems that
        host personal data in clouds located outside Brazil. Thus, there are
        cases where personal data is hosted in other countries so that we can
        fulfill our mission, as is the case with Google and Microsoft.
      </p>
      <br />

      <strong>10. DATA STORAGE AND DISPOSAL </strong>
      <br />

      <p className="px-3">
        <b>10.1.</b> All information or Personal Data provided will comply with
        the security rules necessary to comply with legal provisions and, in
        addition, efforts are made to ensure that this information is not
        subject to destruction, loss, alteration or dissemination. We only store
        your personal information to the extent that we need it to be able to
        use it in keeping with the purpose for which it was collected and in
        keeping with the legal basis for its processing.
      </p>

      <p className="px-3">
        <b>10.2.</b> We will keep your personal information for as long as your
        user on our platform is active, for as long as it is necessary for the
        completion of transactions with you, for as long as there is a
        contractual, commercial or institutional relationship, or for as long as
        you do not exercise your right to suppress, cancel or limit the
        processing of your data.
      </p>

      <p className="px-3">
        <b>10.3.</b> Once your user is made inactive on our platforms, the
        corresponding data will be deleted from our systems, provided that there
        is no basis for storing it for legitimate purposes, as defined by law,
        to comply with legal or regulatory obligations, as well as to exercise
        or safeguard rights or prevent fraud.
      </p>

      <p className="px-3">
        <b>10.4.</b> In order to ensure that information and Personal Data are
        retained for as long as necessary only, the necessary requirements and
        regulations of the time limits in legal and contractual provisions are
        complied with. Therefore, in addition to the constant observance and
        updating of these regulations, regardless of your consent or request for
        deletion, the General Personal Data Protection Law allows Personal Data
        to be retained in the following situations:
      </p>

      <p className="px-3 mx-4">
        <b>a)</b> Compliance with legal or regulatory obligations;
      </p>
      <p className="px-3 mx-4">
        <b>b)</b> Transfer to a third party, respecting their rights and
        provisions in data protection and privacy laws;
      </p>
      <p className="px-3 mx-4">
        <b>c)</b> For exclusive use, access by third parties is prohibited, and
        provided that the data is anonymized.
      </p>

      <p className="px-3">
        <b>10.5.</b> However, you always have the right to request data
        deletion. We guarantee that we will review this request in accordance
        with legal requirements.
      </p>
      <br />

      <strong>11. USER RESPONSIBILITY</strong>
      <br />

      <p className="px-3">
        <b>11.1.</b> The user is exclusively responsible for the use of the
        application and must respect the rules of this Privacy Notice, as well
        as the applicable legislation.
      </p>

      <p className="px-3">
        <b>11.2.</b> The user assures the truthfulness of all data reported, and
        is responsible for keeping their personal information up to date.
      </p>

      <p className="px-3">
        <b>11.3.</b> PARQUE BONDINHO shall not be held liable for any direct or
        indirect damages resulting from, or in connection with, access to, use
        of, or the inability to access or use the Application.
      </p>

      <p className="px-3">
        <b>11.4.</b> It is the user responsibility to keep their login and
        password secure and out of reach of third parties. In this way, the user
        is responsible for all actions carried out on their account.
      </p>

      <p className="px-3">
        <b>11.5.</b> In view of the characteristics inherent in the Internet
        environment, the application is not responsible for interruptions or
        suspensions of connection, incomplete or failed computer transmissions,
        as well as technical failure of any kind, including, but not limited to,
        electronic malfunction of any network, hardware or software.
      </p>

      <p className="px-3">
        <b>11.6.</b> It is under the user responsibility to keep the environment
        of their device (computer, cell phone, tablet, among others) secure,
        using the tools available to ensure security as well as to use reliable
        Internet networks.
      </p>

      <p className="px-3">
        <b>11.7.</b> The user declares that they are civilly capable. In the
        event of access by a user under the age of eighteen (18), with or
        without the authorization of their legal guardian, the latter will be
        responsible for all actions taken by the user, as stipulated by law.
      </p>

      <br />

      <strong>12. COMMUNICATION CHANNEL</strong>
      <br />

      <p className="px-3">
        <b>12.1.</b> If you have any questions, wish to exercise your rights or
        wish to make any communication, PARQUE BONDINHO, in compliance with
        Article 41 of the General Data Protection Law, will contact the Personal
        Data Officer, Louana Costa, at the following email address:{' '}
        <a href="mailto:dpo@bondinho.com.br">dpo@bondinho.com.br</a>.
      </p>
      <br />

      <strong>13. APPLICABLE LAW / JURISDICTION</strong>
      <br />

      <p className="px-3">
        <b>13.1.</b> This Privacy Notice is governed and interpreted in
        accordance with the Laws of the Federative Republic of Brazil
      </p>

      <p className="px-3">
        <b>13.2.</b> The Jurisdiction of Rio de Janeiro is hereby elected as the
        competent venue to settle any issues arising from this document,
        expressly waiving any other, however privileged this might be.
      </p>
      <br />

      <strong>14. UPDATES OF THIS NOTICE</strong>
      <br />

      <p className="px-3">
        <b>14.1.</b> PARQUE BONDINHO reserves the right to change this Privacy
        Notice whenever necessary to ensure functionality, enhancements and
        updates to the services offered, and is not obliged to maintain the
        application structure.
      </p>
      <br />

      <p>
        Our Cookies Policy will always show the last update date or current
        version.
      </p>

      <dl>
        <dd>
          <table className="table-page" width="100%" cellSpacing="0">
            <tbody>
              <tr>
                <td
                  width="55"
                  height="14"
                  style={{ backgroundColor: '#c0c0c0' }}
                >
                  <p className="mb-0 px-2">
                    <span>
                      <strong>Version</strong>
                    </span>
                  </p>
                </td>
                <td width="156" style={{ backgroundColor: '#c0c0c0' }}>
                  <p className="mb-0 px-2">
                    <span>
                      <strong>Modified item</strong>
                    </span>
                  </p>
                </td>
                <td width="299" style={{ backgroundColor: '#c0c0c0' }}>
                  <p className="mb-0 px-2">
                    <span>
                      <strong>Reason</strong>
                    </span>
                  </p>
                </td>
                <td width="113" style={{ backgroundColor: '#c0c0c0' }}>
                  <p className="mb-0 px-2">
                    <span>
                      <strong>Date</strong>
                    </span>
                  </p>
                </td>
              </tr>
              <tr>
                <td width="55" height="4">
                  <p className="my-2 px-2">
                    <span>01</span>
                  </p>
                </td>
                <td width="116">
                  <p className="my-2 px-2">
                    <span>Original version</span>
                  </p>
                </td>
                <td valign="top" width="299">
                  <p className="my-2 px-2">
                    <span>N/A</span>
                  </p>
                </td>
                <td valign="top" width="113">
                  <p className="my-2 px-2">
                    <span>07/27/2021</span>
                  </p>
                </td>
              </tr>
              <tr>
                <td width="55" height="4">
                  <p className="my-2 px-2">
                    <span>02</span>
                  </p>
                </td>
                <td width="116">
                  <p className="my-2 px-2">
                    <span>Original version</span>
                  </p>
                </td>
                <td valign="top" width="299">
                  <p className="my-2 px-2">
                    <span>Update</span>
                  </p>
                </td>
                <td valign="top" width="113">
                  <p className="my-2 px-2">
                    <span>12/28/2023</span>
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
        </dd>
      </dl>
    </>
  );
}

export default PrivacyNoticeCaracolEN;
