import { Link } from "react-router-dom";

function DefaultContractPT() {
    return (
        <>
            <p>
                A <b>COMPANHIA CAMINHO AÉREO PÃO DE AÇÚCAR</b>, com sede à Avenida Pasteur 520, Urca, Rio de Janeiro - RJ, CEP 22.290 - 255, inscrita no CNPJ sob o n.º 33.229.410 /0001 - 68, devidamente representada neste ato por seus diretores, doravante denominada CCAPA, pelo presente instrumento realiza a parceria, a fim de autorizar, em caráter precário, a emissão de bilhetes e experiências do Parque Bondinho Pão de Açúcar, para a PARCEIRA cadastrada no link <Link to={"/parceiros"} target="_blank">Afiliados | Parque Bondinho Pão de Açúcar</Link>, neste ato representada na forma de seus atos constitutivos(doravante, “PARCEIRA”), na condição de devedora compromissada.
            </p>
            <p>
                Ao realizar o cadastro no Módulo Agência, a PARCEIRA(“Usuário”) declara ter lido e concordado com os Termos a seguir dispostos:
            </p>

            <h6><b>1. DOCUMENTAÇÃO NECESSÁRIA:</b></h6>

            <p>
                1.1. A fim de obter a autorização para emissão de bilhetes e experiências, a <b>PARCEIRA</b>, que deverá ser constituída há mais de 1 ano, obriga - se a fornecer e manter atualizados, junto a <b>CCAPA</b>, os seguintes dados e documentação:
            </p>
            <ul className="mb-3">
                <li>
                    a)	Cópia do contrato social(e última alteração, se houver); MEI - Cópia do Certificado da Condição do Microempreendedor Individual(CCMEI);
                </li>
                <li>
                    b)	Telefone e CPF dos sócios;
                </li>
                <li>
                    c)	Cópia do Registro no Ministério do Turismo;
                </li>
                <li>
                    d)	Cópia do cartão do CNPJ com atividade principal Agência de Viagem ou Agência de Turismo ou Transporte Turístico;
                </li>
                <li>
                    e)	Cópia do cartão da inscrição Municipal;
                </li>
                <li>
                    f)	Informações bancárias.
                </li>
            </ul>
            <p>
                1.2. Para a obtenção da autorização para emissão de bilhetes e experiências, além dos documentos acima solicitados, será realizada uma análise de crédito junto ao SERASA para verificar a viabilidade da autorização.
            </p>
            <p>
                1.3. Os documentos apresentados acima deverão ser apresentados anualmente a partir da data de cadastro realizada no link dos afiliados.
            </p>

            <h6><b>2. EMISSÃO</b></h6>

            <p>
                2.1. A <b>PARCEIRA</b> deverá realizar o seu cadastro no portal <Link to="/parceiros" target="_blank">Afiliados | Parque Bondinho Pão de Açúcar</Link>, uma vez aprovado e em vigor o cadastro da <b>PARCEIRA</b>, esta deverá acessar o portal <Link to={{ pathname: "https://sigreact.bondinho.com.br/" }} target="_blank">Sistema Integrado de Gestão (bondinho.com.br)</Link> com o seu login e senha criados no portal dos afiliados para realizar a compra dos bilhetes - Bilhete Bondinho Inteira, Bilhete Acesso Rápido Inteira e Bilhete Bondinho + Visita Guiada Inteira - do Parque Bondinho Pão de Açúcar e – Experiência Amanhecer no Parque Bondinho Pão de Açúcar.
            </p>

            <p>
                2.2. A <b>PARCEIRA</b> terá um limite global de faturamento permitido na plataforma citada acima, de forma que este limite poderá ser avaliado a cada mês para uma possível alteração.A avaliação ocorrerá com nova análise financeira.
            </p>

            <p>
                2.3.	Todo cancelamento de bilhete, até um dia antes da data da visita, será feito através da <b>PARCEIRA</b>, via sistema, no site de Afiliados.
            </p>

            <p>
                2.3.1. O cancelamento das experiências seguirá as regras disponíveis no produto do Módulo Agência e / ou informado pelo Parque via e - mail.
            </p>

            <p>
                2.4	A <b>CCAPA</b> não será responsável pelo cancelamento e reembolso de nenhum bilhete e experiência comprado via a <b>PARCEIRA</b>.
            </p>

            <p>
                2.5	A <b>PARCEIRA</b> desde já tem ciência que o bilhete tem validade de uso até 7(sete) dias após a data do agendamento da visita, desde que o mesmo não tenha sida utilizado.
            </p>

            <p>
                2.5.1. A <b>PARCEIRA</b> tem ciência que o item 2.5.acima não é válido para as vendas das experiências.
            </p>

            <h6><b>3. VALOR E FORMA DE PAGAMENTO</b></h6>

            <p>
                3.1. Os bilhetes de acesso ao Parque Bondinho Pão de Açúcar e experiências emitidos pela <b>PARCEIRA</b> no link acima serão fornecidos com um desconto, de forma que:
            </p>

            <p>
                3.1.1. O pagamento a ser feito pela <b>PARCEIRA</b> à <b>CCAPA</b> será o equivalente ao valor do preço dos bilhetes “Bilhete Bondinho Inteira”, “Bilhete Acesso Rápido Inteira”, “Bilhete Bondinho + Visita Guiada Inteira” e “Experiência Amanhecer no Parque Bondinho” do Parque Bondinho Pão de Açúcar, abatido de 14 % (quatorze por cento) de desconto no Bilhete Bondinho Inteira e 10 % (dez por cento) de desconto no Bilhete Acesso Rápido Inteira, Bilhete Bondinho + Visita Guiada Inteira e Experiência Amanhecer no Parque Bondinho concedidos em razão desta autorização.
            </p>

            <p>
                3.1.2. A <b>CCAPA</b> obriga - se a informar à <b>PARCEIRA</b> todo e qualquer reajuste que venha a ser feito no preço do bilhete e da experiência.
            </p>

            <p>
                3.2. A <b>PARCEIRA</b> obriga - se a pagar a <b>CCAPA</b>, que efetuará a cobrança através de fatura de pagamento e acompanhada do respectivo boleto bancário que terão vencimentos nos dias 10, 20 e 30 de cada mês, conforme especificado em cada uma delas.
            </p>

            <p>
                3.2.1. O não pagamento da fatura no prazo previsto ensejará a aplicação de multa moratória de 10 % sobre o valor do débito, além da incidência de juros de mora de 1 % ao mês.
            </p>

            <p>
                3.2.2. O não pagamento da fatura até o 5º dia após o seu vencimento implicará na imediata suspensão dos efeitos dessa autorização de faturamento para compra de bilhetes e experiências.Sem prejuízo da suspensão ou revogação desta autorização, o presente instrumento servirá, acompanhado do relatório do sistema SIG, como título executivo extrajudicial.Havendo a propositura de ação judicial, o valor do débito será acrescido de 20 % de honorários advocatícios.
            </p>

            <p>
                3.3	O não recebimento de qualquer cobrança pela <b>CCAPA</b> não o dispensará do pagamento das obrigações ajustadas neste termo, devendo a <b>PARCEIRA</b>, neste caso, procurar a <b>CCAPA</b>, até a data do vencimento da obrigação, para o pagamento do (s) valor(es) devido(s).
            </p>

            <p>
                3.4	O não recebimento de qualquer cobrança pela CCAPA não o dispensará do pagamento das obrigações ajustadas neste termo, devendo a <b>PARCEIRA</b>, neste caso, procurar a <b>CCAPA</b>, até a data do vencimento da obrigação, para o pagamento do (s) valor(es) devido(s).
            </p>

            <h6><b>4. OBRIGAÇÕES DA PARCEIRA:</b></h6>

            <p>
                4.1. A <b>PARCEIRA</b> deverá cumprir com todas as diretrizes solicitadas pela <b>CCAPA</b> conforme descritivo, especificações e prazos previstos neste instrumento.
            </p>

            <p>
                4.2. A <b>PARCEIRA</b> se obriga a manter absoluto sigilo sobre as operações, dados, estratégias, materiais, informações e documentos da <b>CCAPA</b>, mesmo após a conclusão dos serviços ou do término da relação contratual.
            </p>

            <p>
                4.3. Os termos, informações, dados, materiais e documentos inerentes à <b>CCAPA</b> ou a seus clientes deverão ser utilizados, pela <b>PARCEIRA</b>, por seus funcionários ou contratados, estritamente para cumprimento dos serviços solicitados pela <b>CCAPA</b>, sendo VEDADO a comercialização ou utilização para outros fins.
            </p>

            <p>
                4.4. Será de responsabilidade da <b>PARCEIRA</b> todo o ônus trabalhista ou tributário referente aos seus funcionários utilizados para a venda dos bilhetes e experiências objeto deste instrumento, ficando a <b>CCAPA</b> isenta de qualquer obrigação em relação a eles.
            </p>

            <p>
                4.5. A <b>PARCEIRA</b> deverá fornecer os respectivos documentos fiscais, referentes ao(s) pagamento(s) do presente instrumento.
            </p>

            <p>
                4.6. Não fazer, durante ou após a Vigência, comentários negativos ou ofensivos, em qualquer meio(incluindo, mas não limitado a redes sociais) ou tomar quaisquer ações que possam depreciar, degradar ou de outra forma impugnar a boa vontade, nome, marca e / ou reputação da <b>CCAPA</b>.
            </p>

            <p>
                4.7. A <b>PARCEIRA</b> concorda em receber a newsletter do Parque Bondinho Pão de Açúcar.
            </p>


            <h6><b>5. PRAZO, SUSPENSÃO E REVOGAÇÃO:</b></h6>

            <p>
                5.1. A presente parceria inicia - se a partir da assinatura do presente instrumento, sendo seu término a partir da suspensão ou revogação da parceria por ambas as Partes.
            </p>

            <p>
                5.2. A presente parceria poderá ser suspensa ou revogada, neste último caso mediante aviso prévio de 15(quinze) dias, pela <b>CCAPA</b>, independentemente de notificação ou interpelação judicial ou extrajudicial.
            </p>

            <p>
                5.3. Constituem, ainda, motivos para rescisão imediata da parceria pela Parte prejudicada, independentemente de prévio aviso, a ocorrência de qualquer das situações abaixo:
            </p>

            <ul className="mb-3">
                <li>
                    i) a prática de atos, por uma das Partes, que a critério da Parte Prejudicada importem em descrédito institucional;
                </li>
                <li>
                    ii) razões de interesse público, de alta relevância e amplo conhecimento que prejudiquem ou impeçam a continuidade da Parceria; e
                </li>
                <li>
                    iii) Descumprimento por qualquer das Partes das leis anticorrupção.
                </li>
            </ul>

            <h6><b>6. DA PROTEÇÃO DOS DADOS</b></h6>

            <p>
                6.1. As partes em comum acordo, submetem - se ao cumprimento dos deveres e obrigações referentes à proteção de dados pessoais e se obrigam a tratar os Dados Pessoais coletados no âmbito do presente instrumento, se houver, de acordo com a legislação vigente aplicável, incluindo, mas não se limitando a Lei nº 13.709 / 2018(Lei Geral de Proteção de Dados Pessoais), no que couber e conforme aplicável.As Partes deverão também garantir que seus empregados, agentes e subcontratados observem os dispositivos do diploma legal em referência relacionado à proteção de dados, incluindo, mas não se limitando, à LGPD.
            </p>

            <p>
                6.2.O CREDENCIADO declara ter ciência que a <b>CCAPA</b> irá coletar e tratar os dados pessoais acima relacionados, tendo como finalidade a emissão de bilhetes do Parque Bondinho Pão de Açúcar e das experiências, bem como o compartilhar com os órgãos competentes para o cumprimento de tal finalidade, de acordo com a Lei nº 13.709 / 2018 - Lei Geral de Proteção de Dados Pessoais(LGPD).
            </p>

            <p>
                6.3. As informações fornecidas serão mantidas em sigilo, exceto nas situações em que a empresa tenha obrigação legal de informar aos órgãos e autoridades governamentais.
            </p>

            <h6><b>7. DAS NORMAS DE PREVENÇÃO À CORRUPÇÃO</b></h6>

            <p>
                7.1. A <b>PARCEIRA</b> declara conhecer as normas de prevenção à corrupção previstas na legislação brasileira, a legislação brasileira contra a lavagem de dinheiro, assim como as normas e exigências constantes das políticas internas da <b>CCAPA</b> (em conjunto, “Leis Anticorrupção”) e se compromete a cumpri - las fielmente, por si e por seus sócios, administradores e colaboradores, bem como exigir o seu cumprimento pelos terceiros por ela contratados.
            </p>

            <p>
                7.2. A <b>PARCEIRA</b>, desde já, se obriga ainda a, no exercício dos direitos e obrigações previstos neste Contrato e no cumprimento de qualquer uma de suas disposições: (i) não dar, oferecer ou prometer qualquer bem de valor ou vantagem de qualquer natureza a agentes públicos ou a pessoas a eles relacionadas ou ainda quaisquer outras pessoas, empresas e / ou entidades privadas, com o objetivo de obter vantagem indevida, influenciar ato ou decisão ou direcionar negócios ilicitamente e(ii) adotar as melhores práticas de monitoramento e verificação do cumprimento das leis anticorrupção e lavagem de dinheiro, com o objetivo de prevenir atos de corrupção, fraude, práticas ilícitas ou lavagem de dinheiro por seus sócios, administradores, colaboradores e / ou terceiros por elas contratados.
            </p>

            <p>
                7.3. O não cumprimento por uma das Partes das leis anticorrupção, lavagem de dinheiro e / ou do disposto nesta Cláusula Sétima será considerado uma infração grave e conferirá à outra Parte o direito de, agindo de boa - fé, declarar suspenso ou rescindido imediatamente o Contrato, sem qualquer ônus ou penalidade, sem prejuízo de eventuais perdas e danos.
            </p>

            <h6><b>8. DA CONFIDENCIALIDADE</b></h6>

            <p>
                8.1. As Partes obrigam - se a manter o mais absoluto sigilo com relação a quaisquer dados, informações, materiais, produtos, sistemas, técnicas, estratégias, métodos de operação, pormenores, inovações, segredos comerciais, marcas, criações, especificações técnicas e comerciais adquiridas, entre outros, doravante denominados “Informações Confidenciais”, a que elas, as Partes, ou qualquer de seus diretores, profissionais e / ou prepostos venham a ter acesso, conhecimento ou que venha a lhe ser confiado em razão da celebração e execução deste Termo, comprometendo - se, outrossim, as Partes, a não revelar, reproduzir, utilizar ou dar conhecimento, em hipótese alguma, a terceiros, bem como a não permitir que nenhum de seus diretores, profissionais e / ou prepostos faça uso indevido desses “Informações Confidenciais”.
            </p>

            <p>
                8.2 .As obrigações de sigilo e confidencialidade previstas na cláusula acima vincularão as Partes durante a vigência desta Parceria e continuarão na hipótese de seu término, independentemente do motivo por que este venha a ocorrer e o seu desrespeito por parte de quaisquer das Partes, sem autorização expressa e por escrito da outra Parte, possibilitará a imediata rescisão deste Termo com as penalidades cabíveis e sem prejuízo da responsabilização pelas perdas e danos comprovadamente causados à(s) Parte Prejudicada e / ou a terceiros, e da responsabilidade penal a que responderão seus administradores em razão da quebra do sigilo.
            </p>

            <p>
                8.3. Pelo descumprimento dos itens 8.1 e 8.2 será devida pela Parte que descumpriu, o pagamento de perdas e danos à outra parte, sem prejuízo das demais medidas judiciais cabíveis.
            </p>

            <p>
                8.4. Obrigam - se as Partes a obter o prévio e expresso consentimento da outra Parte para eventual publicação de quaisquer relatórios, ilustrações, entrevistas ou detalhes relacionados ao objeto do instrumento específico da Parceria, bem como notificar prontamente à outra Parte por escrito, anteriormente a qualquer divulgação em virtude de lei, decreto ou ordem judicial ou, ainda, na hipótese de ter havido uma divulgação não autorizada das Informações Confidenciais.
            </p>

            <p>
                8.5. Excluem - se do compromisso de não utilização de Informações Confidenciais, sem prejuízos a eventuais previsões legais, as informações: (a) todo e qualquer material devidamente aprovado entre as Partes para divulgação nesta Parceria; (b) disponíveis ao público de outra forma que não pela divulgação das mesmas pelas Partes, desde que devidamente comprovado; (b) que já eram do conhecimento de uma ou de ambas as Partes antes da revelação em razão deste Termo e não estavam sujeitas a qualquer obrigação de serem mantidas em sigilo, desde que devidamente comprovado; (c) que a <b>CCAPA</b> ou a <b>PARCEIRA</b>, seus empregados e prepostos sejam obrigados a divulgar, por ordem judicial ou determinação de qualquer autoridade governamental, no exercício de seus poderes, observado o estabelecido no item 8.4.
            </p>


            <p>
                8.6. Findo ou rescindido o presente Termo, cada Parte devolverá à outra todas as Informações Confidenciais e todos os documentos recebidos, persistindo as obrigações de sigilo por prazo indeterminado ou até que tais informações venham a se tornar de domínio público.
            </p>

            <h6><b>9. DISPOSIÇÕES GERAIS</b></h6>

            <p>
                9.1. A execução do presente instrumento não implicará ônus de qualquer natureza para qualquer uma das Partes e as demais empresas que, de forma direta ou indireta, sejam suas controladoras, controladas ou coligadas.
            </p>

            <p>
                9.2. Nenhuma das condições deste Termo deve ser entendida como meio para constituir uma sociedade, joint venture, relação de parceria ou de representação comercial entre as Partes, nem ainda vínculo empregatício entre os empregados, prepostos, contratados e / ou subcontratados da <b>PARCEIRA</b> e a <b>CCAPA</b>, sendo cada uma, única, integral e exclusivamente responsável por seus atos e obrigações.
            </p>

            <p>
                9.3. As Partes declaram expressamente que não empregam ou utilizam, e se obrigam a não empregar e / ou utilizar, trabalho escravo, infantil ou análogo à escravidão na prestação dos seus serviços, bem como também não contratam e / ou mantém relações com quaisquer empresas que lhes prestem serviços(parceiros, fornecedores e / ou subcontratados), que utilizem, explorem e / ou por qualquer meio ou forma empreguem o trabalho infantil, nos termos previstos no ECA – Estatuto da Criança e do Adolescente, Lei nº. 8.069 / 90 e demais normas legais e / ou regulamentares em vigor.
            </p>

            <p>
                9.4. A <b>PARCEIRA</b> responderá juridicamente por qualquer ato que venha acarretar danos materiais ou morais à <b>CCAPA</b>, devendo aquela, no exercício de suas atividades como autônoma, zelar pela imagem e nome da <b>CCAPA</b>.
            </p>

            <p>
                9.5. É vedada a utilização do nome, imagem, logotipo, ou qualquer outra forma que identifique a <b>CCAPA</b>.A utilização somente se dará, em casos excepcionais após formalização do pedido pela <b>PARCEIRA</b> e anuência da <b>CCAPA</b>, por e - mail.
            </p>

            <p>
                9.6. A <b>PARCEIRA</b> se obriga, sob as penas previstas neste Termo e seus eventuais aditivos, a observar e cumprir rigorosamente o “Código de Conduta e Ética para terceiros” (disponível no endereço: <Link to={{ pathname: "https://iter.com.br/Compliance/CCET.pdf" }} target="_blank">https://iter.com.br/Compliance/CCET.pdf</Link>, ao qual adere e se compromete no que for relacionado ao cumprimento do objeto do Contrato.
            </p>

            <h6><b>10. FORO DE ELEIÇÃO</b></h6>

            <p>
                10.1. Fica eleito o Foro da Comarca da cidade do Rio de Janeiro, como competente para apreciar e dirimir todas e quaisquer questões oriundas desta parceria.
            </p>

            <dl>
        <dd>
          <table className="table-page" width="100%" cellSpacing="0">
            <tbody>
              <tr>
                <td
                  width="55"
                  height="14"
                  style={{ backgroundColor: '#c0c0c0' }}
                >
                  <p className="mb-0 px-2">
                    <span>
                      <strong>Versão</strong>
                    </span>
                  </p>
                </td>
                <td width="156" style={{ backgroundColor: '#c0c0c0' }}>
                  <p className="mb-0 px-2">
                    <span>
                      <strong>Item modificado</strong>
                    </span>
                  </p>
                </td>
                <td width="299" style={{ backgroundColor: '#c0c0c0' }}>
                  <p className="mb-0 px-2">
                    <span>
                      <strong>Motivo</strong>
                    </span>
                  </p>
                </td>
                <td width="113" style={{ backgroundColor: '#c0c0c0' }}>
                  <p className="mb-0 px-2">
                    <span>
                      <strong>Data</strong>
                    </span>
                  </p>
                </td>
              </tr>
              <tr>
                <td width="55" height="4">
                  <p className="my-2 px-2">
                    <span>01</span>
                  </p>
                </td>
                <td width="116">
                  <p className="my-2 px-2">
                    <span>Versão original</span>
                  </p>
                </td>
                <td valign="top" width="299">
                  <p className="my-2 px-2">
                    <span>N/A</span>
                  </p>
                </td>
                <td valign="top" width="113">
                  <p className="my-2 px-2">
                    <span>26/07/2024</span>
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
        </dd>
      </dl>
        </>
    )
}

export default DefaultContractPT;

