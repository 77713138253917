import React from "react";

import { Container, Row, Col } from "react-bootstrap";

import FormAffiliates from "./components/FormAffiliates/FormAffiliates";

import { Link as LinkRoll } from "react-scroll";

import bgTop from "../../assets/img/banner-afiliado-destinow.jpg";

import "./Affiliates.scss";
import { Helmet } from "react-helmet";
import i18next from "i18next";
import PageDafault from "../../templates/PageDafault";
import { useTranslation } from "react-i18next";
import HeaderAffiliates from "./components/HeaderAffiliates/HeaderAffiliates";

function Affiliates() {
    const { t } = useTranslation();
    return (
        <>
            <Helmet>
                <html lang={i18next.language} />
                <title>{`Afiliados | ${process.env.REACT_APP_SERVER_NAME}`}</title>
                <meta name="description" content="Participe do Programa de Afiliados Destinow e, com um portfólio de mais de 500 experiencias incluindo os principais atrativos com confirmações imediatas, encante o seu cliente trazendo agilidade e facilidade em suas compras." />
                <meta name="googlebot" content={process.env.REACT_APP_SERVER_ROBOTS} />

                {/* Essential META Tags */}
                <meta property="og:title" content={`Afiliados | ${process.env.REACT_APP_SERVER_NAME}`} />
                <meta property="og:type" content="TravelAgency" />
                <meta property="og:image" content={`${process.env.REACT_APP_SERVER_URL}${process.env.REACT_APP_SERVER_IMG_DEST}`} />
                <meta property="og:url" content={process.env.REACT_APP_SERVER_URL} />

                {/* Non-Essential, But Recommended */}
                <meta property="og:description" content="Participe do Programa de Afiliados Destinow e, com um portfólio de mais de 500 experiencias incluindo os principais atrativos com confirmações imediatas, encante o seu cliente trazendo agilidade e facilidade em suas compras." />
                <meta property="og:site_name" content={process.env.REACT_APP_SERVER_NAME} />

                {/* Non-Essential, But Required for Analytics */}
                {/* <meta property="fb:app_id" content="your_app_id" /> */}

                <meta name="robots" content={process.env.REACT_APP_SERVER_ROBOTS} />
                <link rel="canonical" href={process.env.REACT_APP_SERVER_URL} />

            </Helmet>

            <PageDafault removeHeader={true}>
                <HeaderAffiliates />

                {/* <Header /> */}
                <div className="bg-top-affiliates" style={{ backgroundImage: `url(${bgTop})` }}>
                    <Container className="container">
                        <Row className="w-100" style={{ zIndex: 9 }}>
                            <Col md={8}>
                                <h1 dangerouslySetInnerHTML={{ __html: t("affiliates.title") }}></h1>
                                <p dangerouslySetInnerHTML={{ __html: t("affiliates.subtitle") }}></p>
                                <div>
                                    <LinkRoll
                                        activeClass="active"
                                        to="bg-form"
                                        spy={true}
                                        smooth={true}
                                        offset={-80}
                                        duration={0}
                                        className="btn btn-primary mb-3"
                                    >
                                        {t("affiliates.registerNow")}
                                    </LinkRoll>
                                </div>
                                <p>
                                    <a href={"https://drive.google.com/file/d/1uOeetzY9tIX98GEkrFh4231MjLRBHXkt/view?usp=sharing"} target="_blank" rel="noreferrer">{t("affiliates.clickHere")}</a>{t("affiliates.textLinkAffiliate01")}<br/>{t("affiliates.and")} <a className="text-lowercase" href={"https://drive.google.com/file/d/1OnUAcq6BW9v-JVTiyzG0lM_4jr94EBei/view?usp=sharing"} target="_blank" rel="noreferrer">{t("affiliates.clickHere")}</a>{t("affiliates.textLinkAffiliate02")}
                                </p>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <div id="bg-form" className="bg-form-affiliates">
                    <Container className="container-content">
                        <div className="w-100 d-flex flex-column align-items-center pb-5">
                            <h3 className="pb-0">
                                {t("affiliates.startTodayTitle")}
                            </h3>
                            <p>
                                {t("affiliates.startTodaySubtitle")}
                            </p>
                        </div>
                        <div>
                            <FormAffiliates />
                        </div>
                    </Container>
                </div>
                {/* <div id="session-contacts">
                <Footer />
            </div> */}
            </PageDafault>
        </>
    )
}

export default Affiliates;