import './DefaultContract.scss';
//import i18next from 'i18next';
import DefaultContractPT from './content/DefaultContractPT';

function DefaultContract() {
    /* const lng = i18next.language === "en" ? "BR" : i18next.language === "es" ? "ES" : i18next.language === "en" ? "EN" : "BR"; */

    return (
        <div className='bg-default-contract'>
            <DefaultContractPT />
            {/* {lng === "BR" ? <DefaultContractPT /> : lng === "EN" ? <DefaultContractPT /> : lng === "ES" ? <DefaultContractPT /> : <DefaultContractPT />} */}
        </div>
    )
}

export default DefaultContract;