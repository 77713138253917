import i18next from "i18next";
import ExperienceRulesPT from "./ExperienceRulesPT";
import ExperienceRulesEN from "./ExperienceRulesEN";
import ExperienceRulesES from "./ExperienceRulesES";

function ExperienceRules() {
    return (
        <>
            {
                i18next.language === "pt"
                    ?
                    <ExperienceRulesPT />
                    :
                    i18next.language === "en"
                        ?
                        <ExperienceRulesEN />
                        :
                        i18next.language === "es"
                            ?
                            <ExperienceRulesES />
                            :
                            <ExperienceRulesPT />
            }
        </>
    )
}

export default ExperienceRules;