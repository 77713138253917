import React from "react";

import { Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export interface props {
    modalShow: any,
    setModalShow: any,
    modalLog: any,
    setModalLog: any;
    modalMessage: any;
    setModalMessage: any;
    redirect?: any;
    onClose?: any;
    reloadTable?: any;
};

const ModalShow: React.FC<props> = React.memo(({
    modalShow,
    setModalShow,
    modalLog,
    setModalLog,
    modalMessage,
    setModalMessage,
    redirect = null,
    onClose = () => { },
    reloadTable = () => { }
}: props) => {

    return (
        <>
            <Modal
                className="modalAuth"
                show={modalShow}
                onHide={() => {
                    console.log("AQUI", redirect)
                    console.log("AQUI", modalLog)
                    if (redirect !== null && modalLog === 0) {
                        console.log("JUJUBA")
                        window.location.href = redirect
                    }
                    reloadTable();
                    onClose();
                    setModalLog(null);
                    setModalMessage("Carregando");
                    setModalShow(false);
                }}
                aria-labelledby="contained-modal-title-vcenter"
            >
                <>
                    <Modal.Body
                        className="modal-body text-center sucess-pos d-flex justify-content-evenly flex-column"
                        style={{ height: "600px", overflow: 'unset' }}
                    >
                        {modalLog === null ? (
                            <>
                                <div className="loading-modal">
                                    <div className="load"></div>
                                </div>
                            </>
                        ) : (
                            <div>
                                {modalLog === 0 ? (
                                    <FontAwesomeIcon
                                        icon={["fal", "check-circle"]}
                                        size="5x"
                                        className="text-primary"
                                        style={{ fontSize: "7.5em" }}
                                    />
                                ) : (
                                    <FontAwesomeIcon
                                        icon={["fal", "times-circle"]}
                                        size="5x"
                                        className="text-primary"
                                        style={{ fontSize: "7.5em" }}
                                    />
                                )}
                            </div>
                        )}
                        {
                            typeof modalMessage === "object"
                                ?
                                <>
                                    <ul style={{ maxHeight: "200px", overflow: 'auto' }}>
                                        {modalMessage.map((elem: string, index: number) => {
                                            if (modalMessage.length !== index + 1) {
                                                return (
                                                    <>
                                                        <li>{index + 1} - {elem}</li>
                                                    </>
                                                )
                                            } else {
                                                return (<></>)
                                            }
                                        })}
                                    </ul>
                                </>
                                :
                                <>
                                    <div style={{ maxHeight: "320px", overflow: 'auto' }}>
                                        <p className="mb-4">{modalMessage}</p>
                                    </div>
                                </>
                        }
                        {modalLog === null &&
                            <div className="d-flex justify-content-center pt-3">
                                <button
                                    onClick={() => {
                                        if (redirect !== null && modalLog === 0) {
                                            window.location.href = redirect
                                        }

                                        if (modalLog === 0) {
                                            reloadTable();
                                        }

                                        setModalLog(null);
                                        setModalMessage("Carregando");
                                        setModalShow(false);
                                    }}
                                    className="btn btn-primary mx-2 w-25"
                                >
                                    Confirmar
                                </button>
                            </div>
                        }
                    </Modal.Body>
                </>
            </Modal>
        </>
    )
});

export default ModalShow;