import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
//import { Link } from "react-router-dom";

import BlackWeek from "../../assets/img/moldura 2.png";

import './TopHeader.scss';

function TopHeader() {
    const [t] = useTranslation();

    const [days, setDays] = useState<any>(7);
    const [hours, setHours] = useState<any>(0);
    const [minutes, setMinutes] = useState<any>(0);
    const [seconds, setSeconds] = useState<any>(4);

    const [hideModal, setHideModal] = useState<any>();

    useEffect(() => {
        let dataFinal: any = new Date(2022, 10, 23, 0, 0); ///escolher data limite
        let dataHoje: any = new Date();
        var diffMs = (dataFinal - dataHoje);
        var diffDays = Math.floor(diffMs / (1000 * 60 * 60 * 24));
        var diffHrs = Math.floor((diffMs % 86400000) / 3600000);
        var diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000);
        var diffSecs = 60 - dataHoje.getSeconds();

        setDays(diffMs >= 0 ? diffDays : 0);
        setHours(diffMs >= 0 ? diffHrs : 0);
        setMinutes(diffMs >= 0 ? diffMins : 0);
        setSeconds(diffMs >= 0 ? diffSecs : 0);
    }, [])

    useEffect(() => {
        let myInterval = setInterval(() => {
            if (seconds > 0) {
                setSeconds(seconds - 1);
            }
            if (seconds === 0) {
                setSeconds(59);
                if (minutes > 0) {
                    setMinutes(minutes - 1);
                }
                if (minutes === 0) {
                    setMinutes(59);
                    if (hours > 0) {
                        setHours(hours - 1);
                    }
                    if (hours === 0) {
                        setHours(24);
                        if (days === 0) {
                            setSeconds(0);
                            setHours(0);
                            setMinutes(0);
                        } else {
                            setDays(days - 1);
                        }
                    }
                }
            }
        }, 1000)
        return () => {
            clearInterval(myInterval);
        };
    });

    /* END - Esconder o modal após o dia 13/07/2022 */
    useEffect(() => {
        let date = new Date().toLocaleString('pt');

        let dateNow = date.split(" ")[0].split("/").reverse().join("");
        let timeNow = date.split(" ")[1].split(":").splice(0, 2).join("");

        if (`${dateNow}${timeNow}` < "202211230000") {
            setHideModal(false);
        } else {
            setHideModal(true);
        }
    }, [seconds])
    /* END - Esconder o modal após o dia 13/07/2022 */

    if (hideModal !== true) {
        return (
            <>
                <div className="top-header-dark text-center pt-1 pb-3">
                    <div className="d-flex justify-content-center align-items-center mb-2 mb-sm-0">
                        <div className="bg-come-here mx-3">
                            {t("topHeader.top.come")}
                        </div>
                        <div className="mx-0 mx-sm-3 d-flex justify-content-center">
                            <img src={BlackWeek} alt="Black Week" style={{ height: "90px" }} />
                        </div>
                    </div>
                    <div className="mx-3 mt-3">
                        {/* <small>{t("topHeader.top.missing")}</small> */}

                        <div className="d-flex justify-content-center py-1">
                            <div className="d-flex flex-column align-items-center">
                                <p className="mb-1">{days < 10 ? "0" + days : days}</p>
                                <small>{t("topHeader.top.days")}</small>
                            </div>

                            <span className="px-2">/</span>

                            <div className="d-flex flex-column align-items-center">
                                <p className="mb-1">{hours < 10 ? "0" + hours : hours}</p>
                                <small>{t("topHeader.top.hours")}</small>
                            </div>

                            <span className="px-2">:</span>

                            <div className="d-flex flex-column align-items-center">
                                <p className="mb-1">{minutes < 10 ? "0" + minutes : minutes}</p>
                                <small>{t("topHeader.top.minutes")}</small>
                            </div>

                            <span className="px-2">:</span>

                            <div className="d-flex flex-column align-items-center">
                                <p className="mb-1">{seconds < 10 ? "0" + seconds : seconds}</p>
                                <small>{t("topHeader.top.seconds")}</small>
                            </div>
                        </div>
                    </div>
                    <div className="mx-3 mt-2">
                        <a href={"https://ofertas.bondinho.com.br/cadastro_bf"} target="_blank" rel="noreferrer" className="btn btn-primary">
                            Inscreva-se
                        </a>
                    </div>
                </div>
            </>
        )
    } else {
        return (
            <>

            </>
        )
    }
}

export default TopHeader;