/* eslint-disable jsx-a11y/anchor-has-content */
import React from "react";

import { useTranslation } from "react-i18next";

import Modal from 'react-bootstrap/Modal'
import ExchangePolicy from "../StoresList/ExchangePolicy/ExchangePolicy";

import "../StoresList/ExchangePolicy/ExchangePolicy.scss";

function GlobalModal(props: any) {
  const [t] = useTranslation();
  
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      className="modal-exchange-policy"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {t("exchangePolicy.title")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ExchangePolicy />
      </Modal.Body>
      {/* <Modal.Footer>
          <Button onClick={props.onHide}>Fechar</Button>
        </Modal.Footer> */}
    </Modal>
  )
}

export default GlobalModal;

// Botão do modal (Colocar na página onde será utilizado o Modal)
/* const [modalShow, setModalShow] = React.useState(false);

  return (
    <>
      <Button variant="primary" onClick={() => setModalShow(true)}>
        Abrir Modal
      </Button>

      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </>
  );
} */