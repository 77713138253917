import React from 'react';

function PrivacyNoticeCaracolPT() {
  return (
    <>
      <br />
      <strong>QUEM SOMOS</strong>
      <br />

      <p className="px-3">
        Nós vamos além de lindas vistas. Mais do que um dos mais belos pontos
        turísticos do Brasil, a nossa missão é transportar pessoas para um mundo
        de encantamento, lazer e entretenimento, gerando experiências felizes,
        sustentáveis, inesquecíveis e que valorizem a cultura brasileira.
        Investimos constantemente em segurança, conforto e inovação. Surgimos de
        uma vontade visionária e incontrolável de conectar pessoas a esse
        espetáculo da natureza. Somos a combinação de um trabalho em equipe:
        natureza e homem juntos em perfeita harmonia. Somos um marco natural,
        geológico, histórico e turístico da cidade do Rio de Janeiro. Marco
        natural, por ser um referencial para os navegadores que vêm à Baía de
        Guanabara. Marco geológico, pois é um sítio geológico com datações
        precisas da história do planeta. Marco histórico, porque aos seus pés,
        Estácio de Sá, em 1º de março de 1565, fundou a Cidade de São Sebastião
        do Rio de Janeiro. E, marco turístico, porque a inauguração do
        teleférico do Pão de Açúcar projetou o nome do Brasil no exterior. Fomos
        o primeiro teleférico instalado no Brasil e o terceiro no mundo.
      </p>

      <br />
      <strong>1. OBJETIVOS</strong>
      <br />

      <p className="px-3">
        Este Aviso rege o seu acesso e uso do site, dentro do Brasil,
        disponibilizados, controlados e operados pelo{' '}
        <strong>
          PARQUE BONDINHO PÃO DE AÇÚCAR®, COMPANHIA CAMINHO AÉREO PÃO DE AÇÚCAR
          S A (“CCAPA” ou “PARQUE BONDINHO”)
        </strong>
        , com sede na Avenida Pasteur 520, Urca Cidade e Estado de Rio de
        Janeiro, CEP: 22.290-240.
      </p>

      <p className="px-3">
        Este Aviso de Privacidade tem como objetivo, informar aos nossos
        Usuários, seja no ambiente digital (site), seja no ambiente físico
        (dependências do PARQUE BONDINHO), como tratamos os seus dados pessoais,
        de forma que transitem com tranquilidade e saibam que a sua privacidade
        é uma das nossas preocupações.
      </p>

      <p className="px-3">
        A sua privacidade é importante para nós do <b>PARQUE DO BONDINHO</b>.
        Por isso, protegemos os seus dados pessoais. Elaboramos este aviso de
        privacidade, que estabelece a forma como o <b>PARQUE DO BONDINHO</b>{' '}
        trata os dados pessoais dos colaboradores, clientes, fornecedores e
        demais stakeholders. Estes dados podem estar armazenados fisicamente em
        escritórios ou digitalmente em sistemas de informações.
      </p>

      <p className="px-3">
        Este Aviso descreve a forma pelas quais o <b>PARQUE DO BONDINHO</b>{' '}
        trata e protege os seus Dados Pessoais, sejam coletados diretamente por
        nós, recebidos por terceiros e/ou transferidos a terceiros. Efetiva-se o
        compromisso de agir com transparência e ética em todas as operações,
        estendendo-se aos órgãos públicos e entidades com as quais o{' '}
        <b>PARQUE DO BONDINHO</b> tenha interação.
      </p>

      <p className="px-3">
        Efetiva-se o compromisso de agir com transparência e ética em todas as
        operações, estendendo-se aos órgãos públicos e entidades com as quais o{' '}
        <b>PARQUE DO BONDINHO</b> tenha interação.
      </p>

      <small className="px-3 pt-2">
        Para algumas atividades de tratamento, a depender da finalidade, nós
        utilizamos operadores de tratamento para nos auxiliar com o tratamento
        de seus dados pessoais. Isso ocorre, por exemplo, em relação a algumas
        atividades contábeis, de logística ou marketing. Nós elaboramos uma
        sessão dedicada a lhe informar sobre a nossa relação com esses
        operadores e sobre os seus direitos nessa situação. Desde já, é
        importante que você saiba que nós adotamos todas as medidas necessárias
        e usuais no mercado para resguardar a segurança de seus dados nestas
        situações.
      </small>

      <small className="px-3 pt-2">
        Em relação ao tratamento dos seus dados pessoais, em determinadas
        ocasiões definimos as respectivas finalidades e os meios, ou seja,
        agimos como <b>CONTROLADORES</b>.
      </small>

      <small className="px-3 pt-2">
        O <b>PARQUE DO BONDINHO</b>, é empresa integrante do <b>GRUPO ITER</b>,
        que é composta pelas empresas listadas abaixo, onde constam informações
        de identificação e estrutura caso você queira entrar em contato conosco:
      </small>

      {/* <div
        className="mx-3 p-4 text-center mb-3"
        style={{ border: '1px solid red' }}
      >
        <strong>
          Disponibiliza-se, ao final deste Aviso, o canal de contato específico
          para atendimento do público sobre estas questões.
        </strong>
      </div> */}
      <br />

      <dl>
        <dd>
          <table className="table-page" width="50%" cellSpacing="0">
            <tbody>
              <tr>
                <td
                  width="70%"
                  height="14"
                  style={{ backgroundColor: '#c0c0c0' }}
                >
                  <p className="mb-0 px-2">
                    <span>
                      <strong>Razão Social</strong>
                    </span>
                  </p>
                </td>
                <td width="30%" style={{ backgroundColor: '#c0c0c0' }}>
                  <p className="mb-0 px-2">
                    <span>
                      <strong>CNPJ</strong>
                    </span>
                  </p>
                </td>
              </tr>
              <tr>
                <td width="55" height="4">
                  <p className="my-2 px-2">
                    <span>ITER PARTICIPAÇÕES S.A.</span>
                  </p>
                </td>
                <td width="116">
                  <p className="my-2 px-2">
                    <span>43.697.470/0001-69</span>
                  </p>
                </td>
              </tr>
              <tr>
                <td width="55" height="4">
                  <p className="my-2 px-2">
                    <span>TRAVEL ACTIVITIES HOLDING. </span>
                  </p>
                </td>
                <td width="116">
                  <p className="my-2 px-2">
                    <span>44.317.977/0001-02</span>
                  </p>
                </td>
              </tr>
              <tr>
                <td width="55" height="4">
                  <p className="my-2 px-2">
                    <span>C2RIO VIAGENS E TURISMO LTDA</span>
                  </p>
                </td>
                <td width="116">
                  <p className="my-2 px-2">
                    <span>05.873.416/0001-38</span>
                  </p>
                </td>
              </tr>
              <tr>
                <td width="55" height="4">
                  <p className="my-2 px-2">
                    <span>DESTINOW VIAGENS E TURISMO LTDA. </span>
                  </p>
                </td>
                <td width="116">
                  <p className="my-2 px-2">
                    <span>44.743.360/0002-30</span>
                  </p>
                </td>
              </tr>
              <tr>
                <td width="55" height="4">
                  <p className="my-2 px-2">
                    <span>DESTITECH TECNOLOGIA DA INFORMAÇÃO LTDA.</span>
                  </p>
                </td>
                <td width="116">
                  <p className="my-2 px-2">
                    <span>45.759.751/0001-24</span>
                  </p>
                </td>
              </tr>
              <tr>
                <td width="55" height="4">
                  <p className="my-2 px-2">
                    <span>PÃO DE AÇÚCAR EMPREENDIMENTOS TURÍSTICO LTDA. </span>
                  </p>
                </td>
                <td width="116">
                  <p className="my-2 px-2">
                    <span>42.274.233/0001-22</span>
                  </p>
                </td>
              </tr>
              <tr>
                <td width="55" height="4">
                  <p className="my-2 px-2">
                    <span>NOVO CARACOL E TAINHAS S.A.</span>
                  </p>
                </td>
                <td width="116">
                  <p className="my-2 px-2">
                    <span>48.255.552/0001-77</span>
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
        </dd>
      </dl>

      <strong>2. ABRANGÊNCIA</strong>
      <br />

      <p className="px-3">
        Este Aviso aplica-se aos clientes, fornecedores, visitantes, demais
        stakeholders do <b>PARQUE BONDINHO</b>, devendo ser implementada na
        Plataforma e poderá ser lida por todos os clientes, parceiros e
        terceiros interessados.
      </p>

      <p className="px-3">
        A leitura e ciência deste Aviso poderá ser realizada através de
        checkbox. Isso indicará que você está ciente com a forma que tratamos e
        utilizamos os seus dados e informações. Este documento deve ser lido em
        conjunto com o nosso Termos e Condições de Uso.{' '}
      </p>
      <br />

      <strong>3. SIGLAS E DEFINIÇÕES</strong>
      <br />

      <p className="px-3">
        <b>3.1. Dados Pessoais:</b> Informação relacionada à pessoa natural
        identificada ou identificável, ou seja, são quaisquer informações que
        identifiquem a pessoa física de forma direta ou indireta, como: CPF,
        endereço, voz, imagem, placa de carro e impressão digital. De acordo com
        a Lei Geral de Proteção de Dados Pessoais – LGPD – os dados pessoais
        possuem proteção legal, sendo seus titulares os detentores dos direitos
        sobre eles e o que fazer com eles. Se você fornecer seus dados pessoais
        ao <b>PARQUE BONDINHO</b> é nosso dever dar o tratamento adequado aos
        mesmos e zelar para que o armazenamento destes seja realizado de forma
        segura.
      </p>

      <p className="px-3">
        <b>3.2. Titular de Dados:</b> Trata-se de pessoa física a quem os dados
        pessoais se referem. Ou seja: Você. Podendo, ser ainda, um candidato à
        vaga, um visitante do nosso site, visitantes do nosso Parque e etc.
      </p>

      <p className="px-3">
        <b>3.3. Dados Pessoais Sensíveis:</b> Referem-se aos dados pessoais
        relacionados a convicção religiosa, origem racial ou étnica, opiniões
        políticas, filiação a sindicato ou a organização de caráter religioso,
        filosófico ou político, dado referente à saúde ou à vida sexual, dado
        genético ou biométrico, quando vinculado a uma pessoa natural;
      </p>

      <p className="px-3">
        <b>3.4. Controlador:</b> É a pessoa natural ou jurídica, de direito
        público ou privado, a quem competem as decisões referentes ao Tratamento
        de Dados Pessoais, que neste caso, somos nós, o <b>PARQUE BONDINHO</b>.
      </p>

      <p className="px-3">
        <b>3.5. Operador:</b> São nossos parceiros, fornecedores e prestadores
        de serviços, que realizam o tratamento de dados pessoais em nome do{' '}
        <b>PARQUE BONDINHO</b>, auxiliando no tratamento dos dados pessoais que
        coletamos para ofertar nossos serviços e oferecer a melhor experiência
        ao nosso usuário.
      </p>

      <p className="px-3">
        <b>3.6. Encarregado (a) pelo Tratamento de Dados Pessoais:</b> É a
        pessoa indicada pelo controlador e operador para atuar como canal de
        comunicação entre o controlador, os titulares dos dados e a Autoridade
        Nacional de Proteção de Dados (ANPD).
      </p>

      <p className="px-3">
        <b>3.7. Tratamento:</b> É toda e qualquer operação realizada com Dados
        Pessoais, como as que se referem a coleta, produção, recepção,
        classificação, utilização, acesso, reprodução, transmissão,
        distribuição, processamento, arquivamento, armazenamento, eliminação,
        avaliação ou controle da informação, modificação, comunicação,
        transferência, difusão ou extração.
      </p>

      <p className="px-3">
        <b>3.8. Plataformas:</b> É o site, e-commerce, mídias sociais e canais
        de comunicação do CARACOL.
      </p>

      <p className="px-3">
        <b>3.9. Cookies:</b> Cookies são pequenos arquivos de texto que, através
        do navegador de internet (browser) registram informações de acessos e
        localização dos visitantes que acessem o nosso website. Tais informações
        são armazenadas nos dispositivos (móveis ou não) dos visitantes, podendo
        tornar a experiência e acesso mais rápida.
      </p>

      <p className="px-3">
        <b>3.10. Terceiros:</b> Incluem, mas não se limitam a clientes,
        fornecedores, candidatos à uma vaga e visitantes e usuários em geral do
        site do <b>PARQUE BONDINHO</b> (tais partes são referidas aqui
        individualmente como “Terceiro” ou coletivamente como “Terceiros”).
      </p>

      <p className="px-3">
        <b>3.11. Banco de Dados:</b> Conjunto estruturado de Dados Pessoais,
        estabelecido em um ou em vários locais, em suporte eletrônico ou físico;
      </p>

      <p className="px-3">
        <b>3.12. “Transferência Internacional de Dados”:</b> É a transferência
        de dados pessoais para país estrangeiro ou organismo internacional do
        qual o país seja membro.
      </p>

      <p className="px-3">
        <b>3.13. Lei Geral de Proteção de Dados Pessoais (LGPD):</b> A Lei nº
        13.709 de 14 de agosto de 2018, também conhecida como Lei Geral de
        Proteção de Dados Pessoais, ou ainda LGPD, dispõe legalmente sobre o
        tratamento de Dados Pessoais no Brasil, tanto por meios digitais e
        físicos, por pessoa natural ou por pessoa jurídica de direito público ou
        privado, com o objetivo de proteger os direitos fundamentais de
        liberdade e de privacidade e o livre desenvolvimento da personalidade da
        pessoa natural.
      </p>
      <br />

      <strong>4. DADOS FORNECIDOS AO PARQUE BONDINHO</strong>
      <br />

      <p className="px-3">
        <b>4.1. </b> Os Dados Pessoais fornecidos serão utilizados para
        viabilizar a prestação de serviço, oferecer benefícios e cumprir com as
        obrigações previstas em leis ou regulamentações específicas, e quando
        constatadas necessidade, após o fim do tratamento, ou conforme designado
        por normativa vigente, será realizado o descarte com o cuidado
        específico.
      </p>

      <p className="px-3">
        <b>4.2. </b> Assim, a coleta de Dados Pessoais é realizada por
        diferentes meios, a depender de qual serviço oferecido pelo{' '}
        <b>PARQUE BONDINHO</b> será utilizado. Abaixo, aponta-se quais operações
        coletam dados pessoais, assim como quais tipos de dados são coletados
        através delas:
      </p>

      <p className="px-3 mx-4">
        <b>a) CADASTRO:</b> E-mail, nome, País, Estado, CPF, Passaporte,
        telefone;
      </p>
      <p className="px-3 mx-4">
        <b>b) COMPRA DE BILHETES ON LINE:</b> E-mail, CPF, Passaporte, País,
        Estado, telefone, dados do cartão de crédito, dados PIX;
      </p>
      <p className="px-3 mx-4">
        <b>c) COMPRA DE BILHETES NO TOTEM DE AUTOATENDIMENTO:</b> Email, CPF,
        Passport, Country, State, telephone, credit card data, PIX data;
      </p>
      <p className="px-3 mx-4">
        <b>d) COMPRA DE BILHETES NO AMBIENTE FÍSICO:</b> RG, dados do cartão de
        crédito, comprovante de residência, carteirinha comprovante de
        estudante, e outros documentos necessários para comprovação do
        benefício;
      </p>
      <p className="px-3 mx-4">
        <b>e) NEWSLETTER:</b> Nome, e-mail;
      </p>
      <p className="px-3 mx-4">
        <b>f) EDUCA BONDINHO:</b> Nome, e-mail e telefone do professor ou
        diretor responsável, foto e filmagem do aluno;
      </p>
      <p className="px-3 mx-4">
        <b>g) BONDE EXCLUSIVO:</b> Nome, e-mail, telefone;
      </p>
      <p className="px-3 mx-4">
        <b>h) VISITAS FÍSICAS AO PARQUE TURÍSTICO (MONTANHISTAS): </b> Nome, CPF
        e telefone;
      </p>
      <p className="px-3 mx-4">
        <b>i) NEGÓCIOS E PARCERIAS: </b> Nome, e-mail, telefone;
      </p>
      <p className="px-3 mx-4">
        <b>j) DEPOIMENTOS:</b> E-mail, nome, imagem;
      </p>
      <p className="px-3 mx-4">
        <b>k) FALE COM A GENTE:</b> Nome, e-mail, telefone;
      </p>
      <p className="px-3 mx-4">
        <b>l) TROCAS E DEVOLUÇÕES:</b> nota fiscal, nome completo, CPF/RG;
      </p>
      <p className="px-3 mx-4">
        <b>m) CONTATOS VIA WHATSAPP:</b> nome, e-mail e celular
      </p>
      <p className="px-3 mx-4">
        <b>n) CONTATOS VIA REDES SOCIAIS:</b> nome e e-mail.
      </p>
      <p className="px-3 mx-4">
        <b>o) SIG (Sistema Integrado de Gestão): </b> e-mail, CPF, passaporte,
        país, estado, telefone, endereço e celular.
      </p>
      <p className="px-3 mx-4">
        <b>p) TRABALHE CONOSCO:</b> Nome, data de nascimento, e-mail, currículo;
      </p>
      <p className="px-3 mx-4">
        <b>q) MÓDULO AGÊNCIA :</b> Razão social, nome fantasia, CNPJ, e-mail da
        empresa, telefone da empresa, inscrição municipal e/ou estadual, nome do
        representante, e-mail do representante, celular do representante, CPF,
        RG e data de nascimento do representante legal.
      </p>
      <p className="px-3 mx-4">
        <b>r) CANCELAMENTO DE BILHETES E/OU RESERVA :</b> Nome, e-mail,
        CPF/Passaporte, banco, agência, conta bancária;
      </p>
      <p className="px-3 mx-4">
        <b>s) CANAL DE CONDUTA E ÉTICA:</b>(na hipótese em que o titular optar
        por se identificar) – Nome, CPF, e-mail, data de nascimento, telefone,
        celular
      </p>
      <p className="px-3 mx-4">
        <b>t) PORTAL DO TITULAR (LGPD) :</b> Nome, e-mail e CPF.
      </p>
      <br />

      <strong>5. DADOS COLETADOS PELO PARQUE BONDINHO</strong>
      <br />

      <p className="px-3">
        <b>5.1.</b> As informações anteriores elencadas são fornecidas para o{' '}
        <b>PARQUE BONDINHO</b>, mas existem dados que podem ser coletados
        automaticamente, quando utilizados os serviços, por exemplo:
      </p>

      <p className="px-3 mx-4">
        <b>a)</b> Dados de dispositivos móveis a partir dos acessos à plataforma
        digital e outras informações como o tipo e versão do navegador
        utilizado;
      </p>
      <p className="px-3 mx-4">
        <b>b)</b> Dados de logs;
      </p>
      <p className="px-3 mx-4">
        <b>c)</b> O endereço de IP;
      </p>
      <p className="px-3 mx-4">
        <b>d)</b> Outros dados coletados através Cookies, conforme descrito na
        Política de Cookies.
      </p>

      <p className="px-3">
        <b>5.2.</b> A finalidade do uso destes dados é para melhorar o
        desempenho, segurança e funcionalidade das nossas plataformas, veicular
        publicidades nos ambientes digitais e manter o registro de acesso aos
        websites.
      </p>
      <br />

      <strong>6. FINALIDADE DO TRATAMENTO</strong>
      <br />

      <p className="px-3">
        <b>6.1.</b> O <b>PARQUE BONDINHO</b> trata suas informações pessoais
        para cumprir obrigações legais, contratuais e fornecer-lhe nossos
        produtos e serviços. Nunca coletaremos dados pessoais desnecessários e
        não trataremos suas informações de quaisquer outras formas que não as
        especificadas neste Aviso.
      </p>

      <p className="px-3">
        <b>6.2.</b> O <b>PARQUE BONDINHO</b> coleta Dados Pessoais inseridos por
        Terceiros no site do <b>PARQUE BONDINHO</b>, por meio de cookies,
        conforme especificado na Política de Cookies, ou fornecidos por qualquer
        outra forma, como: verbal, escrito ou chamadas telefônicas/WhatsApp,
        durante qualquer operação comercial, administrativa ou promocional.
      </p>

      <p className="px-3">
        <b>6.3.</b> Se o Titular de Dados fizer uma compra de bilhetes no Site
        do <b>PARQUE BONDINHO</b>, utilizar os canais de comunicação como “fale
        conosco”, “SAC”, “Chat”, “Cadastra-se”; “Trabalhe Conosco”, redes
        sociais, e demais meios de contatos disponíveis no Site, os tipos de
        Dados Pessoais e a forma como nós os coletamos poderá variar. Além
        disso, temos as seguintes hipóteses de finalidades:
      </p>

      <p className="px-3 mx-4">
        <b>a)</b> Para a venda de ingressos com descontos do{' '}
        <b>PARQUE BONDINHO</b>, o cancelamento e/ou reagendamento de bilhetes,
        bem como para a conferência e concessão de benefícios previstos em Lei;
      </p>

      <p className="px-3 mx-4">
        <b>b)</b> Para identificar e/ou enviar conteúdo relevante sobre
        determinada preferência ou interesse manifestado por você ao{' '}
        <b>PARQUE BONDINHO</b>, incluindo, newsletters, eventos, convites,
        promoções, posts em redes sociais, mensagens via plataformas digitais,
        lembretes, notas de agradecimento, entre outros;
      </p>

      <p className="px-3 mx-4">
        <b>c)</b> Para fornecer a você e aos nossos parceiros de negócios, uma
        experiência mais consistente na interação com a empresa, principalmente
        aprendendo com a forma como você utiliza e interage com os sites,
        aplicações (App) e outras soluções tecnológicas. Isto poderá incluir:
        (i) personalizá-los de acordo com as preferências e seus interesses,
        tornando-os mais compatíveis com a sua tecnologia ou facilitando a sua
        utilização de outra forma; (ii) manter a segurança dos mesmos e
        protegê-los de qualquer outra forma; e (iii) desenvolver novos sites,
        aplicações (App), produtos e serviços do <b>PARQUE BONDINHO</b>;
      </p>

      <p className="px-3 mx-4">
        <b>d)</b> Para tratamento e armazenamento de dados de colaboradores,
        estagiários e terceiros prestadores de serviços no{' '}
        <b>PARQUE BONDINHO</b>. Somente trataremos os dados pessoais necessários
        para atender a finalidade exigida pela relação trabalhista e para que a
        execução do contrato de trabalho ou de prestação de serviços seja
        possível, considerando-se a legislação vigente;
      </p>

      <p className="px-3 mx-4">
        <b>e)</b> Para a defesa e administração dos interesses do{' '}
        <b>PARQUE BONDINHO</b>, de seus sócios e parceiros de negócio, inclusive
        para cumprimento de determinações legais, regulações de órgãos
        governamentais, autoridades fiscais, Poder Judiciário e/ou outra
        autoridade competente;
      </p>

      <p className="px-3 mx-4">
        <b>f)</b> Para o tratamento e armazenamento de dados de clientes,
        fornecedores e prestadores de serviços do <b>PARQUE BONDINHO</b> como
        também para a celebração de contratos relacionados;
      </p>

      <p className="px-3 mx-4">
        <b>g)</b> Para prestar serviços ou efetuar transações solicitadas por
        você ou por um parceiro de negócios; fornecendo informações sobre
        produtos, serviços e transações do <b>PARQUE BONDINHO</b>;
      </p>

      <p className="px-3 mx-4">
        <b>h)</b> Para o cumprimento de legislação aplicável ao setor de
        turismo, ambiental, propriedade intelectual, dentre outros setores. Isto
        poderá incluir: (i) cumprir as nossas obrigações de conservação de
        determinados registros durante períodos mínimos; (ii) estabelecer,
        exercer ou atuar em procedimentos legais e reclamações judiciais ou
        outras; (iii) cumprir legislações, regulamentos, ordens judiciais ou
        outros procedimentos legais; (iv) detectar, prevenir e responder a
        fraudes, infrações aos nossos contratos ou acordos, outras utilizações
        indevidas dos sites, aplicações (App), produtos ou serviços da empresa;
        e (v) proteger os direitos ou a propriedade do <b>PARQUE BONDINHO</b> ou
        a saúde, segurança, bem-estar, direitos ou propriedade do titular ou de
        terceiros;
      </p>

      <p className="px-3 mx-4">
        <b>i)</b> Para o cumprimento de qualquer outra demanda solicitada por
        você ao <b>PARQUE BONDINHO</b>, seja respondendo às suas dúvidas,
        incluindo pedidos de apoio ao cliente; ou convidando você a participar
        ou informá-lo sobre os resultados de pesquisa de satisfação ou de
        pesquisa de mercado;
      </p>

      <p className="px-3 mx-4">
        <b>j)</b> O <b>PARQUE BONDINHO</b> também poderá usar as suas
        informações pessoais para outros efeitos, de forma consistente com o
        contexto no qual as informações foram coletadas, ou com o consentimento
        expressado por você.
      </p>

      <p className="px-3 mx-4">
        <b>k)</b> Para registro e/ou relato no Canal de Conduta e Ética;
      </p>
      <br />

      <strong>7. DIREITOS DO USUÁRIO</strong>
      <br />

      <p className="px-3">
        <b>7.1.</b> Em cumprimento à regulamentação aplicável, no que diz
        respeito ao tratamento de Dados Pessoais, o <b>PARQUE BONDINHO</b>{' '}
        respeita e garante ao titular, através de solicitação, os seguintes
        direitos, conforme previsão do Art. 18 da Lei 13.709/2018:
      </p>

      <p className="px-3 mx-4">
        <b>a) Direito de Confirmação e Acesso:</b> Esse direito permite que você
        confirme se o <b>PARQUE BONDINHO</b> utiliza os seus dados pessoais, e
        em caso positivo, ter acesso a uma cópia dos dados utilizados;
      </p>

      <p className="px-3 mx-4">
        <b>b) Direito de Retificação:</b> Este direito permite que você solicite
        a correção e/ou retificação de suas informações pessoais que estejam
        incompletos, inexatos ou desatualizados, caso identifique esta
        necessidade;
      </p>

      <p className="px-3 mx-4">
        <b>
          c) Direito de solicitar a Anonimização, o Bloqueio ou a Eliminação:
        </b>{' '}
        Este direito permite que você nos solicite: (i) anonimização dos seus
        dados pessoais, de modo que eles não possam mais ser relacionados ao
        indivíduo, e assim deixando de ser Dados Pessoais; (ii) o bloqueio dos
        seus dados pessoais, suspendendo-os temporariamente; e (iii) a
        eliminação dos seus dados pessoais, se você se opôs ao uso de seus
        dados, momento em que o <b>PARQUE BONDINHO</b> deletará todos os seus
        dados, sem possibilidade de reversão, salvo se houver qualquer outra
        razão para a sua manutenção, como eventual obrigação legal de retenção
        de dados ou necessidade de preservação destes para o resguardo de
        direitos da aplicação;
      </p>

      <p className="px-3 mx-4">
        <b>d) Direito à Portabilidade:</b> O titular de dados tem o direito de
        obter do <b>PARQUE BONDINHO</b> a transmissão de seus dados para outro
        responsável ou mesmo para a parte interessada, por meio de um formato
        estruturado de uso habitual e leitura mecânica, a depender de
        regulamentação da Autoridade Nacional de Proteção de Dados;
      </p>

      <p className="px-3 mx-4">
        <b>
          e) Direito sobre a Possibilidade de Não Fornecer Consentimento e suas
          Consequências:
        </b>{' '}
        Esse direito permite que você tenha informações claras e completas sobre
        a possibilidade e as consequências de não fornecer o consentimento. Na
        hipótese em que o consentimento solicitado for negado, poderá ocorrer
        uma limitação de acesso aos serviços oferecidos pelo{' '}
        <b>PARQUE BONDINHO</b>;
      </p>

      <p className="px-3 mx-4">
        <b>f) Direito de Revogar o seu Consentimento:</b> Você tem o direito de
        retirar o seu consentimento em relação aos termos deste Aviso de
        Privacidade. No entanto, isso não afetará a legalidade de qualquer
        processamento realizado anteriormente. Se você retirar o seu
        consentimento, talvez não possamos fornecer determinados serviços do{' '}
        <b>PARQUE BONDINHO</b>;
      </p>

      <p className="px-3 mx-4">
        <b>g) Direito de Oposição ao Processamento:</b> O titular dos dados tem
        o direito de se opor ao processamento de seus dados por razões
        relacionadas à sua situação particular. Nesta hipótese, você poderá
        contestar onde e em que contexto estamos tratando seus dados pessoais
        para diferentes finalidades, e se opor, solicitando a interrupção, por
        meio do canal de contato do DPO informado na sessão específica;
      </p>

      <p className="px-3 mx-4">
        <b>
          h) Apresentar petição à Autoridade Nacional de Proteção de Dados
          (ANPD):
        </b>{' '}
        O titular de dados poderá demandar diretamente à ANPD para garantir seus
        Direitos.
      </p>

      <p className="px-3">
        <b>7.2.</b> Para validar os direitos dos titulares dos dados pessoais,
        será necessário solicitar suas informações específicas, para nos ajudar
        a confirmar sua identidade e garantir seu direito e segurança dos seus
        dados pessoais. Nesses casos, solicitamos contato direto com responsável
        pelo tratamento de dados, através do canal:{' '}
        <a href="https://portaldotitular.bondinho.com.br/titular_bondinho">
          https://portaldotitular.bondinho.com.br/titular_bondinho
        </a>
        .
      </p>

      <p className="px-3">
        <b>7.3.</b> O <b>PARQUE BONDINHO</b> responderá a todas as solicitações
        legítimas, no prazo razoável, salvo nos casos em que não será possível o
        atendimento em razão do cumprimento de obrigações legais ou
        regulatórias, bem como para exercer ou resguardar direitos ou prevenir
        fraudes.
      </p>

      <p className="px-3">
        <b>7.4.</b> O <b>PARQUE BONDINHO</b> esclarece que caso os seus dados
        pessoais, sejam requisitados judicialmente, nos reserva no direito de
        fornecer seus dados e informações sobre você de acordo com as leis
        nacionais.
      </p>

      <p className="px-3">
        <b>7.5.</b> Podemos solicitar alguns Dados Pessoais adicionais para
        comprovar a sua identidade, visando evitar incidentes e fraudes e
        assegurar, sempre, a privacidade de nossos clientes.
      </p>

      <p className="px-3">
        <b>7.6.</b> Os Usuários poderão exercer qualquer outro direito previsto
        em lei, mesmo que aqui não elencado. Como exceção, é possível que sua
        solicitação não seja atendida, mas se isso acontecer, explicaremos o
        motivo. No entanto, adiantamos que isso somente ocorrerá caso haja
        fundamento legal.
      </p>
      <br />

      <strong>8. COMPARTILHAMENTO DE DADOS PESSOAIS</strong>
      <br />

      <p className="px-3">
        <b>8.1.</b> Compartilhamos os dados pessoais sob o nosso controle de
        maneira lícita e transparente. Note que todos os operadores que atuam em
        nosso nome apenas tratam seus dados de acordo com nossas instruções e
        cumprem integralmente este Aviso de Privacidade, bem como as legislações
        de proteção de dados e quaisquer outras medidas de confidencialidade e
        segurança apropriadas.
      </p>

      <p className="px-3">
        <b>8.2.</b> Alguns dados pessoais podem ser compartilhados com outras
        empresas do grupo ou empresas parceiras e prestadoras de serviços para
        realizar as nossas operações comerciais diárias e para nos permitir
        manter um relacionamento adequado com você. Em nenhuma hipótese
        comercializamos os dados e informações que você nos fornece. Em alguns
        casos, os seus dados pessoais são fornecidos aos nossos fornecedores ou
        parceiros para tornar possível uma determinada prestação do serviço ou
        para melhorar a experiência dos clientes e lojistas, como, por exemplo,
        para empresas que processam os pagamentos da compra do bilhete para que
        você usufrua dos nossos serviços; empresas/agências de publicidade,
        para, entre outros, desenvolver sites, selecionar e produzir anúncios
        condizentes com o seu perfil, conforme autorizado.
      </p>

      <p className="px-3">
        <b>8.3.</b> É bom que você saiba que nós apenas trabalhamos com
        parceiros que possam garantir um nível adequado de proteção aos seus
        dados pessoais. Estes compartilhamentos estão protegidos pelos contratos
        que possuímos com estes parceiros. Há casos em que somos obrigados, por
        lei ou regulamento, a compartilhar seus dados pessoais com terceiros,
        por exemplo: para o fornecimento de informações pessoais a órgãos
        governamentais, autoridades fiscais, Poder Judiciário e/ou outra
        autoridade competente para o atendimento de obrigações existentes em
        lei.
      </p>

      <p className="px-3">
        <b>8.4.</b> É possível que, em algumas situações, precisemos divulgar
        seus dados pessoais a terceiros. Mas fique tranquilo, o compartilhamento
        de Dados Pessoais com terceiros ocorrerá apenas para atingir alguma
        finalidade legítima e específica e previamente informada a você. O
        importante é você saber que sempre seremos transparentes com você em
        relação aos seus dados.
      </p>
      <br />

      <strong>9. TRANSFERÊNCIA INTERNACIONAL DE DADOS</strong>
      <br />

      <p className="px-3">
        <b>9.1.</b> O <b>PARQUE BONDINHO</b> não pratica Transferências
        Internacionais de dados, como regra. Nenhum dado pessoal será
        transferido para terceiros localizados fora do país, exceto por
        intermédio de consentimento expresso do titular ou para cumprimento de
        obrigação legal, regulatória ou contratual, devidamente informado ao
        titular.
      </p>

      <p className="px-3">
        <b>9.2.</b> Para sermos transparente e garantirmos um serviço de
        qualidade a você e aos nossos parceiros, contratamos alguns sistemas de
        informações que hospedam dados pessoais em nuvens localizadas fora do
        Brasil. Assim, há casos de hospedagem de dados pessoais em outros países
        para que possamos cumprir com nossa missão, como é o caso das empresas
        Google, Microsoft.
      </p>
      <br />

      <strong>10. ARMAZENAMENTO E DESCARTE DE DADOS</strong>
      <br />

      <p className="px-3">
        <b>10.1.</b> Toda informação ou Dado Pessoal fornecido observará os
        padrões de segurança necessários para cumprir com as previsões legais,
        ademais, são empenhados esforços para assegurar que essas informações
        não sejam alvo de destruição, perda, alteração ou difusão. Nós apenas
        armazenamos suas informações pessoais na medida em que precisamos delas
        para poder utilizá-las de acordo com a finalidade para a qual foram
        coletadas e de acordo com a base legal para seu tratamento.
      </p>

      <p className="px-3">
        <b>10.2.</b> Manteremos suas informações pessoais enquanto seu usuário
        em nossa plataforma estiver ativo enquanto for necessário para conclusão
        das transações realizadas com você enquanto houver uma relação
        contratual, comercial ou institucional, ou ainda enquanto você não
        exercer seu direito de suprimir, cancelar ou limitar o processamento de
        seus dados.
      </p>

      <p className="px-3">
        <b>10.3.</b> Inativado seu usuário em nossas plataformas, seus dados
        serão excluídos de nossos sistemas, desde que não haja fundamento para
        armazená-los para fins legítimos, assim definidos em lei, para cumprir
        com obrigações legais ou regulatórias, bem como para exercer ou
        resguardar direitos ou prevenir fraudes.
      </p>

      <p className="px-3">
        <b>10.4.</b> A fim de garantir que as informações e os Dados Pessoais
        sejam retidos apenas pelo tempo necessário, são cumpridos os requisitos
        e regulamentos necessários dos prazos em dispositivos legais e
        contratuais. Portanto, além da constante observância e atualização
        dessas normativas, independente do seu consentimento ou pedido de
        exclusão, a Lei Geral de Proteção de Dados permite conservar os Dados
        Pessoais nas seguintes situações:
      </p>

      <p className="px-3 mx-4">
        <b>a)</b> Cumprimento de obrigação legal ou regulatória;
      </p>
      <p className="px-3 mx-4">
        <b>b)</b> Transferência a terceiro, respeitados os seus direitos e
        previsão nas leis de proteção de dados e privacidade;
      </p>
      <p className="px-3 mx-4">
        <b>c)</b> Para uso exclusivo, vedado acesso por terceiros, e desde que
        anonimizados os dados.
      </p>

      <p className="px-3">
        <b>10.5.</b> Entretanto, há sempre o seu direito de solicitar a
        eliminação dos Dados. Garantimos que analisaremos essa solicitação de
        acordo com os requisitos legais.
      </p>
      <br />

      <strong>11. RESPONSABILIDADE DO USUÁRIO</strong>
      <br />

      <p className="px-3">
        <b>11.1.</b> O usuário é exclusivamente responsável pelo uso da
        aplicação e deverá respeitar as regras deste Aviso de Privacidade, bem
        como a legislação aplicável.
      </p>

      <p className="px-3">
        <b>11.2.</b> O usuário garante a veracidade de todos os dados
        informados, sendo responsável por manter as suas informações pessoais
        atualizadas.
      </p>

      <p className="px-3">
        <b>11.3.</b> O <b>PARQUE BONDINHO</b> não será responsabilizado por
        danos diretos ou indiretos que resultem de, ou que tenham relação com o
        acesso, uso ou a incapacidade de acessar ou utilizar a aplicação.
      </p>

      <p className="px-3">
        <b>11.4.</b> É responsabilidade do usuário manter o seu login e senha
        seguros e fora do alcance de terceiros. Dessa forma, o usuário
        responsabiliza-se por todas as ações realizadas em sua conta.
      </p>

      <p className="px-3">
        <b>11.5.</b> Tendo em vista as características inerentes ao ambiente da
        internet, a aplicação não se responsabiliza por interrupções ou
        suspensões de conexão, transmissões de computador incompletas ou que
        falhem, bem como por falha técnica de qualquer tipo, incluindo, mas não
        se limitando, ao mau funcionamento eletrônico de qualquer rede, hardware
        ou software.
      </p>

      <p className="px-3">
        <b>11.6.</b> É responsabilidade do usuário manter o ambiente de seu
        dispositivo (computador, celular, tablet, entre outros) seguro, com o
        uso de ferramentas disponíveis para garantir a segurança.
      </p>

      <p className="px-3">
        <b>11.7.</b> O usuário declara que é civilmente capaz. Em caso de
        eventual acesso de usuário menor de 18 (dezoito) anos, com ou sem
        autorização do responsável legal, este será responsável por todos os
        atos por aquele praticados, como consta em lei.
      </p>
      <br />

      <strong>12. CANAL DE COMUNICAÇÃO</strong>
      <br />

      <p className="px-3">
        <b>12.1.</b> Em caso de dúvidas, para exercer os seus direitos ou
        qualquer comunicação, o <b>PARQUE BONDINHO</b>, em cumprimento ao artigo
        41 da Lei Geral de Proteção de Dados, indica o contato da Encarregada de
        Dados Pessoais, Louana Costa, através do seguinte e-mail:{' '}
        <a href="mailto:dpo@bondinho.com.br">dpo@bondinho.com.br</a>.
      </p>
      <br />

      <strong>13. LEI APLICÁVEL / FORO</strong>
      <br />

      <p className="px-3">
        <b>13.1.</b> Este Aviso de Privacidade, é regido e interpretado de
        acordo com as Leis da República Federativa do Brasil.
      </p>

      <p className="px-3">
        <b>13.2.</b> Fica eleito o Foro da Comarca do Rio Grande do Sul, como o
        competente para dirimir quaisquer questões porventura oriundas do
        presente documento, com expressa renúncia a qualquer outro, por mais
        privilegiado que seja.
      </p>
      <br />

      <strong>14. ATUALIZAÇÕES DESTE AVISO</strong>
      <br />

      <p className="px-3">
        14.1. O <b>PARQUE BONDINHO</b> se reserva no direito de alterar este
        Aviso de Privacidade sempre que for necessário, para garantir a
        funcionalidade, aprimoramentos e atualizações dos serviços oferecidos,
        não ficando obrigado a manter uma estrutura da aplicação.
      </p>
      <br />

      <p>
        Nosso Aviso de Privacidade sempre contará a indicação da data de
        atualização ou versão atual.
      </p>

      <dl>
        <dd>
          <table className="table-page" width="100%" cellSpacing="0">
            <tbody>
              <tr>
                <td
                  width="55"
                  height="14"
                  style={{ backgroundColor: '#c0c0c0' }}
                >
                  <p className="mb-0 px-2">
                    <span>
                      <strong>Versão</strong>
                    </span>
                  </p>
                </td>
                <td width="156" style={{ backgroundColor: '#c0c0c0' }}>
                  <p className="mb-0 px-2">
                    <span>
                      <strong>Item modificado</strong>
                    </span>
                  </p>
                </td>
                <td width="299" style={{ backgroundColor: '#c0c0c0' }}>
                  <p className="mb-0 px-2">
                    <span>
                      <strong>Motivo</strong>
                    </span>
                  </p>
                </td>
                <td width="113" style={{ backgroundColor: '#c0c0c0' }}>
                  <p className="mb-0 px-2">
                    <span>
                      <strong>Data</strong>
                    </span>
                  </p>
                </td>
              </tr>
              <tr>
                <td width="55" height="4">
                  <p className="my-2 px-2">
                    <span>01</span>
                  </p>
                </td>
                <td width="116">
                  <p className="my-2 px-2">
                    <span>Versão original</span>
                  </p>
                </td>
                <td valign="top" width="299">
                  <p className="my-2 px-2">
                    <span>N/A</span>
                  </p>
                </td>
                <td valign="top" width="113">
                  <p className="my-2 px-2">
                    <span>27/07/2021</span>
                  </p>
                </td>
              </tr>
              <tr>
                <td width="55" height="4">
                  <p className="my-2 px-2">
                    <span>02</span>
                  </p>
                </td>
                <td width="116">
                  <p className="my-2 px-2">
                    <span>Versão original</span>
                  </p>
                </td>
                <td valign="top" width="299">
                  <p className="my-2 px-2">
                    <span>Atualização</span>
                  </p>
                </td>
                <td valign="top" width="113">
                  <p className="my-2 px-2">
                    <span>20/12/2023</span>
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
        </dd>
      </dl>
    </>
  );
}

export default PrivacyNoticeCaracolPT;
