import React from "react";

function WorkUsEN() {
    return (
        <>
            Did you know that the purpose of Parque Bondinho Pão de Açúcar is to inspire happiness in everyone who connects with us?<br />
            <br />
            Know a little more about us:<br />
            <br />
            We are a company with the GPTW Seal (Great Place to Work). This means that we are among the best companies to work for in Brazil!<br />
            <br />
            Far beyond a park, we are an integrated platform for sustainable experiences in tourism, entertainment and media.<br />
            <br />
            Here, we have the feeling of ownership and are intensely motivated and passionate about enchanting people, inspiring smiles and exceeding expectations. Our story began with an innovative vision and that is why we are creative by nature and we are always at the forefront of engineering and technology. We are also constantly concerned about everyone's safety and well-being. Therefore, we are a company that thinks and promotes sustainable actions for social, environmental, cultural and economic development. In our daily lives, we act with integrity, with commitment, respect, honesty, transparency and ethics. In addition, we are focused on extraordinary results with clear goals.<br />
            <br />
            Diversity: We have a vocation to welcome everyone with open arms, respecting individualities. We privilege the diverse, and inclusion is our keyword. We look for talent, regardless of gender, race, creed or disability, for all our opportunities.<br />
            <br />
            See what our benefits are:<br />

            • Transportation vouchers;<br />

            • Restaurant in Morro da Urca with a varied menu, including vegetarian options. Yes, you can take the cable car to lunch when you are there!;<br />

            • Food voucher;<br />

            • Prudential life insurance;<br />

            • SulAmérica medical assistance, extended to spouse and children;<br />

            • SulAmérica Dental Assistance, also extended to spouse and child;<br />

            • Profit Sharing Program (PPR) of up to 2 salaries - this bonus is conditioned to the achievement of the company's goals according to current policy;<br />

            • Childcare assistance;<br />

            • Reimbursement of school supplies.<br />
            <br />
            And there's even more:<br />

            • Gympass;<br />

            • Day Off on birthday 🥳;<br />

            • 02 (two) gratuities per month at Parque Bondinho Pão de Açúcar 🧡;<br />

            • Discounts on services and products from partner companies;<br />

            • Maternity leave is 180 days and paternity leave is 20 days;<br />

            • Maternity and paternity kit.<br />
            <br />
            So, do you want to board with us on this one?<br />
            <br />
            <a href="https://grupoiter.gupy.io/" target="__blank">Click here to learn about our opportunities and sign up!</a>
        </>
    )
}

export default WorkUsEN;