import Button from "@restart/ui/esm/Button";
import React from "react";
//import { Container, Row, Col } from 'react-bootstrap';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Modal from "react-bootstrap/Modal";
import "./ModalDefault.scss";
//import FormLogin from '../../Authenticated/FormLogin';
//import Button from 'react-bootstrap/Button';
//import Form from 'react-bootstrap/Form';

/* const ModalDefault: FC<{}> = ({ children }: any) => { */
function ModalDefault(props: any) {
    const [modalShow, setModalShow] = React.useState(false);
    const customClasses: string = "modal-custom modalAuth " + props.class;

    function Leave() {
        if (window.location.pathname === '/nova-senha' && props.sucess === 'Alterado  com sucesso') {
            window.location.href = "/login";
        } else { }
        if (window.location.pathname === '/nova-conta' && props.sucess === 'Cadastro realizado com sucesso, favor verificar seu e-mail para confirmar o cadastro.') {
            window.location.href = "/login";
        } else { }

    }

    return (
        <div className="w-auto px-0">
            <Modal
                {...props}
                className={customClasses}
                show={modalShow}
                onHide={() => {
                    setModalShow(false)
                    Leave()
                }}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
            >
                <>
                    {
                        props.header === "no"
                            ?
                            ""
                            :
                            <Modal.Header closeButton></Modal.Header>
                    }
                    {props.children}
                    {
                        props.btnLeave !== "yes"
                            ?
                            ""
                            :
                            props.modalContent !== "loading"
                                ?
                                <div className="btn-custom-modal d-flex justify-content-center">
                                    <button
                                        className="btn btn-primary"
                                        onClick={() => {
                                            setModalShow(false)
                                            Leave()
                                            //props.setLoading(false)
                                        }}>
                                        OK
                                    </button>
                                </div>
                                :
                                ""
                    }
                </>
            </Modal>

            <div className={props.class ? props.class : ""}>
                <Button type="submit" className="btn btn-primary" onClick={() => setModalShow(true)}>
                    {props.loading === true
                        ?
                        <div className="load small white"></div>
                        :
                        props.name
                            ?
                            props.name
                            :
                            "Default Button"
                    }
                    {
                        props.hasIcon === "yes"
                            ?
                            <FontAwesomeIcon
                                icon={["fal", props.icon]}
                                size="2x"
                                style={{ margin: "0px 5px 5px 0px", color: "#ff6600" }}
                            />
                            :
                            ""
                    }
                </Button>
            </div>
        </div >
    );
}

export default ModalDefault;
