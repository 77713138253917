import React from 'react';

import { useTranslation } from "react-i18next";

import './Cookies.scss';
import PageDafault from '../../templates/PageDafault';
import i18next from 'i18next';
import { Helmet } from 'react-helmet';

function Cookies() {
  const { t } = useTranslation();
  
  return (
    <>
      <Helmet>
        <html lang={i18next.language} />
        <title>Política de Cookies | Parque Bondinho</title>
        <meta name="description" content="Entenda o objetivo, qual a abrangência , tecnologia e política de cookies do Parque Bondinho e mais. Acesse nosso site e confira!" />
        <meta name="googlebot" content={process.env.REACT_APP_SERVER_ROBOTS} />

        {/* Essential META Tags */}
        <meta property="og:title" content="Política de Cookies | Parque Bondinho" />
        <meta property="og:type" content="TouristAttraction" />
        <meta property="og:image" content={`${process.env.REACT_APP_SERVER_URL}${process.env.REACT_APP_SERVER_LOGO}`} />
        <meta property="og:url" content={process.env.REACT_APP_SERVER_URL} />

        {/* Non-Essential, But Recommended */}
        <meta property="og:description" content="Entenda o objetivo, qual a abrangência , tecnologia e política de cookies do Parque Bondinho e mais. Acesse nosso site e confira!" />
        <meta property="og:site_name" content={process.env.REACT_APP_SERVER_NAME} />

        <meta name="robots" content={process.env.REACT_APP_SERVER_ROBOTS} />
        <link rel="canonical" href={process.env.REACT_APP_SERVER_URL} />
      </Helmet>

      <PageDafault>
        <div className="container py-5">
          <h1 className="mb-5 text-center display-2">{t('cookies.title')}</h1>
          <div className="card py-4 px-3">
            <div dangerouslySetInnerHTML={{ __html: t('cookies.text') }}>
            </div>
          </div>
        </div>
      </PageDafault>
    </>
  );
}

export default Cookies;