import React from "react";

function WorkUsPT() {
    return (
        <>
            Você sabia que o propósito do Parque Bondinho Pão de Açúcar é inspirar felicidade em todos que se conectam conosco?<br />
            <br />
            Somos uma empresa com o Selo GPTW (Great Place to Work). Isso significa que estamos entre as melhores empresas para se trabalhar no Brasil!<br />
            <br />
            Muito além de um parque, somos uma plataforma integrada de experiências sustentáveis em turismo, entretenimento e mídia.<br />
            <br />
            Por aqui, temos o sentimento de dono e somos intensamente motivados e apaixonados por encantar pessoas, inspirar sorrisos e superar expectativas. A nossa história começou através de uma visão inovadora e, por isso, somos criativos por natureza e estamos sempre à frente na engenharia e tecnologia. Também estamos constantemente preocupados com a segurança e o bem-estar de todos. Diante disso, somos uma empresa que pensa e promove ações sustentáveis para o desenvolvimento social, ambiental, cultural e econômico. Em nosso dia a dia agimos com integridade, tendo compromisso, respeito, honestidade, transparência e ética. Além disso, somos focados em resultados extraordinários com objetivos claros.<br />
            <br />
            Diversidade: Temos vocação em acolher a todos e todas de braços abertos, respeitando individualidades. Privilegiamos o diverso, e inclusão é nossa palavra-chave. Buscamos talentos, independente de gênero, raça, credo ou deficiência, para todas as nossas oportunidades.<br />
            <br />
            Veja quais são os nossos benefícios:<br />

            • Vale Transporte;<br />

            • Restaurante no Morro da Urca local com cardápio variado, inclusive com opções vegetarianas. Sim, você anda de bondinho para almoçar, quando estiver por lá!;<br />

            • Vale Alimentação;<br />

            • Seguro de vida Prudential;<br />

            • Assistência médica SulAmérica, extensivos a cônjuge e filhos;<br />

            • Assistência Odontológica SulAmérica, também extensivos a cônjuge e filho;<br />

            • Programa de Participação nos Resultados (PPR) de até 2 salários - este bônus está condicionado ao alcance das metas da empresa conforme política vigente;<br />

            • Auxílio Creche;<br />

            • Reembolso de material escolar.<br />
            <br />
            E ainda tem mais:<br />

            • Gympass;<br />

            • Day Off no aniversário 🥳;<br />

            • 02 (duas) gratuidades por mês no Parque Bondinho Pão de Açúcar 🧡;<br />

            • Descontos em serviços e produtos de empresas parceiras;<br />

            • Licença maternidade é de 180 dias e a paternidade de 20 dias;<br />

            • Kit maternidade e paternidade.<br />
            <br />
            E aí, topa embarcar com a gente nessa?<br />
            <br />
            <a href="https://grupoiter.gupy.io/" target="__blank">Clique aqui para conhecer as nossas oportunidades e inscreva-se!</a>
        </>
    )
}

export default WorkUsPT;