import React, { useState, useEffect } from "react";

import api from '../../services/api';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import PageDafault from "../../templates/PageDafault";

import './Confirmation.scss';
import { Button } from "react-bootstrap";
import monitoringAPI from "../../services/monitoring";
//import { Link } from "react-router-dom";

type Request = {
    "token": string | undefined,
} | undefined

function Confirmation() {
    const [sucess, setSucess] = useState<any>("Processando...");
    const [log, setLog] = useState<any>();
    //const [modalContent, setModalContent] = useState<any>();

    /* Enviar para Home */
    const onConfirm = () => {
        window.location.href = "/login";
    };

    useEffect(() => {
        setTimeout(() => {
            window.location.href = "/login";
        }, 45000);
    }, []);
    /* END - Enviar para Home */

    /* Confirmação de Email */
    useEffect(() => {
        setTimeout(() => {
            const getConfirmAccount = async () => {
                let req: Request = {
                    "token": window.location.href.split('confirmacao-cadastro/')[1],
                }

                try {
                    const { data } = await api.post('api/LoginSite/ConfirmAccount', req);
                    if (data.statusCode !== 400) {
                        setSucess(data.data.texto);
                        setLog(data.data.log);
                        monitoringAPI(200, 'LoginSite', 'ConfirmAccount', data?.data?.texto || 'Sucesso', '', '/api/LoginSite/ConfirmAccount', req);
                    }
                    monitoringAPI(400, 'LoginSite', 'ConfirmAccount', data?.data?.texto || 'Retornado status diferente de 200', '', '/api/LoginSite/ConfirmAccount', req);
                } catch (error: any) {
                    monitoringAPI(400, 'LoginSite', 'ConfirmAccount', error?.response?.data?.errorMessage || 'Retornado status diferente de 200', '', '/api/LoginSite/ConfirmAccount', req);
                    if (error?.response?.status === 400) { }
                }
            }

            getConfirmAccount();
        }, 500);
    }, []);
    /* END - Confirmação de Email */

    return (
        <>
            <PageDafault>
                <div className="container py-5">
                    <div className="card card-confirmation py-5 px-3">
                        <div>
                            {
                                log === 1 || log === "1"
                                    ?
                                    <FontAwesomeIcon
                                        icon={["fal", "times-circle"]}
                                        size="5x"
                                        style={{ fontSize: "7.5em", color: "#FF6600" }}
                                    />
                                    :
                                    log === 0 || log === "0"
                                        ?
                                        <FontAwesomeIcon
                                            icon={["fal", "check-circle"]}
                                            size="5x"
                                            style={{ fontSize: "7.5em", color: "#FF6600" }}
                                        />
                                        :
                                        <div className="text-center">
                                            <div className="load"></div>
                                        </div>
                            }
                        </div>
                        <div>
                            <h5>{sucess}</h5>
                        </div>
                        <div>
                            <Button onClick={onConfirm}>
                                Ok
                            </Button>
                        </div>
                    </div>
                </div>
            </PageDafault>
        </>
    )
}

export default Confirmation;