import DatePicker from "react-multi-date-picker"
import i18next from "i18next";

import { useTranslation } from "react-i18next";

import configData from "../../config/config.json";

import "react-multi-date-picker/styles/layouts/mobile.css";

import './Calendar.scss';

const weekDays = i18next.language === "en" ? ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"] : i18next.language === "es" ? ["Dom", "Lun", "Mar", "Mie", "Jue", "Vie", "Sab"] : ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sab"];

const months = i18next.language === "en" ? ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"] : i18next.language === "es" ? ["Enero", "Febrero", "Marzo", "April", "Mayo", "Junio", "July", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"] : ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"];


const CustomMultipleInput = ({ openCalendar, value, setValue, defaultTime }: any) => {
  console.log(value)
  const { t } = useTranslation();

  if (!value) {
    value = defaultTime;
  }

  setValue(value)

  function setTranslate() {
    document.querySelectorAll('.rmdp-week-day').forEach((item) => {
      item.setAttribute("translate", "no");
    })
  }

  return (
    <input
      className="form-control bg-white rounded d-block"
      onFocus={openCalendar}
      value={value}
      readOnly
      placeholder={t("productOptions.when")}
    />
  )
}


const SingleCalendar = (props: any) => {
  return (
    <DatePicker
      //   value={value}
      //   onChange={setValue}
      weekDays={weekDays}
      months={months}
      maxDate={new Date()}
      /* hideOnScroll */
      format={configData.DATE.PT}
      className={window.innerWidth <= 768 ? "rmdp-mobile multi-locale-days" : "multi-locale-days"}
      render={<CustomMultipleInput 
        setValue={props.setValue}
        defaultTime={props.defaultTime} 
        />}
    />
  )
}

export default SingleCalendar;



