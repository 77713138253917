import Button from "@restart/ui/esm/Button";
import React from "react";
//import { Container, Row, Col } from 'react-bootstrap';

import Modal from "react-bootstrap/Modal";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

//import FormLogin from '../../Authenticated/FormLogin';
//import Button from 'react-bootstrap/Button';
//import Form from 'react-bootstrap/Form';

/* const ModalDefault: FC<{}> = ({ children }: any) => { */
function ModalPrivacy(props: any) {

    const [modalShow, setModalShow] = React.useState(props.open);

    function handleCheck() {
        let checked: any = document.querySelector('#chackPolice')
        checked.checked = true;
        props.setValue('checkPolice', true);
        setModalShow(false)
        props.setPrivacy(true);
    }

    function handleClose() {
        let checked: any = document.querySelector('#chackPolice')
        checked.checked = false;
        props.setValue('checkPolice', false);
        setModalShow(false);
        props.setPrivacy(false);
    }

    return (
        <>
            <Modal
                {...props}
                className="modal-custom modalAuth"
                show={modalShow}
                onHide={() => setModalShow(false)}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered={props.center === "yes" ? true : false}
            >
                <>
                    <Modal.Header className="text-uppercase" closeButton>
                        {props.header ? <h4>{props.header}</h4> : ""}
                    </Modal.Header>
                    <Modal.Body className={props.classBody ? props.classBody : ""}>
                        {props.children}
                    </Modal.Body>

                    {
                        props.footer === "yes"
                            ?
                            <Modal.Footer>
                                <Button className="btn btn-outline-primary" onClick={handleClose}>{props.close ? props.close : ""}</Button>
                                <Button className="btn btn-primary" onClick={handleCheck}>{props.save ? props.save : ""}</Button>
                            </Modal.Footer>
                            :
                            ""
                    }
                </>
            </Modal>

            {
                props.type === "privacy"
                    ?
                    <span onClick={() => setModalShow(true)} className="privacy-check">
                        {props.name}
                    </span>
                    :
                    <Button className="btn" onClick={() => setModalShow(true)} style={{ boxShadow: "none" }}>
                        {
                            <FontAwesomeIcon
                                icon={["fal", props.name]}
                                size="2x"
                                style={{ margin: "0", color: "#163264", fontSize: "20px" }}
                            />
                        }
                    </Button>
            }

        </>
    );
};

export default ModalPrivacy;
